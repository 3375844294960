import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid';
import { Paper, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TextField } from 'material-ui';
import { couponRestApi } from '../config';
import {orange500, blue500} from 'material-ui/styles/colors';

import { numberFormat } from '../filters';
import CouponBreadCrumbs from "./CouponBreadcrumbs";
export const styles = {
    color: 'rgb(255, 64, 129)',
    textFieldStyle: {
        color: orange500
      },
      underlineStyle: {
        borderColor: blue500,
      },
      filterFieldWidth:{
          width:'200px'
      }
}

// equivalent of pipe/filter in Angular
export const number = (value) => {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
};

export const CouponItem = ( coupon ) => {
  let numAvailable = coupon.statistics.numCodesDefined - coupon.statistics.numCodesAllocated;

  let dateFormater = (dateStr) => {
    if (dateStr) {
      let date = new Date(dateStr);
      return date.toLocaleDateString();
    }
  };

  let endsDateTime = new Date(coupon.endsOn).getTime();
  let todaysTime = new Date().getTime();
  let isexpired = todaysTime > endsDateTime ? true : false;
  return (
    <TableRow key={coupon.id}>
      <TableRowColumn width="75px" className="hide-mobile">{coupon.prefix}</TableRowColumn>
      <TableRowColumn>
        <Link to={"/coupons/" + coupon.id}>
          { coupon.name }
        </Link>
      </TableRowColumn>
      <TableRowColumn width="90px" className="hide-mobile">{dateFormater(coupon.endsOn)}</TableRowColumn>
      <TableRowColumn width="90px" className="hide-mobile hide-tablet">{dateFormater(coupon.startsOn)}</TableRowColumn>
      <TableRowColumn width="90px" className="hide-mobile">{!!coupon.statistics ? numberFormat(coupon.statistics.numCodesDefined) : '0'}</TableRowColumn>
      <TableRowColumn width="90px" className="hide-mobile hide-tablet">{!!coupon.statistics ? numberFormat(numAvailable) : '0'}</TableRowColumn>
  {isexpired && <TableRowColumn width="90px" className="hide-mobile hide-tablet"> <RaisedButton secondary={true} style={styles}> Archive </RaisedButton> </TableRowColumn> }

    </TableRow>
  )
};

export const CouponList = ({ onRefresh, coupons }) => {

  const couponList = coupons.map(CouponItem);
  return (
    <Paper>
      <Toolbar>
        <ToolbarGroup className="hide-mobile" firstChild={false}>
          <ToolbarTitle text="Coupons" />
          <TextField
            hintText="Prefix Filter"
            underlineStyle={styles.underlineStyle }
            style={styles.filterFieldWidth}
            />
        </ToolbarGroup>
        <ToolbarGroup>

          <RaisedButton label="Refresh" onClick={onRefresh}/>
        </ToolbarGroup>
      </Toolbar>
      <Table fixedHeader={true}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn width="75px" className="hide-mobile">Prefix</TableHeaderColumn>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn width="90px" className="hide-mobile">Expiration:</TableHeaderColumn>
            <TableHeaderColumn width="90px" className="hide-mobile hide-tablet">Start:</TableHeaderColumn>
            <TableHeaderColumn width="90px" className="hide-mobile"># Total</TableHeaderColumn>
            <TableHeaderColumn width="90px" className="hide-mobile hide-tablet"># Available</TableHeaderColumn>
            <TableHeaderColumn width="90px" className="hide-mobile hide-tablet"># Coupon Action</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {couponList}
        </TableBody>
      </Table>
    </Paper>
  );
};


export const Coupons = ({ onRefresh, coupons }) => (
  <div>
    <CouponBreadCrumbs type="couponsmanager"/>
    {coupons &&
      <Row>
        <Col xs={12}>
          <CouponList coupons={coupons} onRefresh={onRefresh}/>
        </Col>
      </Row>
    }
  </div>
);

class CouponContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { coupons: []};
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  handleRefreshClick(e){
    this.refreshList();
  }

  refreshList(){
    let self = this;
    couponRestApi.get('/coupons?loadStatistics=true&limit=100').then(({data}) => {
      self.setState(Object.assign({}, self.state, { coupons: data }));
    }).catch((error) => {
      console.log("Get coupons failed: " + error);
    });
  }

  render() {
    return (
      <Coupons
        onRefresh={this.handleRefreshClick}
        coupons={this.state.coupons}
      />
    );
  }
}

export default CouponContainer;
