  let ColumnDefs = [
    {headerName: "Agency", field: "agency", filter: true},
    {headerName: "M/F", field: "gender", width:100},
    {headerName: "First Name", field: "firstName", filter: true},
    {headerName: "Last Name", field: "lastName", filter: true},
    {headerName: "Height", field: "height", width:100},
    {headerName: "Bust", field: "bust", width: 100},
    {headerName: "Waist", field: "waist", width: 100},
    {headerName: "Hips", field: "hips", width: 100},
    {headerName: "Inseam", field: "inseam", width: 100},
    {headerName: "Shoe", field: "shoe", width: 100},
    {headerName: "Size", field: "size", width: 100},
    {headerName: "Note 1", field: "note1", width: 200, filter: true},
    {headerName: "Note 2", field: "note2", width: 200, filter: true}
  ];

  export default ColumnDefs;
