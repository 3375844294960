import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import store from '../services/store';
import localStorage from '../services/localStorage';

function setHistory(rest){
  let state = localStorage.get('history');
  let history = state && state.length ? state : [];
  history.push(rest.location.pathname);
  localStorage.set('history', history);
}

export const PrivateRoute = ({ component, ...rest }) => {
  let state = store.getState();
  let profile = state.userState ? state.userState.profile : null;
  setHistory(rest);
  return (
    <Route {...rest} render={props => (
      profile && profile.isLoggedIn ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
      )
    )}/>
  )
};
