import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import MoreVert from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar'
import NavMenuItem from './NavMenuItem';
import { Typography, IconButton, Menu } from '@material-ui/core';
import { updateProfile, logout } from '../../../api/userApi';

function NavMenu ({ classes, profile }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Avatar alt={profile.user.firstName} src={profile.user.image72}/>
      <Typography variant='h5' className={classes.userName}>
        {profile.user.firstName} {profile.user.lastName}
      </Typography>
      <Fragment>
        <IconButton
          aria-label='account of current user'
          aria-controls='menu-navbar'
          aria-haspopup='true'
          onClick={handleMenu}
          className={classes.toolGroupTwo}
        >
          <MoreVert className={classes.icon} />
        </IconButton>
        <Menu
          id='menu-navbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={handleClose}
        >
          {/* Div needed to receive ref from Menu Component */}
          <div>
            <NavMenuItem icon='user-alt' title='Update Profile' func={updateProfile} />
            <NavMenuItem icon='sign-out-alt' title='Logout' func={logout} />
          </div>
        </Menu>
      </Fragment>
    </Fragment>
  )
}

export default NavMenu

NavMenu.propTypes = {
  classes: PropTypes.object,
  profile: PropTypes.object
}
