import * as types from '../actions/actionTypes';

const initialState = {
  order: null,
  loading: false
};

const orderHistoryReducer = function(state = initialState, action) {
  switch(action.type) {
    case types.SEARCH_ORDER_HISTORY_SUCCESS:
      return Object.assign({}, state, { order: action.order });

    case types.SEARCH_ORDER_HISTORY_FAILED:
      return Object.assign({}, state, { order: {}, orderFailed: action.error });

    case types.GET_ORDER_HISTORY_COUNT_SUCCESS:
      return Object.assign({}, state, { count: action.count });

    case types.GET_ORDER_HISTORY_COUNT_FAILED:
      return Object.assign({}, state, { count: {}, countFailed: action.error });

    case types.GET_RECENT_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, { orderStatus: action.orders });

    case types.GET_RECENT_ORDER_STATUS_FAILED:
      return Object.assign({}, state, { orderStatus: {}, recentOrdersFailed: action.error });

    case types.SEARCH_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, { orderStatus: [action.order] });

    case types.SEARCH_ORDER_STATUS_FAILED:
      return Object.assign({}, state, { orderStatus: {}, searchOrderStatusFailed: action.error });

    default:
      return state;
  }
};

export default orderHistoryReducer;
