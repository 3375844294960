import * as types from '../actions/actionTypes';

const initialState = {
  notifyMeStats: [],
  notifyMeRecords: [],
  loading: false
};

const vipReducer = function(state = initialState, action) {
    switch(action.type) {
      case types.GET_NOTIFY_ME_STATS_SUCCESS:
        return Object.assign({}, state, { notifyMeStats: action.notifyMeStats });

      case types.GET_NOTIFY_ME_STATS_FAILED:
        return Object.assign({}, state, { notifyMeStats: {}, notifyMeStatsFailed: action.error });

      case types.GET_NOTIFY_ME_SITES_SUCCESS:
        return Object.assign({}, state, { notifyMeSites: action.notifyMeSites });

      case types.GET_NOTIFY_ME_SITES_FAILED:
        return Object.assign({}, state, { notifyMeSites: {}, notifyMeSitesFailed: action.error });

      case types.SEARCH_NOTIFY_ME_RECORDS_SUCCESS:
        return Object.assign({}, state, { notifyMeRecords: action.notifyMeRecords });

      case types.SEARCH_NOTIFY_ME_RECORDS_FAILED:
        return Object.assign({}, state, { notifyMeRecords: {}, notifyMeRecordsFailed: action.error });

      case types.SET_NOTIFY_ME_ITEMS_PER_PAGE:
        return Object.assign({}, state, { perPage: action.perPage });

      case types.SET_NOTIFY_ME_SITE_ID:
        return Object.assign({}, state, { notifyMeSiteId: action.siteId });

      case types.GET_LOADER_STATUS:
        return Object.assign({}, state, { loading: action.loading });

        default:
            return state;
  }
};

export default vipReducer;
