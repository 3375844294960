import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class AdminJobBreadCrumbs extends Component {

  render() {
    const { job } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            <span>Admin</span>
          </div>
          <div className="breadcrumb">
            { job &&
            <Link to="/admin/jobs">Jobs</Link>
            }
            { !job &&
            <span>Jobs</span>
            }
          </div>
          { job &&
          <div className="breadcrumb">
            <span>{job.id}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}

AdminJobBreadCrumbs.propTypes = {
  job: PropTypes.object
};
