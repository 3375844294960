import React, { Component } from 'react';
import { TextField, RaisedButton, FlatButton } from 'material-ui';

class ProductImageSearchFormContainer extends Component {

  render() {
    const { userInput, submitting, setInput, getProductIds, clearResults } = this.props;
    const copy = {
      searchText    : "Product Ids",
      submit        : "Search",
      clear         : "Clear"
    };
    return (
      <div>
        <TextField
          multiLine={true}
          rowsMax={5}
          fullWidth={true}
          hintText={`Enter ${copy.searchText}`}
          floatingLabelText={copy.searchText}
          floatingLabelFixed={true}
          value={userInput}
          onChange={setInput}
          disabled={submitting}
        />
        <div>
          <RaisedButton
            label={copy.submit}
            primary={true}
            onClick={getProductIds}
            disabled={submitting}
            fullWidth={true}
          />
          <FlatButton
            label={copy.clear}
            onClick={clearResults}
            disabled={submitting}
            fullWidth={true}
          />
        </div>
      </div>
    );
  }
}

export default ProductImageSearchFormContainer;
