import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import "ag-grid-community/dist/styles/ag-theme-material.css";
import ColumnDefs from "./PhotoStudioTableHeader"
import { connect } from 'react-redux';
import store from '../services/store';
import { setSelectedModels, queryModelData } from "../actions/modelProfileActions";
import { getModelsList } from "../api/modelProfileApi";
import { withRouter } from 'react-router-dom';
class PhotoStudioOptions extends Component {
    constructor(props) {
        super(props);
        this.state = { rowData: [] };
        this.selectedRowDataToEdit = {};
        this.viewModelCode = this.viewModelCode.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.gridApi = "";
    }
    viewModelCode(e, data) {
        e.preventDefault();
        store.dispatch(setSelectedModels([data]));
        this.props.history.push(`/photostudio/view/${data.modelCode}`);
    }
    componentDidMount() {
        store.dispatch(setSelectedModels([]));
        store.dispatch(queryModelData([]));
        getModelsList();
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.selectedModels!==this.props.selectedModels && nextProps.selectedModels.length === 0){
        this.gridApi && this.gridApi.deselectAll();
      }
    }

    onGridReady(params) {
        this.gridApi = params.api;
    };
    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        store.dispatch(setSelectedModels(selectedRows))
    }
    onFilterChanged(){
        store.dispatch(setSelectedModels([]));
    }
    render() {
        const modelCodeHeader = [
            {
                headerName: "All Select",
                width: 150,
                suppressSorting: true,
                headerCheckboxSelection: true,
                checkboxSelection: true
            },
            {
                headerName: "Model Code",
                field: "modelCode",
                filter: true,
                width: 150,
                cellRendererFramework: (params) => {
                    return <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.viewModelCode(e, params.data);
                    }
                    } data={params.data}>{params.data.modelCode}  </a>
                }
            }]
        return (
            <div
                className="ag-theme-material"
                style={{
                    height: '580px',
                }}
            >
                <AgGridReact
                    defaultColDef={{ sortable: true }}
                    columnDefs={[...modelCodeHeader, ...ColumnDefs]}
                    rowData={this.props.queryModelData.length > 0 ? this.props.queryModelData : this.props.modelsList}
                    pagination={true}
                    rowSelection="multiple"
                    paginationPageSize={10}
                    onGridReady={this.onGridReady}
                    onFilterChanged={this.onFilterChanged}
                    suppressRowClickSelection={true}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    icons={{ menu: '<i class="fa fa-filter" style="width: 10p; opacity: 1"/>' }}
                />
            </div>
        )
    }
}
const mapStateToProps = function (store) {
    return {
        profile: store.userState.profile,
        modelsList: store.photoStudioState.modelsList,
        selectedModels: store.photoStudioState.selectedModels,
        queryModelData: store.photoStudioState.queryModelData
    };
};
export default withRouter(connect(mapStateToProps, {})(PhotoStudioOptions));
