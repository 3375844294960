import React, { Component } from 'react';
import { PageHeader } from '../Components';
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid';
import { Paper, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, Tabs, Tab, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';
import { restApi } from '../config';
import AdminJobBreadCrumbs from "./AdminJobBreadcrumbs";

export const AdminJobItem = ( job ) => {

  return (
    <TableRow key={job.id}>
      <TableRowColumn>
        <Link to={"/admin/jobs/" + job.id} style={{width:'250px'}}>
          { job.type }
        </Link>
      </TableRowColumn>
      <TableRowColumn>{job.handler}</TableRowColumn>
      <TableRowColumn>{job.status}</TableRowColumn>
      <TableRowColumn>{job.statusOn ? "" + job.statusOn : ""}</TableRowColumn>
      <TableRowColumn>{job.createdBy}</TableRowColumn>
    </TableRow>
  )
};

export const AdminJobList = ({ onRefresh, jobs, filterStatus, currentStatus }) => {
  if (currentStatus !== 'all')
    jobs = jobs.filter(j => j.status === currentStatus);
  const adminJobList = jobs.map(AdminJobItem);
  const filterByAll = function(){filterStatus('all')};
  const filterByCreated = function(){filterStatus('created')};
  const filterByStarted = function(){filterStatus('started')};
  const filterByCompleted = function(){filterStatus('completed')};
  const filterByFailed = function(){filterStatus('failed')};
  return (
    <Paper>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <ToolbarTitle text="Jobs" />
        </ToolbarGroup>
        <ToolbarGroup>
          <RaisedButton label="Refresh" onClick={onRefresh} />
        </ToolbarGroup>
      </Toolbar>
      <Tabs value={currentStatus}>
        <Tab label="All" onActive={filterByAll} value="all" />
        <Tab label="Created" onActive={filterByCreated} value="created" />
        <Tab label="Started" onActive={filterByStarted} value="started" />
        <Tab label="Completed" onActive={filterByCompleted} value="completed" />
        <Tab label="Failed" onActive={filterByFailed} value="failed" />
      </Tabs>
      <Table fixedHeader={true}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>Type</TableHeaderColumn>
            <TableHeaderColumn>Handler</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Last Update</TableHeaderColumn>
            <TableHeaderColumn>Created By</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {adminJobList}
        </TableBody>
      </Table>
    </Paper>
  );
};


export const AdminJobs = ({ onRefresh, jobs, filterStatus, currentStatus }) => (
  <div>
    <PageHeader>Jobs</PageHeader>
    <Row>
      <Col xs={12}>
        <AdminJobList jobs={jobs} onRefresh={onRefresh} filterStatus={filterStatus} currentStatus={currentStatus}/>
      </Col>
    </Row>
  </div>
);

class AdminJobsContainer extends Component {
  constructor(props) {
    super();
    this.state = { jobs: [], status: 'all'};
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.filterStatus = this.filterStatus.bind(this);
    this.refreshList();
  }

  handleRefreshClick(e){
    this.refreshList();
  }

  filterStatus(status){
    let self = this;
    this.setState(Object.assign({}, self.state, { status: status }))
  }

  refreshList(){
    let self = this;
    restApi.get('/jobs').then(({data}) => {
      data.forEach(j => {
        switch(j.status) {
          case "created":
            j.statusOn = new Date(j.createdOn);
            break;
          case "started":
            j.statusOn = new Date(j.startedOn);
            break;
          case "completed":
            j.statusOn = new Date(j.completedOn);
            break;
          case "failed":
            j.statusOn = new Date(j.failedOn);
            break;
          default:
        }
      });
      self.setState(Object.assign({}, self.state, { jobs: data.sort((a, b) => b.statusOn.getTime() - a.statusOn.getTime()) }));
    }).catch((error) => {
      console.log("Get jobs failed: " + error);
    });
  }

  render() {
    return (
      <div>
        <AdminJobBreadCrumbs/>
        <AdminJobs
          onRefresh={this.handleRefreshClick}
          jobs={this.state.jobs}
          filterStatus={this.filterStatus}
          currentStatus={this.state.status}
        />
      </div>
    );
  }
}

export default AdminJobsContainer;
