import React from 'react';
import CouponsManager from './CouponsManager';
import { Route, Switch } from 'react-router-dom'

const CouponManagerRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/couponsmanager" component={CouponsManager}/>
      {/* <Route path="/coupons/:id" component={CouponDetails}/> */}
    </Switch>
  </div>
);

export default CouponManagerRoutes;
