import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class VipBreadCrumbs extends Component {

  render() {
    const { id, siteId } = this.props;
    const title = (siteId === "Prana_US" ? "Influencer" : "Pro Purchase");
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            { id &&
            <Link to="/vip">{title}</Link>
            }
            { !id &&
            <span>{title}</span>
            }
          </div>
          { id &&
            <div className="breadcrumb">
              <span>{id}</span>
            </div>
          }
        </div>
      </nav>
    )
  }
}
