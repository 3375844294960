import React from 'react';
import { Route, Switch } from 'react-router-dom'
import SEOLint from './SEOLint';

const SEOLintRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/seolint" component={SEOLint}/>
    </Switch>
  </div>
);

export default SEOLintRoutes;
