import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@material-ui/core';
import CreateSandbox from '../../buttons/CreateSandbox';
import DeleteSandbox from '../../buttons/DeleteSandbox';
import UpdateSandbox from '../../buttons/UpdateSandbox';
import { hasRoles } from '../../../../api/userApi';

function SandboxActionButtons({ classes, onRefresh }) {

  return (
    <ButtonGroup variant='contained'>
      { hasRoles('admin:sandbox') &&
      <CreateSandbox onRefresh={onRefresh} classes={classes}/>
      }
      { hasRoles('write:sandbox') &&
      <UpdateSandbox onRefresh={onRefresh} classes={classes}/>
      }
      { hasRoles('delete:sandbox') &&
      <DeleteSandbox onRefresh={onRefresh} classes={classes}/>
      }
      <Button onClick={onRefresh} className={classes.buttonCanvas} color='secondary'>Refresh</Button>
    </ButtonGroup>
  )
}

SandboxActionButtons.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func
}

export default SandboxActionButtons
