import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { Paper, SelectField, MenuItem, TextField, ToolbarGroup, ToolbarTitle, Toolbar, RaisedButton } from 'material-ui';
import DashboardBreadCrumbs from './DashboardBreadcrumbs';
import { css } from 'react-emotion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { grey500 } from 'material-ui/styles/colors';
import { getEnvironment } from '../api/dashboardApi';
const tableContainer = css`
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  width: 100%;
`;
const tableStyle = css`
  border-collapse: collapse;
  color: rgb(0,0,0,0.5);
  border: 1px solid #e8e8e8;
  width: 100%;
  padding: 10px;
`;
const tableThStyle = css`
  border-collapse: collapse;
  color: rgb(0,0,0,0.5);
  border: 1px solid #e8e8e8;
  background-color: rgb(232, 232, 232);
  width: 100%;
  font-weight: normal;
  padding: 15px;
`;
export const styles = {
  container: {
    padding: '20px'
  },
  paddingTop20: {
    padding: '20px 0 0 0'
  },
  icon: {
    marginRight: '20px',
    color: grey500,
  }
};
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { apiService: null };
  }
  handleAPIServiceChange = (event, index, value) => {
    this.setState({ apiService: value });
    this.refreshList(value);
  };
  async refreshList(service) {
    getEnvironment(service);
  }
  render() {
    let response = this.props.dashboardInfo;
    let completedOn = null;
    let buildNumber = null;
    let hash = null;
    if (response && response.values.length > 0) {
      response.values.forEach(item => {
        if (item.completed_on) {
          completedOn = new Date(item.completed_on);
        } else {
          completedOn = 'Production Deployments have not been enabled in this repo.';
        }
        if (item.build_number) {
          buildNumber = item.build_number;
        } else {
          buildNumber = 'Could not obtain Build Number as Deployments have not been enabled.';
        }
        if (item.hash) {
          hash = item.hash;
        } else {
          hash = 'Could not obtain commit hash as Deployments have not been enabled.';
        }
      });
    }
    else if (response && (response.type === "error" || response.values.length === 0)) {
      completedOn = 'Production Deployments have not been enabled in this repo.';
      buildNumber = 'Could not obtain Build Number as Deployments have not been enabled.';
      hash = 'Could not obtain commit hash as Deployments have not been enabled.';
    } else if (!response) {
      completedOn = '';
      buildNumber = '';
      hash = '';
    }
    return (
      <div style={{ paddingBottom: '20px' }}>
        <DashboardBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup>
              <FontAwesomeIcon icon="clock" size="2x" fixedWidth style={styles.icon} />
              <ToolbarTitle text="Production Deployments (Project API)" />
            </ToolbarGroup>
          </Toolbar>
          <div style={styles.container}>
            <Row>
              <Col xs={12} md={4}>
                <Row>
                  <Col xs={12}>
                    <SelectField
                      floatingLabelText="api-service"
                      value={this.state.apiService}
                      onChange={this.handleAPIServiceChange}>
                      <MenuItem value={'api-authhandler'} primaryText={'api-authhandler'} />
                      <MenuItem value={'api-grunt-tasks'} primaryText={'api-grunt-tasks'} />
                      <MenuItem value={'api-lib'} primaryText={'api-lib'} />
                      <MenuItem value={'api-lib-transform'} primaryText={'api-lib-transform'} />
                      <MenuItem value={'api-service-coupons'} primaryText={'api-service-coupons'} />
                      <MenuItem value={'api-service-customer'} primaryText={'api-service-customer'} />
                      <MenuItem value={'api-service-dashboard'} primaryText={'api-service-dashboard'} />
                      <MenuItem value={'api-service-email'} primaryText={'api-service-email'} />
                      <MenuItem value={'api-service-epsilon'} primaryText={'api-service-epsilon'} />
                      <MenuItem value={'api-service-exportorders'} primaryText={'api-service-exportorders'} />
                      <MenuItem value={'api-service-exportproduct'} primaryText={'api-service-exportproduct'} />
                      <MenuItem value={'api-service-images'} primaryText={'api-service-images'} />
                      <MenuItem value={'api-service-importcoupon'} primaryText={'api-service-importcoupon'} />
                      <MenuItem value={'api-service-importinventory'} primaryText={'api-service-importinventory'} />
                      <MenuItem value={'api-service-importprice'} primaryText={'api-service-importprice'} />
                      <MenuItem value={'api-service-loyalty'} primaryText={'api-service-loyalty'} />
                      <MenuItem value={'api-service-message'} primaryText={' api-service-message'} />
                      <MenuItem value={'api-service-notifyme'} primaryText={'api-service-notifyme'} />
                      <MenuItem value={'api-service-orderhistory'} primaryText={'api-service-orderhistory'} />
                      <MenuItem value={'api-service-printcatalog'} primaryText={'api-service-printcatalog'} />
                      <MenuItem value={'api-service-utils'} primaryText={'api-service-utils'} />
                      <MenuItem value={'api-service-vipcustomers'} primaryText={'api-service-vipcustomers'} />
                      <MenuItem value={'api-service-warranty'} primaryText={'api-service-warranty'} />
                      <MenuItem value={'api-ui'} primaryText={'api-ui'} />
                    </SelectField>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField
                  value={completedOn}
                  floatingLabelText="Production Deployment Date"
                  fullWidth={true}
                  multiLine={true}
                  rows={1}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  value={buildNumber}
                  floatingLabelText="Build Number"
                  fullWidth={true}
                />
                <TextField
                  value={hash}
                  floatingLabelText="Commit Id"
                  fullWidth={true}
                />
              </Col>
            </Row>
          </div>
          <RaisedButton label="Clear" onClick={this.handleAPIServiceChange} />
        </Paper>
        <div className={tableContainer}>
          <table className={tableStyle}>
            <thead>
              <th className={tableThStyle}>Services that are not deployed</th>
            </thead>
            <tbody>
              <tr>
                <td className={tableStyle}>api-devops</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-ecs-template</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-layer-nodemodules</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-scripts</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-cloudwatch</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-core</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-cron</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-dashboard</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-datalake</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-importproduct</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-local</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-logsNew</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-marketing</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-migratedata</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-notlivereport</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-products</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-sandbox</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-sftp</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-template</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-service-usermgmt</td>
              </tr>
              <tr>
                <td className={tableStyle}>api-tokenservice</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
const mapStateToProps = function (store) {
  return {
    dashboardInfo: store.dashboardState.dashboardInfo
  };
};
export default connect(mapStateToProps)(DashboardContainer);
