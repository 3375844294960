import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import { AppBar, Drawer, Divider, IconButton, List, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import DrawerLink from './DrawerLink';
import { hasRoles } from '../../../api/userApi';
import useStyles from '../../styles/NavBarStyles';

function DrawerList() {
  const classes = useStyles();
  const [open, setOpen] = useState({});
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = id => {
      setOpen({
      ...open,
      [id]: !open[id]
    });
  }

  const list = (anchor) => {
    let adminItems = [];
    if (hasRoles('admin:users'))
      adminItems.push({icon: 'users', primaryText: 'Users', route: '/admin/users'})
    if (hasRoles('admin:jobs'))
      adminItems.push({icon: 'cogs', primaryText: 'Jobs', route: '/admin/jobs'})
    if (hasRoles('admin:cron'))
      adminItems.push({icon: ['far', 'clock'], primaryText: 'Cron Tasks', route: '/admin/cron'})
    if (hasRoles('admin:reports'))
      adminItems.push({icon: 'bar-chart', primaryText: 'Reports', route: '/admin/reports'})
    if (hasRoles('admin:logs'))
      adminItems.push({icon: 'list', primaryText: 'Logs', route: '/admin/logs'})
    if (hasRoles('write:kmsencrypt'))
      adminItems.push({icon: 'lock', primaryText: 'Encrypt', route: '/admin/encrypt'})
    if (hasRoles('write:kmsdecrypt'))
      adminItems.push({icon: 'unlock-alt', primaryText: 'Decrypt', route: '/admin/decrypt'})

    const awsServicesItems = [];
    if (hasRoles('read:swagger'))
      awsServicesItems.push({icon: 'book', primaryText: 'API Docs', route: '/swagger'})
      if (hasRoles('read:coverage'))
      awsServicesItems.push({icon: ['far', 'file-code'], primaryText: 'AWS Code Coverage', route: '/coverage'})
    if (hasRoles('read:awsservices'))
      awsServicesItems.push({icon: ['fab', 'aws'], primaryText: 'API Services', route: '/awsservices'})
    if (hasRoles('read:cloudwatch'))
      awsServicesItems.push({icon: ['far', 'chart-bar'], primaryText: 'Cloudwatch Logs', route: '/cloudwatch'})
    if (hasRoles('read:translate'))
      awsServicesItems.push({icon: 'language', primaryText: 'Amazon Translate', route: '/translator'})
    if (hasRoles('read:seolint'))
      awsServicesItems.push({icon: 'link', primaryText: 'SEO Linter', route: '/seolint'})

    const sfccItems = [];
    if (hasRoles('read:sandbox'))
      sfccItems.push({icon: ['fab', 'salesforce'], primaryText: 'On-Demand Sandbox', route: '/sandboxes'})

    const deploymentItems = [];
    if (hasRoles('read:dashboard'))
      deploymentItems.push({icon: 'file', primaryText: 'Dashboard', route: '/dashboard'})

    let catalogItems = [];
    if (hasRoles('read:catalogs')) {
      catalogItems.push({icon: 'book', primaryText: 'Catalogs', route: '/catalogs'})
      catalogItems.push({icon: 'cube', primaryText: 'Products', route: '/catalogs/products'})
    }

    let catalogLiveReportItems = [];
    if (hasRoles('read:notlivereport')) {
      catalogLiveReportItems.push({icon: ['fas','sort-amount-down'], primaryText: 'Not Live Reports', route: '/notlivereport'})
      catalogLiveReportItems.push({icon: ['fas','sort-amount-down'], primaryText: 'Mismatched Quantities', route: '/mismatchedqty'})
    }

    let taxonomyItems = [];
    if (hasRoles('read:taxonomy')) {
      taxonomyItems.push({icon: ['fas', 'sort-amount-up'], primaryText:'Product Taxonomy', route: '/taxonomy'})
      taxonomyItems.push({icon: 'tags', primaryText:'Attribution', route: '/taxonomy/attribution'})
    }
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role='presentation'
      >
        <AppBar position='static'>
          <Toolbar>
            <IconButton edge='start' aria-label='close' onClick={toggleDrawer('left', false)}>
              <CloseIcon className={classes.icon} style={{padding: '15px 12px'}} />
            </IconButton>
            <Typography variant='h5'>
              Menu
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <DrawerLink
            id='Home'
            route='/'
            classes={classes}
            icon='home'
            primaryText='Home'
            toggleDrawer={toggleDrawer}
          />
          <DrawerLink
            id='About'
            route='/about'
            classes={classes}
            icon={['far', 'question-circle']}
            primaryText='About'
            toggleDrawer={toggleDrawer}
          />
          <Divider />
        { hasRoles('admin:users,admin:jobs,admin:reports,admin:cron') &&
          <DrawerLink
            classes={classes}
            handleClick={handleClick}
            icon='shield-alt'
            primaryText='Admin'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={adminItems}
            id='Admin'
          />
        }
        { hasRoles('read:awsservices,read:swagger,read:coverage,read:translate,read:seolint') &&
          <DrawerLink
            classes={classes}
            handleClick={handleClick}
            icon={['fab', 'aws']}
            primaryText='AWS'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={awsServicesItems}
            id='AWS'
          />
        }
        { hasRoles('read:sandbox') &&
          <DrawerLink
            classes={classes}
            icon={['fab', 'salesforce']}
            handleClick={handleClick}
            primaryText='SFCC'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={sfccItems}
            id='SFCC'
          />
        }
        { hasRoles('read:catalogs') &&
          <DrawerLink
            classes={classes}
            icon='book'
            handleClick={handleClick}
            primaryText='Catalogs'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={catalogItems}
            id='Catalogs'
          />
        }
        { hasRoles('read:dashboard') &&
          <DrawerLink
            classes={classes}
            icon='clock'
            handleClick={handleClick}
            primaryText='Deployments'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={deploymentItems}
            id='Deployments'
          />
        }
        { hasRoles('read:notifyMe') &&
          <DrawerLink
            id='NotifyMe'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='user-clock'
            primaryText='Notify Me'
            route='/notifyme'
          />
        }
        { hasRoles('read:orderhistory') &&
          <DrawerLink
            id='OrderHistory'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='shopping-cart'
            primaryText='Order History'
            route='/orderhistory'
          />
        }
        { hasRoles('read:pingdom') &&
          <DrawerLink
            id='Pingdom'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='satellite-dish'
            primaryText='Pingdom'
            route='/pingdom'
          />
        }
        { hasRoles('read:vipCustomers,write:vipCustomers,delete:vipCustomers') &&
          <DrawerLink
            id='VipCustomer'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon={['far', 'star']}
            primaryText='VIP Customers'
            route='/vip'
          />
        }
        {
          <DrawerLink
            id='Coupons'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='barcode'
            primaryText='Coupons'
            route='/coupons'
          />
        }
        {
          <DrawerLink
            id='CouponsManager'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='barcode'
            primaryText='Coupons Manager'
            route='/couponsmanager'
          />
        }
        { hasRoles('read:taxonomy') &&
          <DrawerLink
            classes={classes}
            icon={['fas','sort-amount-up']}
            handleClick={handleClick}
            primaryText='Taxonomy'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={taxonomyItems}
            id='Taxonomy'
          />
        }
        { hasRoles('read:userMgmt') &&
          <DrawerLink
            id='IdMgmt'
            classes={classes}
            toggleDrawer={toggleDrawer}
            icon='id-badge'
            primaryText='ID Management'
            route='/id-management'
          />
        }
        { hasRoles('read:notlivereport') &&
          <DrawerLink
            classes={classes}
            icon={['fas', 'sort-amount-down']}
            handleClick={handleClick}
            primaryText='Catalog Reports'
            toggleDrawer={toggleDrawer}
            open={open}
            menuItems={catalogLiveReportItems}
            id='Catalog Reports'
          />
        }
        </List>
        <Divider />
      </div>
    )
  }

  return (
    <Fragment>
      <div className={classes.iconButton} onClick={toggleDrawer('left', true)}>
        <MenuIcon className={classes.icon} />
      </div>
      <Drawer anchor='left' open={state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </Fragment>
  )
}

export default DrawerList;
