import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CircularProgress, Paper, RaisedButton, SelectField, MenuItem, TextField } from 'material-ui';
import { grey500 } from 'material-ui/styles/colors';
import { ToolbarDynamic } from '../components/ToolbarDynamic';
import { ConfirmDialog } from '../Components'
import { getCustomerGroups, getVipCustomer, updateVipCustomer, getVipAttachmentUrl } from '../api/vipApi';
import VIPStatus from './vipStatusHelper';
import VipBreadCrumbs from "./VipBreadCrumbs";
import { DatePicker } from 'material-ui';
import moment from 'moment-timezone';

const pranaSite = "Prana_US";
const mountainhardwearSite = "MountainHardwear_US";
const columbiaSite = "Columbia_US";

export const styles = {
  container: {
    marginTop: '20px',
    padding: '20px'
  },
  marginB: {
    marginBottom: '20px'
  },
  marginTB: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  marginR: {
    marginRight: '10px'
  },
  textLeft: {
    marginTop: '10px',
    textAlign: 'left'
  },
  textLeftTextArea: {
    marginTop: '-10px',
    textAlign: 'left'
  },
  textRight: {
    color: grey500,
    marginTop: '10px',
    textAlign: 'right'
  },
  hoverableLink:{
    cursor: 'pointer',
    color: 'rgb(0, 188, 212)'
  },
  paddingLeft:{
    paddingLeft: '0.5rem',
    marginTop: '-5px',
    textAlign: 'left'
  },
  spinner: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    zIndex: "999",
    overflow: "hidden",
    opacity: 0.4
  }
};

class VipDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        id: props.match.params.id,
        vipCustomer: null,
        customerGroup: null,
        vipStatus: null,
        customerGroupAction: null,
        vipCustomerGroups: null,
        media: [],
        getCustomerFailed: false,
        updateCustomerFailed: false,
        getCustomerGroupsFailed: false,
        isApplyButtonEnabled: false,
        visible: false,
        loading: false
    };
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.statusToDisableDropdown = ["pending", "rejected", "renew/a", "renew/e"];
  }

  componentDidMount = async() => {
    await this.fetchCustomerData();
  };

  componentWillReceiveProps(nextProps, nextContext) {
    let errorMsg = "";
    if(nextProps.vipCustomer && ('customerNo' in nextProps.vipCustomer)) {
        this.setState({vipCustomer: nextProps.vipCustomer});

        //get customer groups by vipCustomer.siteId
        if(nextProps.vipCustomerGroups){
          this.setState({vipCustomerGroups: nextProps.vipCustomerGroups});
          if(nextProps.vipCustomer.siteId !== pranaSite && this.statusToDisableDropdown.includes(nextProps.vipCustomer.vipStatus)) {
            this.setState({customerGroup: nextProps.vipCustomerGroups[0].id});
          }
        }

    } else if ( nextProps.getCustomerFailed || nextProps.getCustomerGroupsFailed) {
        if(nextProps.getCustomerFailed && !this.state.getCustomerFailed){
            errorMsg = `Search Pro Purchase Customers failed: ${nextProps.getCustomerFailed.message}\n`;
            this.setState({getCustomerFailed: true});
        }
        if(nextProps.getCustomerGroupsFailed && !this.state.getCustomerGroupsFailed){
            errorMsg = errorMsg + `Get Pro Purchase Customer Groups failed: ${nextProps.getCustomerGroupsFailed.message}`; //eslint-disable-line operator-assignment
            this.setState({getCustomerGroupsFailed: true});
        }
        if(errorMsg)
          alert(errorMsg);
    }
    if (nextProps.updateCustomerFailed) {
      if(nextProps.updateCustomerFailed && !this.state.updateCustomerFailed){

        const failedData = nextProps.updateCustomerFailed;
        let msg = "Error" //default message

        if (failedData && failedData.response && failedData.response.data) {
          msg = failedData.response.data;
        } else if (failedData && failedData.message) {
          msg = failedData.message;
        }

        errorMsg = `${errorMsg} Update Pro Purchase Customers failed: ${JSON.stringify(msg)}\n`; //eslint-disable-line operator-assignment
        this.setState({updateCustomerFailed: true});
        this.setState({ visible: true });
        this.setState({ errorMsg: errorMsg });
      }
    }
  }

  fetchCustomerData = async () => {
    await getVipCustomer(this.props.match.params.id);
    this.initializeState(this.state.vipCustomer);
    if (this.state && this.state.vipCustomer) {
      getCustomerGroups(this.state.vipCustomer.siteId);
    }
  };

  initializeState = (vipCustomer) => {
    if (vipCustomer) {
      this.setState({customerGroup: vipCustomer.customerGroup});
      this.setState({vipStatus: vipCustomer.vipStatus});
    }
    this.setState({ isApplyButtonEnabled: false });
    this.setState({ customerGroupAction: null });
  };

  handleCustomerGroupChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { customerGroup: value }));
  };

  handleRejectedClick = () => {
    this.setState({vipStatus: VIPStatus.rejectedApplicantStatus(this.state.vipCustomer.siteId)});
    this.setState({isApplyButtonEnabled: true});
  };

  handleApprovedClick = () => {
    if (this.state.customerGroup || this.state.vipCustomer.customerGroup) {
      this.setState({vipStatus: VIPStatus.approvedApplicantStatus(this.state.vipCustomer.siteId)});
      this.setState({isApplyButtonEnabled: true});
    }
    else
      alert("Please choose a Customer Group");
  };

  handleRenewClick = () => {
    if (this.state.customerGroup || this.state.vipCustomer.customerGroup) {
      this.setState({vipStatus: VIPStatus.renewApplicantStatus(this.state.vipCustomer.siteId, this.state.vipCustomer.vipStatus, this.state.vipCustomer.customerGroup)});
      this.setState({customerGroupAction: 'none'});
      this.setState({isApplyButtonEnabled: true});
    }
    else
      alert("Please choose a Customer Group");
  };

  handleChangeClick = () => {
    if (this.state.customerGroup) {
      this.setState({customerGroupAction: 'change'});
      this.setState({isApplyButtonEnabled: true});
    }
    else
      alert("Please select a new Customer Group");
  };

  handleRemoveClick = () => {
    this.setState({customerGroupAction: 'remove'});
    this.setState({vipStatus: VIPStatus.getStatusBySiteIdAndType(this.state.vipCustomer.siteId, VIPStatus.INACTIVE)});
    this.setState({customerGroup: null });
    this.setState({isApplyButtonEnabled: true});
  };

  closeDialog = () =>{
    this.setState({visible:false});
    this.setState({updateCustomerFailed: false});
  };

  closeVipDetailsPage = () => {
    window.close();
  };

  formSubmit = async () => {
    let newAttributes = {};
    this.setState({ loading: true });
    if (this.state.vipStatus){
      newAttributes.vipStatus = VIPStatus.renewedApplicant(this.state.vipCustomer.siteId, this.state.vipStatus) ? 'accepted':this.state.vipStatus;
    }
    if (this.state.customerGroupAction){
      newAttributes.previousCustomerGroup = this.state.vipCustomer.customerGroup;
      newAttributes.customerGroupAction = this.state.customerGroupAction;
    } else {
      newAttributes.customerGroupAction = 'none';
    }

    if ((( VIPStatus.canSubmitForm(this.state.vipCustomer.siteId, this.state.vipStatus) || (this.state.customerGroupAction === 'change')) && !this.state.customerGroup) &&
      (!(this.state.customerGroupAction === "remove")))
      alert("Please choose a Customer Group");
    else {
      newAttributes.customerGroup = (this.state.vipStatus === 'rejected') ? null : this.state.customerGroup;
      let updatedCustomer = Object.assign({}, this.state.vipCustomer, newAttributes);
      await updateVipCustomer(updatedCustomer.customerNo, updatedCustomer);
      this.setState({updateCustomerFailed: false});
      this.setState({ visible: true });
      this.fetchCustomerData();
    }
    this.setState({ loading: false });
  };

  getRejectedApprovedBtns = (customer) => {
    if (customer.vipStatus && VIPStatus.canApproveOrRejectApplicant(customer.siteId, customer.vipStatus, customer.customerGroup, customer.expirationDate)) {
      return (
        <Row>
          <Col xs={12}>
            <RaisedButton label="Reject Application" style={styles.marginR} onClick={this.handleRejectedClick}
                          primary={this.state.vipStatus === VIPStatus.rejectedApplicantStatus(customer.siteId)}/>
            <RaisedButton label="Approve Application" style={styles.marginR} onClick={this.handleApprovedClick}
                          primary={this.state.vipStatus === VIPStatus.approvedApplicantStatus(customer.siteId)}/>
          </Col>
        </Row>
      );
    }
    else if (customer.vipStatus && VIPStatus.canRejectApplicant(customer.siteId, customer.vipStatus, customer.expirationDate)) {
      return (
        <Row>
          <Col xs={12}>
            <RaisedButton label="Reject Application" style={styles.marginR} onClick={this.handleRejectedClick}
                          primary={this.state.vipStatus === VIPStatus.rejectedApplicantStatus(customer.siteId)}/>
          </Col>
        </Row>
      );
    }
  };

  getExpiredBtn = (customer) => {
    if (customer.vipStatus && VIPStatus.canRenewApplicant(customer.siteId, customer.vipStatus)) {
      if (customer.siteId === 'Prana_US' || (customer.siteId !== 'Prana_US' && customer.expirationDate)) {
        return (
          <Col xs={2}>
            <RaisedButton label="Renew Membership" style={styles.marginB} onClick={this.handleRenewClick}
                          primary={this.state.vipStatus === VIPStatus.renewingApplicantStatus(customer.siteId)}/>
          </Col>
        );
      }
    }
  };

  getChangeRemoveBtns = (customer) => {
    if (customer.vipStatus && VIPStatus.canRemoveApplicant(customer.siteId, customer.vipStatus)) {
        return(
          <Col xs={12}>
              <RaisedButton label="Change Membership" style={styles.marginR} onClick={this.handleChangeClick} primary={this.state.customerGroupAction === 'change'} />
              <RaisedButton label="Remove Membership" style={styles.marginB} onClick={this.handleRemoveClick} primary={this.state.customerGroupAction === 'remove'} />
          </Col>
        );
    }
  };
  checkDropDownItems = (customerGroups) => {
    if(customerGroups && customerGroups.length === 2){
      //remove the null item
      customerGroups.shift();
    }
    return true;
  }

  getvipCustomerGroupsItems = (customerGroups) => {
    if (!customerGroups.find(group => group.id === null))
      customerGroups.unshift({id: null, name: 'None'});

      return customerGroups.map((group, index) => {
        return(
            <MenuItem value={group.id} primaryText={group.name} key={index} />
        );
    });
  };

  getSpecialtyDisplayText = (enumVal) => {
    let lookup = {
      fishing: 'Fishing',
      hunting: 'Hunting',
      golf: 'Golf',
      ski: 'Ski',
      river: 'River',
      trail: 'Trail',
      kayak: 'Kayak',
      sailing: 'Sailing',
      travel: 'Travel',
      nationalparksblm: 'National Parks BLM',
      government: 'Government',
      nationalparkservice: 'National Park Service',
      nationalskipatrol: 'National Ski Patrol',
      usforestservice: 'US Forest Service',
      bureauoflandmanagement: 'Bureau of Land Management',
      usdepartmentofagriculture: 'US Department of Agriculture',
      nationalwildliferefuge: 'National Wildlife Reguge',
      usgeologicalsurvey: 'US Geological Survey',
      emergencyrespondervolunteer: 'Emergency Responder Volunteer',
      parksandrecreation: 'Parks and Recreation',
      fbilawenforcement: 'FBI / Law Enforcement',
      activemilitary: 'Active Military',
      conservation: 'Conservation',
      outdooreducation: 'Outdoor Education',
      globalrelief: 'Global Relief',
      radio: 'Radio',
      print: 'Print',
      television: 'Television',
      winter: 'Winter',
      water: 'Water',
      digital: 'Digital',
      other: 'Other',
      film: 'Film',
      sports: 'Sports',
      music: 'Music'
    };
    return lookup[enumVal] ? lookup[enumVal] : enumVal;
  };

  getIndustryDisplayText = (enumVal) => {
    let lookup = {
      education: 'Education',
      eventmanagement: 'Event Management',
      government: 'Government',
      media: 'Media',
      nonprofit: 'Non-profit',
      professional: 'Professional'
    };
    return lookup[enumVal] ? lookup[enumVal] : enumVal;
  };

  getCustomerTypeValue = (enumVal) => {
    let lookup = {
      '01': 'Dealer Employee',
      '02': 'Industry Professional',
      '03': 'Direct Sales',
      '04': 'Non Profit Organization'
    };
    return lookup[enumVal] ? lookup[enumVal] : enumVal;
  }

  getDirectSalesTypeValue = (enumVal) => {
    let lookup = {
      '01': 'Military',
      '02': 'Government (local, state and federal)',
      '03': 'Forest Service (state and local)',
      '04': 'Educational',
      '05': 'Resort Employees',
      '06': 'Guide Services',
      '07': 'Search and Rescue',
      '99': 'Educational'
    };
    return lookup[enumVal] ? lookup[enumVal] : enumVal;
  }

  handleMediaClick = async (e) => {
    const id = e.target.id;
    await getVipAttachmentUrl(id);
    if (this.props.mediaUrl) {
      const win = window.open(this.props.mediaUrl.url, '_blank');
      win.focus();
    } else {
      alert('Could not retrieve media S3 signed url.');
    }
  };

  getBusinessFields = (customer) => {
    return (
      <div>
        <Row>
            <Col xs={2} style={styles.textRight}>Business Brochure Upload:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.brochure }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Business License Upload:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.license }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Business License Number:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.licenseNo }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Business Description:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.description }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Years Wearing The Brand:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.yearsWorn }</Col>
        </Row>
      </div>
    );
  };

  getManagerFields = (customer) => {
    return (
      <div>
        <Row>
            <Col xs={2} style={styles.textRight}>Manager First Name:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.managerFirstName }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Manager Last Name:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.managerLastName }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Manager Email:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.managerEmail }</Col>
        </Row>
        <Row>
            <Col xs={2} style={styles.textRight}>Manager Phone:</Col>
            <Col xs={2} style={styles.textLeft}>{ customer.managerPhone }</Col>
        </Row>
      </div>
    );
  };

  showAttachments = (mediaList) => {
   if (!mediaList || !mediaList.length)
        return <Col xs={12} style={styles.textRight}>Media Pending or No Media</Col>;
    return mediaList.map((media, index) => {
      let mediaFileName = media.mediaName.match(/[^\\_]+(?=\.*$)/g);
      return (
        <Row key={index}>
            <Col xs={2} style={styles.textRight}>
                <a
                    id={media.mediaName}
                    onClick={this.handleMediaClick}
                    style={styles.hoverableLink}>
                    { mediaFileName }
                </a>
            </Col>
        </Row>
      );
    });
  };

  handleFieldUpdate (e) {
    let key = e.target.id;
    let value = e.target.value;
    let newVipcustomer = Object.assign({}, this.state.vipCustomer, { [key]: value });
    this.setState(Object.assign({}, this.state, { vipCustomer: newVipcustomer, isApplyButtonEnabled: true }));
  }

  expirationDateChange = (event, date) => {
    let newVipcustomer = Object.assign({}, this.state.vipCustomer, { 'expirationDate': date });
    this.setState(Object.assign({}, this.state, { vipCustomer: newVipcustomer, isApplyButtonEnabled: true }));
  }

  details = () => {
    let customer = this.state.vipCustomer ? this.state.vipCustomer : {};
    let attachments = customer ? this.showAttachments(customer.mediaList) : null;
    let rejectApproveBtns = customer ? this.getRejectedApprovedBtns(customer) : null;
    let expiredBtn = customer ? this.getExpiredBtn(customer) : null;
    let changeRemoveBtns = customer ? this.getChangeRemoveBtns(customer) : null;
    let customerGroups = this.state.vipCustomerGroups ? this.state.vipCustomerGroups : [];
    let customerGroupsDropdown = customerGroups ? this.getvipCustomerGroupsItems(customerGroups) : null;
    let programTitle = customer.siteId === pranaSite ? "Influencer Program" : "Pro Purchase Program";
    let linksTitle = customer.siteId === pranaSite ? "Influencer Photo/Video Links" : "Pro Purchase Photo/Video Links";
    let businessFields = customer.siteId !== pranaSite ? this.getBusinessFields(customer) : null;
    let managerFields = this.getManagerFields(customer);
    let vipCustomerStatus = customer.vipStatus && customer.vipStatus.toLowerCase();
    let expirationDate = customer.expirationDate ? new Date(customer.expirationDate): null;
    return(
        <div>
          {this.state.loading && <div style={styles.spinner}>
              <CircularProgress size={100} thickness={5.6}/>
              <CircularProgress color="primary" size={100} thickness={5.6}/>
          </div>}
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <h2>{customer.customerNo} - {customer.firstName} {customer.lastName}</h2>
                </Col>
              </Row>
            </Grid>
            <ToolbarDynamic title={"Profile Attributes"} style={styles.marginB} />
            <Grid fluid>
              <Row>
                  <Col xs={2} style={styles.textRight}>Customer No:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.customerNo }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Site ID:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.siteId }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>First Name:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.firstName }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Last Name:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.lastName }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Email:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.email }</Col>
              </Row>
            </Grid>
            <ToolbarDynamic title={programTitle} style={styles.marginTB} />
            <Grid fluid>
              <Row>
                  <Col xs={2} style={styles.textRight}>Member:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.vipStatus }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Referral Code:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.referralCode }</Col>
              </Row>
              { customer.siteId === pranaSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Cert Number:</Col>
                <Col xs={2} style={styles.textLeft}>{ customer.businessLicenseNumber }</Col>
              </Row>
              }
              <Row>
                  <Col xs={2} style={styles.textRight}>City:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.city }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>State:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.state }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Application Date:</Col>
                  <Col xs={2} style={styles.textLeft}>{ moment(customer.applicationDate).format('YYYY/MM/DD') }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Last Application Date:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.lastApplicationDate }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Discount Expiration Date:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.discountExpirationDate }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Business Phone Number:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.businessPhone }</Col>
              </Row>

              { businessFields }
              { (customer.siteId === mountainhardwearSite || customer.siteId === columbiaSite ) &&
              <Row>
                  <Col xs={2} style={styles.textRight}>Gender:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.gender }</Col>
              </Row>
              }
              { (customer.siteId === mountainhardwearSite || customer.siteId === columbiaSite ) &&
                <Row>
                    <Col xs={2} style={styles.textRight}>Applicant URL:</Col>
                    <Col xs={2} style={styles.textLeft}>{ customer.applicantURL }</Col>
                </Row>
              }
              { customer.siteId === mountainhardwearSite && managerFields }
              { (customer.siteId === mountainhardwearSite || customer.siteId === columbiaSite ) &&
                <Row>
                    <Col xs={2} style={styles.textRight}>Application Date:</Col>
                    <Col xs={2} style={styles.textLeft}>{ moment(customer.applicationDate).format('YYYY/MM/DD') }</Col>
                </Row>
              }
              { customer.siteId !== pranaSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Business Name:</Col>
                <Col xs={2} style={styles.textLeft}>{ customer.businessName }</Col>
              </Row>
              }
              { customer.siteId === columbiaSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Industry:</Col>
                <Col xs={2} style={styles.textLeft}>{ customer.industry }</Col>
              </Row>
              }
              { customer.siteId !== pranaSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Specialty:</Col>
                <Col xs={2} style={styles.textLeft}>{ customer.specialty }</Col>
              </Row>
              }
              { customer.siteId === mountainhardwearSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Customer Type:</Col>
                <Col xs={3} style={styles.textLeft}>{ this.getCustomerTypeValue(customer.customerType) }</Col>
              </Row>
              }
              { customer.siteId === mountainhardwearSite &&
              <Row>
                <Col xs={2} style={styles.textRight}>Direct Sales Type:</Col>
                <Col xs={3} style={styles.textLeft}>{ this.getDirectSalesTypeValue(customer.directSalesType) }</Col>
                </Row>
              }

              { customer.vipStatus && ( VIPStatus.updateableApplicant(customer.siteId, vipCustomerStatus)) &&
              <Row>
                <Col xs={4}>
                  <DatePicker
                    hintText="Expiration Date"
                    firstDayOfWeek={0}
                    id="expirationDate"
                    floatingLabelText="Expiration Date"
                    formatDate={(expirationDate) => moment.tz(expirationDate, "Europe/London").format('YYYY/MM/DD')}
                    onChange={this.expirationDateChange}
                    minDate={new Date()}
                    fullWidth={true}
                    value={expirationDate}
                  />
                </Col>
              </Row>
              }
              { customer.vipStatus && VIPStatus.pendingApplicant(customer.siteId, customer.vipStatus) &&
              customer.siteId !== 'Prana_US' && customer.expirationDate &&
              <Row>
                <Col xs={2} style={styles.textRight}>Expiration Date:</Col>
                <Col xs={2} style={styles.textLeft}>{ customer.expirationDate }</Col>
              </Row>
              }
              { customer.siteId === pranaSite &&
              <Row>
                <Col xs={4}>
                  <TextField
                    id="businessName"
                    floatingLabelText="Business Name:"
                    onChange={this.handleFieldUpdate}
                    value={customer.businessName}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              }
              { customer.siteId === pranaSite &&
              <Row>
                <Col xs={4}>
                  <TextField
                    id="industry"
                    floatingLabelText="Industry:"
                    onChange={this.handleFieldUpdate}
                    value={customer.industry}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              }
              { customer.siteId === pranaSite &&
              <Row>
                <Col xs={4}>
                  <TextField
                    id="customerType"
                    floatingLabelText="Customer Type:"
                    onChange={this.handleFieldUpdate}
                    value={this.getCustomerTypeValue(customer.customerType)}
                    fullWidth={true}
                  />
                </Col>
              </Row>
              }
              { customer.siteId === pranaSite &&
                <Row>
                  <Col xs={4}>
                    <TextField
                      id="notes"
                      floatingLabelText="Notes:"
                      onChange={this.handleFieldUpdate}
                      value={customer.notes}
                      fullWidth={true}
                      multiLine={true}
                    />
                  </Col>
                </Row>
              }
            </Grid>

            <ToolbarDynamic title={linksTitle} style={styles.marginTB} />
            <Grid fluid>
              <Row>
                  <Col xs={2} style={styles.textRight}>Photo & Video Links:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.photoLinks }</Col>
              </Row>
              <Row>
                  <Col xs={2} style={styles.textRight}>Photo/Video Link Description:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.photoLinkDescription }</Col>
              </Row>
            </Grid>

            <ToolbarDynamic title={"Media"} style={styles.marginTB} />
            <Grid fluid>
                { attachments }
            </Grid>

            <ToolbarDynamic title={"Program Maintenance"} style={styles.marginTB} />
            <Grid fluid>
              <Row>
                  <Col xs={2} style={styles.textRight}>Program Status:</Col>
                  <Col xs={2} style={styles.textLeft}>{ customer.vipStatus }</Col>
              </Row>
              <Row middle="xs">
                  <Col xs={2} style={styles.textRight}>Customer Group:</Col>
                  <Col xs={4} style={styles.textLeft}>
                    {customer.siteId !== pranaSite && (this.statusToDisableDropdown.includes(customer.vipStatus)) ?
                     <SelectField
                     value={this.state.customerGroup }
                     fullWidth={true}
                     disabled
                     >
                      { this.checkDropDownItems(customerGroups) && <MenuItem value={customerGroups[0].id} primaryText={customerGroups[0].name} key={0} />}
                     </SelectField>
                    :
                      <SelectField
                      value={this.state.customerGroup}
                      onChange={this.handleCustomerGroupChange}
                      fullWidth={true}
                      >
                        { customerGroupsDropdown }
                      </SelectField>
                    }
                  </Col>
              </Row>
              <Row>
                <Col xs={2} style={styles.textRight}>Rejection Reason:</Col>
                <Col xs={4} style={styles.textLeftTextArea}>
                  <TextField
                    id="rejectReason"
                    floatingLabelText=""
                    onChange={this.handleFieldUpdate}
                    value={customer.rejectReason}
                    fullWidth={true}
                    multiLine={true}
                  />
                </Col>
              </Row>
            </Grid>

            <Grid fluid>
              <Row>
                { rejectApproveBtns }
                { expiredBtn }
                { changeRemoveBtns }
              </Row>
            </Grid>

          <Grid fluid style={styles.container}>
            <Row>
                <Col xs={12}>{ customer.license ? customer.license : 'No license uploaded' }</Col>
            </Row>
            <Row>
                <Col xs={12}>{ customer.brochure ? customer.brochure : 'No brochure or business cards uploaded' }</Col>
            </Row>
            <Row end="xs">
                <Col xs={2}>
                    <RaisedButton label="Apply" primary={true} disabled={!this.state.isApplyButtonEnabled || this.state.loading} onClick={this.formSubmit} fullWidth />
                    {(this.state.updateCustomerFailed) ? (
                     <ConfirmDialog
                      title="Application was not updated."
                      body={this.state.errorMsg}
                      onConfirm={this.closeDialog}
                      onCancel={this.closeDialog}
                      open={this.state.visible}
                    />
                    ) : (<ConfirmDialog
                      title="Updated successfully."
                      body=""
                      onConfirm={this.closeVipDetailsPage}
                      onCancel={this.closeVipDetailsPage}
                      open={this.state.visible}
                   />)}

                </Col>
            </Row>
          </Grid>

        </div>
    );
  };

  render() {
    return (
        <div style={{paddingBottom: '20px'}}>
          <VipBreadCrumbs id={this.state.id} siteId={(this.state.vipCustomer) ? this.state.vipCustomer.siteId : ""} />
            <Paper>
                { this.details() }
            </Paper>
        </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    vipCustomer: store.vipState.vipCustomer,
    vipCustomerGroups: store.vipState.vipCustomerGroups,
    getCustomerFailed: store.vipState.getCustomerFailed,
    updateCustomerFailed: store.vipState.updateCustomerFailed,
    getCustomerGroupsFailed: store.vipState.getCustomerGroupsFailed,
    media: store.vipState.media,
    getMediaFailed: store.vipState.getMediaFailed,
    mediaUrl: store.vipState.mediaUrl
  };
};

export default connect(mapStateToProps)(VipDetailsContainer);
