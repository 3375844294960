import * as types from './actionTypes';

export function searchOrderHistorySuccess(order) {
  return {
    type: types.SEARCH_ORDER_HISTORY_SUCCESS,
    order
  };
}

export function searchOrderHistoryFailed(error) {
  return {
    type: types.SEARCH_ORDER_HISTORY_FAILED,
    error
  };
}

export function getOrderHistoryCountSuccess(count) {
  return {
    type: types.GET_ORDER_HISTORY_COUNT_SUCCESS,
    count
  };
}

export function getOrderHistoryCountFailed(error) {
  return {
    type: types.GET_ORDER_HISTORY_COUNT_FAILED,
    error
  };
}

export function getRecentOrderStatusSuccess(orders) {
  return {
    type: types.GET_RECENT_ORDER_STATUS_SUCCESS,
    orders
  };
}

export function getRecentOrderStatusFailed(error) {
  return {
    type: types.GET_RECENT_ORDER_STATUS_FAILED,
    error
  };
}

export function searchOrderStatusSuccess(order) {
  return {
    type: types.SEARCH_ORDER_STATUS_SUCCESS,
    order
  };
}

export function searchOrderStatusFailed(error) {
  return {
    type: types.SEARCH_ORDER_STATUS_FAILED,
    error
  };
}
