import React, { Component } from 'react';
import { PageHeader } from '../Components';
import { Row, Col } from 'react-flexbox-grid';
import { restApi } from '../config';

import ProductImageSearchFormContainer from "./ProductImageSeachForm";
import ProductImageSearchListContainer from "./ProductImageSearchList";

export const inArray = (val, arr) => {
  const count = arr.length;
  for(let i=0; i<count; i++) {
    if(arr[i].productId === val){
      return true;
    }
  }
  return false;
};

export const ProductImageSearch = ({ hasImageList, csvUrl, userInput, submitting, setInput, getProductIds, clearResults }) => (
  <div>
    <PageHeader>Product Image Search</PageHeader>
    <Row>
      <Col xs={12} md={2}>
        <ProductImageSearchFormContainer
          userInput={userInput}
          submitting={submitting}
          setInput={setInput}
          getProductIds={getProductIds}
          clearResults={clearResults}
        />
      </Col>
      <Col xs={12} md={8}>
        {(hasImageList) &&
          <ProductImageSearchListContainer
            hasImageList={hasImageList}
            csvUrl={csvUrl}
          />
        }
      </Col>
    </Row>
  </div>
);

class ProductImageSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: "",
      submitting: false
    };
    this.setInput = this.setInput.bind(this);
    this.getProductIds = this.getProductIds.bind(this);
    this.clearResults = this.clearResults.bind(this);
  }

  setInput(e) {
    this.setState({
      userInput : e.target.value
    });
  };

  getProductIds(){ // click event for submit button in ProductImageSearchForm.js
    let self = this;
    self.setState({submitting: true});

    // split user input into an array
    let req = this.state.userInput.replace(/\s/g, ",").split(",").filter((n)=> (n !== ""));
    req = req.filter((el, i, self) => i === self.indexOf(el));

    // check if req exists before submitting
    if( req && req.length > 0 ) {
      restApi.post(
        '/productImageFiles/search',
        {
          "query": req
        }
      ).then((res) => {
        // create a new array of objects comparing inputted product ids to response data
        const data = [].concat.apply([], res.data);
        const newArr = req.map((n) => (
          {
            "productId": n,
            "hasImage": inArray(n, data) ? "Yes" : "No",
            "fileNames": data.filter((no) => no.productId === n).map((n, i) => (
              n.fileName
            ))
          }
        ));

        // get link to csv
        restApi.post(
          '/productImageFiles/upload',
          newArr
        ).then((res) => {
          self.setState({csvUrl: res.data.url});
        }).catch((err) => {
          console.log("Get CSV Link Failed - " + err);
        });

        // set the state using data received;
        self.setState({hasImageList: newArr, submitting: false})
      }).catch((err) => {
        console.log("Get Products Failed - " + err);
        self.setState({submitting: false})
      });
    }
  }

  clearResults() {
    this.setState({hasImageList: null,  userInput: "", csvUrl: ""})
  }

  render() {
    return (
      <ProductImageSearch
        csvUrl={this.state.csvUrl}
        hasImageList={this.state.hasImageList}
        userInput={this.state.userInput}
        submitting={this.state.submitting}
        setInput={this.setInput}
        getProductIds={this.getProductIds}
        clearResults={this.clearResults}
      />
    );
  }
}

export default ProductImageSearchContainer;
