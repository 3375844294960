import * as types from '../actions/actionTypes';
import localStorage from '../services/localStorage';
import { refreshLogin } from '../api/userApi';

const initialState = {
  profile: {
    isLoggedIn: false,
    isAdmin: false,
//    user: null
  }
};

let initialized = false;

const userReducer = function(state, action) {
  if (state === undefined) {
    state = localStorage.get('userState') || initialState;
    if (state.profile && state.profile.isLoggedIn === true && state.profile.token && initialized === false) {
      //console.log("Initial user load from stored!!!!!");
      initialized = true;
      refreshLogin(state.profile.token); // we'll set the stored profile for now, but refresh it too in case it's expired
    }
  }
  switch(action.type) {
    case types.GET_PROFILE_SUCCESS:
      let newState = Object.assign({}, state, { profile: action.profile });
      localStorage.set('userState', newState);
      return newState;

    default:
      return state;
  }
};

export default userReducer;
