import { modelDataRestApi } from '../config';
import store from '../services/store';
import {
  getModelProfilesSuccess,
  getModelProfilesError
} from "../actions/modelProfileActions";
import { setSelectedModels } from "../actions/modelProfileActions";


const axiosConfig = { headers: { 'Content-Type': 'application/json' } };

export function createNewModelProfile(params) {
  return modelDataRestApi.post(`/model/profile`, params);
}

export function updateModelProfile(params) {
  return modelDataRestApi.put(`/model/profile`, params);
}

export function deleteModelProfile(modelCodes) {
  modelDataRestApi.delete(`/model/profile/${modelCodes}`).then(()=> {
    store.dispatch(setSelectedModels([]));
    getModelsList();
  }).catch(error => {
    console.log("Model profile delete actions status failed: " + error);
  })
}

export function publishModel(modelCode) {
  return modelDataRestApi.put(`/model/profile/${modelCode}`);
}

export function publishModels(models) {
  return modelDataRestApi.post('/model/profile/publish', models);
}

export function getModelsList() {
  modelDataRestApi.get(`/model/profile`).then(res => {
    store.dispatch(getModelProfilesSuccess(res));
  }).catch(error => {
    store.dispatch(getModelProfilesError());
  })
}

export function uploadMultipleModels(models) {
  modelDataRestApi.post('/model/profile/upload', models, axiosConfig).then(()=> {
    getModelsList();
  }).catch(error => {
    console.log("Error occured during bulk upload of models: " + error);
  })
}
