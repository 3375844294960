import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class AdminUserBreadCrumbs extends Component {

  render() {
    const { email } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            <span>Admin</span>
          </div>
          <div className="breadcrumb">
            { email &&
            <Link to="/admin/users">Users</Link>
            }
            { !email &&
            <span>Users</span>
            }
          </div>
          { email &&
          <div className="breadcrumb">
            <span>{email}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}

AdminUserBreadCrumbs.propTypes = {
  email: PropTypes.string
};
