import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { ConfirmDialog } from '../../Components';
import { Link } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  AppBar,
  Drawer,
  IconButton,
  RaisedButton,
  Snackbar,
  Table,
  TextField,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  Toggle } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormat, dateFormatUTC } from '../../filters';
import { cronRestApi } from '../../config';
import cronstrue from 'cronstrue';
import cronparser from 'cron-parser';
import { PropagateLoader } from 'react-spinners';
import CronBuilder from  'react-cron-builder';
import 'react-cron-builder/dist/bundle.css';
import './cronBuilder.css';
import { columbiaBlue } from "../../themes/brandColors";
import { ToolbarDynamic } from "../../components/ToolbarDynamic";
import NavigationClose from "material-ui/svg-icons/navigation/close";

const jobIcon = "hourglass-2";

export const styles = {
  success: {
    color: '#008800'
  },
  failed: {
    color: '#880000'
  },
  unknown: {
    color: '#666666'
  },
  col1: {
    width:'20px'
  },
  col2: {
    width:'125px'
  },
  col3: {
    width:'100px'
  },
  col4: {
    width:'200px'
  },
  col5: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    padding: '5px',
  },
  col6: {
  },
  col7: {
  },
  menuIcon: {
  },
  button: {
    margin: '10px'
  }
};

const override = css`
    display: block;
    border-color: red;
    margin-left: auto;
    margin-right: auto;
    width: 10%
`;

function statusStyle(status) {
  if (status === "completed")
    return styles.success;
  if (status === "failed")
    return styles.failed;
  return styles.unknown;
}

export const AdminCronTaskJobItem = ( job ) => {
  return (
    <TableRow key={job.id}>
      <TableRowColumn style={styles.col1}>
        <FontAwesomeIcon style={statusStyle(job.status)} icon={jobIcon} fixedWidth/>
      </TableRowColumn>
      <TableRowColumn style={styles.col2}>
        <span style={statusStyle(job.status)}>{ job.handler } : { job.type }</span>
      </TableRowColumn>
      <TableRowColumn style={styles.col4}>
        { job.completedOn &&
          <span style={statusStyle(job.status)}>{dateFormat(job.completedOn, "l h:mm:ssa")}</span>
        }
        { job.failedOn &&
          <span style={statusStyle(job.status)}>{dateFormat(job.failedOn, "l h:mm:ssa")}</span>
        }
      </TableRowColumn>
      <TableRowColumn style={styles.col5}>
        { job.status === "completed" && job.results &&
          <span style={statusStyle(job.status)}>{JSON.stringify(job.results)}</span>
        }
        { job.status === "failed" && job.error &&
          <span style={statusStyle(job.status)}>{job.error}</span>
        }
        { job.status === "started" && job.update &&
          <span style={statusStyle(job.status)}>{job.update}</span>
        }
      </TableRowColumn>
      <TableRowColumn style={styles.col6}>
        <Link to={"/admin/logs/job/" + job.id}>
          Logs
        </Link>
      </TableRowColumn>
    </TableRow>
  );
};

export default class AdminCronTaskEntry extends Component {
  constructor(props) {
    super(props);
    let invalidSchedule = false;
    let newCronTask = Object.assign({}, props.cronTask);
    if (newCronTask.parameters)
      newCronTask.parameters = JSON.stringify(newCronTask.parameters, null, 2);
    if (newCronTask.maxRetryCount !== null && newCronTask.maxRetryCount !== undefined)
      newCronTask.maxRetryCount = `${newCronTask.maxRetryCount}`;
    if (newCronTask.schedule) {
      // let next = cronparser.parseExpression(newCronTask.schedule, {tz: "UTC"}).next().toDate();
      try {
        newCronTask.schedulePretty = cronstrue.toString(newCronTask.schedule);
        newCronTask.scheduleNext = cronparser.parseExpression(newCronTask.schedule, {tz: "UTC"}).next().toDate();
      } catch (e) {
        invalidSchedule = true;
      }
    }
    this.state = { jobs: [], loading: true, cronTask: newCronTask, invalidSchedule, invalidMaxRetryCounts: false, showExecute: false, new: this.props.new, isValid: false, snackbarOpen: false, drawerOpen: false, hideDrawer: this.props.hideDrawer };
    this.createTask = this.createTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.refreshTask = this.refreshTask.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.executeTask = this.executeTask.bind(this);
    this.confirmExecute = this.confirmExecute.bind(this);
    this.cancelExecute = this.cancelExecute.bind(this);
  }

  componentDidMount() {
    this.refreshTask();
  }

  validate(cronTask){
    if (!cronTask)
      cronTask = this.state.cronTask;
    let { handler, type, schedule, parameters, maxRetryCount } = cronTask;
    if (!handler || handler.trim().length < 1)
      return false;
    if (!type || type.trim().length < 1)
      return false;
    if (!schedule || schedule.trim().length < 1)
      return false;
    try {
      cronstrue.toString(schedule);
      cronparser.parseExpression(schedule, {tz: "UTC"}).next().toDate();
    } catch (e) {
      return false;
    }
    if (parameters && parameters.length > 0) {
      try {
        JSON.parse(parameters.trim());
      } catch (e) {
        return false;
      }
    }
    if (maxRetryCount && maxRetryCount.trim().length > 0) {
      if (isNaN(maxRetryCount.trim()))
        return false;
    }
    return true;
  }

  getCronTaskData(){
    let { handler, type, schedule, description, parameters, maxRetryCount, active } = this.state.cronTask;
    if (!this.validate())
      return;
    let cronTaskData = {
      handler: handler.trim(),
      type: type.trim(),
      schedule: schedule.trim(),
      active: active
    };
    if (description && description.length > 0)
      cronTaskData.description = description.trim();
    if (parameters && parameters.length > 0)
      cronTaskData.parameters = JSON.parse(parameters.trim());
    if (description && description.length > 0)
      cronTaskData.description = description.trim();
    if (maxRetryCount && maxRetryCount.trim().length > 0)
      cronTaskData.maxRetryCount = new Number(maxRetryCount.trim());  //eslint-disable-line no-new-wrappers

    return cronTaskData;
  }

  createTask(){
    let self = this;
    let cronTaskData = this.getCronTaskData();
    if (!cronTaskData)
      return;
    console.log(`Posting update: ${JSON.stringify(cronTaskData, null, 2)}`);
    cronRestApi.post(`/cron`, cronTaskData).then(({data: cronTask}) => {
      if (cronTask && cronTask.parameters)
        cronTask.parameters = JSON.stringify(cronTask.parameters, null, 2);
      if (cronTask.maxRetryCount !== null)
        cronTask.maxRetryCount = `${cronTask.maxRetryCount}`;
      if (cronTask.schedule) {
        try {
          cronTask.schedulePretty = cronstrue.toString(cronTask.schedule);
          cronTask.scheduleNext = cronparser.parseExpression(cronTask.schedule, {tz: "UTC"}).next().toDate();
        } catch (e) {
        }
      }
      self.setState(Object.assign({}, self.state, { loading: false, cronTask, new: false, isValid: this.validate(cronTask) }));
      if (this.props.onRefresh)
        this.props.onRefresh();
    }).catch((error) => {
      return alert(`Create cronTask failed: ${error.message}`);
    });
  };

  updateTask(){
    let self = this;
    let cronTaskData = this.getCronTaskData();
    if (!cronTaskData)
      return;
    console.log(`Putting update: ${JSON.stringify(cronTaskData, null, 2)}`);
    cronRestApi.put(`/cron/${this.state.cronTask.id}`, cronTaskData).then(({data: cronTask}) => {
      if (cronTask && cronTask.parameters)
        cronTask.parameters = JSON.stringify(cronTask.parameters, null, 2);
      if (cronTask.maxRetryCount !== null)
        cronTask.maxRetryCount = `${cronTask.maxRetryCount}`;
      if (cronTask.schedule) {
        try {
          cronTask.schedulePretty = cronstrue.toString(cronTask.schedule);
          cronTask.scheduleNext = cronparser.parseExpression(cronTask.schedule, {tz: "UTC"}).next().toDate();
        } catch (e) {
        }
      }
      self.setState(Object.assign({}, self.state, { loading: false, cronTask, new: false, isValid: this.validate(cronTask), snackbarOpen: true }));
      if (this.props.onRefresh)
        this.props.onRefresh();
    }).catch((error) => {
      return alert(`Update cronTask failed: ${error.message}`);
    });
  };

  refreshTask(){
    if (this.state.new)
      return;
    let self = this;
    self.setState(Object.assign({}, self.state, { loading: true, isValid: this.validate() }));
    cronRestApi.get(`/cron/${this.state.cronTask.id}/history`).then(({data}) => {
      self.setState(Object.assign({}, self.state, { loading: false, jobs: data }));
    }).catch((error) => {
      return alert(`Get cronTask history failed: ${error.message}`);
    });
  };

  executeTask(){
    this.setState(Object.assign({}, this.state, { showExecute: true }));
  };
  confirmExecute(){
    this.setState(Object.assign({}, this.state, { showExecute: false }));
    console.log(`Execute ${this.state.cronTask.id}`);
    cronRestApi.post(`/cron/${this.state.cronTask.id}/execute`).then(() => {
      this.refreshTask();
      if (this.props.onRefresh)
        this.props.onRefresh();
    }).catch((error) => {
      console.log("Execute cronTask failed: " + error);
      console.log(error);
    });
  };
  cancelExecute(){
    this.setState(Object.assign({}, this.state, { showExecute: false }));
  };

  handleFieldUpdate(e) {
    let key = e.target.id;
    let value = e.target.value;
    let invalidParameters = false;
    let invalidSchedule = false;
    let invalidMaxRetryCounts = false;

    let newCronTask = Object.assign({}, this.state.cronTask, { [key]: value });
    if (newCronTask.schedule) {
      try {
        newCronTask.schedulePretty = cronstrue.toString(newCronTask.schedule);
        newCronTask.scheduleNext = cronparser.parseExpression(newCronTask.schedule, {tz: "UTC"}).next().toDate();
      } catch (e) {
        invalidSchedule = true;
      }
    }
    if (newCronTask.parameters) {
      try {
        JSON.parse(newCronTask.parameters);
      } catch (e) {
        invalidParameters = true;
      }
    }
    if (newCronTask.maxRetryCount && newCronTask.maxRetryCount.trim().length > 0) {
      if (isNaN(newCronTask.maxRetryCount.trim()))
        invalidMaxRetryCounts = true;
    }

    this.setState(Object.assign({}, this.state, { cronTask: newCronTask, invalidParameters, invalidSchedule, invalidMaxRetryCounts, isValid: this.validate(newCronTask) }));
  }

  handleSnackbarClose = () => {
    this.setState(Object.assign({}, this.state, { snackbarOpen: false }));
  };

  handleCronScheduleUpdate = schedule => {
    const e = {
      target: {
        id: 'schedule',
        value: schedule
      }
    };
    this.handleFieldUpdate(e);
  };

  toggleActive = () => {
    const e = {
      target: {
        id: 'active',
        value: !this.state.cronTask.active
      }
    };
    this.handleFieldUpdate(e);
  };

  handleToggleDrawer = () => {
    this.setState({drawerOpen: !this.state.drawerOpen});
  };


  render(){
    let cronTask = this.state.cronTask;
    const jobsList = this.state.jobs.map( l => AdminCronTaskJobItem(l));
    return (
    <div>
      <ConfirmDialog
        title="Execute Cron Task"
        body="Are you sure you want to run this cron task right now?"
        onConfirm={this.confirmExecute}
        onCancel={this.cancelExecute}
        open={this.state.showExecute}
      />
      <Grid fluid style={{ paddingBottom: "20px" }}>
        <Row style={{ paddingBottom: "20px" }}>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12} sm={6}>
                <TextField id="handler"
                           defaultValue={cronTask ? cronTask.handler : ""}
                           fullWidth={true}
                           floatingLabelText="Job Handler"
                           underlineDisabledStyle={{border:'none'}}
                           errorText={cronTask && cronTask.handler && cronTask.handler.trim().length > 0 ? null : "Job handler is required"}
                           onChange={this.handleFieldUpdate}
                />
              </Col>
              <Col xs={12} sm={6}>
                <TextField id="type"
                           defaultValue={cronTask ? cronTask.type : ""}
                           fullWidth={true}
                           floatingLabelText="Job Type"
                           underlineDisabledStyle={{border:'none'}}
                           errorText={cronTask && cronTask.type && cronTask.type.trim().length > 0 ? null : "Job type is required"}
                           onChange={this.handleFieldUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField id="description"
                           defaultValue={cronTask ? cronTask.description : ""}
                           fullWidth={true}
                           floatingLabelText="Description"
                           underlineDisabledStyle={{border:'none'}}
                           onChange={this.handleFieldUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField id="schedule"
                           value={cronTask ? cronTask.schedule : ""}
                           fullWidth={true}
                           floatingLabelText="Cron Schedule"
                           underlineDisabledStyle={{border:'none'}}
                           errorText={this.state.invalidSchedule ? "Invalid Schedule" : null}
                           onChange={this.handleFieldUpdate}
                />
                {cronTask.schedulePretty && !this.state.invalidSchedule &&
                  <div>{cronTask.schedulePretty}</div>
                }
                {cronTask.scheduleNext && !this.state.invalidSchedule &&
                  <div>Next run at:
                    <div style={{marginLeft: 5}}>{dateFormat(cronTask.scheduleNext, "l h:mm:ssa")} (local)</div>
                    <div style={{marginLeft: 5}}>{dateFormatUTC(cronTask.scheduleNext, "l h:mm:ssa")} (UTC)</div>
                  </div>
                }
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                <TextField id="parameters"
                           defaultValue={cronTask ? cronTask.parameters : ""}
                           fullWidth={true}
                           floatingLabelText="Job Parameters"
                           underlineDisabledStyle={{border:'none'}}
                           multiLine={true}
                           rows={3}
                           rowsMax={10}
                           errorText={this.state.invalidParameters ? "Invalid JSON" : null}
                           onChange={this.handleFieldUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <TextField id="maxRetryCount"
                           defaultValue={cronTask ? cronTask.maxRetryCount : ""}
                           fullWidth={true}
                           floatingLabelText="Max # Retries"
                           underlineDisabledStyle={{border:'none'}}
                           errorText={this.state.invalidMaxRetryCounts ? "Invalid Number" : null}
                           onChange={this.handleFieldUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Toggle id="active"
                        toggled={cronTask.active}
                        onToggle={this.toggleActive}
                        label="Not In Use"
                        labelPosition="right"
                        style={{paddingTop: "40px"}}
                        disabled={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            { !this.state.hideDrawer &&
              <RaisedButton onClick={this.handleToggleDrawer} label="Open Cron Builder" style={styles.button} />
            }
          </Col>
          <Col xs={8} style={{textAlign: "right"}}>
            { this.state.new ? (
              <RaisedButton primary={true} onClick={this.createTask} label="Create" disabled={!this.state.isValid} style={styles.button} />
            ) : (
              <RaisedButton primary={true} onClick={this.updateTask} label="Update" disabled={!this.state.isValid} style={styles.button} />
            )}
            { !this.state.new &&
              <RaisedButton secondary={true} onClick={this.executeTask} label="Execute" style={styles.button} />
            }
            <RaisedButton onClick={this.refreshTask} label="Refresh" style={styles.button} />
          </Col>
        </Row>
      </Grid>
      { !this.state.hideDrawer &&
        <Drawer open={this.state.drawerOpen} openSecondary={true} width="60%">
          <AppBar title="Cron Builder" iconElementLeft={<IconButton><NavigationClose/></IconButton>}
                  onLeftIconButtonClick={this.handleToggleDrawer}/>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{position: 'absolute', top: '30%', left: '50%', transform: 'translate(-30%, -50%)'}}>
                <CronBuilder onChange={this.handleCronScheduleUpdate} showResult={true}/>
              </Col>
            </Row>
          </Grid>
        </Drawer>
      }

      <Snackbar
        open={this.state.snackbarOpen}
        message="Cron task updated."
        autoHideDuration={4000}
        onRequestClose={this.handleSnackbarClose}
      />

      { !this.state.new &&
        <div>
          <ToolbarDynamic title="Recent Job History"/>
          { this.state.loading ? (
            <PropagateLoader
              className={override}
              sizeUnit={"px"}
              size={15}
              color={columbiaBlue}
              loading={this.state.loading}
            />
          ) : (
            <Table>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn style={styles.col1}>Status</TableHeaderColumn>
                  <TableHeaderColumn style={styles.col2}>Type</TableHeaderColumn>
                  <TableHeaderColumn style={styles.col4}>Finished (Local)</TableHeaderColumn>
                  <TableHeaderColumn style={styles.col5}>Result</TableHeaderColumn>
                  <TableHeaderColumn style={styles.col6}>Actions</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                { jobsList }
              </TableBody>
            </Table>
          )}
        </div>
      }
    </div>
    );
  }
}

AdminCronTaskEntry.propTypes = {
  cronTask: PropTypes.object,
  onRefresh: PropTypes.func,
  new: PropTypes.bool
};
