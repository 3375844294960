import React, { Component } from 'react';
import store from '../services/store';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { MenuItem, RaisedButton, SelectField, TextField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';

import { makeInventoryRequest } from '../api/inventoryApi';
import { getInventoryRecordsSuccess, getInventoryRecordsFailed } from '../actions/inventoryActions';
import localStorage from '../services/localStorage';

export const styles = {
  buttonMargin: {
    margin: "10px"
  }
};

export default class InventoryRecordsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryList: {},
      inventoryListId: '',
      inventoryRecords: [],
      productId: '',
      searchPhrase: '',
      start: 0,
      count: 100
    };
  }

  componentDidMount() {
    let history = localStorage.get('history');
    let location = history && history.length ? history[history.length - 1] : null;
    let inventoryListId = location.split('/')[3];

    let state = store.getState().inventoryState;
    let inventoryList = state.inventoryList.data ? state.inventoryList.data.find(list => list.id === inventoryListId) : {};

    this.setState({ inventoryListId: inventoryListId, inventoryList: inventoryList });
  }

  handleTextChange = (e, text) => {
    let value = {};
    value[`${e.target.name}`] = text.length > 0 ? text : '';
    this.setState(value);
  };

  handleCountChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { count: value }));
  };

  searchSubmit = () => {
    let body = { inventoryListId: this.state.inventoryListId };
    if (this.state.productId) {
      body.productIds = this.state.productId.replace(/\s/g, '').split(',');
    } else if (this.state.searchPhrase) {
      body.count = this.state.count;
      body.searchPhrase = this.state.searchPhrase;
      body.siteIds = this.state.inventoryList.assigned_sites;
      body.start = this.state.start;
    } else {
      body.start = this.state.start;
      body.count = this.state.count;
    }

    makeInventoryRequest(getInventoryRecordsSuccess, getInventoryRecordsFailed, `exportProduct/getProductInventoryRecords`, `post`, body)
  };

  render() {
    let toolbarTitle = `Search Inventory Records ${this.state.inventoryListId}`;
    return (
      <div>
        <Toolbar style={styles.toolbarMargin}>
          <ToolbarGroup>
            <ToolbarTitle text={toolbarTitle} />
          </ToolbarGroup>
          <ToolbarGroup>
            <RaisedButton
              primary={true}
              label="Search"
              style={styles.buttonMargin}
              onClick={this.searchSubmit} />
          </ToolbarGroup>
        </Toolbar>
        <Grid fluid>
          <Row>
            <Col xs={12} md={3}>
              <TextField
                multiLine={true}
                rows={3}
                rowsMax={10}
                hintText="List ID:"
                floatingLabelText="List ID:"
                value={this.state.inventoryListId}
                disabled={true} />
            </Col>
            <Col xs={12} md={3}>
              <TextField
                multiLine={true}
                rows={3}
                rowsMax={10}
                floatingLabelText="Product ID:"
                floatingLabelFixed={true}
                onChange={this.handleTextChange}
                name="productId"
                value={this.state.productId} />
            </Col>
            <Col xs={12} md={3}>
              <TextField
                multiLine={true}
                rows={3}
                rowsMax={10}
                floatingLabelText="Search Product Name:"
                floatingLabelFixed={true}
                onChange={this.handleTextChange}
                name="searchPhrase"
                value={this.state.searchPhrase}
                disabled={this.state.inventoryList && this.state.inventoryList.assigned_sites ? false : true} />
            </Col>
            <Col xs={12} md={3}>
              <SelectField
                floatingLabelText="Results per page"
                value={this.state.count}
                onChange={this.handleCountChange}>
                <MenuItem value={10} primaryText="10" />
                <MenuItem value={25} primaryText="25" />
                <MenuItem value={50} primaryText="50" />
                <MenuItem value={100} primaryText="100" />
              </SelectField>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
