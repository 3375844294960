import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Dialog, FlatButton, RaisedButton } from 'material-ui'
import { restApi } from '../config';

import AdminUserEdit from './AdminUserEdit';

export default class AdminUserCreate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {
        active: true,
        roles: []
      }
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addRole = this.addRole.bind(this);
    this.removeRole = this.removeRole.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.putUpdates = this.putUpdates.bind(this);
    this.setActiveFlag = this.setActiveFlag.bind(this);
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  setActiveFlag(activeFlag){
    let newUser = Object.assign({}, this.state.user, { active: activeFlag });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  addRole(role){
    if (!this.state.user.roles.find(r => r === role)) {
      let newRoles = this.state.user.roles.slice();
      newRoles.push(role);
      let newUser = Object.assign({}, this.state.user, { roles: newRoles });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }
  }

  removeRole(role){
    let index = this.state.user.roles.findIndex(r => r === role);
    if (index >= 0) {
      let newRoles = this.state.user.roles.slice();
      newRoles.splice(index, 1);
      let newUser = Object.assign({}, this.state.user, { roles: newRoles });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }
  }

  handleFieldUpdate(e) {
    let key = e.target.id;
    let value = e.target.value;
    let newUser = Object.assign({}, this.state.user, { [key]: value });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  putUpdates() {
    let user = this.state.user;
    let self = this;
    restApi.post('/users', {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      slackId: user.slackId,
      timezone: user.timezone,
      active: (user.active === true),
      roles: user.roles
    }).then((response) => {
      if (response.status === 201) {
        self.setState({
          open: false,
          user: {
            active: true,
            roles: []
          }
        });
        self.props.onRefresh();
      }
    }).catch((error) => {
      console.log("Post user failed: " + error);
    });
  }


  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
       onClick={this.handleClose}
      />,
      <FlatButton
        label="Submit"
        primary={true}
        disabled={false}
       onClick={this.putUpdates}
      />,
    ];

    return (
      <div>
        <RaisedButton label="New User" primary={true} onClick={this.handleOpen} />
        <Dialog
          title="Create User"
          actions={actions}
          modal={true}
          open={this.state.open}
        >
          <AdminUserEdit
            user={this.state.user}
            isNew={true}
            editable={true}
            addRole={this.addRole}
            removeRole={this.removeRole}
            setActiveFlag={this.setActiveFlag}
            handleFieldUpdate={this.handleFieldUpdate} />
        </Dialog>
      </div>
    );
  }
}

AdminUserCreate.propTypes = {
  onRefresh: PropTypes.func,
};
