import React, { Component } from 'react';
import {Dialog, FlatButton, RaisedButton} from 'material-ui';
import AdminCronTaskEntry from './AdminCronTaskEntry';

export default class AdminCronCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.handleClose}
      />
    ];
    return (
      <div>
        <RaisedButton label="New" primary={true} onClick={this.handleOpen} />
        <Dialog
          title="Create New Cron Task"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
          actions={actions}
        >
          <AdminCronTaskEntry onRefresh={this.props.onRefresh} new={true} hideDrawer={true} />
        </Dialog>
      </div>
    );
  }
}
