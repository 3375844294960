import * as types from './actionTypes';

export function getModelProfilesSuccess(res) {
  return {
    type: types.GET_MODELS_LIST_SUCCESS,
    data: res.data
  };
}
export function getModelProfilesError() {
  return {
    type: types.GET_MODELS_LIST_FAIL,
    data: "failed getting models list"
  }
}

export function setSelectedModels(data) {
  return {
    type: types.SET_SELECTED_MODELS,
    data
  }
}
export function publishMultipleModelsSuccess() {
  return {
    type: types.SET_PUBLISH_MULTIPLE_MODELS_SUCCESS
  }
}
export function publishMultipleModelsError() {
  return {
    type: types.SET_PUBLISH_MULTIPLE_MODELS_ERROR
  }
}
export function publishMultipleModelsPending() {
  return {
    type: types.PUBLISH_MULTIPLE_MODELS_PENDING,
  }
}
export function updateProfileSuccess() {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
  }
}
export function updateProfileError() {
  return {
    type: types.UPDATE_PROFILE_ERROR,
  }
}
export function queryModelData(data) {
  return {
    type: types.QUERY_MODEL_DATA,
    data
  }
}