import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Dialog, RaisedButton, Paper, TextField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import { filter, pickBy } from 'lodash';
import { setSelectedModels, queryModelData } from "../actions/modelProfileActions";
import { connect } from "react-redux";
import store from '../services/store';

const styles = {
  searchBox: {
    marginBottom: '10px' 
  },
  buttonMargin: {
    margin: '10px'
  }
};

class PhotoStudioSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelCode: null,
      agency: null,
      firstName: null,
      lastName: null,
      notes: null
    };
    this.simpleSearchSubmit = this.simpleSearchSubmit.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.refreshSearch = this.refreshSearch.bind(this);
  }

  handleModelCodeTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { modelCode: text.length > 0 ? text : null }));
  };

  handleAgencyNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { agency: text.length > 0 ? text : null }));
  };

  handleFirstNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { firstName: text.length > 0 ? text : null }));
  };

  handleLastNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { lastName: text.length > 0 ? text : null }));
  };

  handleNotesTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { notes: text.length > 0 ? text : null }));
  };

  simpleSearchSubmit() {
    store.dispatch(setSelectedModels([]));
    const searchQuery = pickBy(this.state);
    const filterdata = filter(this.props.modelsList, searchQuery);
    if (filterdata.length === 0) {
      this.setState(Object.assign({}, this.state,{ showDialog: true }));
    }
    store.dispatch(queryModelData(filterdata));
  };

  refreshSearch() {
    store.dispatch(queryModelData([]));
    this.setState({
      modelCode: null,
      agency: null,
      firstName: null,
      lastName: null,
      notes: null,
    });
  };

  closeDialog() {
    this.setState({showDialog: false});
  };

  render() {
    const actions = [
      <RaisedButton
        label="Ok"
        primary={true}
        onClick={this.closeDialog}
      />,
    ];
    return (
      <Paper>
        <Toolbar>
          <ToolbarGroup className="hide-mobile">
              <ToolbarTitle text="Search model data" />
          </ToolbarGroup>
        </Toolbar>
        <div style={styles.searchBox}>
          <Grid fluid>
              <Row>
                <Col xs={12} md={3}>
                  <TextField
                    floatingLabelText="Model Code"
                    onChange={this.handleModelCodeTextChange}
                    id="modelCode"
                    value={this.state.modelCode || ''}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    floatingLabelText="Agency Name"
                    onChange={this.handleAgencyNameTextChange}
                    id="agency"
                    value={this.state.agency || ''}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    floatingLabelText="First Name"
                    onChange={this.handleFirstNameTextChange}
                    id="firstName"
                    value={this.state.firstName || ''}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    floatingLabelText="Last Name"
                    onChange={this.handleLastNameTextChange}
                    id="lastName"
                    value={this.state.lastName || ''}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    floatingLabelText="Notes"
                    onChange={this.handleNotesTextChange}
                    id="notes"
                    value={this.state.notes || ''}
                  />
                </Col>
              </Row>
              <Row>
                <RaisedButton primary={true} label="Search" style={styles.buttonMargin} onClick={ this.simpleSearchSubmit }/>
                <RaisedButton primary={true} label="Reset" style={styles.buttonMargin} onClick={ this.refreshSearch }/>
              </Row>
            </Grid>
            <Dialog
              actions={actions}
              modal={false}
              open={this.state.showDialog}
              onRequestClose={this.handleClose}
            >
              No results found!
            </Dialog>
          </div>
      </Paper>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    modelsList: store.photoStudioState.modelsList
  };
};

export const PhotoStudioSearch = connect(mapStateToProps)(PhotoStudioSearchContainer);
