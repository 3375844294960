import React from 'react';
import Swagger from './Swagger';
import { Route, Switch } from 'react-router-dom'

const SwaggerRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/swagger" component={Swagger}/>
    </Switch>
  </div>
);

export default SwaggerRoutes;
