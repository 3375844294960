import store from '../services/store';
import { getLoaderStatus } from '../actions/inventoryActions';

import { inventoryRestApi } from '../config';

function setLoadingStatus(loading) {
  store.dispatch(getLoaderStatus(loading))
}

export async function makeInventoryRequest(onSuccessDispatch, onFailureDispatch, apiUrl, requestType, body, miscToReducer) {
  setLoadingStatus(true);
  try {
    let response = requestType === "get" ? await inventoryRestApi.get(apiUrl) : await inventoryRestApi.post(apiUrl, body);
    store.dispatch(onSuccessDispatch(response, miscToReducer));
    setLoadingStatus(false);
  } catch (error) {
    console.error(`Request at ${apiUrl} failed: ${error}`);
    store.dispatch(onFailureDispatch(error));
    setLoadingStatus(false);
  }
}
