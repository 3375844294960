import React, { Component } from 'react';
import { FlatButton, MenuItem, RaisedButton, Snackbar, Toolbar, ToolbarGroup } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, SelectField } from 'redux-form-material-ui';
import { Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PhotoStudioBreadCrumbs from './PhotoStudioBreadCrumbs';
import { updateModelProfile } from '../api/modelProfileApi.js'
import { messages } from './messages';
import { validate } from './validator';

export const styles = {
  actionsContainer: {
    margin: '10px 0',
    textAlign: 'right',
    border: '2px solid #aaa'
  },
  headerText: {
    paddingTop: '20px',
    paddingLeft: '10px'
  },
  newBtn: {
    textAlign: "right",
    width: '100%'
  },
  colStyles: {
    height: '30px'
  },
  editForm: {
    padding: '20px',
    background: '#eee',
    marginBottom: '10px'
  },
  formHeading: {
    margin: '20px 0 0',
    borderBottom: '2px solid #aaa',
    padding: '0 0 10px',
    overflow: 'auto',
    color: '#005299'
  },
  formDiv: {
    padding: '40px',
    border: '2px solid #ccc',
    marginBottom: '20px',
    borderRadius: '10px',
    background: '#fff',
    paddingTop: '0px'
  },
  snackbarstyl: {
    top:'30%',
    bottom:'50%'
  }
};

class UpdateModelCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatePending: false,
      updateSuccess: false,
      updateError: false,
      updateErrorMsg: null,
      disableTextFields: this.props.type === "View"
    }
    this.updateModelData = this.updateModelData.bind(this);
  }
  updateModelData(body) {
    const {history} = this.props
    this.setState({updatePending: true})

    updateModelProfile(body).then( ({data})=> {
      this.setState({updatePending: false, updateSuccess: true, updateError: false});
      setTimeout(() => {
        history.push(`/photostudio`)
      }, 1000);
    }).catch((error) =>{
      this.setState({updatePending: false, updateErrorMsg: `${error}`});
      this.setState({ updateError: true }, () => {
      setTimeout(() => {
          this.setState({ updateError: false, updateErrorMsg: null });
        }, 1500);
      });
    })
  }

  handleClick(e){
    this.setState({disableTextFields:!this.state.disableTextFields});
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, initialValues:{gender}, invalid } = this.props;
    return (
      <div>
        <div>
          <PhotoStudioBreadCrumbs type={this.props.type} />
        </div>
        {this.state.disableTextFields ? <Toolbar>
          <ToolbarGroup lastChild={true} float="right">
            <RaisedButton primary={true} style={styles.buttonMargin} value="Enable/Disable" label="Edit" onClick={this.handleClick.bind(this)}/>
          </ToolbarGroup>
        </Toolbar> : ""}
        <form onSubmit={handleSubmit(this.updateModelData)} style={styles.editForm}>
          <div style={styles.formDiv}>
            <h3 className="col-12 m-0" style={styles.formHeading}>Agency Details</h3>
            <Row>
              <Col xs={12} sm={6} style={styles.colStyles}>
                <Field
                  name="modelCode"
                  component={TextField}
                  id="modelCode"
                  hintText="053"
                  floatingLabelText="Model Code *"
                  fullWidth={true}
                  readOnly={true}
                  floatingLabelFixed={true}
                  helperText="Not editable"
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="agency"
                  component={TextField}
                  id="agency"
                  hintText="SMG"
                  floatingLabelText="Agency"
                  floatingLabelFixed={true}
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  fullWidth={true}
                />
              </Col>
            </Row>
          </div>
          <div style={styles.formDiv}>
            <h3 className="col-12" style={styles.formHeading}>Model Details</h3>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="firstName"
                  component={TextField}
                  id="firstName"
                  hintText="John"
                  floatingLabelText="First Name *"
                  fullWidth={true}
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="lastName"
                  component={TextField}
                  id="lastName"
                  hintText="Smith"
                  fullWidth={true}
                  floatingLabelText="Last Name"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>
            </Row>
          </div>
          <div style={styles.formDiv}>
            <h3 className="col-12" style={styles.formHeading}>Model Profile</h3>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  name="gender"
                  component={SelectField}
                  id="gender"
                  hintText="F/M"
                  floatingLabelText="Gender *"
                  fullWidth={true}
                  value={gender}
                  labelStyle={{ color: 'black' }}
                  disabled={submitting || this.state.disableTextFields}
                  floatingLabelFixed={true}>
                    <MenuItem value={"F"} primaryText="Female" />
                    <MenuItem value={"M"} primaryText="Male" />
                </Field>
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="height"
                  component={TextField}
                  id="height"
                  hintText="6'3''"
                  fullWidth={true}
                  floatingLabelText="Height"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="bust"
                  component={TextField}
                  id="bust"
                  hintText="44''"
                  fullWidth={true}
                  floatingLabelText="Bust"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="waist"
                  component={TextField}
                  id="waist"
                  hintText="38''"
                  fullWidth={true}
                  floatingLabelText="Waist"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="hips"
                  component={TextField}
                  id="hips"
                  hintText="47''"
                  fullWidth={true}
                  floatingLabelText="Hips"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="inseam"
                  component={TextField}
                  id="inseam"
                  hintText="36''"
                  fullWidth={true}
                  floatingLabelText="Inseam"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="size"
                  component={TextField}
                  id="size"
                  hintText="XS/S/M/L/XL/2XL/3XL"
                  fullWidth={true}
                  floatingLabelText="Size"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="shoe"
                  component={TextField}
                  id="shoe"
                  hintText="8.5"
                  fullWidth={true}
                  floatingLabelText="Shoe"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>

              <Col xs={12} sm={6}>
                <Field
                  name="note1"
                  component={TextField}
                  id="note1"
                  hintText="Model Description"
                  fullWidth={true}
                  floatingLabelText="Note 1"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="note2"
                  component={TextField}
                  id="note2"
                  hintText="Model Description"
                  fullWidth={true}
                  floatingLabelText="Note 2"
                  disabled={submitting}
                  readOnly={this.state.disableTextFields}
                  floatingLabelFixed={true}
                />
              </Col>
            </Row>
          </div>
          <div>
          <label>* - Required Values</label>
            {!this.state.disableTextFields ?
              <Row>
                <Col xs={12}>
                  <div style={styles.actionsContainer}>
                    <FlatButton
                      label="Save"
                      type="submit"
                      primary={true}
                      disabled={submitting || invalid}>
                      {submitting &&
                        <FontAwesomeIcon icon='sync' spin />
                      }
                    </FlatButton>
                    <FlatButton
                      label="Clear"
                      disabled={pristine || submitting}
                      onClick={reset}
                    />
                    <Link to="/photostudio">
                      <FlatButton
                        label="Cancel"
                        disabled={submitting}
                        primary={true}
                        onClick={this.props.closeButton}
                      />
                    </Link>
                  </div>
                </Col>
              </Row>
            :""}

          </div>
        </form>

        <Snackbar
          bodyStyle={{ backgroundColor: 'green', color: 'coral' }}
          style={styles.snackbarstyl}
          open={this.state.updateSuccess}
          message={messages.updateSuccess}
          autoHideDuration={2000}
        />
        <Snackbar
          style={styles.snackbarstyl}
          bodyStyle={{ backgroundColor: 'red', color: 'coral' }}
          open={this.state.updateError}
          message={this.state.updateErrorMsg}
          autoHideDuration={2000}
        />
      </div>
    )
  }
}

const UpdateReduxFormModelCodes = reduxForm({
  form: 'UpdateModelCodeForm',
  validate,
  enableReinitialize: true,
})(UpdateModelCodes);

const mapStateToProps = function(store) {
  return {
    initialValues: store.photoStudioState.selectedModels[0]
  }
}

export default withRouter(connect(mapStateToProps, {})( UpdateReduxFormModelCodes));
