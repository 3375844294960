import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { PageHeader } from '../Components';
import { okta } from '../config';

export default function Login(props) {
  console.log("Redirect to " + okta.authUrl);
  window.location.assign(okta.authUrl);
  return (
    <Row>
      <Col xs={12} md={12}>
        <PageHeader>Login</PageHeader>
      </Col>
    </Row>
  )
};
