import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from 'material-ui/Dialog';
import { Row, Col } from 'react-flexbox-grid';
import { FlatButton, IconButton, Snackbar } from 'material-ui';
import { couponRestApi } from '../config';
import ContentAddCircle from 'material-ui/svg-icons/content/add-circle';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

export const styles = {
  actionsContainer: {
    margin: '10px 0'
  }
};

export const validate = values => {
  const errors = {};
  if (!values.numCodes) {
    errors.numCodes = 'Please enter the number of codes'
  } else if (isNaN(values.numCodes)) {
    errors.numCodes = 'Please enter a number'
  }
  if (!values.description) {
    errors.description = 'Please enter a description'
  }
  return errors
};

export class CouponAllocationForm extends Component {
  render() {
    const {handleSubmit, pristine, reset, submitting, submitSucceeded, codesAvailable} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Row>
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="numCodes"
                component={TextField}
                id="allocationNumCodes"
                hintText={codesAvailable + " codes remaining"}
                floatingLabelText="Number of Codes*"
                fullWidth={true}
                disabled={submitting}
                validate={ value => {
                  if (!value || value < 1) {
                    return 'Please enter the number of codes'
                  } else if (isNaN(value)) {
                    return 'Please enter a number'
                  } else if (value > codesAvailable) {
                    return 'Only ' + codesAvailable + ' codes available to be assigned'
                  }
                }}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="requestedBy"
                component={TextField}
                id="allocationRequester"
                hintText="Who is requesting the codes?"
                floatingLabelText="Requester Name"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12}>
              <Field
                name="description"
                component={TextField}
                id="allocationDesc"
                hintText="Enter a short description"
                floatingLabelText="Description*"
                multiLine={true}
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
          <Col xs={12}>
            <Field
              name="issueId"
              component={TextField}
              id="allocationIssueId"
              hintText="JIRA Issue #"
              floatingLabelText="JIRA Issue"
              fullWidth={true}
              disabled={submitting}
            />
          </Col>
          }
          <Col xs={12}>
            {submitSucceeded &&
            <p>
              Coupon codes successfully allocated!
            </p>
            }
            <div style={styles.actionsContainer}>
              {!submitSucceeded &&
                <span>
                  <FlatButton
                    label={!submitting ? 'Submit' : ''}
                    type="submit"
                    primary={true}
                    disabled={submitting}
                  >
                    {submitting &&
                    <FontAwesomeIcon icon='sync' spin/>
                    }
                  </FlatButton>
                  <FlatButton
                    label="Clear"
                    disabled={pristine || submitting}
                    onClick={reset}
                  />
                </span>
              }
              <FlatButton
                label="Close"
                onClick={this.props.closeButton}
                disabled={submitting}
                primary={submitSucceeded}
              />
            </div>
          </Col>
        </Row>
      </form>
    )
  };
}

CouponAllocationForm = reduxForm({
  form: 'CouponAllocationForm',
  validate
})(CouponAllocationForm);

export default class CreateAllocationBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alert: false,
      alertMsg: ''
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleAlertOpen = () => {
    this.setState({alert: true});
  };

  handleAlertClose = () => {
    this.setState({alert: false});
  };

  submit = (body) => (
    couponRestApi.post('/coupons/' + this.props.couponId + "/allocate?numCodes=" + body.numCodes, {
      "description": body.description,
      "issueId": body.issueId,
      "requestedBy": body.requestedBy
    }).then(() => {
      this.props.onRefresh && this.props.onRefresh();
      this.setState({alertMsg: "Allocation block initializing"});
      this.handleClose();
      this.handleAlertOpen();
    }).catch((error) => {
      console.log("Post coupon failed: " + error);
      this.setState({alertMsg: "Allocation block creation failed."});
      this.handleClose();
      this.handleAlertOpen();
    })
  );

  render() {
    return (
      <div>
        <IconButton tooltip="Assign Codes" onClick={this.handleOpen}>
          <ContentAddCircle color={ this.props.color } />
        </IconButton>
        <Dialog
          title="Assign Coupon Codes"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <CouponAllocationForm onSubmit={this.submit} closeButton={this.handleClose} codesAvailable={this.props.codesAvailable}/>
        </Dialog>
        <Snackbar
          open={this.state.alert}
          message={this.state.alertMsg}
          autoHideDuration={4000}
          onRequestClose={this.handleAlertClose}
        />
      </div>
    );
  }
}
