import * as types from '../actions/actionTypes';

const initialState = {
  inventoryList: [],
  inventoryRecords: []
};

const inventoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_INVENTORY_LIST_SUCCESS:
      return { ...state, inventoryList: action.inventoryList, inventoryListFailed: null };

    case types.GET_ALL_INVENTORY_LIST_FAILED:
      return { ...state, inventoryList: [], inventoryListFailed: action.error };

    case types.SEARCH_INVENTORY_LIST_SUCCESS:
      return { ...state, inventoryList: { data: action.inventoryList.data.hits }, inventoryListFailed: null };

    case types.SEARCH_INVENTORY_LIST_FAILED:
      return { ...state, inventoryList: [], inventoryListFailed: action.error };

    case types.GET_INVENTORY_RECORDS_SUCCESS:
      return {
        ...state,
        count: action.inventoryRecords.data.count,
        inventoryRecords: action.inventoryRecords.data.records,
        start: action.inventoryRecords.data.start,
        total: action.inventoryRecords.data.total,
        inventoryRecordsFailed: null
      };

    case types.GET_INVENTORY_RECORDS_FAILED:
      return { ...state, inventoryRecords: [], inventoryRecordsFailed: action.error };

    case types.UPDATE_PRODUCT_INVENTORY_RECORD_SUCCESS:
      return { ...state, inventoryRecords: action.inventoryRecords, inventoryRecordsUpdateSuccess: true, inventoryRecordsFailed: null };

    case types.UPDATE_PRODUCT_INVENTORY_RECORD_FAILED:
      return { ...state, inventoryRecordsUpdateSuccess: false, inventoryRecordsFailed: action.error };

    case types.SUCCESSFULLY_ALERTED:
      return { ...state, inventoryRecordsUpdateSuccess: null, inventoryRecordsFailed: null, inventoryListFailed: null };

    case types.GET_LOADER_STATUS:
      return {...state, loading: action.loading };

    default:
      return state;
  }
};

export default inventoryReducer;
