import React from 'react'
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { hasRoles } from '../../../../api/userApi';
import SandboxTableRow from './SandboxTableRow';

function SandboxTable({ classes, handleUpdateClick, isLoading, sandboxes }) {
  const sandboxList = sandboxes.length
    ? sandboxes.map(sandbox => <SandboxTableRow sandbox={sandbox} key={sandbox.id} classes={classes} handleUpdateClick={handleUpdateClick}/>)
    : <TableRow>
        <TableCell align='center' colSpan={9}>
          {isLoading ? '...Loading' : 'No Sandboxes to Display'}
        </TableCell>
      </TableRow>

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table} aria-label='sfcc sandbox table'>
        <TableHead>
          <TableRow>
            <TableCell size='small' className={classes.th}>User</TableCell>
            { hasRoles('start:sandbox') &&
            <TableCell size='small' align='left' className={classes.thOperation}>
              Start/Stop
            </TableCell>
            }
            <TableCell size='small' align='left' className={classes.th}>Sandbox UUID</TableCell>
            <TableCell size='small' align='left' className={classes.th}>Sandbox No.</TableCell>
            <TableCell size='small' align='left' className={classes.th}>CreatedAt (Local Time)</TableCell>
            <TableCell size='small' align='left' className={classes.th}>End of Life (Local Time)</TableCell>
            <TableCell size='small' align='left' className={classes.th}>Status</TableCell>
            <TableCell size='small' align='left' className={classes.thUpTime}>Uptime (Hrs)</TableCell>
            <TableCell size='small' align='left' className={classes.th}>Downtime (Hrs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sandboxList}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

SandboxTable.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  handleUpdateClick: PropTypes.func,
  sandboxes: PropTypes.array
}

export default SandboxTable;
