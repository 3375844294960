import React, { Component } from 'react';
import { Paper } from 'material-ui';

import { ToolbarDynamic } from '../components/ToolbarDynamic';
import { NotifyMeSearchResults } from './NotifyMeSearchResults';
import { NotifyMeStats } from './NotifyMeStats';
import { getNotifyMeStats, setCurrentNotifyMeSite, storePerPage, searchNotifyMeRecords } from "../api/notifyMeApi";
import { setNotifyMeItemsPerPage } from "../actions/notifyMeActions";
import NotifyMeBreadCrumbs from "./NotifyMeBreadCrumbs";
import {grey500} from "material-ui/styles/colors";

const DEFAULT_SITE = 'Columbia_US';

class NotifyMe extends Component {

  componentDidMount() {
    if (!this.props.notifyMeSiteId) {
      setCurrentNotifyMeSite(DEFAULT_SITE);
    }
    searchNotifyMeRecords(DEFAULT_SITE, 'all');
    getNotifyMeStats(DEFAULT_SITE);
    storePerPage(10);
    setNotifyMeItemsPerPage(10);
  }

  render() {
    const styles = {
      icon: {
        marginRight: '20px',
        color: grey500
      }
    };
    return (
      <div>
        <NotifyMeBreadCrumbs />
        <Paper>
          <ToolbarDynamic title="Notify Me" icon="user-clock" iconStyle={styles.icon}/>
          <NotifyMeStats />
          <NotifyMeSearchResults />
        </Paper>
      </div>
    );
  }
}

export default NotifyMe;
