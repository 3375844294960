import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
//import { Link } from "react-router-dom";
import { IconButton, MenuItem, RaisedButton, SelectField, Table, TableBody, TableHeader,
  TableRow, TableRowColumn, TableHeaderColumn } from 'material-ui';
import { getLambdaStatistics, storeLambdaStatisticsPerPage, clearLambdaStatistics } from "../api/utilsApi";
import LoaderComponent from '../components/Loader';

export const styles = {
  loader: {
    margin: '20px 0 20px 0'
  },
  tableRowColumn: {
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  },
  tableLastRowColumn: {
    fontWeight: 'bold'
  },
  link: {
    color: 'rgb(0, 136, 206)'
  },
  textCenter: {
    textAlign: 'center'
  }
};

class AWSServicesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      take: 100
    };
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.perPage)
      this.setState({perPage: nextProps.perPage});

    if(nextProps.error)
      alert(`Get lambda statistics records failed: ${nextProps.error.message}`);
  }

  componentDidMount () {
    clearLambdaStatistics();
    getLambdaStatistics();
    storeLambdaStatisticsPerPage(this.state.take);
  }

  showTable = () => {
    if (this.props.lambdaStatistics && this.props.lambdaStatistics.length) {
      return this.props.lambdaStatistics
        .slice(this.state.page * this.state.perPage, this.state.page * this.state.perPage + this.state.perPage)
        .map((l, i) => {
          return (
            <TableRow key={i} style={ i === this.props.lambdaStatistics.length -1 ? styles.tableLastRowColumn : null }>
              <TableRowColumn style={styles.tableRowColumn}>
                { l.FunctionName }
              </TableRowColumn>
              <TableRowColumn>{ l.PeriodCostDay }</TableRowColumn>
              <TableRowColumn>{ l.PeriodCostMonth }</TableRowColumn>
              <TableRowColumn>{ l.SumInvocations }</TableRowColumn>
              <TableRowColumn>{ l.AverageDuration }</TableRowColumn>
            </TableRow>
          );
        });
    } else {
      return (
        <TableRow key={0}>
        <TableRowColumn>No Results</TableRowColumn>
      </TableRow>
      )
    }
  };

  refreshBtnSubmit = () => {
    clearLambdaStatistics();
    getLambdaStatistics();
    storeLambdaStatisticsPerPage(this.state.take);
  };

  handleTakeChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { take: value }));
  };

  handleBackClick = (event) => {
    let newPage = this.props.lambdaStatistics && (this.state.page !== 0) ? (this.state.page - 1) : this.state.page;
    this.setState({ page: newPage });
  };

  handleForwardClick = (event) => {
    if(this.props.lambdaStatistics) {
      if( (this.state.page + 1) < Math.ceil(this.props.lambdaStatistics.length / this.state.perPage))
        this.setState({ page: (this.state.page + 1) });
    }
  };

  render() {
    let currentPage = this.state.page + 1;
    let totalPages = this.props.lambdaStatistics ? (this.props.lambdaStatistics.length / this.state.perPage) : 1;
    let tableResults = this.showTable();
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            <SelectField
              floatingLabelText="Results per page"
              value={this.state.take}
              onChange={this.handleTakeChange}
            >
              <MenuItem value={10} primaryText="10" />
              <MenuItem value={25} primaryText="25" />
              <MenuItem value={50} primaryText="50" />
              <MenuItem value={100} primaryText="100" />
            </SelectField>
          </Col>
          <Col xs={12} md={4}>
            <LoaderComponent />
          </Col>
          <Col xs={12} md={4} style={{textAlign: "right"}}>
            <RaisedButton
              primary={true}
              label="Refresh"
              onClick={this.refreshBtnSubmit} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table>
              <TableHeader displaySelectAll={false}>
                <TableRow style={styles.tableLastRowColumn}>
                  <TableHeaderColumn style={styles.tableRowColumn}>Service Name</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Cost Per Day</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Cost Per Month</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Invocations Per Day</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Average Duration Per Invocation</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                { tableResults }
              </TableBody>
            </Table>
          </Col>
        </Row>
        <Row end="xs">
          <Col xs={12}>
            {currentPage} of {Math.ceil(totalPages)}
            <IconButton iconClassName="fa fa-angle-left" onClick={this.handleBackClick} />
            <IconButton iconClassName="fa fa-angle-right" onClick={this.handleForwardClick} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    lambdaStatistics: store.utilsState.lambdaStatistics,
    perPage: store.utilsState.perPage,
    error: store.utilsState.searchFailed,
    loading: store.utilsState.loading
  };
};

export const AWSServicesList = connect(mapStateToProps)(AWSServicesListContainer);
