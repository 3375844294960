import React, { Component } from 'react';
import {Paper, Tab, Tabs, Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui';
import SwipeableViews from 'react-swipeable-views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderComponent from '../components/Loader';

import { VipSearchComplex } from './VipSearchComplex';
import { VipSearchSimple } from './VipSearchSimple';
import { VipSearchResults } from './VipSearchResults';
import VipAddCustomer  from './VipAddCustomer';
import { VipStats } from './VipStats';
import VipBreadCrumbs from "./VipBreadCrumbs";
import {grey500} from "material-ui/styles/colors";
import {connect} from "react-redux";
import { hasRoles } from '../api/userApi';

export const styles = {
  container: {
    padding: '20px'
  },
  toolbarMargin: {
    marginTop: '40px'
  },
  searchBtn: {
    marginTop: 10,
    marginRight: 10
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};


class Vip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      searchType: 'simple',
      perPage: 10
    };
  }

  handleChange = (value) => {
    this.setState({ slideIndex: value });
  };

  render() {
    let title = this.props.siteId === "Prana_US" ? "Influencers" : "Pro Purchase Customers";
    return (
        <div style={{paddingBottom: '20px'}}>
          <VipBreadCrumbs siteId={this.props.siteId}/>
          <Paper>
            <Toolbar>
              <ToolbarGroup>
                <FontAwesomeIcon icon={["far","star"]} size="2x" fixedWidth style={styles.icon}/>
                <ToolbarTitle text={title}/>
                </ToolbarGroup>
                { hasRoles('write:vipCustomers') &&
                  <VipAddCustomer />
                }
              <LoaderComponent />
            </Toolbar>
            <VipStats />
            <div>
              <Tabs
                onChange={this.handleChange}
                value={this.state.slideIndex}
              >
                <Tab label="Simple" value={0} icon={<FontAwesomeIcon icon="cog" fixedWidth />}/>
                <Tab label="Complex" value={1} icon={<FontAwesomeIcon icon="cogs" fixedWidth />}/>
              </Tabs>
              <SwipeableViews
                index={this.state.slideIndex}
                onChangeIndex={this.handleChange}>
                <div>
                  <VipSearchSimple />
                </div>
                <div>
                  <VipSearchComplex />
                </div>
              </SwipeableViews>
              <VipSearchResults />
            </div>
          </Paper>
        </div>
    );
  }
}



const mapStateToProps = function(store) {
  return {
    siteId: store.vipState.siteId
  };
};

export default connect(mapStateToProps)(Vip);

