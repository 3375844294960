import * as types from '../actions/actionTypes';

const initialState = {
  translatedText: '',
  swaggerUrls: [],
  swaggerDoc: {},
  lambdaStatistics: [],
  seoLintReport: [],
  loading: false
};

const utilsReducer = function(state = initialState, action) {
  switch(action.type) {
    case types.TRANSLATE_TEXT_SUCCESS:
      return Object.assign({}, state, { translatedText: action.translatedText });

    case types.TRANSLATE_TEXT_FAILED:
      return Object.assign({}, state, { translatedText: null, translateTextFailed: action.error });

    case types.GET_SWAGGER_URLS_SUCCESS:
      return Object.assign({}, state, { swaggerUrls: action.swaggerUrls });

    case types.GET_SWAGGER_URLS_FAILED:
      return Object.assign({}, state, { swaggerUrls: null, swaggerFailed: action.error });

    case types.GET_SWAGGER_DOC_SUCCESS:
      return Object.assign({}, state, { response: action.response });

    case types.GET_SWAGGER_DOC_FAILED:
      return Object.assign({}, state, { response: null, swaggerFailed: action.error });

    case types.GET_LAMBDA_STATISTICS_SUCCESS:
      return Object.assign({}, state, { lambdaStatistics: action.lambdaStatistics, lambdaStatisticsFailed: action.error });

    case types.GET_SERVICE_STATUS_SUCCESS:
      return Object.assign({}, state, { serviceStatus: action.serviceStatus });

    case types.GET_LAMBDA_STATISTICS_FAILED:
      return Object.assign({}, state, { lambdaStatistics: null, lambdaStatisticsFailed: action.error });

    case types.SET_GET_LAMBDA_STATISTICS_RECORDS_PER_PAGE:
      return Object.assign({}, state, { perPage: action.perPage });

    case types.GET_LOADER_STATUS:
      return Object.assign({}, state, { loading: action.loading });

    case types.SEO_LINT_SUCCESS:
      return Object.assign({}, state, { seoLintReport: action.seoLintReport });

    case types.SEO_LINT_FAILED:
      return Object.assign({}, state, { seoLintReport: null, seoLintReportFailed: action.error });

    case types.GET_AWS_SERVICES_SUCCESS:
      return Object.assign({}, state, { awsServices: action.awsServices });

    case types.GET_AWS_SERVICES_FAILED:
      return Object.assign({}, state, { awsServices: null, awsServicesFailed: action.error });

    case types.SET_AWS_SERVICES_SUCCESS:
      return Object.assign({}, state, { awsServices: action.awsServices });

    case types.ENCRYPT_FAILED:
      return Object.assign({}, state, { encryptedData: null, encryptFailed: action.error });

    case types.ENCRYPT_SUCCESS:
      return Object.assign({}, state, { encryptedData: action.encryptedData });

    case types.DECRYPT_FAILED:
      return Object.assign({}, state, { plainData: null, decryptFailed: action.error });

    case types.DECRYPT_SUCCESS:
      return Object.assign({}, state, { plainData: action.plainData });

    case types.GET_SERVICES_LIST_FAILED:
      return Object.assign({}, state, { servicesList: null, getServicesListFailed: action.error });

    case types.GET_SERVICES_LIST_SUCCESS:
      return Object.assign({}, state, { servicesList: action.servicesList });

    case types.GET_SERVICE_CONFIGURATION_FAILED:
      return Object.assign({}, state, { serviceConfiguration: null, getServicesConfigurationFailed: action.error });

    case types.GET_SERVICE_CONFIGURATION_SUCCESS:
      return Object.assign({}, state, { serviceConfiguration: action.serviceConfiguration });

    default:
      return state;
  }
};

export default utilsReducer;
