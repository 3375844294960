import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { sandboxRestApi } from '../../../config';
import FlexibleSnackBar from '../elements/FlexibleSnackBar';

export default function StartStopSandbox({ handleUpdateClick, state, uuid }) {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isDisabled, setisDisabled] = useState(false);
  const [sandboxState, setSandboxState] = useState(state)
  const anchorRef = useRef(null);
  const [snack, setSnack] = useState({});

  useEffect(() => {
    setSandboxState(state)
  },[state])

  const isStopOperation = (sandboxState === 'stopped' || sandboxState === 'stopping' || sandboxState === 'creating');
  const operationType = isStopOperation ? 'start' : 'stop';
  const options = ['restart', operationType];

  const handleClick = () => {
    const oldState = isStopOperation ? 'stop' : 'start';
    setisDisabled(true);
    handleUpdateClick(uuid, operationType);
    sandboxRestApi.post(`/sandboxes/${uuid}/operations`, {'operation': options[selectedIndex]}).then(() => {
      setSnack({
        open: true,
        message: `Sandbox successfully performed the ${options[selectedIndex]} operation`,
        severity: 'success'
      })
    }).catch((e) => {
      setSnack({
        open: true,
        message: `Sandbox failed to perform the ${options[selectedIndex]} operation with error: ${e}`,
        severity: 'error'
      })
      handleUpdateClick(uuid, oldState, true);
      console.log(`${options[selectedIndex]} sandbox failed for sandbox ${uuid}: ${e}`);
    }).finally(() => {
      setisDisabled(false);
    })
  }

  const handleMenuItemClick = (e, index) => {
    setSelectedIndex(index);
    setOpen(false);
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }

    setOpen(false)
  };

  return (
    <Fragment>
      <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='sandbox operation'>
        <Button onClick={handleClick} disabled={isDisabled}>{options[selectedIndex]}</Button>
        <Button
          color='primary'
          size='small'
          aria-controls={open ? 'sandbox-operation-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select sandbox operation'
          aria-haspopup='menu'
          onClick={handleToggle}
          disabled={isDisabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id='sandbox-operation-menu'>
                {options.map((option, index) => (
                <MenuItem
                  key={option}
                  disabled={index === 2}
                  selected={index === selectedIndex}
                  onClick={e => handleMenuItemClick(e, index)}
                >
                  {option}
                </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
        )}
      </Popper>
      <FlexibleSnackBar snack={snack} />
    </Fragment>
  )
}

StartStopSandbox.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func,
  state: PropTypes.string,
  uuid: PropTypes.string
}
