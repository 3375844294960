import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { css } from 'react-emotion';
import { PropagateLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { columbiaDarkBlue } from '../themes/brandColors';

const override = css`
    display: block;
    border-color: red;
    margin-left: auto;
    margin-right: auto;
    width: 10%
`;

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState(Object.assign({}, this.state, { loading: nextProps.loading }));
  }

  render () {
    return (
      <div className='sweet-loading'>
        {
          this.state.loading &&
        <PropagateLoader
          className={override}
          sizeUnit={"px"}
          size={15}
          color={columbiaDarkBlue}
          loading={this.state.loading}
        /> }
      </div>
    )
  }
}

LoaderComponent.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = function(store) {
  return {
    loading: store.utilsState.loading
  };
};

export default connect(mapStateToProps)(LoaderComponent);
