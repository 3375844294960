import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, FlatButton } from 'material-ui';
import { uploadMultipleModels } from '../api/modelProfileApi.js';
import { DuplicateModels } from './DuplicateModels';
const csv = require('csvtojson');

class UploadModelProfiles extends Component {

  constructor(props) {
    super();
    this.state = { open: props.open, showDuplicte: false, checkingForDuplicate: false }
    this.newModels = "";
    this.modelsToUpdte = [];
    this.duplicateModelsList = [];
  }

  submitProfiles = () => {
    if (this.newModels) {
      if (this.modelsToUpdte && this.modelsToUpdte.length) {
        this.newModels.newProfiles = [...this.newModels.newProfiles, ...this.modelsToUpdte];
      }
      uploadMultipleModels(this.newModels);
      this.props.handleClose();
    }
  }

  setModelsToUpdate(models) {
    this.modelsToUpdte = models;
  }

  handleUpload = (e) => {
    e.preventDefault();
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.readAsText(f);
    reader.onload = (e) => {
      const data = reader.result;
      csv().fromString(data).then((modelsList)=>{
        modelsList.map((model) => {
          model.modelCode = model.modelCode;
          return model;
        });
        this.newModels = { newProfiles: modelsList };
        this.checkForDuplicateModels();
        this.setState({ checkingForDuplicate: true });
      });
    }
  }

  checkForDuplicateModels() {
    const allModelCodes = this.props.modelsList.map(model => model.modelCode);
    const modelsWithOutDuplicates = this.newModels.newProfiles.filter(model => {
      if (allModelCodes.includes(model.modelCode)) {
        this.duplicateModelsList.push(model);
        return false;
      }
      return true;
    });

    if (this.duplicateModelsList.length) {
      this.newModels.newProfiles = modelsWithOutDuplicates;
      this.setState({ showDuplicte: true, checkingForDuplicate: false });
    }
  }

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.props.handleClose}
      />,
      <FlatButton
        label="Submit"
        primary={true}
        onClick={this.submitProfiles.bind(this)}
      />
    ];
    return (
      <Dialog
        title={!this.state.showDuplicte ? "Upload model Data" : "Duplicate Model Codes"}
        actions={!this.state.showDuplicte ? actions : []}
        modal={true}
        open={this.props.open}
      >
        <div>
          {this.state.showDuplicte ?
            <div>
              <DuplicateModels modelsList={this.duplicateModelsList} setModelsToUpdate={this.setModelsToUpdate.bind(this)}
                handleClose={this.props.handleClose}
                submitProfiles={this.submitProfiles.bind(this)}
              />
            </div>
            :
            <input type="file" onChange={this.handleUpload.bind(this)} />
          }
        </div>

      </Dialog>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    selectedModels: store.photoStudioState.selectedModels,
    modelsPublished: store.photoStudioState.modelsPublished,
    modelsPublishPending: store.photoStudioState.modelsPublishPending,
    modelsList: store.photoStudioState.modelsList,
  };
};

export default connect(mapStateToProps, {})(UploadModelProfiles);


