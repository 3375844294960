import axios from 'axios';

import config from './config.json';

export const comingSoon = (config.comingSoon === true);

export const appTitle = config.title;

export const uiBaseUrl = axios.create({
  baseURL: config.uiBaseUrl
});

export const restApi = axios.create({
  baseURL: config.restBaseUrl + "/api"
});

export const cronRestApi = !config.cronRestBaseUrl ? restApi : axios.create({
  baseURL: config.cronRestBaseUrl + "/api"
});

export const couponRestApi = !config.couponRestBaseUrl ? restApi : axios.create({
  baseURL: config.couponRestBaseUrl + "/api"
});
export const notlivereportRestApi = !config.notlivereportRestBaseUrl ? restApi : axios.create({
  baseURL: config.notlivereportRestBaseUrl + "/api"
});
export const emailRestApi = !config.emailRestBaseUrl ? restApi : axios.create({
  baseURL: config.emailRestBaseUrl + "/api"
});
export const emailEpsilonRestApi = !config.emailEpsilonBaseUrl ? restApi : axios.create({
  baseURL: config.emailEpsilonBaseUrl + "/api"
});

export const inventoryRestApi = !config.inventoryRestApi ? restApi : axios.create({
  baseURL: config.inventoryRestApi + "/api"
});

export const logsRestApi = !config.logsRestBaseUrl ? restApi : axios.create({
  baseURL: config.logsRestBaseUrl + "/api",
  timeout: 240000
});

export const vipRestApi = !config.vipRestBaseUrl ? restApi : axios.create({
  baseURL: config.vipRestBaseUrl + "/api"
});

export const utilsRestApi = !config.utilsRestBaseUrl ? restApi : axios.create({
  baseURL: config.utilsRestBaseUrl + "/api"
});

export const notifyMeRestApi = !config.notifyMeRestBaseUrl ? restApi : axios.create({
  baseURL: config.notifyMeRestBaseUrl + "/api"
});

export const orderHistoryRestApi = !config.orderHistoryRestBaseUrl ? restApi : axios.create({
  baseURL: config.orderHistoryRestBaseUrl + "/api"
});

export const exportOrdersRestApi = !config.exportOrdersRestBaseUrl ? restApi : axios.create({
  baseURL: config.exportOrdersRestBaseUrl + "/api"
});

export const modelDataRestApi = !config.modelDataRestBaseUrl ? restApi : axios.create({
  baseURL: config.modelDataRestBaseUrl + "/api"
});

export const sandboxRestApi = !config.sandboxRestBaseUrl ? restApi : axios.create({
  baseURL: config.sandboxRestBaseUrl + "/api"
});

export const cloudwatchRestApi = !config.cloudwatchRestBaseUrl ? restApi : axios.create({
  baseURL: config.cloudwatchRestBaseUrl + "/api"
});

export const authApi = axios.create({
  baseURL: config.restBaseUrl + "/auth"
});

export const axiosApi = axios.create({
  baseURL: ''
});

export const okta = {
  authUrl: config.oktaAuthUrl,
  samlRequest: config.oktaSamlRequest
};

export const azureAD = {
  authUrl: config.AzureADAuthUrl,
  samlRequest: config.AzureADSamlRequest
};

export const dashboardRestApi = !config.dashboardRestBaseUrl ? restApi : axios.create({
  baseURL: config.dashboardRestBaseUrl + "/api"
});

export const awsEnvironment = config.awsEnvironment;

export const awsBucketPrefix = config.awsBucketPrefix;

export const restBaseApi = config.restBaseUrl + '/api';

//authApi.defaults.headers.post['Content-Type'] = "application/json2";
//export const webUrlBase = "http://local.colmdev.com:3100";
//export const slackAuthProvider = "slack-cscdev-test-react-material";
//export const oktaAuthProvider = "okta-local";
//export const oktaAuthUrl = "https://dev-538448.oktapreview.com/app/columbiasportsweardev538448_magellanlocal_1/exk93g9zboMWpUxSz0h7/sso/saml";
