import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { hasRoles } from '../../../../api/userApi';
import CreateSandbox from '../../buttons/CreateSandbox';
import DeleteSandbox from '../../buttons/DeleteSandbox';
import UpdateSandbox from '../../buttons/UpdateSandbox';

function SandboxMobileMenu({ classes, onRefresh }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <Button variant='contained' color='primary' edge='end' aria-controls='smallMenu' aria-haspopup='true' onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id='smallMenu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { hasRoles('admin:sandbox') &&
        <MenuItem onClick={handleClose}>
          <CreateSandbox onRefresh={onRefresh} classes={classes}/>
        </MenuItem>
        }
        { hasRoles('write:sandbox') &&
        <MenuItem onClick={handleClose}>
          <UpdateSandbox onRefresh={onRefresh} classes={classes}/>
        </MenuItem>
        }
        { hasRoles('delete:sandbox') &&
        <MenuItem onClick={handleClose}>
          <DeleteSandbox onRefresh={onRefresh} classes={classes}/>
        </MenuItem>
        }
        <MenuItem onClick={handleClose}>
          <Button variant='contained' onClick={onRefresh} className={classes.buttonCanvas} color='secondary'>Refresh</Button>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

SandboxMobileMenu.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func
}

export default SandboxMobileMenu;
