import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Button,  Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { TextField} from 'redux-form-material-ui';
import { sandboxRestApi } from '../../../config';
import Transition from '../elements/Transition';
import FlexibleSnackBar from '../elements/FlexibleSnackBar';

const validate = values => {
  const errors = {};
  if (!values.sandboxId) {
    errors.sandboxId = 'Please enter the sandbox id';
  }
  if (!values.ttl) {
    errors.ttl = 'Please enter the ttl';
  }
  if (!Number.isInteger(Number(values.ttl, 10))) {
    errors.ttl = 'Please enter only integers';
  };
  return errors;
};

function UpdateSandboxForm ({ classes, closeButton, handleSubmit, pristine, reset, submitting })  {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='sandboxId'
            component={TextField}
            id='sandboxId'
            hintText='sandbox UUID'
            floatingLabelText='Sandbox UUID*'
            fullWidth={true}
          />
        </Col>
        }
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='ttl'
            component={TextField}
            id='ttl'
            hintText='0 (for no EndOfLife) or No. of hrs (max 2160)'
            floatingLabelText='Time to Live*'
            fullWidth={true}
            pattern='^[0-9]*$'
          />
        </Col>
        }
        <Col xs={12}>
          {submitting &&
          <p>
            Your request has been received.
            On-Demand Sandbox is being updated!
          </p>
          }
          <div className={classes.actionsContainer}>
            <DialogActions>
              {!submitting &&
              <Fragment>
                <Button type='submit' color='primary'>
                  Submit
                  {submitting &&
                  <FontAwesomeIcon icon='sync' spin/>
                  }
                </Button>
                <Button disabled={pristine} onClick={reset}>
                  Clear
                </Button>
              </Fragment>
              }
              <Button onClick={closeButton}>
                Close
              </Button>
            </DialogActions>
          </div>
        </Col>
      </Row>
    </form>
  )
}

const ValidateUpdateSandboxForm = reduxForm({
  form: 'updateSandboxForm',
  validate
})(UpdateSandboxForm);

export default function UpdateSandbox({ classes, onRefresh }) {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState({});
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleSubmit = (updateRequest) => (
    sandboxRestApi.patch(`/sandboxes/${updateRequest.sandboxId}`, {'ttl' : updateRequest.ttl.toString()}).then(() => {
      onRefresh && onRefresh();
    }).catch((e) => {
      setSnack({
        open: true,
        message: `Update sandbox failed ${JSON.stringify(updateRequest)}: ${e}`,
        severity: 'error'
      })
      console.log(`Update sandbox failed ${JSON.stringify(updateRequest)}: ${e}`);
    })
  );

  return (
    <Fragment>
      <Button variant='contained' color='primary' onClick={handleOpen} className={classes.button}>
        Update
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Update Sandbox</DialogTitle>
        <DialogContent>
          <ValidateUpdateSandboxForm classes={classes} onSubmit={handleSubmit} closeButton={handleClose} initialValues={{sandboxId: '', ttl: ''}} />
        </DialogContent>
      </Dialog>
      <FlexibleSnackBar snack={snack} />
    </Fragment>
  )
}

UpdateSandbox.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func
}
