import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItemIcon, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavMenuItem({ icon, title, func }) {
  const handleClick = e => {
    e.preventDefault();
    func();
  }

  return (
    <MenuItem button onClick={(e) => handleClick(e)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={icon} fixedWidth/>
      </ListItemIcon>
      <Typography>
        {title}
      </Typography>
    </MenuItem>
  )
}

export default NavMenuItem;

NavMenuItem.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  func: PropTypes.func
}
