import * as types from "./actionTypes";

export function searchNotifyMeRecordsSuccess(notifyMeRecords) {
  return {
    type: types.SEARCH_NOTIFY_ME_RECORDS_SUCCESS,
    notifyMeRecords
  };
}

export function searchtNotifyMeRecordsFailed(error) {
  return {
    type: types.SEARCH_NOTIFY_ME_RECORDS_FAILED,
    error
  };
}

export function getNotifyMeSitesSuccess(notifyMeSites) {
  return {
    type: types.GET_NOTIFY_ME_SITES_SUCCESS,
    notifyMeSites
  };
}

export function getNotifyMeSitesFailed(error) {
  return {
    type: types.GET_NOTIFY_ME_SITES_FAILED,
    error
  };
}

export function getNotifyMeStatsSuccess(notifyMeStats) {
  return {
    type: types.GET_NOTIFY_ME_STATS_SUCCESS,
    notifyMeStats
  };
}

export function getNotifyMeStatsFailed(error) {
  return {
    type: types.GET_NOTIFY_ME_STATS_FAILED,
    error
  };
}

export function setNotifyMeItemsPerPage(perPage) {
  return {
    type: types.SET_NOTIFY_ME_ITEMS_PER_PAGE,
    perPage
  }
}

export function setNotifyMeSiteId(siteId) {
  return {
    type: types.SET_NOTIFY_ME_SITE_ID,
    siteId
  };
}

