import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const UseStyles = makeStyles(theme => ({
  actionsContainer: {
    margin: '10px 0'
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
      borderRadius: theme.borderRadius
    },
    borderRadius: '0'
  },
  buttonCanvas: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
      borderRadius: theme.borderRadius
    },
    borderRadius: '0'
  },
  buttonGroup: {
    borderRadius: theme.borderRadius
  },
  cardAction: {
    float: 'right'
  },
  container: {
    maxHeight: 800
  },
  icon: {
    verticalAlign: 'middle'
  },
  iconButton: {
    padding: '12px'
  },
  iconButtonMobile: {
    padding: '0 12px'
  },
  inlineDiv: {
    display: 'inline-block'
  },
  paddedIcon: {
    paddingLeft: '10px',
    verticalAlign: 'middle'
  },
  paper: {
    marginBottom: '48px'
  },
  table: {
    minWidth: 650,
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '0'
    }
  },
  td: {
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70px'
    }
  },
  tdOperation: {
    maxWidth: '8rem'
  },
  tdUpTime: {
    minWidth: '80px'
  },
  th: {
    color: 'rgb(158, 158, 158)',
    fontSize: '12px',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70px'
    }
  },
  thMobile: {
    color: 'rgb(158, 158, 158)',
    fontWeight: 'bold'
  },
  thOperation: {
    color: 'rgb(158, 158, 158)',
    fontSize: '12px',
    fontWeight: 'bold',
    maxWidth: '130px'
  },
  thUpTime: {
    color: 'rgb(158, 158, 158)',
    fontSize: '12px',
    fontWeight: 'bold',
    minWidth: '80px'
  },
  toolbar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(232, 232, 232)',
    padding: '0 24px'
  },
  toolbarTitle: {
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.4)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  }
}));

export default UseStyles;

UseStyles.propTypes = {
  theme: PropTypes.object
}
