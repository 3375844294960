import * as types from './actionTypes';

export function translateTextSuccess(translatedText) {
  return {
    type: types.TRANSLATE_TEXT_SUCCESS,
    translatedText
  };
}

export function translateTextFailed(error) {
  return {
    type: types.TRANSLATE_TEXT_FAILED,
    error
  };
}

export function getSwaggerUrlsSuccess(swaggerUrls) {
  return {
    type: types.GET_SWAGGER_URLS_SUCCESS,
    swaggerUrls
  };
}

export function getSwaggerUrlsFailed(error) {
  return {
    type: types.GET_SWAGGER_URLS_FAILED,
    error
  };
}

export function getLoaderStatus(loading) {
  return {
    type: types.GET_LOADER_STATUS,
    loading
  };
}

export function getLambdaStatisticsSuccess(lambdaStatistics) {
  return {
    type: types.GET_LAMBDA_STATISTICS_SUCCESS,
    lambdaStatistics
  };
}

export function getLambdaStatisticsFailed (error) {
  return {
    type: types.GET_LAMBDA_STATISTICS_FAILED,
    error
  };
}

export function setLambdaStatisticsRecordsPerPage (perPage) {
  return {
    type: types.SET_GET_LAMBDA_STATISTICS_RECORDS_PER_PAGE,
    perPage
  };
}

export function seoLintSuccess (seoLintReport) {
  return {
    type: types.SEO_LINT_SUCCESS,
    seoLintReport
  };
}

export function seoLintFailed (error) {
  return {
    type: types.SEO_LINT_FAILED,
    error
  };
}

export function getAWSServicesSuccess (awsServices) {
  return {
    type: types.GET_AWS_SERVICES_SUCCESS,
    awsServices
  };
}


export function getAWSServicesFailed(error) {
  return {
    type: types.GET_AWS_SERVICES_FAILED,
    error
  };
}

export function setAWSServicesSuccess (awsServices) {
  return {
    type: types.SET_AWS_SERVICES_SUCCESS,
    awsServices
  };
}

export function encryptFailed(error) {
  return {
    type: types.ENCRYPT_FAILED,
    error
  };
}

export function encryptSuccess (encryptedData) {
  return {
    type: types.ENCRYPT_SUCCESS,
    encryptedData
  };
}

export function decryptFailed (error) {
  return {
    type: types.DECRYPT_FAILED,
    error
  };
}

export function decryptSuccess (plainData) {
  return {
    type: types.DECRYPT_SUCCESS,
    plainData
  };
}

export function getServicesListFailed (error) {
  return {
    type: types.GET_SERVICES_LIST_FAILED,
    error
  };
}

export function getServicesListSuccess (servicesList) {
  return {
    type: types.GET_SERVICES_LIST_SUCCESS,
    servicesList
  };
}

export function getServiceConfigurationFailed (error) {
  return {
    type: types.GET_SERVICE_CONFIGURATION_FAILED,
    error
  };
}

export function getServiceConfigurationSuccess (serviceConfiguration) {
  return {
    type: types.GET_SERVICE_CONFIGURATION_SUCCESS,
    serviceConfiguration
  };
}
