import React, { Component } from 'react';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, FlatButton, Paper } from 'material-ui';
import ProductImageDetails from './ProductImageDetails';

export const copy = {
  tableHeaders : ["Product ID", "Has Image(s)?"]
};

// Compares user input list to returned results. Returns TableRows.
export const matchedProducts = (hasImageList) => (
  hasImageList.map(( n, i ) => (
    <TableRow key={i}>
      <TableRowColumn>
        {n.productId}
      </TableRowColumn>
      <TableRowColumn>
        { n.hasImage === "Yes" ?
          <ProductImageDetails id={n.productId}>
            {n.fileNames.map((n, i) => (
              <div key={i}>{n}</div>
            ))}
          </ProductImageDetails>
          :
          <FlatButton label="NO" disabled={true}/>
        }
      </TableRowColumn>
    </TableRow>
  ))
);

export const ProductImageSearchList = ({ csvUrl, hasImageList, tableHeaders, getDownload }) => (
  <div>
    {(hasImageList && hasImageList.length > 0) &&
      <div>
        <div>
          <Paper zDepth={0}>
          <span>Your search returned <b>{hasImageList.length}</b> unique product ID(s). </span>
            { csvUrl &&
            <a href={csvUrl} download>Download&nbsp;CSV</a>
            }
          </Paper>
        </div>
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow selectable={false}>
              {tableHeaders.map((n, i) => (
                <TableHeaderColumn key={i}>{n}</TableHeaderColumn>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {matchedProducts(hasImageList)}
          </TableBody>
        </Table>
      </div>
    }
  </div>
);

class ProductImageSearchListContainer extends Component {

  render() {
    return (
      <ProductImageSearchList
        csvUrl={this.props.csvUrl}
        hasImageList={this.props.hasImageList}
        tableHeaders={copy.tableHeaders}
        getDownload={this.getDownload}
      />
    );
  }
}

export default ProductImageSearchListContainer;
