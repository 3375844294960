import React from 'react';
import Dashboard from './Dashboard';

import { Route, Switch } from 'react-router-dom'

const DashboardRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/dashboard" component={Dashboard}/>
     
    </Switch>
  </div>
);

export default DashboardRoutes;
