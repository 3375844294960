import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FlatButton } from 'material-ui';

export const DuplicateModels = (props) => {
    let gridApi;
    let selectedRows;

    const onSelectionChanged = () => {
        selectedRows = gridApi.getSelectedRows();
        props.setModelsToUpdate(selectedRows);
    }

    const overrideAllHandler = () => {
        gridApi.selectAll();
    }

    const skipAllHandler = () => {
        gridApi.deselectAll();
    }

    const onGridReady = (params) => {
        gridApi = params.api;
    };

    const colDef = [
        {
            headerName: "Model Code",
            field: "modelCode",
            width: 300
        },
        {
            headerName: "Select To Override",
            width: 300,
            suppressSorting: true,
            checkboxSelection: true
        }
    ]

    return (
        <div>
            <div
                className="ag-theme-material"
                style={{
                    height: '450px',
                    width: '600px',
                    marginLeft: "5%"
                }}
            >
                <AgGridReact
                    columnDefs={colDef}
                    rowData={props.modelsList}
                    rowSelection="multiple"
                    onGridReady={onGridReady}
                    suppressRowClickSelection={true}
                    onSelectionChanged={onSelectionChanged.bind(this)}
                />
            </div>
            <div className="duplicateModelsActions" style={{
                textAlign: 'right'
            }}>
                <FlatButton
                    label="Override All"
                    primary={true}
                    onClick={overrideAllHandler.bind(this)}
                />
                <FlatButton
                    label="Skip All"
                    primary={true}
                    onClick={skipAllHandler.bind(this)}
                />
                <FlatButton
                    label="Cancel"
                    primary={true}
                    onClick={props.handleClose}
                />
                <FlatButton
                    label="Submit"
                    primary={true}
                    onClick={props.submitProfiles.bind(this)}
                />
            </div>
        </div>
    )
}