import React from 'react';
import { ClickableTile, PageHeader } from '../Components';
import { Row, Col } from 'react-flexbox-grid';
import { grey700 } from 'material-ui/styles/colors';

const styles = {
  icon: {
    color: grey700
  }
};

function About() {
  return(
    <div className='About'>
        <Row>
          <Col xs={12}>
            <PageHeader>About</PageHeader>
          </Col>
        </Row>
        <Row start={'xs'}>
          <Col xs={12} md={4}>
            <ClickableTile route='/' icon='home' text='Back to Home' iconStyle={styles.icon} />
          </Col>
        </Row>
      </div>
  )
}

export default About;
