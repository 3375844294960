import React, {  Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { sandboxRestApi } from '../../../config';
import Transition from '../elements/Transition';
import FlexibleSnackBar from '../elements/FlexibleSnackBar';

const validate = values => {
  const errors = {};
  if (!values.sandboxId) {
    errors.sandboxId = 'Please enter the sandbox id';
  }
  return errors;
};

function DeleteSandboxForm({ classes, closeButton, handleSubmit, pristine, reset, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='sandboxId'
            component={TextField}
            id='sandboxId'
            hintText='The sandbox ID (uuid string)'
            floatingLabelText='Sandbox UUID*'
            fullWidth={true}
            disabled={submitting}
          />
        </Col>
        }
        <Col xs={12}>
          {submitting &&
          <p>
            Your request has been received.
            On-Demand Sandbox is being deleted!
          </p>
          }
          <div className={classes.actionsContainer}>
            <DialogActions>
              {!submitting &&
              <Fragment>
                <Button type='submit' color='primary'>
                  Submit
                </Button>
                <Button disabled={pristine} onClick={reset}>
                  Clear
                </Button>
              </Fragment>
              }
              <Button onClick={closeButton}>
                Close
              </Button>
            </DialogActions>
          </div>
        </Col>
      </Row>
    </form>
  )
};

const ValidateDeleteSandboxForm = reduxForm({
  form: 'DeleteSandboxForm',
  validate
})(DeleteSandboxForm);

export default function DeleteSandbox({ classes, onRefresh }) {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState({});
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleSubmit = (body) => (
    sandboxRestApi.delete(`/sandboxes/${body.sandboxId}`).then(() => {
      onRefresh && onRefresh();
    }).catch((e) => {
      setSnack({
        open: true,
        message: `Delete sandbox failed for ${JSON.stringify(body)}: ${e}`,
        severity: 'error'
      })
      console.log(`Delete sandbox failed for ${JSON.stringify(body)}: ${e}`);
    })
  );

  return (
    <Fragment>
      <Button variant='contained' color='primary' onClick={handleOpen} className={classes.button}>
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Sandbox</DialogTitle>
        <DialogContent>
          <ValidateDeleteSandboxForm classes={classes} onSubmit={handleSubmit} closeButton={handleClose} initialValues={{sandboxId: ''}} />
        </DialogContent>
      </Dialog>
      <FlexibleSnackBar snack={snack} />
    </Fragment>
  );
}

DeleteSandbox.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func
}
