import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid,  Row, Col } from 'react-flexbox-grid';
import {
  IconButton, MenuItem, RaisedButton, SelectField, Table, TableBody, TableHeader, TableRow,
  TableRowColumn, Toolbar, ToolbarGroup, ToolbarTitle, TableHeaderColumn, ToolbarSeparator } from 'material-ui';
import LoaderComponent from '../components/Loader';
import { searchNotifyMeRecords, storePerPage } from "../api/notifyMeApi";

export const styles = {
  tableRowColumn: {
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  },
};

class NotifyMeSearchResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyMeSiteId: '',
      notifyMeStatus: 'all',
      page: 0,
      perPage: 10
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.perPage)
      this.setState(Object.assign({}, this.state, { perPage: nextProps.perPage }));
    if(nextProps.notifyMeSiteId)
      this.setState(Object.assign({}, this.state, { notifyMeSiteId: nextProps.notifyMeSiteId }));
    if(nextProps.error)
        alert(`Search notify me records failed: ${nextProps.error.message}`);
  }

  showTable = () => {
    if (!this.props.loading && this.props.notifyMeRecords && this.props.notifyMeRecords.length >0) {
        return this.props.notifyMeRecords
        .slice(this.state.page * this.state.perPage, this.state.page * this.state.perPage + this.state.perPage)
        .map((notifyMeRecord, index) => {
            return (
                <TableRow key={index}>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.email }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.customerNo }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.firstName }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.lastName }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.status }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.variantId }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.masterProductId }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.lastNotifyDate }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.expireDate }</TableRowColumn>
                    <TableRowColumn style={styles.tableRowColumn}>{ notifyMeRecord.notifyCount }</TableRowColumn>
                </TableRow>
            );
        });
    }
    return (
        <TableRow>
            <TableRowColumn colSpan={10}>No Results</TableRowColumn>
        </TableRow>
    );
  };

  refreshBtnSubmit = () => {
    storePerPage(this.state.perPage);
    searchNotifyMeRecords(this.state.notifyMeSiteId, this.state.notifyMeStatus);
  };

  handlePerPageChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { perPage: value }));
  };

  handleStatusChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { notifyMeStatus: value }));
  };

  handleBackClick = (event) => {
    let newPage = this.props.notifyMeRecords && (this.state.page !== 0) ? (this.state.page - 1) : this.state.page;
    this.setState(Object.assign({}, this.state,{ page: newPage }));
  };

  handleForwardClick = (event) => {
    if(this.props.notifyMeRecords) {
        if( (this.state.page + 1) < Math.ceil(this.props.notifyMeRecords.length / this.state.perPage))
            this.setState({ page: (this.state.page + 1) });
    }
  };

  render() {
    let currentPage = this.state.page + 1;
    let totalPages = this.props.notifyMeRecords ? (this.props.notifyMeRecords.length / this.state.perPage) : 1;
    let tableResults = this.showTable();
    return (
        <div>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text={`Search Results: ${this.props.notifyMeSiteId}`}/>
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarTitle text={`Total: ${this.props.notifyMeRecords.length}`} />
              <ToolbarSeparator />
              <RaisedButton label="Refresh" primary={true} onClick={this.refreshBtnSubmit} />
            </ToolbarGroup>
          </Toolbar>
          <Grid>
            <Row>
              <Col xs={12} md={6}>
                <SelectField
                  floatingLabelText="Results per page"
                  value={this.state.perPage}
                  onChange={this.handlePerPageChange}
                >
                  <MenuItem value={10} primaryText="10" />
                  <MenuItem value={25} primaryText="25" />
                  <MenuItem value={50} primaryText="50" />
                  <MenuItem value={100} primaryText="100" />
                </SelectField>
              </Col>
              <Col xs={12} md={6}>
                <SelectField
                  floatingLabelText="Status"
                  value={this.state.notifyMeStatus}
                  onChange={this.handleStatusChange}
                >
                  <MenuItem value="all" primaryText="all" />
                  <MenuItem value="new" primaryText="new" />
                  <MenuItem value="completed" primaryText="completed" />
                  <MenuItem value="expired" primaryText="expired" />
                </SelectField>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                      <TableHeaderColumn style={styles.tableRowColumn}>Email</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Customer No.</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Last Name</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>First Name</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Status</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Variant Id</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Master Product Id</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Last Notified Date</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Expired Date</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Notified Count</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        { tableResults }
                    </TableBody>
                </Table>
              </Col>
            </Row>
              <Row end="xs">
                  <Col xs={12}>
                      {currentPage} of {Math.ceil(totalPages)}
                      <IconButton iconClassName="fa fa-angle-left" onClick={this.handleBackClick} />
                      <IconButton iconClassName="fa fa-angle-right" onClick={this.handleForwardClick} />
                  </Col>
              </Row>
          </Grid>
        </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    notifyMeRecords: store.notifyMeState.notifyMeRecords,
    perPage: store.notifyMeState.perPage,
    error: store.notifyMeState.searchFailed,
    notifyMeSiteId: store.notifyMeState.notifyMeSiteId,
    loading: store.notifyMeState.loading
  };
};

export const NotifyMeSearchResults = connect(mapStateToProps)(NotifyMeSearchResultsContainer);
