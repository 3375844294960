import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Reducers
import userReducer from './userReducer';
import emailReducer from './emailReducer';
import inventoryReducer from './inventoryReducer';
import logReducer from './logReducer';
import vipReducer from './vipReducer';
import notifyMeReducer from './notifyMeReducer';
import utilsReducer from './utilsReducer';
import orderHistoryReducer from './orderHistoryReducer';
import photoStudioReducer from './photoStudioReducer';
import dashboardReducer from './dashboardReducer';

// Combine Reducers
const reducers = combineReducers({
  userState: userReducer,
  emailState: emailReducer,
  inventoryState: inventoryReducer,
  logState: logReducer,
  vipState: vipReducer,
  notifyMeState: notifyMeReducer,
  utilsState: utilsReducer,
  orderHistoryState: orderHistoryReducer,
  form: formReducer,
  photoStudioState: photoStudioReducer,
  dashboardState: dashboardReducer
});

export default reducers;
