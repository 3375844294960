import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { AWSServicesClickableTile } from "./AWSServicesClickableTile";
import LoaderComponent from '../components/Loader';
import { getAWSServices, clearAWSServices } from "../api/utilsApi";

export const styles = {
  loader: {
    padding: '20px'
  },
  link: {
    color: 'rgb(0, 136, 206)'
  },
  toolbarMargin: {
    marginTop: '40px',
    marginBottom: '20px'
  }
};

class AWSServicesDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awsServices: [],
      page: 0,
      take: 100
    };
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.error) {
      return alert(`Get lambda records failed: ${nextProps.error.message}`);
    }
    this.setState(Object.assign({}, this.state, { awsServices: nextProps.awsServices }));
  }

  componentDidMount () {
    clearAWSServices();
    getAWSServices();
  }

  showTiles () {
    if (this.props.awsServices && this.props.awsServices.length) {
      return this.props.awsServices.map(function (s, i) {
        const match = s.info.serviceName.match(/([^-]*$)/gi);
        const heading = (match && match.length) ? match[0] : s.info.serviceName;
        const link = `/awsservices/${s.info.serviceName}`;
        return (
          <Col key={i} xs={12} sm={6} md={2}>
            <AWSServicesClickableTile route={link}
                                      heading={heading}
                                      text={s.health.text}
                                      icon={s.health.icon}
                                      iconStyle={s.health.iconStyle} />
          </Col>
        )
      });
    }
  }

  render() {
    let tiles = this.showTiles();
    return (
      <div>
        <Grid fluid>
          <Row style={styles.loader}>
            <Col xs={12}>
              <LoaderComponent />
            </Col>
          </Row>
          <Row>
            { tiles }
          </Row>
        </Grid>
      </div>
    );
  }
}

AWSServicesDashboardContainer.propTypes = {
  error: PropTypes.string,
  awsServices: PropTypes.array,
};

const mapStateToProps = function (store) {

  return {
    error: store.utilsState.awsServicesFailed,
    awsServices: store.utilsState.awsServices
  };
};

export const AWSServicesDashboard = connect(mapStateToProps)(AWSServicesDashboardContainer);
