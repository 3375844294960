/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import "ag-grid-community/dist/styles/ag-theme-bootstrap.css";

import LoaderComponent from "../components/Loader";

export const styles = {
  link: {
    color: 'rgb(0, 136, 206)'
  },
  toolbar: {
    marginTop: '20px'
  },
  paginate: {
    borderTop: `1px solid rgb(232, 232, 232)`,
    textAlign: 'right',
    padding: '10px'
  }
};

class InventoryListResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryList: [],
      query: null,
      start: 0,
      count: 100,
      redirect: false
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.error) {
      alert(`Search Inventory List failed: ${nextProps.error.message}`);
    }
    
    let inventoryList = nextProps.inventoryList && nextProps.inventoryList.data ? nextProps.inventoryList.data : [];
    this.setState({ inventoryList: inventoryList });
  }

  renderInventoryList() {
    let inventoryList = [];
    if (this.state.inventoryList.length) {
      inventoryList = this.state.inventoryList.map(list => {
        return {
          id: list.id,
          description: list.description,
          assigned_sites: list.assigned_sites,
          creation_date: list.creation_date,
          last_modified: list.last_modified
        };
      });
    }
    return inventoryList;
  }

  rowSelected(event) {
    this.setState({ redirect: `/inventory/records/${event.data.id}` });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    let ColumnDefs = [
      { headerName: "Id", field: "id", flex: 1, unSortIcon: true },
      { headerName: "Description", field: "description", flex: 2, unSortIcon: true },
      { headerName: "Assigned Sites", field: "assigned_sites", flex: 2, unSortIcon: true },
      { headerName: "Creation Date", field: "creation_date", flex: 1, unSortIcon: true },
      { headerName: "Last Modified Date", field: "last_modified", flex: 1, unSortIcon: true }
    ];

    let inventoryList = this.renderInventoryList();

    return (
      <div>
        <Toolbar style={styles.toolbar}>
          <ToolbarGroup>
            <ToolbarTitle text="Inventory Search Results" />
          </ToolbarGroup>
          <ToolbarGroup>
            <LoaderComponent />
          </ToolbarGroup>
          <ToolbarGroup>
          </ToolbarGroup>
        </Toolbar>
        <div className="ag-theme-bootstrap" style={{ height: '580px' }}>
          <AgGridReact
            defaultColDef={{ sortable: true, resizable: true, cellStyle: { display: 'flex', justifyContent: 'left', alignItems: 'center' } }}
            columnDefs={[...ColumnDefs]}
            rowData={inventoryList}
            pagination={true}
            paginationPageSize={this.state.count}
            accentedSort={true}
            rowHeight={50}
            headerHeight={50}
            rowSelection={'single'}
            onRowSelected={this.rowSelected.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    inventoryList: store.inventoryState.inventoryList,
    loading: store.inventoryState.loading,
    error: store.inventoryState.inventoryListFailed
  };
};

export const InventoryListSearchResults = connect(mapStateToProps)(InventoryListResultsContainer);
