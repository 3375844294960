import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { restApi } from '../config';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardText,
  Divider,
  Paper,
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn } from 'material-ui';
import {columbiaBlue} from '../themes/brandColors';
import { hasRoles } from '../api/userApi';

import {
  red300,
  green300
} from 'material-ui/styles/colors';


export const accountDetail = (account, i) => {
  return (
    <TableRow key={i} selectable={false}>
      <TableRowColumn>{account.username}</TableRowColumn>
      <TableRowColumn>{account.accountType}</TableRowColumn>
      <TableRowColumn>{account.accountStatus}</TableRowColumn>
      <TableRowColumn>{account.environment}</TableRowColumn>
    </TableRow>
  )
};

export const IdManagementUserDetailsContainer = ({ user, whitelistUser, removeFromWhitelist }) => {

  const getActiveADStatus = (user) => {
    let status = "";
    switch (user.activeAD) {
      case "active":
        status = "Okta verfied";
        break;
      case "inactive":
        status = "Not Okta verified";
        break;
        case "deprovisioned":
        status = "Not Okta verified";
        break;
      case "ignore":
        status = "Whitelisted ID";
        break;
      default :
        status = "Unknown";
    }
    return status;
  };

  return (
    <div>
      {user &&
      <div>
        <Row>
          <Col xs={12} smOffset={3} sm={6}>
            <Paper zDepth={0} style={{margin: "20px 0 10px"}}>
              <Link to="/id-management/" style={{ color: columbiaBlue, textDecoration: "none", fontSize: "13px"}}>
                Back to ID Management
              </Link>
            </Paper>
            <Card>
              <CardHeader
                title={user.name ? user.name.firstName + " " + user.name.lastName : "No Name"}
                subtitle={`${getActiveADStatus(user)} - ${user.emailAddress}` }
                avatar={
                  <Avatar backgroundColor={user.activeAD === "active" || user.activeAD === "ignore" ? green300 : red300}>
                    {user.name ? user.name.firstName.charAt(0).toUpperCase() : "?"}
                  </Avatar>}
              />
              <Divider />
              <CardText>
                <Table>
                  <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                    <TableRow>
                      <TableHeaderColumn>Username</TableHeaderColumn>
                      <TableHeaderColumn>Account Type</TableHeaderColumn>
                      <TableHeaderColumn>Status</TableHeaderColumn>
                      <TableHeaderColumn>Environment</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody displayRowCheckbox={false}>
                    {user.accounts && user.accounts.map(accountDetail)}
                  </TableBody>
                </Table>
              </CardText>
              <Divider />
              <CardActions>
                { user.activeAD !== "active" && hasRoles('write:userMgmt') &&
                  <RaisedButton fullWidth={true} label="Whitelist" primary={true} onClick={whitelistUser}/>
                }
                { user.activeAD === "ignore" && hasRoles('write:userMgmt') &&
                  <RaisedButton fullWidth={true}  label="Remove from Whitelist" secondary={true} onClick={removeFromWhitelist}/>
                }
              </CardActions>
            </Card>
          </Col>
        </Row>
      </div>
      }
    </div>
  )
};

class IdManagementUserDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      userId : props.match.params.id,
    };
    this.whitelistUser = this.whitelistUser.bind(this);
    this.removeFromWhitelist = this.removeFromWhitelist.bind(this);
  }

  getUsers(){
    let self = this;
    restApi.get("userAudit/" + self.state.userId).then(({data}) => {
      console.log(data);
      self.setState(
        Object.assign(
          {}, self.state, {
            user: data
          }
        )
      );
    }).catch((error) => {
      console.log("Get users failed: " + error);
    });
  }

  whitelistUser(){
    let self = this;
    const body = {activeAD : "ignore"};

    restApi.patch("userAudit/" + self.state.userId, body).then((data) => {
      self.getUsers();
    });
  }

  removeFromWhitelist(){
    let self = this;
    const body = {activeAD : "inactive"};
    restApi.patch("userAudit/" + self.state.userId, body).then((data) => {
      self.getUsers();
    });
  }

  componentDidMount(){
    this.getUsers();
  }

  render() {
    return (
      <IdManagementUserDetailsContainer user={this.state.user} whitelistUser={this.whitelistUser} removeFromWhitelist={this.removeFromWhitelist}/>
    );
  }
}

export default IdManagementUserDetails;
