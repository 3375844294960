import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import {Paper, SelectField, MenuItem, TextField, RaisedButton, ToolbarGroup, ToolbarTitle, Toolbar} from 'material-ui';
import LoaderComponent from '../components/Loader';
import { translateText, clearTranslatedText } from '../api/utilsApi';
import TranslatorBreadCrumbs from './TranslatorBreadCrumbs'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from 'material-ui/styles/colors';

export const styles = {
  container: {
    padding: '20px'
  },
  paddingTop20: {
    padding: '20px 0 0 0'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class TranslatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceLanguageCode: 'auto',
      targetLanguageCode: 'none',
      text: '',
      translatedText: ''
    };
  }

  componentDidMount () {
    clearTranslatedText();
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      return alert(`Translate failed: ${nextProps.error.message}`);
    }
    this.setState(Object.assign({}, this.state, { translatedText: nextProps.translatedText }));
  }

  handleSourceLanguageChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { sourceLanguageCode: value }));
  };

  handleTargetLanguageChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { targetLanguageCode: value }));
  };

  handleTextChange = (event, value) => {
    this.setState(Object.assign({}, this.state, { text: value }));
  };

  translate = async () => {
    if (this.state.targetLanguageCode === 'none') {
      return alert('Please select a target language.');
    }
    if (!this.state.text) {
      return alert('Please enter text to translate.');
    }
    clearTranslatedText();
    const translateTextRequest = {
      SourceLanguageCode: this.state.sourceLanguageCode,
      TargetLanguageCode: this.state.targetLanguageCode,
      Text: this.state.text
    };
    translateText(translateTextRequest);
  };

  render () {
    return (
      <div style={{paddingBottom: '20px'}}>
        <TranslatorBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup>
              <FontAwesomeIcon icon="language" size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="Amazon Translate Service"/>
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton id="translate" label="Translate" primary={true} onClick={this.translate} />
            </ToolbarGroup>
          </Toolbar>

          <div style={styles.container}>
            <Row>
              <Col xs={12} md={4}>
                <Row>
                  <Col xs={12}>
                    <SelectField
                      floatingLabelText="Source Language"
                      value={this.state.sourceLanguageCode}
                      onChange={this.handleSourceLanguageChange}
                    >
                      <MenuItem value={'auto'} primaryText={'Auto'} />
                      <MenuItem value={'en'} primaryText={'English'} />
                      <MenuItem value={'ar'} primaryText={'Arabic'} />
                      <MenuItem value={'zh'} primaryText={'Chinese (Simplified)'} />
                      <MenuItem value={'zh-TW'} primaryText={'Chinese (Traditional)'} />
                      <MenuItem value={'cs'} primaryText={'Czech'} />
                      <MenuItem value={'fr'} primaryText={'French'} />
                      <MenuItem value={'de'} primaryText={'German'} />
                      <MenuItem value={'it'} primaryText={'Italian'} />
                      <MenuItem value={'ja'} primaryText={'Japanese'} />
                      <MenuItem value={'pt'} primaryText={'Portuguese'} />
                      <MenuItem value={'ru'} primaryText={'Russian'} />
                      <MenuItem value={'es'} primaryText={'Spanish'} />
                      <MenuItem value={'tr'} primaryText={'Turkish'} />
                    </SelectField>
                  </Col>
                  <Col xs={12}>
                    <SelectField
                      floatingLabelText="Target Language"
                      value={this.state.targetLanguageCode}
                      onChange={this.handleTargetLanguageChange}
                    >
                      < MenuItem value={'none'} primaryText="None Selected" />
                      <MenuItem value={'en'} primaryText={'English'} />
                      <MenuItem value={'ar'} primaryText={'Arabic'} />
                      <MenuItem value={'zh'} primaryText={'Chinese (Simplified)'} />
                      <MenuItem value={'zh-TW'} primaryText={'Chinese (Traditional)'} />
                      <MenuItem value={'cs'} primaryText={'Czech'} />
                      <MenuItem value={'fr'} primaryText={'French'} />
                      <MenuItem value={'de'} primaryText={'German'} />
                      <MenuItem value={'it'} primaryText={'Italian'} />
                      <MenuItem value={'ja'} primaryText={'Japanese'} />
                      <MenuItem value={'pt'} primaryText={'Portuguese'} />
                      <MenuItem value={'ru'} primaryText={'Russian'} />
                      <MenuItem value={'es'} primaryText={'Spanish'} />
                      <MenuItem value={'tr'} primaryText={'Turkish'} />
                    </SelectField>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={8}>
                <TextField
                  floatingLabelText="Text to Translate"
                  fullWidth={true}
                  multiLine={true}
                  rows={2}
                  onChange={this.handleTextChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField
                  value={this.state.translatedText}
                  floatingLabelText="Translated Text"
                  fullWidth={true}
                  multiLine={true}
                  rows={2}
                />
              </Col>
            </Row>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = function(store) {
  return {
    translatedText: store.utilsState.translatedText
  };
};

export default connect(mapStateToProps)(TranslatorContainer);
