import {axiosApi, utilsRestApi, awsEnvironment} from '../config';
import store from "../services/store";
import {green500, grey500, red500, yellow500} from "material-ui/styles/colors";
import { translateTextSuccess, translateTextFailed,
  getSwaggerUrlsSuccess, getSwaggerUrlsFailed, getLoaderStatus, getLambdaStatisticsSuccess,
  getLambdaStatisticsFailed, setLambdaStatisticsRecordsPerPage, seoLintSuccess, seoLintFailed,
  getAWSServicesSuccess, getAWSServicesFailed, setAWSServicesSuccess,
  encryptSuccess, encryptFailed, decryptSuccess, decryptFailed, getServicesListSuccess,
  getServicesListFailed, getServiceConfigurationSuccess, getServiceConfigurationFailed } from '../actions/utilsActions';
import * as config from "../config";

const axiosConfig = { headers: { 'Content-Type': 'application/json' } };


function setLoadingStatus (loading) {
  store.dispatch(getLoaderStatus(loading))
}

// AWS Translator
export function translateText (body) {
  setLoadingStatus(true);
  utilsRestApi.post('/utils/translator/translate', body, axiosConfig).then(function({data}) {
    store.dispatch(translateTextSuccess(data.TranslatedText));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error('translateText failed: ' + error.message);
    store.dispatch(translateTextFailed(error));
    setLoadingStatus(false);
  });
}

export function clearTranslatedText() {
  store.dispatch(translateTextSuccess(''));
}

export function clearEncryptedData() {
  store.dispatch(encryptSuccess(''));
}

// KMS Encryption
export function encrypt (plainText) {
  setLoadingStatus(true);
  utilsRestApi.post('/utils/encrypt', { plainText: plainText })
    .then(response => {
      store.dispatch(encryptSuccess(response.data));
      setLoadingStatus(false);
    })
    .catch(error => {
      store.dispatch(encryptFailed(error));
      setLoadingStatus(false);
    });
}

export function clearDecryptedData() {
  store.dispatch(decryptSuccess(''));
}

// KMS Decryption
export function decrypt (cipherTextBlob) {
  setLoadingStatus(true);
  utilsRestApi.post('/utils/decrypt', { cipherTextBlob: cipherTextBlob })
    .then(response => {
      store.dispatch(decryptSuccess(response.data));
      setLoadingStatus(false);
    })
    .catch(error => {
      store.dispatch(decryptFailed(error));
      setLoadingStatus(false);
    });
}

// SEO Linter
export function clearSeoLintReport() {
  store.dispatch(seoLintSuccess());
}

export function seoLint (url) {
  setLoadingStatus(true);
  utilsRestApi.post('/utils/seolint', { url: url}, axiosConfig).then(function({data}) {
    store.dispatch(seoLintSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    store.dispatch(seoLintFailed(error));
    setLoadingStatus(false);
  });
}


// Swagger
export function getSwaggerUrls () {
  setLoadingStatus(true);
  utilsRestApi.get('/utils/swagger/urls', axiosConfig)
    .then(response => {
      store.dispatch(getSwaggerUrlsSuccess(response.data));
      setLoadingStatus(false);
    })
    .catch(error => {
      store.dispatch(getSwaggerUrlsFailed(error));
      setLoadingStatus(false);
   });
}
// AWS Services
export function storeLambdaStatisticsPerPage (perPage) {
  store.dispatch(setLambdaStatisticsRecordsPerPage(perPage))
}

export function clearLambdaStatistics() {
  store.dispatch(getLambdaStatisticsSuccess());
}

export function getLambdaStatistics () {
  setLoadingStatus(true);
  utilsRestApi.get(`/utils/lambda/statistics`, axiosConfig).then(function({data}) {
    store.dispatch(getLambdaStatisticsSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error("Get Lambda list failed: " + error.message);
    store.dispatch(getLambdaStatisticsFailed(error));
    setLoadingStatus(false);
  });
}

function getServiceHealth (serviceStatus) {
  const health = {};
  const total = serviceStatus.reduce((a, b) => (a + (b.display.marker) || 0), 0);
  if (total > 0 && awsEnvironment === 'colmlocal') {
    health.icon = ['far', 'circle'];
    health.iconStyle = {color: grey500};
    health.text = 'Offline or Network Error';
  } else if (total > 0) {
    health.icon = 'exclamation-circle';
    health.iconStyle = {color: red500};
    health.text = 'Health Checks Failed';
  } else {
    health.icon = 'check-circle';
    health.iconStyle = {color: green500};
    health.text = 'Health Checks Passed';
  }
  return health;
}

async function getS3Counts (path, url) {
  let responses = [];
  const s3Buckets = path.post[`x-${config.awsBucketPrefix}`];
  if (s3Buckets && s3Buckets.length) {
    const promises = s3Buckets.map( b => new Promise( async resolve => {
      const result = await axiosApi.post(url, b.bucket, axiosConfig);
      let response = {
        url: result.config.url,
        data: { __html: `Bucket: ${b.bucket.bucketPath + b.bucket.delimiter + b.bucket.prefix}<br/>Threshold: ${b.bucket.threshold}<br/>Count: ${result.data}` },
        status: result.status,
        statusText: result.statusText,
        display: getServiceDisplay(result)
      };
      resolve(response);
    }));
  responses = await Promise.all(promises);
  }
  return responses;
}

async function geCouponCounts (path, url) {
  return [{
    url: url,
    data: { __html: 'To be implemented'},
    status: 503,
    statusText: 'TO BE IMPLEMENTED',
    display: getServiceDisplay()
  }];
}

async function addServiceStatus (swaggerDoc) {
  if (swaggerDoc && swaggerDoc.paths && typeof swaggerDoc.paths === 'object') {
    const baseUrl = (awsEnvironment === 'colmlocal') ? `http://${swaggerDoc.host}${swaggerDoc.basePath}` : config.restBaseApi;
    const objectKeys = Object.keys(swaggerDoc.paths);
    if (objectKeys && objectKeys.length) {
      const keys = objectKeys.filter(k => k.includes('/status/'));
      const promises = keys.map( k => new Promise( async resolve => {
        const url = baseUrl + k;
        let responses = [];
        try {
          if (k.includes('s3Count')) {
            responses = await getS3Counts(swaggerDoc.paths[k], url);
          } else if (k.includes('CouponCounts')) {
            responses = geCouponCounts(swaggerDoc.paths[k], url);
          } else {
            const response = await axiosApi.get(url, axiosConfig);
            if (response) {
              responses.push({
                url: response.config.url,
                data: {__html: (response.data instanceof Object) ? JSON.stringify(response.data).replace(/^"|"$/g, '') : response.data},
                status: response.status,
                statusText: response.statusText,
                display: getServiceDisplay(response)
              });
            }
          }
        } catch (error) {
          responses.push({
            url: url,
            data: { __html: error.message },
            status: 503,
            statusText: 'NETWORK ERROR',
            display: getServiceDisplay()
          });
        }
        resolve(responses);
      }));
      const statuses = await Promise.all(promises);
      swaggerDoc.serviceStatus = statuses.map(s => s[0]);
    }
  }
  return swaggerDoc;
}

function getServiceDisplay (r) {
  let status = {};
  if (r) {
    status.text = r.statusText;
    if (r.status >= 200 && r.status <= 202) {
      status.icon = 'check-circle';
      status.iconStyle = {color: green500};
      status.marker = 0;
    } else if (r.status === 503) {
      status.icon = ['far', 'circle'];
      status.iconStyle = {color: grey500};
      status.marker = 1;
    } else if (r.status >= 400) {
      status.icon = 'exclamation-circle';
      status.iconStyle = {color: red500};
      status.marker = 1;
    }
  } else {
    status.text = 'No Results';
    status.icon = ['far', 'circle'];
    status.iconStyle = { color: grey500 };
    status.marker = 1;
  }
  return status;
}

function filterSwaggerUrls (serviceName, swaggerUrls) {
  if (swaggerUrls && swaggerUrls.data && swaggerUrls.data.length) {
    if (serviceName) {
      return swaggerUrls.data.filter(s => {
        const value = JSON.parse(s.value);
        return value.name === serviceName;
      });
    }
  }
  return swaggerUrls.data;
}

function reduceSwaggerDoc (swaggerDoc) {
  delete swaggerDoc.tags;
  delete swaggerDoc.config;
  delete swaggerDoc.data.swagger;
  delete swaggerDoc.data.tags;
  delete swaggerDoc.data.consumes;
  delete swaggerDoc.data.produces;
  delete swaggerDoc.data.securityDefinitions;
  delete swaggerDoc.data.definitions;
  return swaggerDoc.data;
}

async function getSwaggerDocs (serviceName, swaggerUrls) {
  const promises = swaggerUrls.map(s => new Promise( async resolve => {
    let swaggerDoc;
    const value = JSON.parse(s.value);
    const errorSwaggerDoc = {
      info: {
        serviceName: serviceName ? serviceName : value.name,
        swaggerDocUrl: value.url
      },
      serviceStatus: [
        {
          url: 'No results',
          status: 503,
          display: {
            text: 'No Swagger Found',
            icon: 'exclamation-triangle',
            iconStyle: { color: yellow500 },
            marker: 1
          }
        }
      ]
    };
    try {
      const response = await axiosApi.get(value.url, axiosConfig);
      if (response && response.data && typeof response.data === 'object') {
        swaggerDoc = reduceSwaggerDoc(response);
        swaggerDoc.info.serviceName = serviceName ? serviceName : value.name;
        swaggerDoc.info.swaggerDocUrl = value.url;
        swaggerDoc = await addServiceStatus(swaggerDoc);
        delete swaggerDoc.paths;
      } else {
        swaggerDoc = errorSwaggerDoc;
      }
      swaggerDoc.health = getServiceHealth(swaggerDoc.serviceStatus);
      return resolve(swaggerDoc);
    } catch (error) {
      console.error('getSwaggerDocs failed: ' + error.message);
      setLoadingStatus(false);
      swaggerDoc = errorSwaggerDoc;
      swaggerDoc.health = getServiceHealth(swaggerDoc.serviceStatus);
      return resolve(swaggerDoc);
    }
  }));
  return await Promise.all(promises);
}

export function clearAWSServices () {
  store.dispatch(getAWSServicesSuccess());
}

export function getAWSServices (serviceName) {
  setLoadingStatus(true);
  utilsRestApi.get('/utils/swagger/urls', axiosConfig).then(async swaggerUrls => {
    swaggerUrls = filterSwaggerUrls(serviceName, swaggerUrls);
    const swaggerDocs = await getSwaggerDocs(serviceName, swaggerUrls);
    store.dispatch(getAWSServicesSuccess(swaggerDocs));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error('getAWSServices failed: ' + error.message);
    store.dispatch(getAWSServicesFailed(error));
    setLoadingStatus(false);
  });
}

export function setAWSServices (awsServices) {
  setLoadingStatus(true);
  store.dispatch(setAWSServicesSuccess(awsServices));
  setLoadingStatus(false);
}

export function getServicesList () {
  setLoadingStatus(true);
  utilsRestApi.get(`/utils/awsservices/list`).then(function({data}) {
    setLoadingStatus(false);
    store.dispatch(getServicesListSuccess(data));
  }).catch(function (error) {
    setLoadingStatus(false);
    store.dispatch(getServicesListFailed(error));
  });
}

export function getServiceConfiguration (id) {
  setLoadingStatus(true);
  utilsRestApi.get(`/utils/awsservices/${id}/configuration`).then(function({data}) {
    setLoadingStatus(false);
    store.dispatch(getServiceConfigurationSuccess(data));
  }).catch(function (error) {
    setLoadingStatus(false);
    store.dispatch(getServiceConfigurationFailed(error));
  });
}
