import React, { Component } from 'react';
import { PageHeader } from '../Components';

import { Row, Col } from 'react-flexbox-grid';
import { DropDownMenu, MenuItem, Paper, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, Toolbar, ToolbarGroup, ToolbarTitle, Tabs, Tab } from 'material-ui';
import { notlivereportRestApi } from '../config';

import PropTypes from 'prop-types';

import logo from '../logo.svg';
import jsonexport from 'jsonexport/dist';


// import {columbiaBlue} from '../themes/brandColors';

export const NotLiveReportItem = ( notLiveProduct ) => {
  if (!notLiveProduct.reportDownloadLink) {

    return (
      <TableRow selectable={false} key={notLiveProduct.id}>
        <TableRowColumn className="hide-mobile" width="130px">
          {/*<Link to={"/notlivereport/" + notLiveProduct.model}>*/}
            {notLiveProduct.model}
          {/*</Link>*/}
        </TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.quantity}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.notlivedata.openqty}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.notlivedata.material}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.notlivedata.rddyearmonth}</TableRowColumn>


      </TableRow>
    )
  }
};

export const NotLiveReportList = ({ notliveitems, label, csvreport }) => {
  return (
    <div>
      <Table fixedHeader={true} height="450px">
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn>Product ID / SKU</TableHeaderColumn>
            <TableHeaderColumn>SFCC Quantity</TableHeaderColumn>
            <TableHeaderColumn>BW Quantity</TableHeaderColumn>
            <TableHeaderColumn>BW Material</TableHeaderColumn>
            <TableHeaderColumn>BW Contract Year</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {notliveitems.length > 0 && notliveitems.map(NotLiveReportItem)}
        </TableBody>
      </Table>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <ToolbarTitle text={`${notliveitems.length} ${label} Products`}/>
          {<RaisedButton href={`data:text/csv;charset=utf-8,`+ csvreport} download="mismatched_quantity.csv" >download</RaisedButton>}

        </ToolbarGroup>
      </Toolbar>
    </div>
  )
};

export const NotLiveReportContainer = ({dropdownItems, notLiveReports, csvReport}) => (
  <div>
    <PageHeader>NotLive Reports - Mismatched Quantity</PageHeader>
    <Row height={100}>
      <Col xs={12} sm={3}>
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <ToolbarTitle text="Site" />
            </ToolbarGroup>
          </Toolbar>
          {dropdownItems}

        </Paper>
      </Col>
      <Col xs={12} sm={9}>
        <Paper>
          <Tabs>

            <Tab label="Product Details" >
              {notLiveReports.data !== "loading" ?
                <NotLiveReportList label="NotLiveReport" notliveitems={notLiveReports} csvreport={csvReport}/>
                :
                <div style={{justifyContent: "center", alignItems: "center", minHeight: "450px"}}>
                  <div style={{padding: "20px"}}>
                    <img className="app-logo-spinner" src={logo} alt="logo"/>
                  </div>
                </div>
              }


            </Tab>

          </Tabs>
        </Paper>
      </Col>
    </Row>
  </div>
);

class MismatchedQty extends Component {

  constructor(props) {
    super(props);

    this.state = {
      siteId: '',
      salesorg: '1001',
      value: '1001,',
      notLiveReports: {data: "loading"},
      csvData: []
    }

    this.handleChange = this.handleChange.bind(this);


  }

  getNotLiveItems(){

    let csvReport = [];

    notlivereportRestApi.get(`/notlivereport/mismatchedqty/${this.state.salesorg}?siteId=${this.state.siteId}`).then(async ({data}) => {

      await jsonexport(data, function (err, csv) {
        if (err) {
          return console.log(err);
        }
        console.log(csv);
        csvReport = csv;
      });
      //
      this.setState(
        Object.assign(
          {}, this.state, {
            notLiveReports: data,
            csvData: csvReport
          }
        )
      );

    }).catch((error) => {
      console.log("Get notlivereport failed: " + error);
    });
  }


  handleChange = async (event, index, value) => {
    console.log("this value " + value);

    let options = value.split(',');

    await this.setState({value});

    await this.setState({notLiveReports: {data: "loading"}, salesorg: options[0], siteId: options[1]});


    this.getNotLiveItems();

  };

  getDownloadReport(){


    notlivereportRestApi.get(`/notlivereport/mismatchedqty/${this.state.salesorg}?siteId=${this.state.siteId}`).then(({data}) => {

      //
      this.setState(
        Object.assign(
          {}, this.state, {
            notLiveReports: data
          }
        )
      );

      }).catch((error) => {
        console.log("Get notlivereport failed: " + error);
      });
    }


  componentDidMount() {
    console.log("the Report ID in componentDidMount " + this.state.siteId);

    this.getNotLiveItems();
  }


  static propTypes = {
    //onChange: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
    multi: PropTypes.bool,
  };

  render() {

    return (
      <NotLiveReportContainer notLiveReports={this.state.notLiveReports} csvReport={this.state.csvData}
    dropdownItems={
      <DropDownMenu value={this.state.value} onChange={this.handleChange} openImmediately={false}>
        <MenuItem value={'1001,'} primaryText="US Stores"/>
        <MenuItem value={'1001,Columbia_USen_US'} primaryText=" - Columbia US"/>
        <MenuItem value={'1001,MountainHardwear_USen_US'} primaryText=" - MountainHardwear US"/>
        <MenuItem value={'1001,Sorel_USen_US'} primaryText=" - Sorel US"/>
        <MenuItem value={'---- ---'} primaryText=" -- "/>
        <MenuItem value={'2001,'} primaryText="CA Stores"/>
        <MenuItem value={'2001,Columbia_CAen_CA'} primaryText=" - Columbia CA"/>
        <MenuItem value={'2001,MountainHardwear_CA_2016en_CA'} primaryText=" - MountainHardwear CA"/>
        <MenuItem value={'2001,Sorel_CAen_CA'} primaryText=" - Sorel CA"/>

    </DropDownMenu>
    } />
    );
  }

}

export default MismatchedQty;

