import React, { Component } from 'react';
import {Paper, Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui';
import AdminCronBreadcrumbs from "./AdminCronBreadcrumbs";
import { cronRestApi } from '../../config';
import AdminCronTaskEntry from './AdminCronTaskEntry';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from "material-ui/styles/colors";

export const styles = {
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

export const AdminCronTask = ({ cronTask, refreshCronTask }) => {
  return (
    <Paper>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <FontAwesomeIcon icon={["far", "clock"]} size="2x" fixedWidth style={styles.icon}/>
          <ToolbarTitle text="Cron Task" />
        </ToolbarGroup>
      </Toolbar>
      <AdminCronTaskEntry cronTask={cronTask} onRefresh={refreshCronTask}/>
    </Paper>
  );
};

export default class AdminCronTaskDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { cronTask: null, loading: true, cronTaskId: props.match.params.id };
    this.refreshCronTask = this.refreshCronTask.bind(this);
  }

  componentDidMount() {
    this.refreshCronTask();
  }

  refreshCronTask(){
    let self = this;
    self.setState(Object.assign({}, self.state, { loading: true }));
    cronRestApi.get(`/cron/${this.state.cronTaskId}`).then(({data}) => {
      self.setState(Object.assign({}, self.state, { loading: false, cronTask: data }));
    }).catch((error) => {
      console.log("Get cronTask failed: " + error);
      console.log(error);
    });
  }

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <AdminCronBreadcrumbs cronTaskId={this.state.cronTaskId} />
        { this.state.cronTask &&
          <AdminCronTask cronTask={this.state.cronTask} refreshCronTask={this.refreshCronTask}/>
        }
      </div>
    );
  }
}
