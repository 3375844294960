import React, { Fragment }  from 'react';
import { PageSpacer, ClickableTile, NewUserTile, ComingSoonTile } from '../Components';
import { hasRoles, isNewUser } from '../api/userApi';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { comingSoon } from '../config';
import {grey700} from 'material-ui/styles/colors';

export default function Home() {
  const styles = {
    icon: {
      color: grey700
    }
  };

  return (
    <div style={{paddingBottom: '20px'}}>
      <Grid fluid>
        <Row>
          <PageSpacer/>
        </Row>
        <Row>
          { hasRoles('read:coupons') && !comingSoon &&
          <Fragment>
            <Col xs={12} sm={6} md={4}>
              <ClickableTile route='/coupons' heading='Coupons' icon='barcode' text='Friends &amp; Family Coupons' iconStyle={styles.icon} />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ClickableTile route='/couponsmanager' heading='Coupons Manager' icon='barcode' text='Friends &amp; Family Coupons' iconStyle={styles.icon} />
            </Col>
          </Fragment>
          }

          { hasRoles('read:catalogs') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/' heading='Catalogs' icon='book' text='Catalogs, Products &amp; Inventory' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:taxonomy') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/' heading='Taxonomy' icon='sort-amount-down' text='Product Taxonomy and Attribution' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:userMgmt') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/id-management' heading='ID Management' icon='id-badge' text='Manage Users IDs' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:vipCustomers,write:vipCustomers,delete:vipCustomers') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/vip' heading='VIP Customer' icon={['far', 'star']} text='Pro Purchase / Influencer Profiles' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:notlivereport,write:notlivereport,delete:notlivereport') && !comingSoon &&
          <Fragment>
            <Col xs={12} sm={6} md={4}>
              <ClickableTile route='/mismatchedqty' heading='Product Reports' icon='sort-amount-down' text='Mismatched Quantities' iconStyle={styles.icon}/>
            </Col>

            <Col xs={12} sm={6} md={4}>
              <ClickableTile route='/notlivereport' heading='Product Reports' icon='sort-amount-down' text='Not Live Reports' iconStyle={styles.icon}/>
            </Col>
          </Fragment>
          }

          { hasRoles('read:swagger,write:swagger') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/swagger' heading='API Docs' icon='book' text='API Gateway Documentation' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:translate') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/translator' heading='Translate' icon='language' text='Amazon Translate Service' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:notifyMe') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/notifyme' heading='Notify Me' icon='user-clock' text='Notify Me Records' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:awsservices') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/awsservices' heading='Amazon Web Services' icon={['fab', 'aws']} text='Services Dashboard' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:seolint') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/seolint' heading='SEO Linter' icon='link' text='Search Engine Optimization Linter' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:coverage') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/coverage' heading='AWS Code Coverage' icon={['far', 'file-code']} text='AWS Code Coverage Reports' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:pingdom') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/pingdom' heading='Pingdom' icon='satellite-dish' text='Pingdom Reports' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:orderhistory') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/orderhistory' heading='Order History' icon='shopping-cart' text='Search for Orders' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:photoStudio,write:photoStudio') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/photostudio' heading='Photo Studio' icon='images' text='Model Codes' iconStyle={styles.icon}/>
          </Col>
          }

          { hasRoles('read:sandbox') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/sandboxes' heading='On-Demand Sandbox Manager' icon={['fab', 'salesforce']} text='SFCC Sandbox Manager' iconStyle={styles.icon} />
          </Col>
          }

          { hasRoles('read:cloudwatch') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/cloudwatch' heading='Cloudwatch Logs Insights' icon={['fas', 'chart-bar']} text='Run a Query' iconStyle={styles.icon} />
          </Col>
          }

          { hasRoles('read:dashboard') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/dashboard' heading='Dashboards' icon='clock' text='Production Deployments' iconStyle={styles.icon} />
          </Col>
          }

          { hasRoles('read:inventoryRecord') && !comingSoon &&
          <Col xs={12} sm={6} md={4}>
            <ClickableTile route='/inventory/list' heading='Inventory' icon='tshirt' text='Search & Edit Inventory Records' iconStyle={styles.icon} />
          </Col>
          }

        </Row>

        { isNewUser() && !comingSoon &&
        <Row>

          <Col xs={12}>
            <NewUserTile/>
          </Col>

        </Row>
        }

        { comingSoon &&
        <Row>

          <Col xs={12}>
            <ComingSoonTile/>
          </Col>

        </Row>
        }
      </Grid>
    </div>
  )
};
