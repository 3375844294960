import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class PingdomBreadCrumbs extends Component {

  render() {
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            <span>Pingdom</span>
          </div>
        </div>
      </nav>
    )
  }
}
