import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
export default class DashboardBreadCrumbs extends Component {
  render() {
    const { dashboard } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
          { dashboard &&
            <Link to="/dashboard">Production Deployments</Link>
          }
            { !dashboard &&
              <span>Deployment Dashboards</span>
            }
          </div>
          
        </div>
      </nav>
    )
  }
}
DashboardBreadCrumbs.propTypes = {
  dashboard: PropTypes.object
};
