import store from '../services/store';
import { searchLogsSuccess, searchLogsFailed } from '../actions/logActions';
import { logsRestApi } from '../config';

export function getRecentLogs() {
  logsRestApi.get('/awslogs/recent').then(function({data}) {
    console.log(data);
  }).catch(function (error) {
    console.log("Get recent logs failed: " + error);
  });
}

export function searchLogs(search, logGroup, environment, startDate, endDate) {
  logsRestApi.post(`/awslogs/searchLogs`, {
    search: search,
    logGroupName: logGroup,
    start: startDate,
    end: endDate,
    profile: environment,
  }).then(function({data}) {
    console.log(data);
    store.dispatch(searchLogsSuccess(data));
  }).catch(function (error) {
    console.log("Search Logs failed: " + error);
    store.dispatch(searchLogsFailed(error));
  });
}
