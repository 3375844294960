import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { CircularProgress, DatePicker, Dialog, DropDownMenu, MenuItem, Paper, RaisedButton, Table, TableBody, TableRow, TableRowColumn, TextField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import { awsEnvironment } from '../../config';
import { searchLogs } from '../../api/logsApi';
import AdminLogsBreadcrumbs from "./AdminLogsBreadcrumbs";
import moment from 'moment';

const devLogGroups = [
  "/aws/lambda/dev-api-service-core-api",
  "/aws/lambda/dev-api-service-core-job",
  "/aws/lambda/dev-api-service-coupons-api",
  "/aws/lambda/dev-api-service-coupons-job",
  "/aws/lambda/dev-api-service-cron-api",
  "/aws/lambda/dev-api-service-cron-cron",
  "/aws/lambda/dev-api-service-cron-job",
  "/aws/lambda/dev-api-service-emailProfile-api",
  "/aws/lambda/dev-api-service-emailProfile-job",
  "/aws/lambda/dev-api-service-emailProfile-s3",
  "/aws/lambda/dev-api-service-logsnew-api",
  "/aws/lambda/dev-api-service-logsnew-job",
  "/aws/lambda/dev-api-service-logsnew-s3",
  "/aws/lambda/dev-api-service-rest-auth-handler",
  "/aws/lambda/dev-api-service-rest-token-service",
  "/aws/lambda/dev-api-service-sandbox-api",
  "/aws/lambda/dev-api-service-sandbox-job",
  "/aws/lambda/dev-api-service-dashboard-api",
  "/aws/lambda/dev-api-service-dashboard-job"
];

const prodLogGroups = [
  "/aws/lambda/prod-api-service-core-api",
  "/aws/lambda/prod-api-service-core-job",
  "/aws/lambda/prod-api-service-coupons-api",
  "/aws/lambda/prod-api-service-coupons-job",
  "/aws/lambda/prod-api-service-cron-api",
  "/aws/lambda/prod-api-service-cron-cron",
  "/aws/lambda/prod-api-service-cron-job",
  "/aws/lambda/prod-api-service-emailProfile-api",
  "/aws/lambda/prod-api-service-emailProfile-job",
  "/aws/lambda/prod-api-service-emailProfile-s3",
  "/aws/lambda/prod-api-service-jobs-jobs",
  "/aws/lambda/prod-api-service-logs-api",
  "/aws/lambda/prod-api-service-logs-logs",
  "/aws/lambda/prod-api-service-logsnew-api",
  "/aws/lambda/prod-api-service-logsnew-job",
  "/aws/lambda/prod-api-service-logsnew-s3",
  "/aws/lambda/prod-api-service-rest-api-service",
  "/aws/lambda/prod-api-service-rest-auth-handler",
  "/aws/lambda/prod-api-service-rest-token-service",
  "/aws/lambda/prod-api-service-sandbox-api",
  "/aws/lambda/prod-api-service-sandbox-job",
  "/aws/lambda/prod-api-service-dashboard-api",
  "/aws/lambda/prod-api-service-dashboard-job"
];

export const styles = {
  container: {
    marginTop: '20px',
    padding: '20px'
  },
  header: {
   // paddingTop: '20px',
    fontSize: '20px',
    marginBottom: '10px'
  },
  search: {
    marginRight: '10px',
    display: 'inline-block'
  },
  center:{
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block'
  },
  modal:{
    width: '80%',
    maxWidth: 'none'
  }
};


export class AdminLogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: null,
      logGroup: null,
      awsEnvironment: null,
      startDate: null,
      endDate: null,
      searching: false,
      open: false,
      modalData: null
    }
  }

  componentDidMount() {
    let logGroupDropDownItems = awsEnvironment === "colmdev" ? prodLogGroups : devLogGroups;
    this.setState({awsEnvironment: awsEnvironment, logGroup: logGroupDropDownItems[0]});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({searching: false});
  }

  onSearchChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { search: text.length > 0 ? text : null }));
  }

  onLogGroupChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { logGroup: value }));
  }

  startDateChange = (event, date) => {
    this.setState(Object.assign({}, this.state, { startDate: date }));
  }

  endDateChange = (event, date) => {
    this.setState(Object.assign({}, this.state, { endDate: date }));
  }

  onDismissStart = () => {
    console.log("start dismiss clicked");
    this.setState(Object.assign({}, this.state, { startDate: null }))
  }

  onDismissEnd = () => {
    console.log("end dismiss clicked");
    this.setState(Object.assign({}, this.state, { endDate: null }))
  }

  searchLogsBtnPress = () => {
    this.setState({searching: true});
    searchLogs(this.state.search, this.state.logGroup, this.state.awsEnvironment, this.state.startDate, this.state.endDate);
  }

  handleRowSelection = (selectedRows) => {
    console.log(selectedRows);
    if(selectedRows.length){
      this.setState({
        open: true,
        modalData: this.props.logs[selectedRows]
      });
    }
    else
      this.handleClose();
  }

  handleClose = () => {
    this.setState({open: false});
  }

  showData = () => {
    if(this.state.searching){
      return(
        <CircularProgress size={80} thickness={5} style={styles.center} />
      )
    }
    else if(this.props.logs && this.props.logs.length === 0){
      return(
        <div>No Results</div>
      );
    }
    else if(this.props.logs && this.props.logs.length > 0){
      let logs = this.props.logs.map((log, index) => {
        return(
          <TableRow key={index} >
            <TableRowColumn>
              <pre style={{ whiteSpace: "pre" }}>{log}</pre>
            </TableRowColumn>
          </TableRow>
        );
      });
      return(
        <Table onRowSelection={this.handleRowSelection}>
          <TableBody displayRowCheckbox={false}>
            {logs}
          </TableBody>
        </Table>

      );
    }
    else if(this.props.error){
      return(
        <div>{this.props.error.message}</div>
      );
    }
  }

  render(){
    let logGroupDropDownItems = this.state.awsEnvironment === "colmdev" ? prodLogGroups : devLogGroups;
    let dropdownItems = logGroupDropDownItems.map((logGroup, index) => {
      return <MenuItem key={index} value={logGroup} primaryText={logGroup} />
    });
    return(
      <div>
        <Dialog
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
          contentStyle={styles.modal}>
          <pre>{this.state.modalData}</pre>
        </Dialog>
        <AdminLogsBreadcrumbs />
        <Row>
          <Col xs={12}>
            <Paper style={styles.container}>
              <Toolbar>
                <ToolbarGroup firstChild={false}>
                  <ToolbarTitle text="Logs"/>
                </ToolbarGroup>
              </Toolbar>

              <Row middle="xs" center="xs">
                <Col md={12} lg={2}>
                  <TextField
                    id="search"
                    hintText="Search Term"
                    style={styles.search}
                    onChange={this.onSearchChange}
                  />
                </Col>
                <Col md={12} lg={3}>
                  <DropDownMenu
                    style={styles.search}
                    value={this.state.logGroup}
                    onChange={this.onLogGroupChange}>
                    {dropdownItems}
                  </DropDownMenu>
                </Col>
                <Col md={12} lg={1}>
                  <DatePicker
                    hintText="Start Date"
                    firstDayOfWeek={0}
                    formatDate={(date) => moment(date).format('YYYY/MM/DD')}
                    onChange={this.startDateChange}
                    onDismiss={this.onDismissStart}
                    style={styles.search}
                    value={this.state.startDate}
                  />
                </Col>
                <Col lg={1} />
                <Col md={12} lg={1}>
                  <DatePicker
                    hintText="End Date"
                    firstDayOfWeek={0}
                    formatDate={(date) => moment(date).format('YYYY/MM/DD')}
                    onChange={this.endDateChange}
                    onDismiss={this.onDismissEnd}
                    style={styles.search}
                    value={this.state.endDate}
                  />
                </Col>
                <Col lg={1} />
                <Col md={12} lg={1}>
                  <TextField
                    id="environment"
                    defaultValue={this.state.awsEnvironment}
                    disabled={true}
                    style={styles.search}/>
                </Col>
                <Col lg={1} />
                <Col md={12} lg={1}>
                  <RaisedButton label="Search" primary={true} onClick={ () => this.searchLogsBtnPress()} />
                </Col>
              </Row>

              { this.showData() }

            </Paper>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    logs: store.logState.logs,
    error: store.logState.error
  };
};

export default connect(mapStateToProps)(AdminLogsContainer);
