import React, { Component } from 'react';

import { Row, Col } from 'react-flexbox-grid';
import { Dialog, MenuItem, FlatButton, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { TextField, SelectField } from 'redux-form-material-ui';
import { pickBy } from 'lodash';
import { vipRestApi } from '../config';
import VIPStatus from './vipStatusHelper';
import states from '../states.json'

let createVipCustomerResponse ='';

export const styles = {
  actionsContainer: {
    margin: '10px 0'
  },
  headerText: {
    paddingTop: '20px',
    paddingLeft: '10px'
  },
  newBtn: {
    textAlign: "right",
    width: '100%',
    paddingTop: '10px'
  }
};

export const validate = values => {
  const errors = {};
  if (!values.siteId) {
    errors.siteId = 'Please select Site.'
  }
  if (!values.firstName) {
    errors.firstName = 'Please enter first name.'
  }
  else if ((values.firstName).length > 50) {
    errors.firstName = 'Invalid. Cannot be more than 50 characters.'
  }
  if (!values.lastName) {
    errors.lastName = 'Please enter last name.'
  }
  else if ((values.lastName).length > 50) {
    errors.lastName = 'Invalid. Cannot be more than 50 characters.'
  }
  let re = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
  if (!values.email) {
    errors.email = 'Please enter email.'
  }
  else if ((!re.test(values.email)) || (values.email).length > 50){
    errors.email = 'Not a valid email.'
  }
  if (!values.industry) {
    errors.industry = 'Please select the classification.'
  }
  if (!values.businessName) {
    errors.businessName = 'Please enter Business/Organization Name.'
  }
  else if ((values.businessName).length > 50){
    errors.businessName = 'Invalid. Cannot be more than 50 characters.'
  }
  re = /^\+?(?=(?:.*\d){5})[\d \-.\(\)\[\]/]{7,20}$/;
  if (!values.businessPhone) {
    errors.businessPhone = 'Please enter phone number.'
  }
  else if ((values.businessPhone).length < 10 || (values.businessPhone).length > 20 || (!re.test(values.businessPhone))) {
    errors.businessPhone = 'Invalid phone number.'
  }
  if (!values.businessLicenseNumber) {
    errors.businessLicenseNumber = 'Please enter ID/Cert.'
  }
  else if ((values.businessLicenseNumber).length > 50) {
    errors.businessLicenseNumber = 'Invalid ID/Cert.'
  }
  if (!values.country) {
    errors.country = 'Please select country.'
  }
  if (!values.customerGroup) {
    errors.customerGroup = 'Please select customer group.'
  }
  if (!values.address1) {
    errors.address1 = 'Please enter Address 1.'
  }
  else if ((values.address1).length > 50) {
    errors.address1 = 'Invalid Address. Cannot be more than 50 characters.'
  }
  if ((values.address2).length > 50) {
    errors.address2 = 'Invalid Address. Cannot be more than 50 characters.'
  }
  if (!values.city) {
    errors.city = 'Please enter city.'
  }
  else if ((values.city).length > 35 || (values.city).length < 2) {
    errors.city = 'Invalid city name.'
  }
  if (!values.state) {
    errors.state = 'Please select state.'
  }
  re = /^\d{5}(?:-\d{4})?$/;
  if (!values.zip) {
    errors.zip = 'Please enter Postal Code.'
  }
  else if ((!re.test(values.zip)) || (values.zip).length > 5) {
    errors.zip = 'Not a valid Postal Code/Must be between 5 to 10 characters.'
  }
  if ((values.notes).length > 250) {
    errors.notes = 'Cannot be more than 250 characters.'
  }
  return errors;
};

class CreateVipCustomerForm extends Component {

  getDropdownItems = () => {
    return states.map(( states, index) => {
      return <MenuItem key={index} value={states.value} primaryText={states.primaryText}/>
    });
  };

  render() {
    const {handleSubmit, pristine, reset, submitting, submitSucceeded} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {!submitSucceeded &&
          <Row>
            <Col xs={12} sm={6}>
              <Field
                name="siteId"
                component={SelectField}
                id="siteId"
                floatingLabelText="Site ID*"
                fullWidth={true}
                disabled={submitting}
                >
                <MenuItem value="Prana_US" primaryText="Prana_US"/>
              </Field>
            </Col>
          </Row>
        }
        {!submitSucceeded &&
          <div style={styles.headerText}>
            <Row>
              <label>Personal Information</label>
            </Row>
          </div>
        }
        <Row>
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="firstName"
                component={TextField}
                id="firstName"
                floatingLabelText="First Name*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="lastName"
                component={TextField}
                id="lastName"
                floatingLabelText="Last Name*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="email"
                component={TextField}
                id="email"
                floatingLabelText="Email*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
        </Row>
        {!submitSucceeded &&
          <div style={styles.headerText}>
            <Row>
              <label>Business/Organization Information</label>
            </Row>
          </div>
        }
        <Row>
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="industry"
                component={SelectField}
                id="industry"
                floatingLabelText="Classification*"
                fullWidth={true}
                disabled={submitting}
              >
                <MenuItem value="adventureactivetravelprofessional" primaryText="Adventure/Active Travel Professional"/>
                <MenuItem value="athlete" primaryText="Athlete"/>
                <MenuItem value="climbingmountaineeringguide" primaryText="Climbing/Mountaineering guide"/>
                <MenuItem value="climbinggymemployee" primaryText="Climbing Gym Employee"/>
                <MenuItem value="collegiateoutdooreducation" primaryText="Collegiate Outdoor Education"/>
                <MenuItem value="pranadealeremployee" primaryText="prAna Dealer Employee"/>
                <MenuItem value="environmentalemployee" primaryText="Environmental Employee"/>
                <MenuItem value="federallandresourceprofessional" primaryText="Federal Land Resource Professional"/>
                <MenuItem value="fitnessbasedinstructor" primaryText="Fitness Based Instructor"/>
                <MenuItem value="likemindedcompany" primaryText="Like Minded Company"/>
                <MenuItem value="mediaindividual" primaryText="Media Individual"/>
                <MenuItem value="nonprofitemployee" primaryText="Non Profit Employee"/>
                <MenuItem value="outdoorindustryindividual" primaryText="Outdoor Industry Individual"/>
                <MenuItem value="outdoorwildernesseducator" primaryText="Outdoor Wilderness Educator"/>
                <MenuItem value="pilatesinstructor" primaryText="Pilates Instructor"/>
                <MenuItem value="photographyprofessional" primaryText="Photography Professional"/>
                <MenuItem value="riverraftkayakguide" primaryText="River/Raft/Kayak guide"/>
                <MenuItem value="snowsportsinstructor" primaryText="Snowsports Instructor (PSIA - AASI based)"/>
                <MenuItem value="usarmedforces" primaryText="US Armed Forces"/>
                <MenuItem value="yogainstructor" primaryText="Yoga Instructor"/>
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="businessName"
                component={TextField}
                id="businessName"
                format={null}
                fullWidth={true}
                floatingLabelText="Business/Organization Name*"
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="businessPhone"
                component={TextField}
                id="businessPhone"
                format={null}
                fullWidth={true}
                floatingLabelText="Phone*"
                disabled={submitting}
              />
            </Col>
          }

          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="businessLicenseNumber"
                component={TextField}
                id="businessLicenseNumber"
                format={null}
                fullWidth={true}
                floatingLabelText="ID/Cert*"
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="customerGroup"
                component={SelectField}
                id="customerGroup"
                floatingLabelText="Customer Group*"
                fullWidth={true}
                disabled={submitting}
              >
                <MenuItem value="Prodeal 1 ALL" primaryText="Prodeal 1 ALL, Dual Gender, 50% Off"/>
                <MenuItem value="Prodeal 1 MEN" primaryText="Prodeal 1 MEN, Men Only, 50% Off"/>
                <MenuItem value="Prodeal 1 WMN" primaryText="Prodeal 1 WMN, Women Only, 50% Off"/>
                <MenuItem value="Prodeal 2 ALL" primaryText="Prodeal 2 ALL, Dual Gender, 62.5% Off"/>
                <MenuItem value="Prodeal 2 MEN" primaryText="Prodeal 2 MEN, Men Only, 62.5% Off"/>
                <MenuItem value="Prodeal 2 WMN" primaryText="Prodeal 2 WMN, Women Only, 62.5% Off"/>
                <MenuItem value="Prodeal 3 ALL" primaryText="Prodeal 3 ALL, prAna employees, 65% Off"/>
              </Field>
            </Col>
          }
        </Row>
        {!submitSucceeded &&
          <div style={styles.headerText}>
            <Row>
              <label>Address</label>
            </Row>
          </div>
        }
        <Row>
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="country"
                component={SelectField}
                id="country"
                floatingLabelText="Country*"
                fullWidth={true}
                disabled={submitting}
              >
                <MenuItem value="1" primaryText="United States"/>
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="address1"
                component={TextField}
                id="address1"
                floatingLabelText="Address 1*"
                fullWidth={true}
                disabled={submitting}
              >
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="address2"
                component={TextField}
                id="address2"
                floatingLabelText="Address 2"
                fullWidth={true}
                disabled={submitting}
              >
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="city"
                component={TextField}
                id="city"
                floatingLabelText="City*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="state"
                component={SelectField}
                id="state"
                floatingLabelText="State*"
                fullWidth={true}
                disabled={submitting}
              >
                { this.getDropdownItems() }
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="zip"
                component={TextField}
                id="zip"
                floatingLabelText="Postal Code*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
        </Row>
        <Row>
          {!submitSucceeded &&
            <div style={styles.headerText}>
              <Row>
                <label>Additional Information</label>
              </Row>
            </div>
          }
        </Row>
        <Row>
          {!submitSucceeded &&
            <Field
                name="notes"
                component={TextField}
                id="notes"
                multiLine={true}
                fullWidth={true}
                disabled={submitting}
            />
          }
        </Row>
        <Row>
          <Col xs={12}>
            {submitSucceeded &&
              <p>
                { createVipCustomerResponse }
              </p>
            }
            <div style={styles.actionsContainer}>
              {!submitSucceeded &&
                <span>
                  <FlatButton
                    label="Submit"
                    type="submit"
                    primary={true}
                    disabled={submitting}
                  >
                    {submitting &&
                    <FontAwesomeIcon icon='sync' spin/>
                    }
                  </FlatButton>
                  <FlatButton
                    label="Clear"
                    disabled={pristine || submitting}
                    onClick={reset}
                  />
                </span>
              }
              <FlatButton
                label="Close"
                disabled={submitting}
                onClick={this.props.closeButton}
              />
            </div>
          </Col>
        </Row>
      </form>
    )
  };
}

CreateVipCustomerForm = reduxForm({
  form: 'CreateVipCustomerForm',
  initialValues: {
    siteId: '',
    firstName: '',
    lastName: '',
    gender:'',
    email:'',
    industry:'',
    businessName:'',
    businessPhone:'',
    businessLicenseNumber:'',
    customerGroup:'',
    country:'',
    address1:'',
    address2:'',
    city:'',
    state:'',
    zip:'',
    customerNo:'',
    vipStatus: VIPStatus.getStatusBySiteIdAndType('Prana_US', VIPStatus.APPROVED),
    notes:'',
    source:'eCommHub',
    applicationDate: new Date().toISOString()
  },
  validate
})(CreateVipCustomerForm);

export default class VipAddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  submit = (body) => (
    vipRestApi.post('/vipcustomers', pickBy(body, value => value)).then((res) => {
      this.props.onRefresh && this.props.onRefresh();
      createVipCustomerResponse = JSON.stringify(res.data);
    }).catch((error) => {
      createVipCustomerResponse = "Failed to create VIP Customer: " + JSON.stringify(error.response.data);
    })
  );

  render() {
    return (
      <div style={styles.newBtn}>
        <RaisedButton label="NEW" primary={true} onClick={this.handleOpen} />
        <Dialog
          title="Create New Customer"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
        >
          <CreateVipCustomerForm onSubmit={this.submit} closeButton={this.handleClose} />
        </Dialog>
      </div>
    );
  }
}
