import React, {Component} from "react";
import {Card, CardHeader, CardText, Checkbox, TextField} from "material-ui";
import {Col, Row} from "react-flexbox-grid";
import {dateFormat} from "../filters";
import {restApi} from "../config";

class AdminUserAccounts extends Component {
  constructor (props) {
    super(props);
    this.state = { user: props.user };
  }

  componentDidMount() {
    this.getSfccUser();
    this.getIamUser();
    this.getIamUserGroups();
  }

  getSfccUser() {
    restApi.get(`/users/${this.state.user.email}/sfcc`).then(({data}) => {
      let newUser = Object.assign({}, this.state.user, { sfccUser: data });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }).catch((error) => {
      console.log("Get SFCC user failed: " + error);
    });
  }

  getIamUser() {
    const userId = this.state.user.email.match(/^.*?(?=@)/)[0];
    restApi.get(`/users/${userId}/iam`).then(({data}) => {
      let newUser = Object.assign({}, this.state.user, { iamUser: data });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }).catch((error) => {
      console.log("Get IAM user failed: " + error);
    });
  }

  getIamUserGroups() {
    const userId = this.state.user.email.match(/^.*?(?=@)/)[0];
    restApi.get(`/users/${userId}/iamgroups`).then(({data}) => {
      let newUser = Object.assign({}, this.state.user, { iamUserGroups: data });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }).catch((error) => {
      console.log("Get IAM Groups user failed: " + error);
    });
  }

  render () {
    return (
      <Row>
        <Col xs={12}>
          {this.state.user.sfccUser ?
          <Card>
            <CardHeader title="SFCC Account"/>
            <CardText>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.sfccUser.host}
                    fullWidth={true}
                    floatingLabelText="Host"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                    multiLine={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.sfccUser.email}
                    fullWidth={true}
                    floatingLabelText="Email Address"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField defaultValue={this.state.user.sfccUser.firstName}
                    fullWidth={true}
                    floatingLabelText="First Name"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField defaultValue={this.state.user.sfccUser.lastName}
                    fullWidth={true}
                    floatingLabelText="Last Name"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.sfccUser.lastLoginDate}
                    fullWidth={true}
                    floatingLabelText="Last Login"
                    underlineDisabledStyle={{border: 'none'}}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Checkbox
                    checked={this.state.user.sfccUser.locked}
                    label="Locked"
                    disabled={true}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Checkbox
                    checked={this.state.user.sfccUser.disabled}
                    label="Disabled"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.sfccUser ? this.state.user.sfccUser.roles.join(', ') : ""}
                    fullWidth={true}
                    floatingLabelText="Roles"
                    underlineDisabledStyle={{border: 'none'}}
                    disabled={true}
                    multiLine={true}
                  />
                </Col>
              </Row>
            </CardText>
          </Card>
          :
          <Card>
            <CardHeader title="SFCC Account"/>
            <CardText>No SFCC Account Found</CardText>
          </Card>
        }
          { this.state.user.iamUser ?
          <Card>
            <CardHeader title="AWS Account"/>
            <CardText>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.iamUser.UserName}
                    fullWidth={true}
                    floatingLabelText="User Name"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <TextField defaultValue={dateFormat(this.state.user.iamUser.PasswordLastUsed, 'l h:mm:ssa')}
                    fullWidth={true}
                    floatingLabelText="Last Login Date"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField defaultValue={dateFormat(this.state.user.iamUser.CreateDate, 'l h:mm:ssa')}
                    fullWidth={true}
                    floatingLabelText="Date Created"
                    underlineDisabledStyle={{border:'none'}}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField defaultValue={this.state.user.iamUserGroups ? this.state.user.iamUserGroups.map(g => g.GroupName).join(', ') : ''}
                    fullWidth={true}
                    floatingLabelText="Groups"
                    underlineDisabledStyle={{border: 'none'}}
                    disabled={true}
                    multiLine={true}
                  />
                </Col>
              </Row>
            </CardText>
          </Card>
          :
          <Card>
            <CardHeader title="AWS Account"/>
            <CardText>No AWS Account Found</CardText>
          </Card>
        }
        </Col>
      </Row>
    )
  }
}

export default AdminUserAccounts;
