import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class CouponBreadCrumbs extends Component {

  render() {
    const { coupon, type } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>


          <div className="breadcrumb">
            { coupon &&
            <Link to={type === "couponsmanager"  ? "/couponsmanager" : "/coupons"}>  {type === "couponsmanager" ? "Coupons Manager" : "Coupons"} </Link>
            }
            { !coupon &&
            <span> {type === "couponsmanager" ? "Coupons Manager" : "Coupons"} </span>
            }
          </div>
          { coupon &&
            <div className="breadcrumb">
              <span>{coupon.name}</span>
            </div>
          }
        </div>
      </nav>
    )
  }
}

CouponBreadCrumbs.propTypes = {
  coupon: PropTypes.object
};
