import React from 'react';
import PageHeader from './components/PageHeader';

export { Tile } from './components/Tile';
export { ClickableTile } from './components/ClickableTile';
export { NewUserTile } from './components/NewUserTile';
export { ComingSoonTile } from './components/ComingSoonTile';
export { PageHeader };
export { PrivateRoute } from './components/PrivateRoute';
export { ConfirmDialog } from './components/ConfirmDialog';

export function PageSpacer(props) {
  return (
    <div style={{height: '20px'}}>{props.children}</div>
  )
}
