import * as types from '../actions/actionTypes';

const initialState = {
  logs: null
};

const logReducer = function(state = initialState, action) {
  switch(action.type) {
    case types.SEARCH_LOGS_SUCCESS:
      return Object.assign({}, state, { logs: action.logs });

    case types.SEARCH_LOGS_FAILED:
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
};

export default logReducer;
