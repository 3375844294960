import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

function DrawerSubLink ({ classes, icon, primaryText, route, toggleDrawer }) {
  return (
    <NavLink className={classes.link} exact to={route} onClick={toggleDrawer('left', false)}>
      <ListItem button className={classes.nested}>
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} fixedWidth/>
        </ListItemIcon>
        <ListItemText primary={primaryText} />
      </ListItem>
    </NavLink>
  )
}

export default DrawerSubLink

DrawerSubLink.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  primaryText: PropTypes.string,
  route: PropTypes.string,
  toggleDrawer: PropTypes.func
}
