import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SelectField, MenuItem, Paper, RaisedButton, Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui';
import { getSwaggerUrls } from '../api/utilsApi';
import ApiDocs from './ApiDocs';
import {Col, Row} from 'react-flexbox-grid';
import ApiDocsBreadCrumbs from './ApiDocsBreadCrumbs';
import LoaderComponent from '../components/Loader';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from 'material-ui/styles/colors';

export const styles = {
  container: {
    padding: '0 10px 10px 10px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class SwaggerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swaggerUrls: [],
      selectedUrlId: '',
      selectedServiceId: '',
      token: ''
    };
  }

  componentDidMount = async () => {
    await getSwaggerUrls();
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.swaggerUrls) {
      this.setState(Object.assign({}, this.state, { swaggerUrls: nextProps.swaggerUrls, token: nextProps.token }));
    }
  }

  handleServiceChange = (event, index, value) => {
    let serviceId = (index > 0) ? this.state.swaggerUrls[index-1]['id'] : 'Not Selected';
    this.setState(Object.assign({}, this.state, { selectedUrlId: value, selectedServiceId: 'Service ID: ' + serviceId }));
  };

  renderServiceOptions () {
    if (this.state.swaggerUrls && this.state.swaggerUrls.length) {
      return this.state.swaggerUrls.map((s) => {
        const item = JSON.parse(s.value);
        return (
          <MenuItem key={s.id} value={item.url}>{item.name}</MenuItem>
        );
      });
    }
  }

  refreshBtnSubmit = async () => {
    await getSwaggerUrls();
  };

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <ApiDocsBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <FontAwesomeIcon icon="book" size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="API Gateway Documentation" />
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton
                primary={true}
                label="Refresh"
                onClick={this.refreshBtnSubmit} />
            </ToolbarGroup>
          </Toolbar>
          <div style={styles.container}>
            <Row>
              <Col xs={4}>
                <SelectField
                  floatingLabelText={this.state.selectedServiceId}
                  hintText="Select Service"
                  value={this.state.selectedUrlId}
                  onChange={this.handleServiceChange}
                  fullWidth={true}
                >
                  < MenuItem value={'none'} primaryText="None Selected" />
                  {this.renderServiceOptions()}
                </SelectField>
              </Col>
            </Row>
          </div>
        </Paper>
        <ApiDocs urlLink={this.state.selectedUrlId} token={this.state.token}/>
      </div>
    )
  }
}

const mapStateToProps = function(store) {
  return {
    swaggerUrls: store.utilsState.swaggerUrls,
    token: store.userState.profile.token
  };
};

export default connect(mapStateToProps)(SwaggerContainer);
