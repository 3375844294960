import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { notlivereportRestApi } from '../config';
import { Divider, Paper, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, LinearProgress } from 'material-ui';
import { Field, reduxForm } from 'redux-form';
import { TextField, DatePicker } from 'redux-form-material-ui';
import BlockCard from './BlockCard';
import { hasRoles } from '../api/userApi';
import { numberFormat } from '../filters';
import NotLiveBreadcrumbs from "./NotLiveBreadcrumbs";

export const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a NotLiveReport Name'
  }
  if (!values.description) {
    errors.description = 'Please enter a description'
  }
  if (!values.prefix) {
    errors.prefix = 'Required'
  }
  if (!values.startsOn) {
    errors.startsOn = 'Required';
  }
  if (!values.endsOn) {
    errors.endsOn = 'Required'
  } else if (values.startsOn >= values.endsOn) {
    errors.endsOn = 'Please enter a date later than the start date'
  }
  return errors
};

const styles = {
  section: {
    marginBottom: '20px'
  },
  buttonMargin: {
    margin: "10px"
  }
};

export class EditNotLiveReportForm extends Component {
  constructor(props) {
    super(props);
    this.putUpdates = this.putUpdates.bind(this);
  }

  putUpdates(body) {
    return (
      notlivereportRestApi.put('/notlivereport/' + this.props.notlivereport.id, body).then((response) => {
        this.props.onRefresh && this.props.onRefresh();
      }).catch((error) => {
        console.log("Post notlivereport failed: " + error);
      })
    )
  }

  render() {
    const { handleSubmit, pristine, reset } = this.props;
    const canUpdate = hasRoles('write:notlivereport');
    return (
        <Col xs={12}>
          <form onSubmit={handleSubmit(this.putUpdates)}>
          <Paper zDepth={1}>
            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text="NotLiveReport Details" style={{fontSize: "1rem"}}/>
              </ToolbarGroup>
            </Toolbar>
            <Row style={{ padding:"20px" }}>
              <Col xs={12} sm={6}>
                <Field
                  id="notlivereportName"
                  name="name"
                  component={TextField}
                  fullWidth={true}
                  floatingLabelText="NotLiveReport Name"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Field
                  id="notlivereportPrefix"
                  name="prefix"
                  component={TextField}
                  fullWidth={true}
                  floatingLabelText="NotLiveReport Site"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={true}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Field
                  id="notlivereportType"
                  name="type"
                  component={TextField}
                  fullWidth={true}
                  floatingLabelText="NotLiveReport UPC/Product ID"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={true}
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  id="notlivereportDesc"
                  name="description"
                  component={TextField}
                  fullWidth={true}
                  multiLine={true}
                  floatingLabelText="NotLiveReport Open Qty"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  id="notlivereportDesc"
                  name="description"
                  component={TextField}
                  fullWidth={true}
                  multiLine={true}
                  floatingLabelText="NotLiveReport Material"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  id="notlivereportDesc"
                  name="description"
                  component={TextField}
                  fullWidth={true}
                  multiLine={true}
                  floatingLabelText="NotLiveReport Sales Org"
                  onChange={this.handleFieldUpdate}
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Field
                  id="notlivereportStart"
                  name="startsOn"
                  component={DatePicker}
                  hintText="Start Date"
                  fullWidth={true}
                  floatingLabelText="Site Impressions Per Year"
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <Field
                  id="notlivereportEnd"
                  name="endsOn"
                  component={DatePicker}
                  hintText="Start Date"
                  fullWidth={true}
                  floatingLabelText="Expiration Date"
                  underlineDisabledStyle={{border:'none'}}
                  disabled={!canUpdate}
                />
              </Col>
              <Col xs={12}>
                { (canUpdate && !pristine) &&
                <div>
                  <RaisedButton style={styles.buttonMargin} primary={true} disabled={pristine} label="Save Changes" type="submit" />
                  <RaisedButton style={styles.buttonMargin} disabled={pristine} label="Reset Changes" onClick={reset} />
                </div>
                }
              </Col>
            </Row>
          </Paper>
          </form>
        </Col>
    );
  }
}

// Decorate EditNotLiveReportForm with reduxForm

EditNotLiveReportForm = reduxForm({
  form: 'EditNotLiveReportForm',
  validate,
  enableReinitialize: true
})(EditNotLiveReportForm);

export const NotLiveReportStats = ({ notlivereport, onRefresh }) => (
    <Paper style={{padding: '20px'}} zDepth={1}>
      <h4>
        NotLiveReport Statistics
      </h4>
      <Divider/>
      <p><small>Codes Generated</small></p>
      <h3>{!!notlivereport.statistics ? numberFormat(notlivereport.statistics.numCodesDefined) : '0'}</h3>
      <Divider />
      <p><small>Codes Assigned</small></p>
      <h3>{!!notlivereport.statistics ? numberFormat(notlivereport.statistics.numCodesAllocated) : '0'}</h3>
      <LinearProgress style={{height: '15px'}} mode="determinate" max={notlivereport.statistics.numCodesDefined} value={notlivereport.statistics.numCodesAllocated}/>
      <p><small><i>({!!notlivereport.statistics ? numberFormat(notlivereport.statistics.numCodesDefined - notlivereport.statistics.numCodesAllocated) : '0'} Codes Remaining)</i></small></p>
    </Paper>
);

export const NotLiveReportBlockList = ({ notlivereportBlocks, isDefinition, onRefresh }) => {

  const NotLiveReportBlockRows = ( block ) => {
    return (
      <Col key={block.id} xs={12} md={6} lg={4}>
        <BlockCard
          status={block.status}
          id={block.id}
          createdBy={block.createdBy}
          requestedBy={block.requestedBy}
          issueId={block.issueId}
          description={block.description}
          numCodes={block.numCodes}
          isDefinition={isDefinition}
          dateCreated={block.createdOn}
          onRefresh={onRefresh}
        />
      </Col>
    );
  };

  let blocks = [];

  if (isDefinition) {
    blocks = notlivereportBlocks.map(NotLiveReportBlockRows);
  }
  else {
    blocks = notlivereportBlocks.map(NotLiveReportBlockRows);
  }

  return (
    <Row>
      {blocks}
    </Row>
  );
};

export const NotLiveReportDetail = ({ onRefresh, notlivereport, definitionBlocks, allocationBlocks }) => (
  <div>
    {/*{ notlivereport &&*/}
    {/*<PageHeader>{notlivereport ? notlivereport.name : ""}</PageHeader>*/}
    {/*}*/}
    { notlivereport &&
      <Row>
        <Col xs={12}>
          <NotLiveBreadcrumbs notlivereport={notlivereport}/>
        </Col>
      </Row>
    }
    <Row>
      <Col xs={12} sm={9}>
        { notlivereport &&
          <Row style={styles.section}>
            <EditNotLiveReportForm initialValues={notlivereport} notlivereport={notlivereport} onRefresh={onRefresh}/>
          </Row>
        }


      </Col>
      <Col xs={12} sm={3}>
        <Row>
          <Col xs={12}>
            { notlivereport &&
              <NotLiveReportStats notlivereport={notlivereport}/>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

class NotLiveReportContainer extends Component {
  constructor(props) {
    super();
    this.state = { notlivereportId: props.match.params.id };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.filterBlocks = this.filterBlocks.bind(this);
  }

  componentDidMount() {
    this.handleRefresh();
  }

  handleRefresh(e){
    this.loadNotLiveReport();
    this.loadBlocks();
  }

  filterBlocks(data) {
    const filterAllocations = (block) => block.type === "allocation";
    let allocationBlocks = data.filter(filterAllocations);

    const filterDefinitions = (block) => block.type === "definition";
    let definitionBlocks = data.filter(filterDefinitions);

    this.setState({
      allocationBlocks: allocationBlocks,
      definitionBlocks: definitionBlocks
    });
  }

  loadNotLiveReport() {
    let self = this;
    notlivereportRestApi.get("/notlivereport/" + this.state.notlivereportId + "?loadStatistics=true").then(({data}) => {
      data.endsOn = new Date(data.endsOn);
      data.startsOn = new Date(data.startsOn);
      self.setState(Object.assign({}, self.state, { notlivereport: data }));
    }).catch((error) => {
      console.log("Get notlivereport failed: " + error);
    });
  }

  loadBlocks() {
    notlivereportRestApi.get('/notlivereport/' + this.state.notlivereportId + '/blocks').then(({data}) => {
      this.filterBlocks(data);
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <NotLiveReportDetail
        onRefresh={this.handleRefresh}
        notlivereport={this.state.notlivereport}
        definitionBlocks={this.state.definitionBlocks}
        allocationBlocks={this.state.allocationBlocks}
      />
    );
  }
}

export default NotLiveReportContainer;
