import React, { Component } from 'react';
import { Paper } from 'material-ui';

import InventoryBreadCrumbs from './InventoryBreadCrumbs';
import InventoryListSearch from './InventoryListSearch';
import { InventoryListSearchResults } from './InventoryListSearchResults';

export const styles = {};


class InventoryList extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '20px' }}>
        <InventoryBreadCrumbs records={false} />
        <Paper>
          <InventoryListSearch />
          <InventoryListSearchResults />
        </Paper>
      </div>
    );
  }
}

export default InventoryList;
