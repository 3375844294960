import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Paper, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';
import { cloudwatchRestApi } from '../config';
import StartQuery from './StartQuery';
import { hasRoles } from '../api/userApi';
import CloudwatchBreadCrumbs from "./CloudwatchBreadcrumbs";

export const CloudwatchItem = ( cloudwatch ) => {
  return (
    <TableRow key={cloudwatch.id}>
        <TableRowColumn width="30px" className="hide-mobile" style={{ whiteSpace: "normal", wordWrap: "break-word"}}>{new Date(cloudwatch.id.timestamp).toString()}</TableRowColumn>
        <TableRowColumn width="300px" className="hide-mobile" style={{ whiteSpace: "normal", wordWrap: "break-word"}}>{cloudwatch.id.message}</TableRowColumn>
    </TableRow>
  )
};

export const CloudwatchList = ({ onRefresh, cloudwatch }) => {
  const cloudwatchList = cloudwatch.map(CloudwatchItem);
  return (
    <Paper>
      <Toolbar>

        <ToolbarGroup className="hide-mobile" firstChild={false}>
          <ToolbarTitle text="AWS Cloudwatch Logs" />
        </ToolbarGroup>

        <ToolbarGroup>
          { hasRoles('read:cloudwatch') &&
            <StartQuery onRefresh={onRefresh} />
          }
        </ToolbarGroup>

        <ToolbarGroup>
          <RaisedButton label="Refresh" onClick={onRefresh}/>
        </ToolbarGroup>
      </Toolbar>

      <Table fixedHeader={true}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn width="30px" className="hide-mobile">Time Stamp</TableHeaderColumn>
            <TableHeaderColumn width="300px" className="hide-mobile">Log Messages</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false} wrapped={true}>
          {cloudwatchList}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const Cloudwatch = ({ onRefresh, cloudwatch }) => (
  <div>
    <CloudwatchBreadCrumbs />
    {cloudwatch &&
      <Row>
        <Col xs={12}>
          <CloudwatchList cloudwatch={cloudwatch} onRefresh={onRefresh}/>
        </Col>
      </Row>
    }
  </div>
);

class CloudwatchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { cloudwatch: []};
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
  }

  componentDidMount(){
    this.timer = setInterval(()=> this.refreshList(), 10*1000) // reloads the cloudwatch log stream information every ten seconds
   }

  handleRefreshClick(e){
    this.refreshList();
  }

  refreshList(){
    let self = this;
    console.log(window.getQueryRequest)
    cloudwatchRestApi.post('/cloudwatch/getQueryResults', window.getQueryRequest).then(({res,data}) => {
      data = data.results
      // eslint-disable-next-line
      let id = 0;
      let arr = [];
    for(var i=0;i<data.length;i++){
      let newdata = data[i];
      id = i;
      let mydata ={
        'timestamp' : newdata[0].value,
        'message'  : (newdata[1].value).slice(62)
      };
      let t = {id : mydata};
       arr.push(t);
       console.log(arr);
    }
      self.setState(Object.assign({}, self.state, { cloudwatch: arr}));
    }).catch((error) => {
      console.log("Get cloudwatch logs failed: " + error);
    });
  }

  render() {
    return (
      <Cloudwatch
        onRefresh={this.handleRefreshClick}
        cloudwatch={this.state.cloudwatch}
      />
    );
  }
}
export default CloudwatchContainer;
