import store from '../services/store';
import { getEnvironmntSuccess } from '../actions/dashboardAction'; 
import { dashboardRestApi } from '../config';
export function getEnvironment(service) {
  if(service) {
    dashboardRestApi.get('/dashboard/' + service).then(function({data}) {
    store.dispatch(getEnvironmntSuccess(data));
  }).catch(function (error) {
    console.log("Get environments failed: " + error);
  });
} else {
  store.dispatch(getEnvironmntSuccess(null));
}
}
