import React from 'react';
import Vip from './Vip';
import VipDetails from './VipDetails';
import { Route, Switch } from 'react-router-dom'

const VipRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/vip" component={Vip}/>
      <Route path="/vip/:id" component={VipDetails}/>
    </Switch>
  </div>
);

export default VipRoutes;
