import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatButton, Snackbar } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notlivereportRestApi } from '../config';

export default class GetNotLiveReportFileButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gettingDownloadFile: false,
      readyForDownload: false,
      downloadError: false
    };
    this.getDownloadUrl = this.getDownloadUrl.bind(this);
  };

  getDownloadUrl() {
    let self = this;
    self.setState({
      gettingDownloadFile: true,
    });
    notlivereportRestApi.get("/notlivereportCodeBlocks/" + this.props.id + "/download?type=" + this.props.type).then(({data}) => {
      setTimeout(function(){
        self.setState({
          downloadUrl: data.url,
          gettingDownloadFile: false,
          readyForDownload: true,
          downloadError: false
        })}, 500)
    }).catch((error) => {
      console.log("Get url failed: " + error);
      self.setState({
        gettingDownloadFile : false,
        error: true
      })
    });
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired // defines file type - needs to be import or list
  };

  render(){
    const { type, status } = this.props;
    return(
      <div>
        { (status === "ready") &&
        <div>
          { !this.state.readyForDownload &&
          <FlatButton
            label={`Get ${type === "import" ? "Zip" : "CSV"} Download` }
            primary={false}
            icon={
              <FontAwesomeIcon
                name={
                  this.state.gettingDownloadFile
                  ? "sync"
                  : `file-${type === "import" ? "archive" : "csv"}`
                }
                spin={this.state.gettingDownloadFile}
              />
            }
            onClick={this.getDownloadUrl}
            disabled={this.state.gettingDownloadFile}
          />
          }
          { this.state.readyForDownload &&
          <FlatButton
            label={ `Download ${type === "import" ? "Zip" : "CSV"}` }
            primary={true}
            icon={<FontAwesomeIcon icon="cloud-download-alt"/>}
            href={this.state.downloadUrl}
            disabled={!this.state.readyForDownload}
            download
          />
          }
          <Snackbar style={{textAlign: "center"}} autoHideDuration={3000} message="Error getting download link" open={this.state.error}/>
        </div>
        }
      </div>
    )
  }
}
