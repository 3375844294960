import React from 'react';
import AdminUsers from './AdminUsers';
import AdminJobs from './AdminJobs';
import AdminUserDetails from './AdminUserDetails';
import AdminLogs from './logs/AdminLogs';
import AdminLogsDetails from './logs/AdminLogsDetails';
import AdminCronTasks from './cron/AdminCron';
import AdminCronDetails from './cron/AdminCronDetails';
import AdminEncrypt from './AdminEncrypt';
import AdminDecrypt from './AdminDecrypt';
import { Route, Switch } from 'react-router-dom'

const AdminRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/admin/users" component={AdminUsers}/>
      <Route path="/admin/users/:id" component={AdminUserDetails}/>
      <Route exact path="/admin/jobs" component={AdminJobs}/>
      <Route exact path="/admin/logs" component={AdminLogs}/>
      <Route exact path="/admin/logs/:id" component={AdminLogsDetails}/>
      <Route exact path="/admin/cron" component={AdminCronTasks}/>
      <Route exact path="/admin/cron/:id" component={AdminCronDetails}/>
      <Route exact path="/admin/encrypt" component={AdminEncrypt}/>
      <Route exact path="/admin/decrypt" component={AdminDecrypt}/>
    </Switch>
  </div>
);

export default AdminRoutes;
