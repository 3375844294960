import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import spacing from 'material-ui/styles/spacing';
import transitions from 'material-ui/styles/transitions';
import typography from 'material-ui/styles/typography';
import {grey100, grey200} from 'material-ui/styles/colors';
import { Paper } from 'material-ui';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import logo from '../logo.svg';

export class ComingSoonTileInternal extends Component {

  // static propTypes = {
  //   heading: PropTypes.string,
  //   text: PropTypes.string,
  //   spin: PropTypes.bool,
  //   width: PropTypes.number,
  // };

  getStyles() {
    const desktopGutter = spacing.desktopGutter;
    const desktopKeylineIncrement = spacing.desktopKeylineIncrement;
    const styles = {
      root: {
        transition: transitions.easeOut(),
        backgroundColor: grey100,
        maxWidth: '600px',
        //width: '100%',
        margin: `0 auto ${desktopGutter}px auto`,
      },
      link: {
        textDecoration: 'none'
      },
      heading: {
        fontSize: 20,
        letterSpacing: 0,
        fontWeight: typography.fontWeightMedium,
        color: typography.textDarkBlack,
        backgroundColor: grey200,
        textAlign: 'center',
        margin: 0,
        padding: 0,
        lineHeight: `${desktopKeylineIncrement}px`,
      },
      body: {
        fontSize: 16,
        letterSpacing: 0,
        fontWeight: typography.fontWeightNormal,
        color: typography.textDarkBlack,
        backgroundColor: grey100,
        textAlign: 'center',
        margin: 0,
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        marginBottom: 11,
        lineHeight: `22px`,
      },
      innerGrid: {
        padding: 0,
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem'
      },
      icon: {
        width: '92px'
      },
      iconColumn: {
        //paddingTop: 16,
        //marginBottom: 11,
        //backgroundColor: grey200
      },
      iconColumnInner: {
        //  backgroundColor: grey200,
        //   padding: 0,
        //   margin: 0,
        //      paddingTop: 10,
        //     paddingBottom: 10
      },
      textColumn: {
        textAlign: 'left'
        //paddingTop: 16,
        //marginBottom: 11,
      },
      rootWhenLastChild: {
        marginBottom: 0,
      },
      rootWhenMediumAndLastChild: {
        marginRight: 0,
        marginBottom: 0,
      },
      rootWhenMediumAndFirstChild: {
        marginLeft: 0,
      },
    };

    return styles;
  }

  state = {
    zDepth: 0,
  };

  handleMouseEnter = () => {
    this.setState({
      zDepth: 4,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      zDepth: 0,
    });
  };

  render() {
    const styles = this.getStyles();
    return (
      <Paper
        zDepth={this.state.zDepth}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={Object.assign(
          styles.root,
          this.props.lastChild && styles.rootWhenLastChild
        )}
      >
        <h3 style={styles.heading}>eCommerce Hub Coming Soon!</h3>
        <div style={styles.body}>
          <Grid fluid style={styles.innerGrid}>
            <Row middle="xs">
              <Col xs={4} style={styles.iconColumn}>
                <div style={styles.iconColumnInner}>
                  <img style={styles.icon} src={logo} className="App-logo" alt="logo" />
                </div>
              </Col>
              <Col xs={8} style={styles.textColumn}>
                { this.props.profile.user && this.props.profile.user.firstName ? (
                  <p>Hi {this.props.profile.user.firstName}, we are almost there!</p>
                ) : (
                  <p>Hi, we are almost there!</p>
                )}
                <p>
                  This application should be ready by the end of this sprint, check back around May 3 or
                  talk to Andy, Sean, Michael & Robeson to see how it's coming along!
                </p>
                {this.props.children}
              </Col>
            </Row>
          </Grid>
        </div>
      </Paper>
    );
  }
}

ComingSoonTileInternal.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  spin: PropTypes.bool,
  width: PropTypes.number,
};

const mapStateToProps = function(store) {
  return {
    profile: store.userState.profile
  };
};

export const ComingSoonTile = connect(mapStateToProps)(ComingSoonTileInternal);
