import {orderHistoryRestApi, exportOrdersRestApi} from '../config';
import store from "../services/store";
import {getLoaderStatus} from '../actions/utilsActions';
import {searchOrderHistorySuccess, searchOrderHistoryFailed, getOrderHistoryCountSuccess, getOrderHistoryCountFailed, getRecentOrderStatusSuccess, getRecentOrderStatusFailed, searchOrderStatusSuccess, searchOrderStatusFailed} from '../actions/orderHistoryActions';

function setLoadingStatus (loading) {
  store.dispatch(getLoaderStatus(loading))
}

export function searchOrderHistory (orderNumber) {
  setLoadingStatus(true);
  orderHistoryRestApi.get(`/orderhistory/orders/${orderNumber}`).then(function({data}) {
    store.dispatch(searchOrderHistorySuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    store.dispatch(searchOrderHistoryFailed(error));
    setLoadingStatus(false);
    if (error.message.indexOf('404')) {
      alert('Order Not found');
    }
  });
}

export function getOrderHistoryCount () {
  orderHistoryRestApi.get(`/orderhistory/orders/count`).then(function({data}) {
    store.dispatch(getOrderHistoryCountSuccess(data.count));
  }).catch(function (error) {
    store.dispatch(getOrderHistoryCountFailed(error));
  });
}

export function getRecentOrderStatus () {
  setLoadingStatus(true);
  // The below 20 in URL is the limit/total records to return
  exportOrdersRestApi.get(`/exportOrders/recentorderstatus/20`).then(function({data}) {
    store.dispatch(getRecentOrderStatusSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    store.dispatch(getRecentOrderStatusFailed(error));
    setLoadingStatus(false);
  });
}

export function searchOrderStatus (orderNumber) {
  setLoadingStatus(true);
  exportOrdersRestApi.get(`/exportOrders/orderstatus/${orderNumber}`).then(function({data}) {
    store.dispatch(searchOrderStatusSuccess(data));
    setLoadingStatus(false);
    if (!data) {
      alert('Order Not found');
    }
  }).catch(function (error) {
    store.dispatch(searchOrderStatusFailed(error));
    setLoadingStatus(false);
  });
}
