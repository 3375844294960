import React from 'react';
import Cloudwatch from './Cloudwatch';
import { Route, Switch } from 'react-router-dom';

const CloudwatchRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/cloudwatch" component={Cloudwatch}/>
    </Switch>
  </div>
);

export default CloudwatchRoutes;
