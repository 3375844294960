import React, {Component} from 'react';
import axios from 'axios';
import config from '../config.json';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import 'swagger-ui-themes/themes/3.x/theme-material.css'
import { hasRoles } from '../api/userApi';
import url from 'url';

class ApiDocs extends Component {

  componentWillReceiveProps(nextProps) {
    let swaggerWrite = hasRoles('write:swagger');

    const DisableTryItOutPlugin = function() {
      return {
        statePlugins: {
          spec: {
            wrapSelectors: {
              allowTryItOutFor: () => () => false
            }
          }
        }
      }
    };

    if(nextProps && nextProps.urlLink) {
      axios.get(nextProps.urlLink).then(result => {
        const parts = url.parse(config.restBaseUrl);
        result.data.host = parts.host + parts.pathname;
        const plugins = swaggerWrite ? [] : [DisableTryItOutPlugin];
        const ui = SwaggerUI({
          spec: result.data,
          dom_id: '#swaggerContainer',
          presets: [SwaggerUI.presets.apis],
          plugins: plugins,
          onComplete: function () {
            ui.preauthorizeApiKey('jwt_auth', 'Bearer ' + nextProps.token);
          }
        });

      });
    }
  }

  render() {
    return (
      <div id="swaggerContainer" />
    );
  }
}

export default ApiDocs;
