import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, TableCell, TableRow, Tooltip } from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StartStopSandbox from '../../buttons/StartStopSandbox';
import ToolTipClick from '../../elements/ToolTipClick';
import { hasRoles } from '../../../../api/userApi';
import { copyToClipboard, statusColorHelper } from '../../../utils/utils';

function SandboxTableRow({ classes, handleUpdateClick, sandbox }) {
  const statusColors = statusColorHelper(sandbox.state, sandbox.uptimeStatus);

  return (
    <Fragment>
      <TableRow>
        <TableCell className={classes.td}>{sandbox.name}</TableCell>
        { hasRoles('start:sandbox') &&
        <TableCell size='small' align='left' className={classes.tdOperation}>
          <StartStopSandbox state={sandbox.state} uuid={sandbox.id} handleUpdateClick={handleUpdateClick} />
        </TableCell>
        }
        <TableCell className={classes.td} onClick={(e) => copyToClipboard(sandbox.id, e)}>
            <span>{'*****'}</span>
            <ToolTipClick icon={['far', 'clone']} classes={classes}/>
          </TableCell>
        <TableCell className={classes.td}>
          <Tooltip title='Navigate to Business Manager' arrow>
            <Link href={sandbox.bmlink} color='primary' target='_blank' rel='nopener'>
              {sandbox.realm}-{sandbox.instance}
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.td}>{new Date(sandbox.createdAt).toLocaleDateString()}</TableCell>
        <TableCell className={classes.td}>{sandbox.eol ? sandbox.eol : 'No End of Life'}</TableCell>
        <TableCell className={classes.td}>
          {sandbox.state === 'starting' || sandbox.state === 'stopping'
            ? <FontAwesomeIcon icon={['fas', 'spinner']} color={statusColors.state} pulse size='2x' className={classes.icon}/>
            : <FontAwesomeIcon icon={['fas', 'circle']} color={statusColors.state} size='2x' className={classes.icon}/>
          }
        </TableCell>
        <TableCell className={classes.tdUpTime}>
          <div style={{display: 'inline-block', minWidth: '50px'}}>{sandbox.uptime}</div>
          <FontAwesomeIcon icon={['fas', 'circle']} color={statusColors.upTime} size='2x' className={classes.paddedIcon}/>
        </TableCell>
        <TableCell className={classes.td}>{sandbox.downtime}</TableCell>
      </TableRow>
    </Fragment>
  )
}

SandboxTableRow.propTypes = {
  classes: PropTypes.object,
  handleUpdateClick: PropTypes.func,
  sandbox: PropTypes.object
}

export default SandboxTableRow;
