import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Row, Col } from 'react-flexbox-grid';
import { restApi } from '../config';
import { Dialog, FlatButton, Paper, RaisedButton, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import AdminUserBreadCrumbs from "./AdminUserBreadcrumbs";
import { currentUserId } from '../api/userApi';
import AdminUserEdit from './AdminUserEdit';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from "material-ui/styles/colors";

const defaultRoles = {
  "read:photoStudio": { name: "Read Photo Studio", icon: "images", hint: "read" },
  "write:photoStudio": { name: "Write Photo Studio", icon: "images", hint: "write" },
  "publish:photoStudio": { name: "Publish Photo Studio", icon: "images", hint: "write" },
  "delete:photoStudio": { name: "Delete Photo Studio", icon: "images", hint: "delete" },
  "read:coupons": { name: "Read Coupons", icon: "barcode", hint: "read", category: "coupons" },
  "write:coupons": { name: "Write Coupons", icon: "barcode", hint: "write", category: "coupons" },
  "delete:coupons": { name: "Delete Coupons", icon: "barcode", hint: "write", category: "coupons" },
  "define:coupons": { name: "Define Coupons", icon: "barcode", hint: "write", category: "coupons" },
  "allocate:coupons": { name: "Allocate Coupons", icon: "barcode", hint: "write", category: "email" },
  "read:userMgmt": { name: "Read id management", icon: ["far", "id-badge"], hint: "read" },
  "write:userMgmt": { name: "write id management", icon: ["far", "id-badge"], hint: "write" },
  "admin:userMgmt": { name: "administer id management", icon: ["far", "id-badge"], hint: "admin" },
  "admin:users": { name: "administer users", icon: "shield-alt", hint: "admin" },
  "admin:jobs": { name: "administer jobs", icon: "cogs", hint: "admin" },
  "read:status": { name: "read status", icon: ["far", "check-circle"], hint: "read" },
  "read:logs": { name: "read logs", icon: "list", hint: "read" },
  "admin:cron": { name: "administer cron tasks", icon: ["far", "clock"], hint: "admin" },
  "read:vipCustomers": { name: "Read VIP Customers", icon: ["far", "star"], hint: "read" },
  "write:vipCustomers": { name: "Write VIP Customers", icon: ["far", "star"], hint: "write" },
  "delete:vipCustomers": { name: "Delete VIP Customers", icon: ["far", "star"], hint: "delete" },
  "vipCustomers:All": { name: "All Sites VIP Customers", icon: ["far", "star"], hint: "write" },
  "vipCustomers:Columbia_US": { name: "COL US VIP Customers", icon: ["far", "star"], hint: "write" },
  "vipCustomers:Columbia_CA": { name: "COL CA VIP Customers", icon: ["far", "star"], hint: "write" },
  "vipCustomers:MountainHardwear_US": { name: "MHW US VIP Customers", icon: ["far", "star"], hint: "write" },
  "vipCustomers:MountainHardwear_CA": { name: "MHW CA VIP Customers", icon: ["far", "star"], hint: "write" },
  "vipCustomers:Prana_US": { name: "PRA US VIP Customers", icon: ["far", "star"], hint: "write" },
  "read:notlivereport": { name: "Read Notlive Reports", icon: "sort-amount-down", hint: "read" },
  "write:notlivereport": { name: "Write Notlive Reports", icon: "sort-amount-down", hint: "write" },
  "delete:notlivereport": { name: "Delete Notlive Reports", icon: "sort-amount-down", hint: "write" },
  "read:failedObjects": { name: "Read Failed Objects", icon: "bug", hint: "read" },
  "write:failedObjects": { name: "Resubmit Failed Objects", icon: ["far", "file-excel"], hint: "write" },
  "delete:failedObjects": { name: "Delete Failed Objects", icon: ["far", "file-excel"], hint: "write" },
  "read:swagger": { name: "read swagger", icon: "book", hint: "read" },
  "write:swagger": { name: "write swagger", icon: "book", hint: "write" },
  "read:translate": { name: "read translate", icon: "language", hint: "read" },
  "read:notifyMe": { name: "read notify me", icon: "user-clock", hint: "read" },
  "write:notifyMe": { name: "write notify me", icon: "user-clock", hint: "write" },
  "write:warranty": { name: "write warranty", icon: "certificate", hint: "write" },
  "read:orderhistory": { name: "read orderhistory", icon: "history", hint: "read" },
  "write:orderhistory": { name: "write orderhistory", icon: "history", hint: "write" },
  "read:p2p": { name: "read p2p", icon: "share-alt", hint: "read" },
  "write:p2p": { name: "write p2p", icon: "share-alt", hint: "write" },
  "delete:p2p": { name: "delete p2p", icon: "share-alt", hint: "write" },
  "write:importPrice": {name: "write importPrice", icon: "arrow-right", hint: "write" },
  "read:printcatalog": { name: "read printcatalog", icon: "print", hint: "read" },
  "write:printcatalog": { name: "write printcatalog", icon: "print", hint: "write" },
  "write:kmsencrypt": { name: "write encrypt", icon: "lock", hint: "write" },
  "read:customer": { name: "read customer", icon: "user-plus", hint: "read" },
  "write:customer": { name: "write customer", icon:"user-plus", hint: "write" },
  "write:exportOrders": { name: "write exportorders", icon:"money-bill", hint: "write" },
  "write:exportProduct": { name: "write export product", icon:"cubes", hint: "write" },
  "write:friendsFamilyProfiles": { name: "write friends and family profiles", icon:"users", hint:"write" },
  "write:importInventory": { name: "write import inventory", icon:"mail-bulk", hint: "write" },
  "write:importProduct": { name: "write import product", icon:"cube", hint:"write" },
  "read:eazybi": { name: "read eazybi", icon: "print", hint: "read" },
  "read:sandbox": { name: "Read Sandbox", icon: "salesforce", hint: "read", category: "sandbox" },
  "write:sandbox": { name: "Write Sandbox", icon: "salesforce", hint: "write", category: "sandbox" },
  "admin:sandbox": { name: "Admin Sandbox", icon: "salesforce", hint: "admin", category: "sandbox" },
  "start:sandbox": { name: "Start Sandbox", icon: "salesforce", hint: "start", category: "sandbox" },
  "delete:sandbox": { name: "Delete Sandbox", icon: "salesforce", hint: "delete", category: "sandbox" },
  "write:message": {name: "write message", icon:"envelope-square", hint:"message"},
  "write:loadToMongo": {name: "write loadToMongo", icon:"cubes", hint:"export product"},
  "read:cloudwatch": { name: "Read Cloudwatch", icon: "chart-bar", hint: "read", category: "cloudwatch" },
  "read:dashboard": { name: "Read Dashboard", icon: "file", hint: "read", category: "dashboard" },
  "read:inventoryRecord": { name: "Read Inventory Records", icon: "file", hint: "read" },
  "write:inventoryRecord": { name: "Write Inventory Records", icon: "file", hint: "write" },
  "read:rewards": { name: "Read Rewards", icon: "heart", hint: "read" },
  "write:rewards": { name: "Write Rewards", icon: "heart", hint: "write" },
  "write:router": { name: "Write Router", icon: "share-alt", hint: "write" }
};

const styles = {
  section: {
    marginBottom: '20px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

export const AdminUserDetail = ({ onRefresh, user, editable, addRole, addAllRoles, removeRole, removeAllRoles,
                                  addAdminRoles, setActiveFlag, putUpdates, handleFieldUpdate, onDeleteUser,
                                  definedRoles }) => (
  <div>
    { user &&
    <div>
      <Row style={styles.section}>
        <Col xs={12}>
          <Paper zDepth={1}>
            <Toolbar>
              <ToolbarGroup firstChild={false}>
                <FontAwesomeIcon icon="user" size="2x" fixedWidth style={styles.icon}/>
                <ToolbarTitle text="User Details"/>
              </ToolbarGroup>
              <ToolbarGroup>
                { editable &&
                  <RaisedButton primary={true} label="Update" onClick={putUpdates} style={{margin: '10px 4px'}}/>
                }
                <RaisedButton label="Refresh" onClick={onRefresh} style={{margin: '10px 4px'}}/>
                { editable &&
                  <RaisedButton label="Delete" onClick={onDeleteUser} style={{margin: '10px 4px'}}/>
                }
              </ToolbarGroup>
            </Toolbar>
            <AdminUserEdit
              user={user}
              addRole={addRole}
              addAllRoles={addAllRoles}
              removeRole={removeRole}
              removeAllRoles={removeAllRoles}
              addAdminRoles={addAdminRoles}
              setActiveFlag={setActiveFlag}
              handleFieldUpdate={handleFieldUpdate}
              editable={editable}
              isNew={false}
              definedRoles={definedRoles}
            />
          </Paper>
        </Col>
      </Row>
    </div>
    }
  </div>
);

export class ConfirmDeletePrompt extends React.Component {

  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onConfirm = () => {
    this.props.onResponse(true);
  };

  onCancel = () => {
    this.props.onResponse(false);
  };

  render() {
    const actions = [
      <FlatButton
        label="Delete User"
        primary={true}
        onClick={this.onConfirm}
      />,
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.onCancel}
      />,
    ];

    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.props.show}
        onRequestClose={this.handleClose}
      >
        Are you sure you want to delete this user? This cannot be undone!
      </Dialog>
    );
  }
}

ConfirmDeletePrompt.propTypes = {
  show: PropTypes.bool,
  onResponse: PropTypes.func,
};

class AdminUserContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { userId: props.match.params.id, showDeletePrompt: false, myself: props.match.params.id === currentUserId() };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.addRole = this.addRole.bind(this);
    this.addAllRoles = this.addAllRoles.bind(this);
    this.removeRole = this.removeRole.bind(this);
    this.removeAllRoles = this.removeAllRoles.bind(this);
    this.addAdminRoles = this.addAdminRoles.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.putUpdates = this.putUpdates.bind(this);
    this.setActiveFlag = this.setActiveFlag.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.onConfirmDeleteUser = this.onConfirmDeleteUser.bind(this);
    this.getAllRoles();
    this.loadUser();
  }


  handleRefresh(e){
    this.getAllRoles();
    this.loadUser();
  }

  setActiveFlag(activeFlag){
    let newUser = Object.assign({}, this.state.user, { active: activeFlag });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  addRole (role) {
    if (!this.state.user.roles.find(r => r === role)) {
      let newRoles = this.state.user.roles.slice();
      newRoles.push(role);
      let newUser = Object.assign({}, this.state.user, { roles: newRoles });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }
  }

  addAllRoles (definedRoles) {
    let roles = Object.keys(definedRoles).filter(k => !k.startsWith('admin:'));
    let existingRoles = this.state.user.roles.slice();
    let newRoles = existingRoles.concat(roles.slice());
    let newUser = Object.assign({}, this.state.user, { roles: newRoles });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  removeRole(role){
    let index = this.state.user.roles.findIndex(r => r === role);
    if (index >= 0) {
      let newRoles = this.state.user.roles.slice();
      newRoles.splice(index, 1);
      let newUser = Object.assign({}, this.state.user, { roles: newRoles });
      this.setState(Object.assign({}, this.state, { user: newUser }));
    }
  }

  removeAllRoles () {
    let newUser = Object.assign({}, this.state.user, { roles: [] });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  addAdminRoles (definedRoles) {
    let roles = Object.keys(definedRoles).filter(k => k.startsWith('admin:'));
    let existingRoles = this.state.user.roles.slice();
    let newRoles = existingRoles.concat(roles.slice());
    let newUser = Object.assign({}, this.state.user, { roles: newRoles });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  handleFieldUpdate(e) {
    let key = e.target.id;
    let value = e.target.value;
    let newUser = Object.assign({}, this.state.user, { [key]: value });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  putUpdates() {
    let user = this.state.user;
    const email = user.email;
    const handleRefresh = this.handleRefresh;
    restApi.put('/users/' + email, {
      firstName: user.firstName,
      lastName: user.lastName,
      slackId: user.slackId,
      timezone: user.timezone,
      active: (user.active === true),
      roles: user.roles
    }).then((response) => {
      if (response.status === 200) {
        handleRefresh();
      }
    }).catch((error) => {
      console.log("Post user failed: " + error);
    });
  }

  loadUser() {
    let self = this;
    restApi.get('/users/' + this.state.userId).then(({data}) => {
      self.setState(Object.assign({}, self.state, { user: data }));
    }).catch((error) => {
      console.log("Get user failed: " + error);
    });
  }

  onDeleteUser() {
    this.setState(Object.assign({}, this.state, { showDeletePrompt: true }));
  }

  onConfirmDeleteUser(result) {
    let self = this;
    this.setState(Object.assign({}, this.state, { showDeletePrompt: false }));
    if (result === true) {
      restApi.delete('/users/' + this.state.userId).then(({data}) => {
        self.context.router.history.push('/admin/users');
      }).catch((error) => {
        console.log("Delete user failed: " + error);
      });
    }
  }

  getAllRoles() {
    let self = this;
    restApi.get('/users/roles').then(({data}) => {
      const definedRoles = Object.assign({}, defaultRoles, data);
      this.setState(Object.assign({}, self.state, { definedRoles: definedRoles }));
    }).catch((error) => {
      console.error("Get roles failed: " + error);
    });
  }

  render() {
    return (
      <div>
        <AdminUserBreadCrumbs email={this.state.userId} />
        <ConfirmDeletePrompt
          show={this.state.showDeletePrompt}
          onResponse={this.onConfirmDeleteUser}
        />
        <AdminUserDetail
          onRefresh={this.handleRefresh}
          user={this.state.user}
          editable={!this.state.myself}
          addRole={this.addRole}
          addAllRoles={this.addAllRoles}
          removeRole={this.removeRole}
          removeAllRoles={this.removeAllRoles}
          addAdminRoles={this.addAdminRoles}
          setActiveFlag={this.setActiveFlag}
          handleFieldUpdate={this.handleFieldUpdate}
          putUpdates={this.putUpdates}
          onDeleteUser={this.onDeleteUser}
          definedRoles={this.state.definedRoles}
        />
      </div>
    );
  }
}

AdminUserContainer.propTypes = {
  router: PropTypes.object
};

export default AdminUserContainer;
