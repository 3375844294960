import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid';
import { Paper, Toolbar, ToolbarGroup, ToolbarTitle, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';
import AdminLogsBreadcrumbs from "./AdminLogsBreadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormat } from '../../filters';
import { logsRestApi } from '../../config';

export const styles = {
  logIconSuccess: {
    color: '#88ff88'
  },
  logIconFailed: {
    color: '#ff8888'
  },
  logIconUnknown: {
    color: '#666666'
  },
  logApiSuccess: {
    color: '#88ff88'
  },
  logApiOther: {
    color: '#8888ff'
  },
  logApiFailed: {
    color: '#ff8888'
  },
  logApiUnknown: {
    color: '#666666'
  },
  logJobSuccess: {
    color: '#88ff88'
  },
  logJobOther: {
    color: '#8888ff'
  },
  logJobFailed: {
    color: '#ff8888'
  },
  logJobUnknown: {
    color: '#666666'
  }
};

function iconStyle(success) {
  if (success === true)
    return styles.logIconSuccess;
  if (success === false)
    return styles.logIconFailed;
  return styles.logIconUnknown;
}

function apiStatusStyle(statusCode) {
  if (statusCode >= 200 && statusCode <= 299)
    return styles.logApiSuccess;
  if (statusCode >= 300 && statusCode <= 399)
    return styles.logApiOther;
  if (statusCode >= 400)
    return styles.logApiFailed;
  return styles.logApiUnknown;
}

function jobSuccessStyle(success) {
  if (success === true)
    return styles.logJobSuccess;
  if (success === false)
    return styles.logJobFailed;
  return styles.logJobUnknown;
}

export const AdminLogsApiItem = ( log ) => {
  return (
    <TableRow key={log.id}>
      <TableRowColumn>
        <FontAwesomeIcon style={iconStyle(log.api.success)} icon="arrow-circle-o-right" fixedWidth/>
      </TableRowColumn>
      <TableRowColumn>
        <Link to={"/admin/logs/" + log.id}>
          { dateFormat(log.end) }
        </Link>
      </TableRowColumn>
      <TableRowColumn>
        { log.api.method }
        { log.api.path }
      </TableRowColumn>
      <TableRowColumn>
        <span style={apiStatusStyle(log.api.statusCode)}>{ log.api.statusCode }</span>
      </TableRowColumn>
    </TableRow>
  )
};

export const AdminLogsJobItem = ( log ) => {
  return (
    <TableRow key={log.id}>
      <TableRowColumn>
        <FontAwesomeIcon style={jobSuccessStyle(log.job.success)} icon="hourglass-2" fixedWidth/>
      </TableRowColumn>
      <TableRowColumn>
        <Link to={"/admin/logs/" + log.id}>
          { dateFormat(log.end) }
        </Link>
      </TableRowColumn>
      <TableRowColumn>
        { log.job.handler } :
        { log.job.type }
      </TableRowColumn>
      <TableRowColumn>

      </TableRowColumn>
    </TableRow>
  )
};

export const AdminLogsOtherItem = ( log ) => {
  return (
    <TableRow key={log.id}>
      <TableRowColumn>
        <FontAwesomeIcon style={styles.logIconUnknown} icon="question" fixedWidth/>
      </TableRowColumn>
      <TableRowColumn>
        <Link to={"/admin/logs/" + log.id}>
          { dateFormat(log.end) }
        </Link>
      </TableRowColumn>
      <TableRowColumn>
        ?
      </TableRowColumn>
      <TableRowColumn>
        ?
      </TableRowColumn>
    </TableRow>
  )
};

export const AdminLogsItem = ( log ) => {
  if (log.api)
    return (<AdminLogsApiItem log={log}/>);
  if (log.job)
    return (<AdminLogsJobItem log={log}/>);
  return (<AdminLogsOtherItem log={log}/>);
};

export const AdminLogs = ({ filters, logs, setFilters, refreshLogs }) => {
  const logList = logs.map( l => AdminLogsItem(l));
  return (
    <Paper>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <ToolbarTitle text="Sites" />
        </ToolbarGroup>
      </Toolbar>
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn></TableHeaderColumn>
            <TableHeaderColumn>Type</TableHeaderColumn>
            <TableHeaderColumn>Activity</TableHeaderColumn>
            <TableHeaderColumn></TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {logList}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default class AdminLogsDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { filters: {}, logs: [], loading: true };
    this.setFilters = this.setFilters.bind(this);
    this.refreshLogs = this.refreshLogs.bind(this);
  }

  componentDidMount() {
    this.refreshLogs();
  }

  setFilters(filters){
    let self = this;
    this.setState(Object.assign({}, self.state, { filters }));
    this.refreshLogs();
  }

  refreshLogs(){
    let self = this;
    self.setState(Object.assign({}, self.state, { loading: true }));
    logsRestApi.get('/logs').then(({data}) => {
      self.setState(Object.assign({}, self.state, { loading: false, logs: data }));
    }).catch((error) => {
      console.log("Get logs failed: " + error);
    });
  }

  render() {
    return (
      <div>
        <AdminLogsBreadcrumbs />
        <Row>
          <Col xs={12}>
            <AdminLogs filters={this.state.filters} logs={this.state.logs} setFilters={this.setFilters} refreshLogs={this.refreshLogs}/>
          </Col>
        </Row>
      </div>
    );
  }
}
