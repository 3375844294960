import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center'
  },
  fullList: {
    width: 'auto'
  },
  icon: {
    color: 'white'
  },
  iconButton: {
    flex: '0 0 auto',
    padding: '12px',
    fontSize: '24px',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  list: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  nested: {
    paddingLeft: theme.spacing(16),
  },
  root: {
    width: '100%'
  },
  secondaryText: {
    color: 'white'
  },
  subMenu: {
    justifyContent: 'flex-end'
  },
  title: {
    flexGrow: 1
  },
  userName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '0',
    padding: '0 0 0 10px',
    letterSpacing: '0',
    fontSize: '24px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255)',
    height: '64px',
    lineHeight: '64px'
  }
}))

export default useStyles;

useStyles.propTypes = {
  theme: PropTypes.object
}
