import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paper } from 'material-ui';
import { ToolbarDynamic } from '../components/ToolbarDynamic';
import PingdomBreadCrumbs from "./PingdomBreadCrumbs";
import {grey500} from "material-ui/styles/colors";


export const styles = {
  container: {
    padding: '10px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class Pingdom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    };
  }

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <PingdomBreadCrumbs />
          <Paper>
            <ToolbarDynamic title="Ecommerce Websites" icon="shopping-cart" iconStyle={styles.icon}  />
            <Grid fluid style={styles.container}>
              <Row>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/110bd16e" alt="Uptime Report for Columbia.com: Last 30 days" title="Uptime Report for Columbia.com: Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/93432484" alt="Uptime Report for MountainHardwear.com: Last 30 days" title="Uptime Report for MountainHardwear.com: Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/18f6b78a" alt="Uptime Report for Sorel.com: Last 30 days" title="Uptime Report for Sorel.com: Last 30 days" width="300" height="165" /></a>
                </Col>
              </Row>
            </Grid>
            <ToolbarDynamic title="Ecommerce Hub" icon={["fab", "hubspot"]} iconStyle={styles.icon}  />
            <Grid fluid style={styles.container}>
              <Row>
                <Col xs={12} md={6}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/29efc5c8" alt="Uptime Report for eComm Hub API - Epsilon (Production): Last 30 days" title="Uptime Report for eComm Hub API - Epsilon (Production): Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={6}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/b700fd71" alt="Uptime Report for eCommHub - User Token Expiration Check (PROD): Last 30 days" title="Uptime Report for eCommHub - User Token Expiration Check (PROD): Last 30 days" width="300" height="165" /></a>
                </Col>
              </Row>
            </Grid>
            <ToolbarDynamic title="Vendors" icon="cloud" iconStyle={styles.icon}  />
            <Grid fluid style={styles.container}>
              <Row>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/c22d9033" alt="Uptime Report for Loyalty Lab: Last 30 days" title="Uptime Report for Loyalty Lab: Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/eec49884" alt="Uptime Report for Ogone: Last 30 days" title="Uptime Report for Ogone: Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={4}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/c09d01b5" alt="Uptime Report for SVS: Last 30 days" title="Uptime Report for SVS: Last 30 days" width="300" height="165" /></a>
                </Col>
              </Row>
            </Grid>
            <ToolbarDynamic title="Adobe Experience Manager" icon={["fab", "adobe"]} iconStyle={styles.icon}  />
            <Grid fluid style={styles.container}>
              <Row>
                <Col xs={12} md={6}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/7bfc36ab" alt="Uptime Report for AEM Publisher Production: Last 30 days" title="Uptime Report for AEM Publisher Production: Last 30 days" width="300" height="165" /></a>
                </Col>
                <Col xs={12} md={6}>
                  <a href="http://www.pingdom.com"><img src="https://share.pingdom.com/banners/1e8f7f3a" alt="Uptime Report for AEM Author Production: Last 30 days" title="Uptime Report for AEM Author Production: Last 30 days" width="300" height="165" /></a>
                </Col>
              </Row>
            </Grid>
        </Paper>
      </div>
    );
  }
}

export default Pingdom;
