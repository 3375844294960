import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { PageHeader, Tile } from '../Components';
import { login } from '../api/userApi';

export default class AuthorizeOkta extends Component {
  constructor(props) {
    super(props);

    let token = props.match.params.token;
    console.log("Token: " + token);
    login(token, props.history);
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <PageHeader>Login</PageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} mdOffset={4}>
            <Tile heading="Logging in..." text="Please wait while we log you in." icon="refresh" spin/>
          </Col>
        </Row>
      </div>
    );
  }
}
