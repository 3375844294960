import React, { Component } from 'react';
import { PageHeader } from '../Components';
import { Row, Col } from 'react-flexbox-grid';
import { DropDownMenu, MenuItem, Paper, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, Toolbar, ToolbarGroup, ToolbarTitle, Tabs, Tab } from 'material-ui';
import {notlivereportRestApi} from '../config';

import PropTypes from 'prop-types';

import exportFromJSON from 'export-from-json'


import logo from '../logo.svg';
// import {columbiaBlue} from '../themes/brandColors';


export const NotLiveReportItem = ( notLiveProduct ) => {
  if (!notLiveProduct.reportDownloadLink) {

    return (
      <TableRow selectable={false} key={notLiveProduct.id}>
        <TableRowColumn className="hide-mobile" width="130px">
          {/*<Link to={"/notlivereport/" + notLiveProduct.upccode}>*/}
            {notLiveProduct.upccode}
          {/*</Link>*/}
        </TableRowColumn>
        <TableRowColumn width="130px" className="hide-mobile">{notLiveProduct.material}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.openqty}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.jdacode}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.colorcode}</TableRowColumn>
        <TableRowColumn width="90px" className="hide-mobile">{notLiveProduct.rddyearmonth}</TableRowColumn>


      </TableRow>
    )
  }
};

export const NotLiveReportList = ({ notliveitems, label, reportHandler}) => {
  return (
    <div>
      <Table fixedHeader={true} height="450px">
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn>Product ID / SKU</TableHeaderColumn>
            <TableHeaderColumn>Material ID</TableHeaderColumn>
            <TableHeaderColumn>Open Quantity</TableHeaderColumn>
            <TableHeaderColumn>JDA Code</TableHeaderColumn>
            <TableHeaderColumn>Color Code</TableHeaderColumn>
            <TableHeaderColumn>BW Contract Year</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {notliveitems.length > 0 && notliveitems.map(NotLiveReportItem)}
        </TableBody>
      </Table>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <ToolbarTitle text={`${notliveitems.length} ${label} Products`}/>
          <a href="#" onClick={reportHandler} >download</a>
          {/*<RaisedButton href='#' onMouseUp={reportHandler}>Download CSV</RaisedButton>*/}
          {/*<CSVLink headers={headers} data={data} target="_blank">This Report </CSVLink>*/}
          {/*<CsvDownloader datas={[{"the":"test"}]} filename={"csvReport.csv"} />*/}

        </ToolbarGroup>
      </Toolbar>
    </div>
  )
};


export const NotLiveReportContainer = ({dropdownItems,yearItems, notLiveReports, reportHandler}) => (
  <div>
    <PageHeader>NotLive Reports</PageHeader>
    <Row height={100}>
      <Col xs={12} sm={3}>
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <ToolbarTitle text="Site" />
            </ToolbarGroup>
          </Toolbar>

          {dropdownItems} {yearItems}

        </Paper>
      </Col>
      <Col xs={12} sm={9}>
        <Paper>
          <Tabs>

            <Tab label="Product Details" >
              {notLiveReports.data !== "loading" ?
                <NotLiveReportList label="NotLiveReport" notliveitems={notLiveReports} reportHandler={reportHandler}/>
                :
                <div style={{justifyContent: "center", alignItems: "center", minHeight: "450px"}}>
                  <div style={{padding: "20px"}}>
                    <img className="app-logo-spinner" src={logo} alt="logo"/>
                  </div>
                </div>
              }
            </Tab>

          </Tabs>
        </Paper>
      </Col>
    </Row>
  </div>
);

class NotLiveReport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      params: {
        limit: 20000,
        skip: 0,
        start: 0,
        sort: 'upccode'
      },
      salesorg: '1001',
      division: '',
      salesDiv: '1001,',
      rddyear: '2018',
      notLiveReports: {data: "loading"},
      csvHeaders: ["productid", "materialid", "openqty", "jdacode", "colorcode", "rddyearmonth"],
      csvData: [{cell1: 'row 1 - cell 1'}]

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleYear = this.handleYear.bind(this);

  }

  getNotLiveItems(){

    notlivereportRestApi.get(`/notlivereport/${this.state.salesorg}?division=${this.state.division}&rddyear=${this.state.rddyear}&limit=${this.state.params.limit}&sort=${this.state.params.sort}&skip=${this.state.params.skip}`).then(({data}) => {

      console.log("there it is ... ");

      this.setState(
      Object.assign(
        {}, this.state, {
          notLiveReports: data
        }
      )
    );

    }).catch((error) => {
      console.log("Get notlivereport failed: " + error);
    });
  }


  handleChange = async (event, index, value) => {
    console.log("this value " + value);
    await this.setState({notLiveReports: {data: "loading"}});

    let options = value.split(',');

    await this.setState(
      Object.assign( {}, this.state, {salesDiv: value, salesorg: options[0], division: options[1]})
    );
    this.getNotLiveItems();

  };

  handleYear = async (event, index, value) => {

    await this.setState({notLiveReports: {}, rddyear: value});

    console.log("the rdd state value " + this.state.rddyear);

    this.getNotLiveItems();

  };

  handleDownload = async (event) => {

      console.log("Downloading Report " + this.state.salesorg + ' ' + this.state.division + ' ' + this.state.rddyear);

    let fileName = "notlivereport";
    let exportType = 'csv';
    let data = this.state.notLiveReports;

    try{
      exportFromJSON({data, fileName, exportType});

    }catch (e) {
      console.log("there was an error getting the data " + e.message);
    }
    };


  getDownloadReport () {

    notlivereportRestApi.get(`/notlivereport/download/${this.state.salesorg}?division=${this.state.division}&rddyear=${this.state.rddyear}&limit=${this.state.params.limit}&sort=${this.state.params.sort}&skip=${this.state.params.skip}`).then(({data}) => {

    }).catch((error) => {
      console.log("Get notlivereport failed: " + error);
    });
  }

  componentDidMount() {
    console.log("the Report ID in ComponentDidMount " + this.division);

    this.getNotLiveItems();

  }


  static propTypes = {
    //onChange: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
    multi: PropTypes.bool,
  };

  render() {

    return (
      <NotLiveReportContainer notLiveReports={this.state.notLiveReports} reportHandler={this.handleDownload}
        dropdownItems={
          <DropDownMenu value={this.state.salesDiv} onChange={this.handleChange} openImmediately={false}>
            <MenuItem value={'1001,'}  primaryText="US Stores"/>
            <MenuItem value={'1001,01'}  primaryText=" - Columbia US"/>
            <MenuItem value={'1001,03'}  primaryText=" - MountainHardwear US"/>
            <MenuItem value={'1001,02'}  primaryText=" - Sorel US"/>
            <MenuItem value={'---- ---'}  primaryText=" -- " />
            <MenuItem value={'2001,'}  primaryText="CA Stores" />
            <MenuItem value={'2001,01'}  primaryText=" - Columbia CA"/>
            <MenuItem value={'2001,03'}  primaryText=" - MountainHardwear CA"/>
            <MenuItem value={'2001,02'}  primaryText=" - Sorel CA"/>
          </DropDownMenu>
        }
        yearItems={
          <DropDownMenu value={this.state.rddyear} onChange={this.handleYear} openImmediately={false}>
            <MenuItem value={''}  primaryText="All RDD"/>
            <MenuItem value={'2018'}  primaryText="2018"/>
            <MenuItem value={'2019'}  primaryText="2019"/>
          </DropDownMenu>
        }
      />
    );
  }

}

export default NotLiveReport;

