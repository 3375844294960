// borrows concepts from https://github.com/phenomnomnominal/angular-2-local-storage

// https://gist.github.com/jarrodirwin/0ce4c0888336b533b2c4
let Storage = function (type) {
  function createCookie(name, value, days) {
    let date, expires;

    if (days) {
      date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = "; expires="+date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
  }

  function readCookie(name) {
    let nameEQ = name + "=",
      ca = document.cookie.split(';'),
      i, c;

    for (i=0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0)===' ') {
        c = c.substring(1,c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
  }

  function setData(data) {
    // Convert data into JSON and encode to accommodate for special characters.
    data = encodeURIComponent(JSON.stringify(data));
    // Create cookie.
    if (type === 'session') {
      createCookie(getSessionName(), data, 365);
    } else {
      createCookie('localStorage', data, 365);
    }
  }

  function clearData() {
    if (type === 'session') {
      createCookie(getSessionName(), '', 365);
    } else {
      createCookie('localStorage', '', 365);
    }
  }

  function getData() {
    // Get cookie data.
    let data = type === 'session' ? readCookie(getSessionName()) : readCookie('localStorage');
    // If we have some data decode, parse and return it.
    return data ? JSON.parse(decodeURIComponent(data)) : {};
  }

  function getSessionName() {
    // If there is no name for this window, set one.
    // To ensure it's unquie use the current timestamp.
    if(!window.name) {
      window.name = "" + new Date().getTime();
    }
    return 'sessionStorage' + window.name;
  }

  // Initialise if there's already data.
  let data = getData();

  return {
    length: 0,
    clear: function () {
      data = {};
      this.length = 0;
      clearData();
    },
    getItem: function (key) {
      return data[key] === undefined ? null : data[key];
    },
    key: function (i) {
      // not perfect, but works
      let ctr = 0;
      for (let k in data) {
        if (ctr === i) return k;
        else ctr++;
      }
      return null;
    },
    removeItem: function (key) {
      delete data[key];
      this.length--;
      setData(data);
    },
    setItem: function (key, value) {
      data[key] = value+''; // forces the value to a string
      this.length++;
      setData(data);
    }
  };
};

class StorageService {

  constructor() {
    this.prefix = 'ls';
    //this.storageType = 'sessionStorage' | 'localStorage' | 'fakeStorage';
    this.fakeStorage = null;
    //this.webStorage;
    this.detectStorageType();
  }

  detectStorageType() {
    // try to use local storage first
    if (window.localStorage && window.localStorage !== null) {
      try {
        // When Safari (OS X or iOS) is in private browsing mode, it
        // appears as though localStorage is available, but trying to
        // call .setItem throws an exception.
        //
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
        // to add something to storage that exceeded the quota."
        let key = this.deriveKey(`__${Math.round(Math.random() * 1e7)}`);
        window.localStorage.setItem(key, '');
        window.localStorage.removeItem(key);
        //this.storageType = 'localStorage';
        this.webStorage = window.localStorage;
        return;
      } catch (e) {
        console.log("Local storage not detected: " + e);
      }
    }

    // next try to use session storage
    if (window.sessionStorage && window.sessionStorage !== null) {
      try {
        // When Safari (OS X or iOS) is in private browsing mode, it
        // appears as though localStorage is available, but trying to
        // call .setItem throws an exception.
        //
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
        // to add something to storage that exceeded the quota."
        let key = this.deriveKey(`__${Math.round(Math.random() * 1e7)}`);
        window.sessionStorage.setItem(key, '');
        window.sessionStorage.removeItem(key);
        //this.storageType = 'sessionStorage';
        this.webStorage = window.sessionStorage;
        return;
      } catch (e) {
        console.log("Session storage not detected: " + e);
      }
    }

    if (this.fakeStorage === null) {
      this.fakeStorage = Storage('session');
    }
    //this.storageType = 'fakeStorage';
    this.webStorage = this.fakeStorage;

    //throw new Error("Neither local storage nor session storage are available!");
  }

  deriveKey(key) {
    return `${this.prefix}${key}`;
  }

  setPrefix(prefix) {
    this.prefix = prefix;

    // If there is a prefix set in the config let's use that with an appended
    // period for readability:
    const PERIOD = '.';
    if (this.prefix && !this.prefix.endsWith(PERIOD)) {
      this.prefix = !!this.prefix ? `${this.prefix}${PERIOD}` : '';
    }
  }

  set(key, value) {
    let storedValue;
    if (value === undefined || value === null) {
      // FIXME: not a perfect solution, since a valid 'null' string can't be stored
      storedValue = 'null';
    } else {
      storedValue = JSON.stringify(value);
    }

    if (this.webStorage) {
      this.webStorage.setItem(this.deriveKey(key), storedValue);
      return;
    }

    throw new Error("Neither local storage nor session storage are available!");
  }

  remove(...keys) {
    keys.forEach((key) => {
      if (this.webStorage) {
        this.webStorage.removeItem(this.deriveKey(key));
      } else {
        throw new Error("Neither local storage nor session storage are available!");
      }
    });
  }

  get(key) {
    let item = this.webStorage ? this.webStorage.getItem(this.deriveKey(key)) : "";
    // FIXME: not a perfect solution, since a valid 'null' string can't be stored
    if (!item || item === 'null') {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  }
}

const service = new StorageService();

export default service;
