import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Row, Col, Grid} from 'react-flexbox-grid';
import { MenuItem, RaisedButton, SelectField, TextField, DatePicker } from 'material-ui';
import { simpleSearchVip } from "../api/vipApi";

class VipSearchComplexContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: null,
      customerNo: '',
      businessName: '',
      referralCode: '',
      industry: '',
      customerType: '',
      directSalesType:'',
      city: '',
      state: '',
      firstName: '',
      lastName: '',
      specialty: '',
      email: '',
      applicationDate: undefined,
      mediaUploadDate: undefined,
      vipStatus: '',
      take: 10,
      searching: false
    };
  }

  componentDidMount() {
    this.setState(Object.assign({}, this.state, { siteId: this.props.siteId }));
  }

  componentWillReceiveProps(nextProps) {
    this.setState(Object.assign({}, this.state, { siteId: nextProps.siteId }));
  }

  handleCustomerNoTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { customerNo: text.length > 0 ? text : undefined }));
  };

  handleBusinessNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { businessName: text.length > 0 ? text : undefined }));
  };

  handleReferralCodeTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { referralCode: text.length > 0 ? text : undefined }));
  };

  handleCityTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { city: text.length > 0 ? text : undefined }));
  };

  handleFirstNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { firstName: text.length > 0 ? text : undefined }));
  };

  handleEmailTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { email: text.length > 0 ? text : undefined }));
  };

  handleLastNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { lastName: text.length > 0 ? text : undefined }));
  };

  handleSpecialtyChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { specialty: value === 'none' ? undefined : value }));
  };

  handleIndustryChange = (event, index, value) => {
    let newState = Object.assign({}, this.state);
    newState.industry = (value === 'none' ? undefined : value );
    
    if(this.state.customerType || this.state.directSalesType){
      newState.customerType = undefined;
      newState.directSalesType = undefined;
    }
    this.setState(newState);
  };

  handleCustomerTypeChange = (event, index, value) => {
    let newState = Object.assign({}, this.state);
    newState.customerType = (value === 'none' ? undefined : value );

    if(this.state.industry){
      newState.industry = undefined;
    }
    this.setState(newState);
  }

  handleDirectSalesTypeChange = (event, index, value) => {
    let newState = Object.assign({}, this.state);
    newState.directSalesType = (value === 'none' ? undefined : value );

    if(this.state.industry){
      newState.industry = undefined;
    }
    this.setState(newState);
  }

  handleStateChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { state: value === 'none' ? undefined : value }));
  };

  handleVipStatusChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { vipStatus: value === 'all' ? undefined : value }));
  };

  handleTakeChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { take: value }));
  };

  handleApplicationDateChange = (event, date) => {
    this.setState(Object.assign({}, this.state, { applicationDate: date }));
  };

  handleMediaUploadDateChange = (event, date) => {
    this.setState(Object.assign({}, this.state, { mediaUploadDate: date }));
  };

  complexSearchSubmit = () => {
    simpleSearchVip({query: this.state, offset: 0, take: this.state.take});
  };

  buildStatusMenuItems = () => {
    const menuInfo = [
      { "key": "All", "value": "all", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Accepted", "value": "accepted", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Approved", "value": "approved", "sites": ["Prana_US"] },
      { "key": "Expired", "value": "expired", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Inactive", "value": "inactive", "sites": ["Prana_US"] },
      { "key": "Pending", "value": "pending", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Rejected", "value": "rejected", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Renew Accepted", "value": "renew/a", "sites": ["Columbia_US", "MountainHardwear_US"] },
      { "key": "Renew Expiring", "value": "renew/e", "sites": ["Columbia_US", "MountainHardwear_US"] },
      { "key": "Renewed", "value": "renewed", "sites": ["Prana_US"] }
    ];

    const menuItems = menuInfo.map((menuItem)=>{
      return (menuItem.sites.includes(this.state.siteId)) ? <MenuItem value={menuItem.value} primaryText={menuItem.key} /> : undefined;
    });

    return menuItems;
  }

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} md={3}>
            <TextField
              hintText="Customer No:"
              floatingLabelText="Customer No:"
              onChange={this.handleCustomerNoTextChange}
              value={this.state.customerNo}
            />
          </Col>
          <Col xs={12} md={3}>
            <TextField
              hintText="Business Name:"
              floatingLabelText="Business Name:"
              onChange={this.handleBusinessNameTextChange}
              value={this.state.businessName}
            />
          </Col>
          <Col xs={12} md={3}>
            <TextField
              hintText="Referral Code:"
              floatingLabelText="Referral Code:"
              onChange={this.handleReferralCodeTextChange}
              value={this.state.referralCode}
            />
          </Col>
          { this.state.siteId !== "MountainHardwear_US" &&
          <Col xs={12} md={3}>
            <SelectField
              floatingLabelText="Industry"
              value={this.state.industry}
              onChange={this.handleIndustryChange}
            >
              <MenuItem value={'none'} primaryText="None Selected" />
              <MenuItem value={'education'} primaryText="Education" />
              <MenuItem value={'eventmanagement'} primaryText="Event Management" />
              <MenuItem value={'government'} primaryText="Government" />
              <MenuItem value={'media'} primaryText="Media" />
              <MenuItem value={'nonprofit'} primaryText="Non-profit" />
              <MenuItem value={'professional'} primaryText="Professional" />
            </SelectField>
          </Col>
          }
          { this.state.siteId === "MountainHardwear_US" && 
             <Col xs={12} md={3}>
             <SelectField
              floatingLabelText="Customer Type"
             value={this.state.customerType}
              onChange={this.handleCustomerTypeChange}
            >
              <MenuItem value={'none'} primaryText="None Selected" />
              <MenuItem value={'01'} primaryText="Dealer Employee" />
              <MenuItem value={'02'} primaryText="Industry Professional" />
              <MenuItem value={'03'} primaryText="Direct Sales" />
              <MenuItem value={'04'} primaryText="Non Profit Organization" />
            </SelectField>
          </Col>
          } 
          { this.state.siteId === "MountainHardwear_US" && 
          <Col xs={12} md={3}>
             <SelectField
              floatingLabelText="Direct Sales Type"
             value={this.state.directSalesType}
              onChange={this.handleDirectSalesTypeChange}
            >
              <MenuItem value={'none'} primaryText="None Selected" />
              <MenuItem value={'01'} primaryText="Military" />
              <MenuItem value={'02'} primaryText="Government (local, state and federal)" />
              <MenuItem value={'03'} primaryText="Forest Service (state and local)" />
              <MenuItem value={'04'} primaryText="Educational" />
              <MenuItem value={'05'} primaryText="Resort Employees" />
              <MenuItem value={'06'} primaryText="Guide Services" />
              <MenuItem value={'07'} primaryText="Search and Rescue" />
              <MenuItem value={'99'} primaryText="Other" />
            </SelectField>
          </Col>
          }
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <TextField
              hintText="City:"
              floatingLabelText="City:"
              onChange={this.handleCityTextChange}
              value={this.state.city}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectField
              floatingLabelText="State"
              value={this.state.state}
              onChange={this.handleStateChange}
            >
              <MenuItem value={'none'} primaryText="None Selected" />
              <MenuItem value={'Alabama'} primaryText="Alabama" />
              <MenuItem value={'Alaska'} primaryText="Alaska" />
              <MenuItem value={'Arizona'} primaryText="Arizona" />
              <MenuItem value={'Arkansas'} primaryText="Arkansas" />
              <MenuItem value={'California'} primaryText="California" />
              <MenuItem value={'Colorado'} primaryText="Colorado" />
              <MenuItem value={'Connecticut'} primaryText="Connecticut" />
              <MenuItem value={'Delaware'} primaryText="Delaware" />
              <MenuItem value={'District of Columbia'} primaryText="District of Columbia" />
              <MenuItem value={'Florida'} primaryText="Florida" />
              <MenuItem value={'Georgia'} primaryText="Georgia" />
              <MenuItem value={'Hawaii'} primaryText="Hawaii" />
              <MenuItem value={'Idaho'} primaryText="Idaho" />
              <MenuItem value={'Illinois'} primaryText="Illinois" />
              <MenuItem value={'Indiana'} primaryText="Indiana" />
              <MenuItem value={'Iowa'} primaryText="Iowa" />
              <MenuItem value={'Kansas'} primaryText="Kansas" />
              <MenuItem value={'Kentucky'} primaryText="Kentucky" />
              <MenuItem value={'Louisiana'} primaryText="Louisiana" />
              <MenuItem value={'Maine'} primaryText="Maine" />
              <MenuItem value={'Montana'} primaryText="Montana" />
              <MenuItem value={'Nebraska'} primaryText="Nebraska" />
              <MenuItem value={'Nevada'} primaryText="Nevada" />
              <MenuItem value={'New Hampshire'} primaryText="New Hampshire" />
              <MenuItem value={'New Jersey'} primaryText="New Jersey" />
              <MenuItem value={'New Mexico'} primaryText="New Mexico" />
              <MenuItem value={'New York'} primaryText="New York" />
              <MenuItem value={'North Carolina'} primaryText="North Carolina" />
              <MenuItem value={'North Dakota'} primaryText="North Dakota" />
              <MenuItem value={'Ohio'} primaryText="Ohio" />
              <MenuItem value={'Oklahoma'} primaryText="Oklahoma" />
              <MenuItem value={'Oregon'} primaryText="Oregon" />
              <MenuItem value={'Maryland'} primaryText="Maryland" />
              <MenuItem value={'Massachusetts'} primaryText="Massachusetts" />
              <MenuItem value={'Michigan'} primaryText="Michigan" />
              <MenuItem value={'Minnesota'} primaryText="Minnesota" />
              <MenuItem value={'Mississippi'} primaryText="Mississippi" />
              <MenuItem value={'Missouri'} primaryText="Missouri" />
              <MenuItem value={'Pennsylvania'} primaryText="Pennsylvania" />
              <MenuItem value={'Rhode Island'} primaryText="Rhode Island" />
              <MenuItem value={'South Carolina'} primaryText="South Carolina" />
              <MenuItem value={'South Dakota'} primaryText="South Dakota" />
              <MenuItem value={'Tennessee'} primaryText="Tennessee" />
              <MenuItem value={'Texas'} primaryText="Texas" />
              <MenuItem value={'Utah'} primaryText="Utah" />
              <MenuItem value={'Vermont'} primaryText="Vermont" />
              <MenuItem value={'Virginia'} primaryText="Virginia" />
              <MenuItem value={'Washington'} primaryText="Washington" />
              <MenuItem value={'West Virginia'} primaryText="West Virginia" />
              <MenuItem value={'Wisconsin'} primaryText="Wisconsin" />
              <MenuItem value={'Wyoming'} primaryText="Wyoming" />
            </SelectField>
          </Col>
          <Col xs={12} md={3}>
            <TextField
              hintText="First Name:"
              floatingLabelText="First Name:"
              onChange={this.handleFirstNameTextChange}
              value={this.state.firstName}
            />
          </Col>
          <Col xs={12} md={3}>
            <TextField
              hintText="Last Name:"
              floatingLabelText="Last Name:"
              onChange={this.handleLastNameTextChange}
              value={this.state.lastName}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <TextField
              hintText="Email"
              floatingLabelText="Email:"
              onChange={this.handleEmailTextChange}
              value={this.state.email}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectField
              floatingLabelText="Specialty"
              value={this.state.specialty}
              onChange={this.handleSpecialtyChange}
            >
              <MenuItem value={'none'} primaryText="None Selected" />
              <MenuItem value={'fishing'} primaryText="fishing" />
              <MenuItem value={'hunting'} primaryText="hunting" />
              <MenuItem value={'golf'} primaryText="golf" />
              <MenuItem value={'ski'} primaryText="ski" />
              <MenuItem value={'river'} primaryText="river" />
              <MenuItem value={'trail'} primaryText="trail" />
              <MenuItem value={'kayak'} primaryText="kayak" />
              <MenuItem value={'sailing'} primaryText="sailing" />
              <MenuItem value={'travel'} primaryText="travel" />
              <MenuItem value={'nationalparksblm'} primaryText="nationalparksblm" />
              <MenuItem value={'government'} primaryText="government" />
              <MenuItem value={'nationalparkservice'} primaryText="nationalparkservice" />
              <MenuItem value={'nationalskipatrol'} primaryText="nationalskipatrol" />
              <MenuItem value={'usforestservice'} primaryText="usforestservice" />
              <MenuItem value={'bureauoflandmanagement'} primaryText="bureauoflandmanagement" />
              <MenuItem value={'usdepartmentofagriculture'} primaryText="usdepartmentofagriculture" />
              <MenuItem value={'nationalwildliferefuge'} primaryText="nationalwildliferefuge" />
              <MenuItem value={'usgeologicalsurvey'} primaryText="usgeologicalsurvey" />
              <MenuItem value={'emergencyrespondervolunteer'} primaryText="emergencyrespondervolunteer" />
              <MenuItem value={'parksandrecreation'} primaryText="parksandrecreation" />
              <MenuItem value={'fbilawenforcement'} primaryText="fbilawenforcement" />
              <MenuItem value={'activemilitary'} primaryText="activemilitary" />
              <MenuItem value={'conservation'} primaryText="conservation" />
              <MenuItem value={'outdooreducation'} primaryText="outdooreducation" />
              <MenuItem value={'globalrelief'} primaryText="globalrelief" />
              <MenuItem value={'radio'} primaryText="radio" />
              <MenuItem value={'print'} primaryText="print" />
              <MenuItem value={'television'} primaryText="television" />
              <MenuItem value={'winter'} primaryText="winter" />
              <MenuItem value={'water'} primaryText="water" />
              <MenuItem value={'digital'} primaryText="digital" />
              <MenuItem value={'other'} primaryText="other" />
              <MenuItem value={'film'} primaryText="film" />
              <MenuItem value={'sports'} primaryText="sports" />
              <MenuItem value={'music'} primaryText="music" />
            </SelectField>
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              hintText="Application Date:"
              floatingLabelText="Application Date:"
              container="inline"
              onChange={this.handleApplicationDateChange}
              value={this.state.applicationDate}
            />
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              hintText="Media Upload Date:"
              floatingLabelText="Media Upload Date:"
              container="inline"
              onChange={this.handleMediaUploadDateChange}
              value={this.state.mediaUploadDate}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <SelectField
              floatingLabelText="Status"
              value={this.state.vipStatus}
              onChange={this.handleVipStatusChange}
            >
                {this.buildStatusMenuItems()}
            </SelectField>
          </Col>
          <Col xs={12} md={3}>
            <SelectField
              floatingLabelText="Results per page"
              value={this.state.take}
              onChange={this.handleTakeChange}
            >
              <MenuItem value={10} primaryText="10" />
              <MenuItem value={25} primaryText="25" />
              <MenuItem value={50} primaryText="50" />
              <MenuItem value={100} primaryText="100" />
            </SelectField>
          </Col>
        </Row>
        <RaisedButton primary={true} label="Search" onClick={ this.complexSearchSubmit }/>
      </Grid>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    siteId: store.vipState.siteId,
  };
};

export const VipSearchComplex = connect(mapStateToProps)(VipSearchComplexContainer);
