import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { MenuItem, RaisedButton, SelectField, TextField, Toolbar, ToolbarTitle } from 'material-ui';

import { makeInventoryRequest } from '../api/inventoryApi';
import { getAllInventoryListSuccess, getAllInventoryListFailed, searchInventoryListSuccess, searchInventoryListFailed } from '../actions/inventoryActions';

export const styles = {
  buttonMargin: {
    margin: "10px"
  }
};

export default class InventoryListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: '',
      start: 0,
      count: 100,
      searching: false
    };
  }

  handleInventorySearchTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { searchPhrase: text.length > 0 ? text : undefined }));
  };

  handleCountChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { count: value }));
  };

  searchSubmit = () => {
    if (this.state.searchPhrase) {
      makeInventoryRequest(searchInventoryListSuccess, searchInventoryListFailed, `exportProduct/searchInventoryLists`, `post`, { searchPhrase: this.state.searchPhrase })
    } else {
      makeInventoryRequest(getAllInventoryListSuccess, getAllInventoryListFailed, `exportProduct/getInventoryLists/${this.state.start}/${this.state.count}`, 'get');
    }
  };

  render() {
    return (
      <div>
        <Toolbar style={styles.toolbarMargin}>
          <ToolbarTitle text="Find Inventory List" />
        </Toolbar>
        <Grid fluid>
          <Row>
            <Col xs={12} md={3}>
              <TextField
                hintText="Search Phrase:"
                floatingLabelText="Search Phrase:"
                onChange={this.handleInventorySearchTextChange}
                value={this.state.searchPhrase} />
            </Col>
            <Col xs={12} md={3}>
              <SelectField
                floatingLabelText="Results per page"
                value={this.state.count}
                onChange={this.handleCountChange} >
                <MenuItem value={10} primaryText="10" />
                <MenuItem value={25} primaryText="25" />
                <MenuItem value={50} primaryText="50" />
                <MenuItem value={100} primaryText="100" />
              </SelectField>
            </Col>
            <Col xs={12} md={3}>
              <RaisedButton
                primary={true}
                label="Search"
                style={styles.buttonMargin}
                onClick={this.searchSubmit} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
