
const new_val = 'new';
const accepted = 'accepted';
const rejected = 'rejected';
const pending = 'pending';
const expired = 'expired';
const legacy_renewaccepted = 'renew/a';
const legacy_renewexpired = 'renew/e';
const approved = 'approved';
const renewed = 'renewed';
const inactive = 'inactive';

const VIP_STATUS = {
  Columbia_US: {
    ACCEPTED: accepted,
    REJECTED: rejected,
    PENDING: pending,
    EXPIRED: expired,
    RENEW_ACCEPTED: legacy_renewaccepted,
    RENEW_EXPIRED: legacy_renewexpired,
    INACTIVE: rejected,
    NEW_APPLICANT: [rejected, expired, pending],
    EXPIRED_APPLICANT: [expired],
    PENDING_APPLICANT: [pending],
    APPROVED_APPLICANT: accepted,
    REJECTED_APPLICANT: rejected,
    UPDATEABLE_APPLICANT: [accepted, expired, legacy_renewaccepted, legacy_renewexpired],
    APPROVE_REJECT_APPLICANT: [rejected, pending, legacy_renewaccepted, legacy_renewexpired],
    RENEW_APPLICANT: [expired, pending],
    RENEW_APPLICANT_MAP: {accepted : legacy_renewaccepted, expired : legacy_renewexpired},
    UPDATEABLE_GROUPS: [accepted, legacy_renewaccepted, legacy_renewexpired],
    SUBMIT_APPLICANT: [accepted, legacy_renewaccepted, legacy_renewexpired],
    REMOVEABLE_APPLICANT: [accepted],
    RENEWED_APPLICANT: [legacy_renewaccepted, legacy_renewexpired]
  },
  MountainHardwear_US: {
    ACCEPTED: accepted,
    REJECTED: rejected,
    PENDING: pending,
    EXPIRED: expired,
    RENEW_ACCEPTED: legacy_renewaccepted,
    RENEW_EXPIRED: legacy_renewexpired,
    INACTIVE: rejected,
    NEW_APPLICANT: [rejected, expired, pending],
    EXPIRED_APPLICANT: [expired],
    PENDING_APPLICANT: [pending],
    APPROVED_APPLICANT: accepted,
    REJECTED_APPLICANT: rejected,
    UPDATEABLE_APPLICANT: [accepted, expired, legacy_renewaccepted, legacy_renewexpired],
    APPROVE_REJECT_APPLICANT: [rejected, pending, legacy_renewaccepted, legacy_renewexpired],
    RENEW_APPLICANT: [expired, pending],
    RENEW_APPLICANT_MAP: {accepted : legacy_renewaccepted, expired : legacy_renewexpired},
    UPDATEABLE_GROUPS: [accepted, legacy_renewaccepted, legacy_renewexpired],
    SUBMIT_APPLICANT: [accepted, legacy_renewaccepted, legacy_renewexpired],
    REMOVEABLE_APPLICANT: [accepted],
    RENEWED_APPLICANT: [legacy_renewaccepted, legacy_renewexpired]
  },
  Prana_US: {
    NEW: new_val,
    ACCEPTED: accepted,
    REJECTED: rejected,
    PENDING: pending,
    EXPIRED: expired,
    RENEW_ACCEPTED: renewed,
    RENEW_EXPIRED: renewed,
    APPROVED: approved,
    RENEWED: renewed,
    INACTIVE: inactive,
    NEW_APPLICANT: [new_val, rejected, expired, pending],
    EXPIRED_APPLICANT: [expired],
    PENDING_APPLICANT: [pending],
    APPROVED_APPLICANT: approved,
    REJECTED_APPLICANT: rejected,
    UPDATEABLE_APPLICANT: [approved, expired, renewed],
    APPROVE_REJECT_APPLICANT: [new_val, rejected, pending],
    RENEW_APPLICANT: [expired, inactive],
    RENEW_APPLICANT_MAP: {accepted : renewed, expired : renewed},
    UPDATEABLE_GROUPS: [approved, renewed],
    SUBMIT_APPLICANT: [approved, renewed],
    REMOVEABLE_APPLICANT: [approved, accepted, renewed],
    RENEWED_APPLICANT: [renewed]
  }
};

/**
 * Client for Tibco calls
 */
class VIPStatus {
  static get NEW() { return 'NEW'; }
  static get ACCEPTED() { return 'ACCEPTED'; }
  static get REJECTED() { return 'REJECTED'; }
  static get PENDING() { return 'PENDING'; }
  static get EXPIRED() { return 'EXPIRED'; }
  static get RENEW_ACCEPTED() { return 'RENEW_ACCEPTED'; }
  static get RENEW_EXPIRED() { return 'RENEW_EXPIRED'; }
  static get APPROVED() { return 'APPROVED'; }
  static get RENEWED() { return 'RENEWED'; }
  static get INACTIVE() { return 'INACTIVE'; }

  /**
   * Retrieves the proper status value by site id and type
   * @param siteId
   * @param type
   */
  static getStatusBySiteIdAndType(siteId, type) {
    return VIP_STATUS[siteId][type];
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is new or not
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static newApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].NEW_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is expired or not
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static expiredApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].EXPIRED_APPLICANT.includes(vipStatus);
  }

  /**
   * Retrieves the proper statuses, per site id
   * @param siteId
   * @returns {string}
   */
  static expiredApplicantStatus(siteId) {
    return VIP_STATUS[siteId].EXPIRED_APPLICANT;
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is pending or not
   * @param siteId
   * @param type
   * @returns {boolean}
   */
  static pendingApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].PENDING_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is rejected or not
   * @param siteId
   * @param type
   * @returns {boolean}
   */
  static rejectedApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].REJECTED_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is pending or not
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static approvedApplicant(siteId, vipStatus) {
    return (VIP_STATUS[siteId].APPROVED_APPLICANT === vipStatus);
  }

  /**
   * Retrieves the proper status, per site id
   * @param siteId
   * @returns {string}
   */
  static approvedApplicantStatus(siteId) {
    return VIP_STATUS[siteId].APPROVED_APPLICANT;
  }

  /**
   * Retrieves the proper status, per site id
   * @param siteId
   * @returns {string}
   */
  static rejectedApplicantStatus(siteId) {
    return VIP_STATUS[siteId].REJECTED_APPLICANT;
  }

  /**
   * Retrieves the proper status, per site id
   * @param siteId
   * @param vipStatus
   * @param customerGroup
   * @returns {string}
   */
  static renewApplicantStatus(siteId, vipStatus, customerGroup) {
    if (siteId === 'Prana_US') {
      return VIP_STATUS[siteId].RENEW_EXPIRED;
    }
    else if (customerGroup) {
      return VIP_STATUS[siteId].RENEW_ACCEPTED;
    }
    else {
      return VIP_STATUS[siteId].RENEW_EXPIRED;
    }
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is updateable or not
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static updateableApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].UPDATEABLE_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is can be rejected or approved
   * @param siteId
   * @param vipStatus
   * @param customerGroup
   * @param expirationDate
   * @returns {boolean}
   */
  static canApproveOrRejectApplicant(siteId, vipStatus, customerGroup, expirationDate) {
    console.log(`Group: ${customerGroup}`);
    console.log(`Status: ${vipStatus}`);
    console.log(`Site: ${siteId}`);
    if (VIP_STATUS[siteId].APPROVE_REJECT_APPLICANT.includes(vipStatus)) {
      if (siteId === 'Prana_US' || VIPStatus.renewedApplicant(siteId, vipStatus)) {
        return true;
      }
      else if (VIPStatus.rejectedApplicant(siteId, vipStatus) ||
        (VIPStatus.pendingApplicant(siteId, vipStatus) &&
        (!customerGroup && !expirationDate))) {
        return true;
      }
    }
    return false;
  }

  /**
   * Evaluates the customer state, based upon siteId and expirationDate, as to whether the customer can be rejected
   * @param siteId
   * @param expirationDate
   * @param vipStatus
   * @param customerGroup
   * @returns {boolean}
   */
  static canRejectApplicant(siteId, vipStatus, expirationDate) {
    if (siteId !== 'Prana_US' && VIP_STATUS[siteId].PENDING_APPLICANT.includes(vipStatus) && expirationDate) {
      return true;
    }
    return false;
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant can be renewed
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static canRenewApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].RENEW_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant is renewed
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static renewedApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].RENEWED_APPLICANT.includes(vipStatus);
  }

  /**
   * Looks up the proper status to apply when renewing an applicant, by existing status, per site id
   * @param siteId
   * @param vipStatus
   * @returns {string}
   */
  static renewingApplicantStatus(siteId, vipStatus) {
    return VIP_STATUS[siteId].RENEW_APPLICANT_MAP[vipStatus];
  }

  /**
   * Evaluates the type, per site id, to determine if the customer groups for the applicant can be updated
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static canUpdateGroups(siteId, vipStatus) {
    return VIP_STATUS[siteId].UPDATEABLE_GROUPS.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant form can be submitted
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static canSubmitForm(siteId, vipStatus) {
    return VIP_STATUS[siteId].SUBMIT_APPLICANT.includes(vipStatus);
  }

  /**
   * Evaluates the type, per site id, to determine if the applicant can be removed
   * @param siteId
   * @param vipStatus
   * @returns {boolean}
   */
  static canRemoveApplicant(siteId, vipStatus) {
    return VIP_STATUS[siteId].REMOVEABLE_APPLICANT.includes(vipStatus);
  }
}

export default VIPStatus;
