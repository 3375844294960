import React, { Component } from 'react';
import { Dialog, CircularProgress, Snackbar, TextField, Paper, RaisedButton, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey500, red50, red300 } from "material-ui/styles/colors";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteModelProfile, publishModel, publishModels, getModelsList } from '../api/modelProfileApi.js';
import UploadModelProfiles from './UploadModelProfiles';
import exportFromJSON from 'export-from-json';
import { messages } from './messages';
import store from '../services/store';
import { setSelectedModels } from "../actions/modelProfileActions";
import { PhotoStudioSearch } from './PhotoStudioSearch';

const styles = {
  container: {
    padding: "10px"
  },
  icon: {
    marginRight: '20px',
    color: grey500
  },
  downloadButton: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addLink: {
    marginRight: "30px",
    marginLeft: "100px"
  },
  firstChild: {
    width: '200px'
  },
  deleteStyles: {
    color: '#ffffff',
    backgroundColor: red50
  },
  buttonMargin: {
    margin: "10px"
  },
  spinner: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    zIndex: "9999999999",
    overflow: "hidden",
    opacity: 0.4
  }
};

class PhotoStudioOptions extends Component {
    constructor(props) {
      super(props);
      this.state = {
        errorMsg: "",
        open: false,
        publishSuccess: false,
        publishModelError: false
      };
      this.gridApi = ""
    }

    publishModelProfile = (modelCode) => {
      this.setState({ loading: true })
      publishModel(modelCode).then(({data})=> {
        this.setState({ errorMsg: "", loading: false, publishSuccess: true, publishError: false });
        setTimeout(() => {
          this.setState({ publishSuccess: false });
          store.dispatch(setSelectedModels([]));
        }, 2000);
      }).catch(function(error) {
        this.setState({ errorMsg: error.message, loading: false, publishError: true })
      })
    }

    publishModelProfiles = (models) => {
      this.setState({ loading: true })
      publishModels(models).then(()=> {
        this.setState({ errorMsg: "", loading: false, publishSuccess: true, publishError: false });
        setTimeout(() => {
          this.setState({ publishSuccess: false });
          store.dispatch(setSelectedModels([]));
        }, 2000);
      }).catch(function(error) {
        this.setState({ errorMsg: error.message, loading: false, publishError: true })
      })

    }

    uploadModelProfile(modelCode) {
      this.setState({ open: true })
    }

    downloadModelProfile = ()=> {
      let fileName = this.state.fileName;
      this.setState({openFilenameChangeModal: false});
      const exportType = 'csv';
      let data = this.props.selectedModels;
      if(data.length === 0) {
        getModelsList();
        data = this.props.modelProfiles.modelsList;
      }
      try {
        exportFromJSON({ data, fileName, exportType });
        store.dispatch(setSelectedModels([]));
      } catch (error) {
        console.log("there was an error getting the data " + error.message);
      }
    }

    openFilenameChangeDialog() {
      this.setState({
        openFilenameChangeModal: true,
        fileName: "Photo_Studio_Model_Data"
      });
    }

    handleClose = () => {
      this.setState({
        open: false,
        openDeleteModal: false,
        openFilenameChangeModal: false
      });
    }

    handleChange = event => {
      this.setState({fileName: event.target.value});
    }

    openDeleteDialog(modelCode) {
      this.setState({openDeleteModal: true});
    }

    deleteModelProfile(modelCode) {
      this.setState({openDeleteModal: false});
      deleteModelProfile(modelCode);
    }

    render() {

        return (
          <div>
            <PhotoStudioSearch/>
            <Paper>
              {this.state.loading && <div style={styles.spinner}>
                  <CircularProgress size={100} thickness={5.6}/>
                  <CircularProgress color="primary" size={100} thickness={5.6}/>
                </div>}
                <Toolbar>
                    <ToolbarGroup className="hide-mobile" style={styles.firstChild}>
                        <FontAwesomeIcon icon={["far", "images"]} size="2x" fixedWidth style={styles.icon} />
                        <ToolbarTitle text="Model Codes" />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <RaisedButton primary={true} style={styles.buttonMargin} label="Upload" onClick={() => this.uploadModelProfile()} />
                        <RaisedButton primary={true} style={styles.downloadButton} label="Download" onClick={() => this.openFilenameChangeDialog()} />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <Link to="/photostudio/add" style={styles.addLink}> <RaisedButton primary={true} style={styles.buttonMargin} label="Add" /></Link>
                        {this.props.selectedModels.length === 1 ?
                            <Link to="/photostudio/edit"> <RaisedButton primary={true} style={styles.buttonMargin} label="Edit" /></Link>
                            : <RaisedButton primary={true} style={styles.buttonMargin} label="Edit" disabled />
                        }
                        {this.props.selectedModels.length ?
                            <RaisedButton label="Delete" backgroundColor={red300} buttonStyle={{ color: "#ffffff" }} style={styles.deleteStyles} onClick={() => this.openDeleteDialog(this.props.selectedModels[0].modelCode)} />
                            : <RaisedButton primary={true} style={styles.buttonMargin} label="Delete" disabled />
                        }
                        {this.props.selectedModels.length === 1 ?
                            <RaisedButton label="Publish" style={styles.buttonMargin} primary={true} onClick={() => this.publishModelProfile(this.props.selectedModels[0].modelCode)} />
                            : <RaisedButton primary={true} style={styles.buttonMargin} label="Publish" disabled />
                        }
                        {this.props.selectedModels.length > 1 ?
                            <RaisedButton label="Publish All" style={styles.buttonMargin} primary={true} onClick={() => this.publishModelProfiles(this.props.selectedModels)} />
                          : <RaisedButton label="Publish All" style={styles.buttonMargin} primary={true} disabled />
                        }

                    </ToolbarGroup>
                </Toolbar>
                {this.state.open ?  <UploadModelProfiles open={this.state.open} handleClose={this.handleClose} />: ""}
                <Dialog
                  title="Are you sure you want to delete this model profile!!"
                  modal={false}
                  contentStyle={{width: 550, textAlign: "center"}}
                  open={this.state.openDeleteModal}
                  onRequestClose={this.handleClose}
                  autoScrollBodyContent={true}
                >
                  <div>
                    <RaisedButton style={styles.buttonMargin} backgroundColor={red300} buttonStyle={{ color: "#ffffff" }} label="Delete" onClick={() => {
                      let modelCodes = this.props.selectedModels.map(selectedModel => selectedModel.modelCode);
                      modelCodes = modelCodes.length > 1 ? modelCodes.join(`&`) : modelCodes;
                      this.deleteModelProfile(modelCodes)
                      }}/>
                    <RaisedButton style={styles.buttonMargin} primary={true} label="Cancel" onClick={this.handleClose}/>
                  </div>
                </Dialog>
                <Dialog
                  title="Download Model Data"
                  modal={false}
                  contentStyle={{width: 550, textAlign: "center"}}
                  open={this.state.openFilenameChangeModal}
                  onRequestClose={this.handleClose}
                  autoScrollBodyContent={true}
                >
                  <TextField
                  autoFocus
                    margin="dense"
                    name="fileName"
                    id="fileName"
                    value={this.state.fileName}
                    onChange={this.handleChange}
                    type="text"
                    floatingLabelFixed={true}
                    fullWidth={true}
                  />
                  <div>
                    <RaisedButton style={styles.buttonMargin} primary={true} label="Download" onClick={() => this.downloadModelProfile()}/>
                    <RaisedButton style={styles.buttonMargin} primary={true} label="Cancel" onClick={this.handleClose}/>
                  </div>
                </Dialog>
                <div>
                  <Snackbar
                    open={this.state.publishSuccess}
                    message={messages.publishSuccess}
                    autoHideDuration={2000}
                  />
                  <Snackbar
                    open={this.state.publishError}
                    message={messages.publishError + " Error message: " + this.state.errorMsg}
                    autoHideDuration={2000}
                  />
                </div>
            </Paper>
          </div>
        )
    }
}

const mapStateToProps = function (store) {
    return {
        selectedModels: store.photoStudioState.selectedModels,
        modelProfiles: store.photoStudioState
    };
};
export default connect(mapStateToProps, {})(PhotoStudioOptions);
