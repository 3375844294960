import React from 'react';
import ReactDOM from 'react-dom';

import 'flexboxgrid/dist/flexboxgrid.css';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { Provider } from 'react-redux';
import store from './services/store';
import router from './services/router';

ReactDOM.render(
  <Provider store={store}>{router}</Provider>,
  document.getElementById('root')
);
