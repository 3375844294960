import React, { Fragment } from 'react';
import Sandboxes from './Sandboxes';
import { Route, Switch } from 'react-router-dom'

const SandboxRoutes = () => (
  <Fragment>
    <Switch>
      <Route exact path='/sandboxes' component={Sandboxes}/>
    </Switch>
  </Fragment>
);

export default SandboxRoutes;
