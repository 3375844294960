import store from '../services/store';
import { getProfileSuccess } from '../actions/userActions';
import { restApi, emailRestApi } from '../config';
import localStorage from '../services/localStorage';
import _ from 'lodash';

// Returns whether this user is new (they exist and are logged in, but have no roles assigned)
export function isNewUser() {
  let state = store.getState();
  return state.userState && state.userState.profile && state.userState.profile.user
    && ((state.userState.profile.user.roles && state.userState.profile.user.roles.length === 0) || (state.userState.profile.user.active === false));
}

export function getProfile() {
  let state = store.getState();
  return state.userState ? state.userState.profile : null;
}

export function currentUserId() {
  let state = store.getState();
  return state.userState && state.userState.profile && state.userState.profile.user ? state.userState.profile.user.email : null;
}

function hasRoleInternal(roles){
  let state = store.getState();
  if (state.userState && state.userState.profile && state.userState.profile.user) {
    if (state.userState.profile.user.active === false) {
      return false;
    }

    let userRoles = state.userState.profile.user.roles;
    if (userRoles && userRoles.length >= 1) {
      for (let role of roles){
        if (_.includes(userRoles, role)) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Determines whether a user has a role (or one of several roles)
 * @param roles Either a single role (string), a comma-delimited list of roles (string), or an array of roles
 * @returns {*} Returns true if the user has the role (or one of the multiple roles) specified
 */
export function hasRoles(roles) {
  if (_.isArray(roles))
    return hasRoleInternal(roles);
  else
    return hasRoleInternal(roles.split(','));
}

export function updateProfile(history, token) {
  restApi.defaults.headers['Authorization'] = "Bearer " + token;
  if (emailRestApi !== restApi)
    emailRestApi.defaults.headers['Authorization'] = "Bearer " + token;
  restApi.get('/users/me').then(function({data}) {
    let newUser = {
      user: data,
      isLoggedIn: true,
      isAdmin: true,
      token: token
    };
  store.dispatch(currentUserId(newUser));
  window.location.assign('/updateProfile/:id');
});
}

export function logout(history) {
  let newUser = {
    user: {},
    isLoggedIn: false,
    isAdmin: false,
    token: null
  };
  store.dispatch(getProfileSuccess(newUser));
  window.location.assign('/login');
}

export function login(token, history) {
  restApi.defaults.headers['Authorization'] = "Bearer " + token;
  if (emailRestApi !== restApi)
    emailRestApi.defaults.headers['Authorization'] = "Bearer " + token;
  restApi.get('/users/me').then(function({data}) {
    let newUser = {
      user: data,
      isLoggedIn: true,
      isAdmin: true,
      token: token
    };
    store.dispatch(getProfileSuccess(newUser));
    let state = localStorage.get('history');
    let location = state && state.length ? state.pop() : '/';
    window.location.assign(location);
  }).catch(function (error) {
    console.log("Get profile failed: " + error);
    logout();
  });

}

export function refreshLogin(token, history) {
  restApi.defaults.headers['Authorization'] = "Bearer " + token;
  if (emailRestApi !== restApi)
    emailRestApi.defaults.headers['Authorization'] = "Bearer " + token;
  restApi.get('/users/me').then(function({data}) {
    let newUser = {
      user: data,
      isLoggedIn: true,
      isAdmin: true,
      token: token
    };
    store.dispatch(getProfileSuccess(newUser));
  }).catch(function (error) {
    console.log("Get profile failed: " + error);
    logout(history);
  });

}
