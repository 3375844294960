import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { MenuItem } from 'material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { TextField, SelectField } from 'redux-form-material-ui';
import { sandboxRestApi } from '../../../config';
import Transition from '../elements/Transition';
import FlexibleSnackBar from '../elements/FlexibleSnackBar';

const validate = values => {
  const errors = {};
  if (!values.ownerName) {
    errors.ownerName = 'Please enter sandbox owner name*.'
  }
  else if ((values.ownerName).length > 50) {
    errors.ownerName = 'Invalid. Cannot be more than 50 characters.'
  }
  let re = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
  if (!values.ownerEmail) {
    errors.ownerEmail = 'Please enter sandbox owner email*.'
  }
  else if ((!re.test(values.ownerEmail)) || (values.ownerEmail).length > 50){
    errors.ownerEmail = 'Not a valid email.'
  }
  if (!values.realm) {
    errors.realm = 'Please select the realm*';
  }
  if (!values.ttl) {
    errors.ttl = 'Please enter the ttl*';
  }
  return errors;
};

function CreateSandboxForm ({ classes, closeButton, handleSubmit, pristine, reset, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='ownerName'
            component={TextField}
            id='ownerName'
            hintText='Name of the User'
            floatingLabelText='Sandbox User Name*'
            fullWidth={true}
          />
        </Col>
        }
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='ownerEmail'
            component={TextField}
            id='ownerEmail'
            hintText='Email Id of the User'
            floatingLabelText='Sandbox User Email Id*'
            fullWidth={true}
          />
        </Col>
        }
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='realm'
            component={SelectField}
            id='realm'
            floatingLabelText='Realm*'
            fullWidth={true}
          >
            <MenuItem value='bcpx' primaryText='X1 Realm'/>
            <MenuItem value='aasn' primaryText='Legacy Realm'/>
          </Field>
        </Col>
        }
        {!submitting &&
        <Col xs={12} sm={6}>
          <Field
            name='ttl'
            component={TextField}
            id='ttl'
            hintText='0 (for no EndOfLife) or No. of hrs (max 2160)'
            floatingLabelText='Time to Live*'
            fullWidth={true}
          />
        </Col>
        }
        <Col xs={12}>
          {submitting &&
          <p>
            Your request has been received.
            On-Demand Sandbox is being created!
          </p>
          }
          <div className={classes.actionsContainer}>
            <DialogActions>
              {!submitting &&
              <Fragment>
                <Button type='submit' color='primary'>
                  Submit
                </Button>
                <Button disabled={pristine} onClick={reset}>
                  Clear
                </Button>
              </Fragment>
              }
              <Button onClick={closeButton}>
                Close
              </Button>
            </DialogActions>
          </div>
        </Col>
      </Row>
    </form>
  )
}

const ValidateCreateSandboxForm = reduxForm({
  form: 'createSandboxForm',
  initialValues: {
    ownerName: '',
    ownerEmail: '',
    realm: '',
    ttl: ''
  },
  validate
})(CreateSandboxForm);

export default function CreateSandbox({ classes, onRefresh }) {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState({});
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleSubmit = (provisioningRequest) => (
    sandboxRestApi.post('/sandboxes', provisioningRequest).then(() => {
      onRefresh && onRefresh();
    }).catch((e) => {
      setSnack({
        open: true,
        message: `Create sandbox creation failed for ${JSON.stringify(provisioningRequest)}: ${e}`,
        severity: 'error'
      })
      console.log(`Create sandbox creation failed for ${JSON.stringify(provisioningRequest)}: ${e}`);
    })
  );

  return (
    <Fragment>
        <Button variant='contained' color='primary' onClick={handleOpen} className={classes.button}>
          Create
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          >
          <DialogTitle>Create New Sandbox</DialogTitle>
          <DialogContent>
            <ValidateCreateSandboxForm classes={classes} onSubmit={handleSubmit} closeButton={handleClose} />
          </DialogContent>
        </Dialog>
        <FlexibleSnackBar snack={snack} />
      </Fragment>
  )
}

CreateSandbox.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func
}
