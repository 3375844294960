import React, { Component } from 'react';
import { ConfirmDialog } from '../../Components';
import { Link } from 'react-router-dom'
import { Dialog, FlatButton, RaisedButton, Paper, Toolbar, ToolbarGroup, ToolbarTitle, Table, TableBody, TableHeader,
  TableHeaderColumn, TableRow, TableRowColumn, SelectField, MenuItem } from 'material-ui';
import AdminCronBreadcrumbs from "./AdminCronBreadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormat } from '../../filters';
import { cronRestApi } from '../../config';
import AdminCronTaskEntry from './AdminCronTaskEntry';
import AdminCronCreate from './AdminCronCreate';
import {grey500} from "material-ui/styles/colors";

export const styles = {
  success: {
    color: '#008800'
  },
  failed: {
    color: '#880000'
  },
  unknown: {
    color: '#666666'
  },
  col1: {
    width:'20px'
  },
  col2: {
    width:'225px'
  },
  col3: {
    width:'70px'
  },
  col4: {
//    width:'25px'
  },
  col5: {
//    width:'25px'
  },
  col6: {
//    width:'25px'
      wordWrap: 'break-word',
      whiteSpace: 'normal',
  },
  col7: {
//    width:'25px'
  },
  menuIcon: {
  },
  icon: {
    marginRight: '20px',
    color: grey500
  },
  searchFieldText: {
    paddingTop:'10px'
  },
  searchFieldDropdown: {
    fontSize: '13px',
    marginTop: '-25px'
  }
};

function statusStyle(status) {
  if (status === "completed")
    return styles.success;
  if (status === "failed")
    return styles.failed;
  return styles.unknown;
}

export const AdminCronTaskItem = ( cronTask, deleteTask, executeTask ) => {
  return (
    <TableRow key={cronTask.id}>
      <TableRowColumn style={styles.col1}>
        <FontAwesomeIcon style={statusStyle(cronTask.lastStatus)} icon={["far", "clock"]} fixedWidth/>
      </TableRowColumn>
      <TableRowColumn style={styles.col2}>
        <Link to={"/admin/cron/" + cronTask.id}>
          { cronTask.handler } : { cronTask.type }
        </Link>
      </TableRowColumn>
      <TableRowColumn style={styles.col3}>
        { cronTask.schedule }
      </TableRowColumn>
      <TableRowColumn style={styles.col4}>
        { dateFormat(cronTask.nextRunOn, "l h:mm:ssa") }
      </TableRowColumn>
      <TableRowColumn style={styles.col5}>
        { cronTask.lastCompletedOn &&
          dateFormat(cronTask.lastCompletedOn, "l h:mm:ssa")
        }
      </TableRowColumn>
      <TableRowColumn style={styles.col6}>
        { cronTask.description }
      </TableRowColumn>
      <TableRowColumn style={styles.col7}>
        <FlatButton
          primary={true}
          onClick={e => {
            executeTask(cronTask);
            e.stopPropagation();
          }}
          label="Execute"
        />
        <FlatButton
          primary={true}
          onClick={e => {
            deleteTask(cronTask)
            e.stopPropagation()
          }}
          label="Delete"
        />
      </TableRowColumn>
    </TableRow>
  );
};

function sortTasks(a, b){
  let nameA = a.handler + a.type;
  let nameB = b.handler + b.type;
  let compare = nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase());
  if (compare !== 0)
    return compare;
  if (a.id < b.id)
    return 1;
  if (b.id > a.id)
    return -1;
  return 0;
}

export class AdminCronTasks extends Component {
  constructor(props){
    super(props);
    this.state = {
      showDelete: false,
      showExecute: false,
      cronTaskState: '',
      selectedHandler: 'Handler'
    };
    this.deleteTask = this.deleteTask.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.executeTask = this.executeTask.bind(this);
    this.confirmExecute = this.confirmExecute.bind(this);
    this.cancelExecute = this.cancelExecute.bind(this);
    this.handlerOnChange = this.handlerOnChange.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      cronTaskState:nextProps.cronTasks.map(l => AdminCronTaskItem(l, this.deleteTask, this.executeTask))
    });
  };

  deleteTask(item){
    this.setState(Object.assign({}, this.state, { showDelete: true, deleteItem: item}));
  };
  confirmDelete(){
    let itemToDelete = this.state.deleteItem;
    this.setState(Object.assign({}, this.state, { showDelete: false, deleteItem: null}));
    console.log(`Delete ${itemToDelete.id}`);
    let self = this;
    cronRestApi.delete(`/cron/${itemToDelete.id}`).then(({data}) => {
      if (self.props.refreshCronTasks)
        self.props.refreshCronTasks();
    }).catch((error) => {
      return alert(`Delete cron task failed: ${error.message}`);
    });
  };
  cancelDelete(){
    this.setState(Object.assign({}, this.state, { showDelete: false, deleteItem: null}));
  };

  executeTask(item){
    this.setState(Object.assign({}, this.state, { showExecute: true, executeItem: item}));
  };
  confirmExecute(){
    let itemToExecute = this.state.executeItem;
    this.setState(Object.assign({}, this.state, { showExecute: false, executeItem: null}));
    console.log(`Execute ${itemToExecute.id}`);
    let self = this;
    cronRestApi.post(`/cron/${itemToExecute.id}/execute`).then(({data}) => {
      if (self.props.refreshCronTasks)
        self.props.refreshCronTasks();
    }).catch((error) => {
      return alert(`Execute cron task failed: ${error.message}`);
    });
  };
  cancelExecute(){
    this.setState(Object.assign({}, this.state, { showExecute: false, executeItem: null}));
  };

  renderHandlerOptions () {
    let taskHandler = this.props.cronTasks.reduce(function(result, item) {
      if(result.indexOf(item.handler) === -1) {
        result.push(item.handler)
      };
      return result;
    }, []);
    return taskHandler.map((handler, index) => <MenuItem key={index} value={handler}>{handler}</MenuItem>);
  };

  handlerOnChange = (event, index, value) => {
    const filteredCronTasks = this.props.cronTasks.filter((task) => {
      if (task.handler === value) {
        return task
      }
      return false;
    });
    const tasksList = (value !== 'Handler') ? filteredCronTasks : this.props.cronTasks;
    const mappedTasksList = tasksList.map(l => AdminCronTaskItem(l, this.deleteTask, this.executeTask));
    this.setState({cronTaskState: mappedTasksList, selectedHandler: value });
  };

  render() {
    let {refreshCronTasks, showCronTaskDetail} = this.props;
    return (
      <Paper>
        <ConfirmDialog
          title="Delete Cron Task"
          body="Are you sure you want to delete this cron task?"
          onConfirm={this.confirmDelete}
          onCancel={this.cancelDelete}
          open={this.state.showDelete}
        />
        <ConfirmDialog
          title="Execute Cron Task"
          body="Are you sure you want to run this cron task right now?"
          onConfirm={this.confirmExecute}
          onCancel={this.cancelExecute}
          open={this.state.showExecute}
        />
        <Toolbar>
          <ToolbarGroup firstChild={false}>
            <FontAwesomeIcon icon={["far", "clock"]} size="2x" fixedWidth style={styles.icon}/>
            <ToolbarTitle text="Cron Tasks"/>
          </ToolbarGroup>
          <ToolbarGroup>
            <AdminCronCreate onRefresh={refreshCronTasks} />
            <RaisedButton label="Refresh" onClick={refreshCronTasks}/>
          </ToolbarGroup>

        </Toolbar>
        <Table onRowSelection={showCronTaskDetail}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn style={styles.col1}>Status</TableHeaderColumn>
              <TableHeaderColumn style={styles.col2}>
                <SelectField id="cronHandler"
                  floatingLabelText={this.state.selectedHandler}
                  onChange={this.handlerOnChange}
                  fullWidth={false} style={styles.searchFieldDropdown}>
                  < MenuItem value={'Handler'} primaryText="Handler" />
                  {this.renderHandlerOptions()}
                </SelectField>
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.col3}>Schedule</TableHeaderColumn>
              <TableHeaderColumn style={styles.col4}>Next Run (Local)</TableHeaderColumn>
              <TableHeaderColumn style={styles.col5}>Last Success (Local)</TableHeaderColumn>
              <TableHeaderColumn style={styles.col6}>Description</TableHeaderColumn>
              <TableHeaderColumn style={styles.col7}>Actions</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            { this.state.loading ? (
              <TableRow>
                <TableRowColumn>
                  Loading...
                </TableRowColumn>
              </TableRow>
            ) : (
              this.state.cronTaskState
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default class AdminCronTasksContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { cronTasks: [], loading: true, showDetail: false };
    this.refreshCronTasks = this.refreshCronTasks.bind(this);
    this.showCronTaskDetail = this.showCronTaskDetail.bind(this);
    this.hideCronTaskDetail = this.hideCronTaskDetail.bind(this);
  }

  componentDidMount() {
    this.refreshCronTasks();
  }

  showCronTaskDetail(selectedIndex){
    if (this.state.showDetail === true)
      return;
    if (selectedIndex.length === 0)
      return;
    this.setState(Object.assign({}, this.state, { showDetail: true, selectedCronTask: this.state.cronTasks[selectedIndex] }));
  }

  hideCronTaskDetail(){
    this.setState(Object.assign({}, this.state, { showDetail: false, selectedCronTask: null }));
  }

  refreshCronTasks(){
    let self = this;
    self.setState(Object.assign({}, self.state, { loading: true }));
    cronRestApi.get(`/cron`).then(({data: cronTasks}) => {
      cronTasks.sort(sortTasks);
      self.setState(Object.assign({}, self.state, { loading: false, cronTasks }));
    }).catch((error) => {
      return alert(`Refresh cron task failed: ${error.message}`);
    });
  }

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <AdminCronBreadcrumbs />
        <Dialog
          title="Cron Task Details"
          modal={false}
          open={!!(this.state.showDetail && this.state.selectedCronTask)}
          autoScrollBodyContent={true}
          onRequestClose={this.hideCronTaskDetail}
        >
          {this.state.selectedCronTask &&
            <AdminCronTaskEntry cronTask={this.state.selectedCronTask} onRefresh={this.refreshCronTasks} hideDrawer={true} />
          }
        </Dialog>
          <AdminCronTasks cronTasks={this.state.cronTasks} refreshCronTasks={this.refreshCronTasks} showCronTaskDetail={this.showCronTaskDetail} new={false}/>
      </div>
    );
  }
}
