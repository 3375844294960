/*eslint-disable no-useless-escape*/
export const validate = values => {

  const modelCodeRegEx = new RegExp(/\b^[0-9]{3,4}\b/);

  const agencyRegEx = new RegExp(/^[a-zA-Z0-9]*$/);

  const errors = {};

  if (!values.modelCode) {
      errors.modelCode = 'Please enter model code';
  }

  if (values.modelCode) {
    if (Number(values.modelCode) === 0  || Number(values.modelCode) < 0 ) {
      errors.modelCode = 'Model Code value should be greater than zero';
    }
    else if (!modelCodeRegEx.test(values.modelCode)) {
      errors.modelCode = 'Model Code should be three digits number';
    }
  }

  if (values.agency && !agencyRegEx.test(values.agency)) {
      errors.agency = 'Agency can be alphanumeric or letters or can be empty value';
  }

  if (!values.gender) {
      errors.gender = 'Gender value is Required';
  }
  if (!values.firstName) {
      errors.firstName = 'FirstName value is Required';
  }

  return errors
};
