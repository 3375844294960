import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class PhotoStudioBreadCrumbs extends Component {

  render() {
    const { type } = this.props;
    const subTypeLink = type && "/photostudio"+ type

    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            {
              subTypeLink ?  <Link to="/photostudio/">Photo Studio Model</Link> :  <span >Photo Studio Model</span>
            }
        </div>

            {  type ?  <div className="breadcrumb"> <span > {type} </span> </div>: "" }

        </div>
      </nav>
    )
  }
}
