import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IconButton, Tooltip } from '@material-ui/core';

function ToolTipClick({ classes, icon, isMobile }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return(
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={classes.inlineDiv}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          disableHoverListener
          title='Copied UUID'
          arrow
        >
          <IconButton onClick={handleTooltipOpen} className={isMobile ? classes.iconButtonMobile : classes.iconButton}>
            <FontAwesomeIcon icon={icon} fixedWidth/>
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

ToolTipClick.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  isMobile: PropTypes.bool
}

export default ToolTipClick;
