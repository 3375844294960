import {
  cyan500,
  grey100, grey300, grey400, grey500,
  white, darkBlack, fullBlack,
} from 'material-ui/styles/colors';

import {
  columbiaBlue, columbiaDarkBlue,
//  columbiaWarmRed, columbiaDryBlue, columbiaCoolTeal,
  columbiaProtectedGold,
//  columbiaVividGold, columbiaDarkGray, columbiaMediumGray,
//  columbiaMediumDarkGray, columbiaLightGray, columbiaPaleGray,
} from './brandColors';

import {fade} from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';


/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
export default {
  defaultTheme: {
    spacing: spacing,
    fontFamily: 'Roboto, sans-serif',
    borderRadius: 2,
    palette: {
      primary1Color: columbiaDarkBlue,
      primary2Color: columbiaBlue,
      primary3Color: grey400,
      accent1Color: columbiaProtectedGold,
      accent2Color: grey100,
      accent3Color: grey500,
      textColor: darkBlack,
      secondaryTextColor: fade(darkBlack, 0.54),
      alternateTextColor: white,
      canvasColor: white,
      borderColor: grey300,
      disabledColor: fade(darkBlack, 0.3),
      pickerHeaderColor: cyan500,
      clockCircleColor: fade(darkBlack, 0.07),
      shadowColor: fullBlack
    }
  },
  updatedTheme: {
    spacing: 2,
    fontFamily: 'Roboto, sans-serif',
    shape: {
      borderRadius: 2
    },
    palette: {
      primary: {
        main: columbiaDarkBlue
      },
      secondary: {
        main: white
      },
      primaryText: {
        primary: darkBlack,
        disabled: fade(darkBlack, 0.54)
      },
      seconardyText: {
        primary: white,
        disabled: fade(darkBlack, 0.54)
      },
      action: {
        disabled: fade(darkBlack, 0.3),
        disabledBackground: fade(darkBlack, 0.3)
      }
    }
  }
};
