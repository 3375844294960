import React, { Component } from 'react';
import { PageHeader } from '../Components';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { DropDownMenu, MenuItem, Paper, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, Tabs, Tab } from 'material-ui';
import { restApi } from '../config';
import logo from '../logo.svg';
import {columbiaBlue} from '../themes/brandColors';

export const adminInactiveUserAccounts = ( account, i ) => (
  <div key={i}>
   {account.accountType} - {account.environment} [ {account.username} ] 
  </div>
);

export const adminInactiveUser = ( user ) => {
  if (!user.reportDownloadLink) {
    return (
      <TableRow selectable={false} key={user.id}>
        <TableRowColumn>
        <Link to={"/id-management/" + user.id}
              style={{color: columbiaBlue, textDecoration: "none", fontSize: "13px"}}>
          {user.name ? user.name.firstName + " " + user.name.lastName : "No Name"}
        </Link>
        </TableRowColumn>
        <TableRowColumn>{user.slackId ? user.slackId : "Slack Handle Not Available"}</TableRowColumn>
        <TableRowColumn>{user.timezone ? user.timezone : "Timezone Not Available"}</TableRowColumn>
        <TableRowColumn>{user.emailAddress ? user.emailAddress : "Not Available"}</TableRowColumn>
        <TableRowColumn>{user.activeAD === "active" ? "Active" : "Inactive"}</TableRowColumn>
        <TableRowColumn>{(typeof user.accounts !== 'undefined') && user.accounts.map(adminInactiveUserAccounts)}</TableRowColumn>
      </TableRow>
    )
  }
};

export const AdminInactiveUsersList = ({ users, label }) => {
  return (
    <div>
      <Table fixedHeader={true} height="450px">
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn>Slack Id</TableHeaderColumn>
            <TableHeaderColumn>Timezone</TableHeaderColumn>
            <TableHeaderColumn>Email</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Accounts</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {users.length > 0 && users.map(adminInactiveUser)}
        </TableBody>
      </Table>
      <Toolbar>
        <ToolbarGroup firstChild={false}>
          <ToolbarTitle text={`${users.length - 1} ${label} Demandware User(s)`}/>
          <RaisedButton label="Download CSV" download secondary={true} href={users[users.length - 1].reportDownloadLink.url}/>
        </ToolbarGroup>
      </Toolbar>
    </div>
  )
};

export const IdManagementContainer = ({ activeUsers, inactiveUsers, whiteListedUsers }) => (
  <div>
    <PageHeader>ID Management</PageHeader>
    <Row>
      <Col xs={12} sm={3}>
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <ToolbarTitle text="Settings" />
            </ToolbarGroup>
          </Toolbar>
          <DropDownMenu value={1}>
            <MenuItem value={1} primaryText="Demandware" />
          </DropDownMenu>
        </Paper>
      </Col>
      <Col xs={12} sm={9}>
          <Paper>
            <Tabs>
              <Tab label="Active Users">
                {activeUsers ?
                  <AdminInactiveUsersList label="Active" users={activeUsers}/>
                :
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{padding: "20px"}}>
                    <img className="app-logo-spinner" src={logo} alt="logo"/>
                    </div>
                  </div>
                }
              </Tab>
              <Tab label="Inactive Users">
                {inactiveUsers ?
                  <AdminInactiveUsersList label="Inactive" users={inactiveUsers}/>
                :
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "450px"}}>
                    <div style={{padding: "20px"}}>
                    <img className="app-logo-spinner" src={logo} alt="logo"/>
                    </div>
                  </div>
                }
              </Tab>
              <Tab label="Whitelist">
                {whiteListedUsers ?
                  <AdminInactiveUsersList label="Whitelisted" users={whiteListedUsers}/>
                  :
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "450px"}}>
                    <div style={{padding: "20px"}}>
                      <img className="app-logo-spinner" src={logo} alt="logo"/>
                    </div>
                  </div>
                }
              </Tab>
            </Tabs>
          </Paper>
      </Col>
    </Row>
  </div>
);

class IdManagement extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.getUsers = this.getUsers.bind(this);
  }

  getUsers(){

    let self = this;

    restApi.get("/userAudit/audit/active/demandware").then(({data}) => {
      self.setState(
        Object.assign(
          {}, self.state, {
            activeUsers: data
          }
        )
      );
    }).catch((error) => {
      console.log("Get active users failed: " + error);
    });

    restApi.get("/userAudit/audit/inactive/demandware").then(({data}) => {
      self.setState(
        Object.assign(
          {}, self.state, {
            inactiveUsers: data
          }
        )
      );
    }).catch((error) => {
      console.log("Get inactive users failed: " + error);
    });

    restApi.get("/userAudit/audit/whitelisted").then(({data}) => {
      self.setState(
        Object.assign(
          {}, self.state, {
            whiteListedUsers: data
          }
        )
      );
    }).catch((error) => {
      console.log("Get inactive users failed: " + error);
    });

  }

  componentDidMount(){
    this.getUsers();
  }


  render() {
    return (
      <IdManagementContainer
        activeUsers={this.state.activeUsers}
        inactiveUsers={this.state.inactiveUsers}
        whiteListedUsers={this.state.whiteListedUsers}
      />
    );
  }
}

export default IdManagement;
