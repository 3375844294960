import React from 'react';
import InventoryList from './InventoryList';
import InventoryRecords from './InventoryRecords';
import { Route } from 'react-router-dom'

const InventoryRoutes = () => (
  <div>
    <Route exact path="/inventory/list" component={InventoryList} />
    <Route path="/inventory/records/:id" component={InventoryRecords} />
  </div>
);

export default InventoryRoutes;
