import React from 'react';
import typography from 'material-ui/styles/typography';
import muiThemeable from 'material-ui/styles/muiThemeable'

function PageHeader(props) {
  const styles={
    heading: {
      fontWeight: typography.fontWeightMedium,
      fontFamily: props.muiTheme.fontFamily,

      borderBottom: '1px solid #eee'
    }
  };
  return (
    <h2 style={styles.heading}>{props.children}</h2>
  )
}

export default muiThemeable()(PageHeader);
