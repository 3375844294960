import * as types from './actionTypes';

export function searchLogsSuccess(logs) {
  return {
    type: types.SEARCH_LOGS_SUCCESS,
    logs
  };
}

export function searchLogsFailed(error) {
  return {
    type: types.SEARCH_LOGS_FAILED,
    error
  };
}