import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import SandboxContainer from './container/SandboxContainer';
import { sandboxRestApi } from '../../config';
import SandboxBreadCrumbs from './elements/SandboxBreadcrumbs';
import FlexibleSnackBar from '../components/elements/FlexibleSnackBar';

export default function Sandboxes() {
  const [sandboxes, setSandboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snack, setSnack] = useState({});

  const fetchSandboxes = async () => {
    setIsLoading(true);
    try {
      const response = await sandboxRestApi.get('/sandboxes?include_deleted=false');
      setSandboxes(response.data.data);
      console.log('Sandbox Data', response.data);
    } catch(e) {
      setSnack({
        open: true,
        message: `There was an error getting the sandboxes: ${e}`,
        severity: 'error'
      })
      console.log(`get sandboxes failed: ${e}`)
    } finally {
      setIsLoading(false);
    }
  }

  const handleUpdateClick = (sandboxId, operation, isErr) => {
    const stop = isErr ? 'stopped' : 'stopping';
    const start = isErr ? 'started' : 'starting'
    operation = (operation === 'stop') ? stop : start;
    const updatedSandboxes = [...sandboxes];
    const index = updatedSandboxes.findIndex(sandbox => sandbox.id === sandboxId);
    updatedSandboxes[index].state = operation;
    setSandboxes(updatedSandboxes);
  }

  const handleRefreshClick = () => { fetchSandboxes() };

  useEffect(() => {
    fetchSandboxes()
  }, [])

  return (
    <Fragment>
    <SandboxBreadCrumbs />
      {sandboxes &&
      <Row>
        <Col xs={12}>
          <SandboxContainer
            sandboxes={sandboxes}
            handleUpdateClick={handleUpdateClick}
            isLoading={isLoading}
            onRefresh={handleRefreshClick}
          />
          <FlexibleSnackBar snack={snack} />
        </Col>
      </Row>
      }
    </Fragment>
  );
}
