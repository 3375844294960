import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { FlatButton, MenuItem, Snackbar, Dialog, RaisedButton} from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { TextField, SelectField } from 'redux-form-material-ui';
import PhotoStudioBreadCrumbs from './PhotoStudioBreadCrumbs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createNewModelProfile, getModelsList, updateModelProfile } from '../api/modelProfileApi.js';
import { messages } from './messages';
import { withRouter } from 'react-router-dom';
import { validate } from './validator';

export const styles = {
  actionsContainer: {
      margin: '10px 0',
      textAlign: 'right',
      border: '2px solid #aaa'
  },
  headerText: {
      paddingTop: '20px',
      paddingLeft: '10px'
  },
  newBtn: {
      textAlign: "right",
      width: '100%'
  },
  colStyles: {
      height: '30px'
  },
  addForm:{
    padding: '20px',
    background: '#eee'
  },
  formHeading: {
    margin: '20px 0 0',
    borderBottom: '2px solid #aaa',
    padding: '0 0 10px',
    overflow: 'auto',
    color: '#005299'
  },
  formDiv: {
    padding: '40px',
    border: '2px solid #ccc',
    marginBottom: '20px',
    borderRadius: '10px',
    background: '#fff',
    paddingTop: '0px'
  },
  note2hidden:{
    display:'none'
  },
  buttonMargin: {
    margin: "10px"
  },
  snackbarstyl: {
    top:'30%',
    bottom:'50%'
  }
};

class ModelForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            updatePending: false,
            formSuccess : false,
            modelProfileFormError: false,
            snackBarColor: 'green'
        }
        getModelsList();
        this.getNextModelCode();
    }

    handleClose = () => {
      this.setState({
        modelProfileFormError: false,
        duplicateModelCode: false
      });
    }

    submitModelData = (body) => {
      let isDuplicateModelCode = false;
      const modelCode = body.modelCode;
      const data = this.props.modelProfiles.modelsList;
      const modelProfile = data && data.length && data.find((val, i) => {
        return modelCode === val.modelCode;
      });

      if(data && modelProfile) {
        isDuplicateModelCode = true;
        this.setState({
          title: `${body.modelCode} ${messages.duplicateModelCode}`
        });
      }

      this.setState({
        duplicateModelCode: isDuplicateModelCode
      }, ()=> {
        if(!isDuplicateModelCode) {
          this.createModelProfile(body);
        }
      });
    }

    createModelProfile = (body) => {
      const { history } = this.props;
      body.modelCode = body.modelCode.toString();
      createNewModelProfile(body).then(() => {
        this.setState({snackBarMsg: messages.createSuccess, openSnackBar: true, snackBarColor: 'green', updatePending: false, formSuccess: true, duplicateModelCode: false});
        setTimeout(() => {
          history.push(`/photostudio`)
        }, 1500);
      }).catch((error) => {
        this.setState({
          snackBarMsg: `${error.response.statusText} : ${error.response.data}`,
          updatePending: false,
          duplicateModelCode: false,
          modelProfileFormError:true,
          snackBarColor: 'red',
          openSnackBar: true
        });
      });
    }

    updateModelProfile = (body) => {
      const {history} = this.props
      this.setState({updatePending: true});
      updateModelProfile(body).then( ({data})=> {
        this.setState({snackBarMsg: messages.updateSuccess, openSnackBar: true, snackBarColor: 'green', updatePending: false, formSuccess: true, duplicateModelCode: false});
        setTimeout(() => {
          history.push(`/photostudio`)
        }, 1000);
      }).catch(function(error) {
        this.setState({
          snackBarMsg: `${error.response.statusText} : ${error.response.data}`,
          updatePending: false,
          modelProfileFormError:true,
          snackBarColor: 'red',
          openSnackBar: true
        });
      })
    }

    getNextModelCode() {
      let nextModelCode;
      const modelCodes = this.props.modelProfiles.modelsList.map(modelProfile => {
        return modelProfile.modelCode;
      });
      if(modelCodes.length) {
        const modelCodeNumberArray = modelCodes.map(Number);
        const sortedModelCodesArray = modelCodeNumberArray.sort(function(a,b) {return (a-b)});
        nextModelCode = sortedModelCodesArray[sortedModelCodesArray.length-1] + 1;
        const between10and99 = nextModelCode > 9 && nextModelCode <= 99;
        const lessThan9 = nextModelCode < 9;

        nextModelCode = between10and99 ?  "0" + nextModelCode : lessThan9 ? "00" + nextModelCode
                                                                          : nextModelCode  ;
        if (nextModelCode > 999) {
          nextModelCode = "Next Model Code: 1000, Code is max -3- digits and cannot be > 999, choose between 001-999, if available.";
        }
      }
      else {
        nextModelCode = "001";
      }
      this.props.initialValues.modelCode = nextModelCode;
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, invalid } = this.props;
        return (
            <div>
                <div>
                    <PhotoStudioBreadCrumbs type={this.props.type} />
                </div>
                <form onSubmit={handleSubmit(this.submitModelData)} style={styles.addForm}>
                <div style={styles.formDiv}>
                  <h3 class="col-12 m-0" style={styles.formHeading}>Agency Details</h3>
                  <Row>
                    <Col xs={12} sm={6} style={styles.colStyles}>
                        <Field
                            name="modelCode"
                            component={TextField}
                            id="modelCode"
                            hintText="053"
                            floatingLabelText="Model Code *"
                            fullWidth={true}
                            disabled={submitting}
														floatingLabelFixed={true}
                            onChange={this.handleFieldUpdate}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Field
                            name="agency"
                            component={TextField}
                            id="agency"
                            hintText="SMG"
                            floatingLabelText="Agency"
                            floatingLabelFixed={true}
                            fullWidth={true}
                        />
                    </Col>
                  </Row>
                </div>

                <div style={styles.formDiv}>
                    <h3 class="col-12" style={styles.formHeading}>Model Details</h3>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Field
                            name="firstName"
                            component={TextField}
                            id="firstName"
                            hintText="John"
                            floatingLabelText="First Name *"
                            fullWidth={true}
                            disabled={submitting}
                            floatingLabelFixed={true}
                        >
                        </Field>
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="lastName"
                              component={TextField}
                              id="lastName"
                              hintText="Smith"
                              fullWidth={true}
                              floatingLabelText="Last Name"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>
                    </Row>
                  </div>
                  <div style={styles.formDiv}>
                    <h3 class="col-12" style={styles.formHeading}>Model Profile</h3>
                    <Row>
                      <Col xs={12} sm={6}>
                          <Field
                              name="gender"
                              component={SelectField}
                              id="gender"
                              hintText="F/M"
                              floatingLabelText="Gender *"
                              fullWidth={true}
                              disabled={submitting}
                              floatingLabelFixed={true}
                          >
                              <MenuItem value={"F"} primaryText="Female" />
                              <MenuItem value={"M"} primaryText="Male" />
                          </Field>

                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="height"
                              component={TextField}
                              id="height"
                              hintText="6'3''"
                              fullWidth={true}
                              floatingLabelText="Height"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="bust"
                              component={TextField}
                              id="bust"
                              hintText="44''"
                              fullWidth={true}
                              floatingLabelText="Bust"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="waist"
                              component={TextField}
                              id="waist"
                              hintText="38''"
                              fullWidth={true}
                              floatingLabelText="Waist"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="hips"
                              component={TextField}
                              id="hips"
                              hintText="47''"
                              fullWidth={true}
                              floatingLabelText="Hips"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="inseam"
                              component={TextField}
                              id="inseam"
                              hintText="36''"
                              fullWidth={true}
                              floatingLabelText="Inseam"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="size"
                              component={TextField}
                              id="size"
                              hintText="XS/S/M/L/XL/2XL/3XL"
                              fullWidth={true}
                              floatingLabelText="Size"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="shoe"
                              component={TextField}
                              id="shoe"
                              hintText="8.5"
                              fullWidth={true}
                              floatingLabelText="Shoe"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>

                      <Col xs={12} sm={6}>
                          <Field
                              name="note1"
                              component={TextField}
                              id="note1"
                              hintText="Model Description note1"
                              fullWidth={true}
                              floatingLabelText="Note1"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                       </Col>
                       <Col xs={12} sm={6}>
                          <Field
                              name="note2"
                              component={TextField}
                              id="note2"
                              hintText="Model Description note2"
                              fullWidth={true}
                              floatingLabelText="Note2"
                              disabled={submitting}
                              floatingLabelFixed={true}
                          />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col xs={12}>
                          <div style={styles.actionsContainer}>
                                  <FlatButton
                                      label="Save"
                                      type="submit"
                                      primary={true}
                                      disabled={submitting || invalid}
                                  >
                                      {submitting &&
                                          <FontAwesomeIcon icon='sync' spin />
                                      }
                                  </FlatButton>
                                  <FlatButton
                                      label="Clear"
                                      disabled={pristine || submitting}
                                      onClick={reset}
                                  />

                              <Link to="/photostudio">
                                  <FlatButton
                                      label="Cancel"
                                      disabled={submitting}
                                      primary={true}
                                      onClick={this.props.closeButton}
                                  />
                              </Link>
                          </div>
                      </Col>
                    </Row>
                    <label>* - Required Values</label>
                  </div>
            </form>
            <Dialog
              title={this.state.title}
              modal={false}
              contentStyle={{width: 550, textAlign: "center"}}
              open={this.state.duplicateModelCode}
              onRequestClose={this.handleClose}
              autoScrollBodyContent={true}
            >
              <div>
                <RaisedButton style={styles.buttonMargin} primary={true} label="Continue" onClick={handleSubmit(this.updateModelProfile)}/>
                <RaisedButton style={styles.buttonMargin} primary={true} label="Cancel" onClick={this.handleClose}/>
              </div>
            </Dialog>

            <Snackbar
                bodyStyle={{ backgroundColor: this.state.snackBarColor, color: 'coral' }}
                style={styles.snackbarstyl}
                open={this.state.openSnackBar}
                message={this.state.snackBarMsg}
                autoHideDuration={2000}
            />
          </div>
        )
    };
}

const ReduxFormModelCodes =  reduxForm({
    form: 'CreateModelCodeForm',
    validate,
    enableReinitialize: true,
    initialValues: {
        modelCode: '',
        agency: '',
        firstName: '',
        lastName: '',
        bust: '',
        waist: '',
        hips: '',
        inseam: '',
        height: '',
        shoe: '',
        size: ''
    }
})(ModelForm);

const mapStateToProps = function (store) {
    return {
        selectedModels: store.photoStudioState.selectedModels,
        modelProfiles: store.photoStudioState
    };
};

export default withRouter(connect(mapStateToProps, {}) (ReduxFormModelCodes));
