import * as types from './actionTypes';

export function getLoaderStatus(loading) {
  return {
    type: types.GET_LOADER_STATUS,
    loading
  };
}

export function getAllInventoryListSuccess(inventoryList) {
  return {
    type: types.GET_ALL_INVENTORY_LIST_SUCCESS,
    inventoryList
  };
}

export function getAllInventoryListFailed(error) {
  return {
    type: types.GET_ALL_INVENTORY_LIST_FAILED,
    error
  };
}

export function searchInventoryListSuccess(inventoryList) {
  return {
    type: types.SEARCH_INVENTORY_LIST_SUCCESS,
    inventoryList
  };
}

export function searchInventoryListFailed(error) {
  return {
    type: types.SEARCH_INVENTORY_LIST_FAILED,
    error
  };
}

export function getInventoryRecordsSuccess(inventoryRecords) {
  return {
    type: types.GET_INVENTORY_RECORDS_SUCCESS,
    inventoryRecords
  }

}

export function getInventoryRecordsFailed(error) {
  return {
    type: types.GET_INVENTORY_RECORDS_FAILED,
    error
  };
}

export function updateProductInventoryRecordSuccess(response, inventoryRecords) {
  return {
    type: types.UPDATE_PRODUCT_INVENTORY_RECORD_SUCCESS,
    inventoryRecords
  }
}

export function updateProductInventoryRecordFailed(error) {
  return {
    type: types.UPDATE_PRODUCT_INVENTORY_RECORD_FAILED,
    error
  };
}

export function successfullyAlerted() {
  return {
    type: types.SUCCESSFULLY_ALERTED
  };
}
