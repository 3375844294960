import _ from 'lodash';
import store from '../services/store';
import { getLoaderStatus, getVipStatsSuccess, getVipStatsFailed, simpleSearchSuccess, searchFailed,
  getVipCustomerSuccess, getVipCustomerFailed, deleteVipCustomerSuccess, deleteVipCustomerFailed,
  updateVipCustomerSuccess, updateVipCustomerFailed, getCustomerGroupsSuccess, getCustomerGroupsFailed,
  getVipSitesSuccess, getVipSitesFailed, setVipQuery, setSiteId, getVipAttachmentUrlSuccess,
  getVipAttachmentUrlFailed } from '../actions/vipActions';

import {vipRestApi} from '../config';

let searchableFields = [
  'siteId',
  'customerNo',
  'firstName',
  'lastName',
  'email',
  'vipStatus',
  'referralCode',
  'city',
  'state',
  'industry',
  'customerType',
  'directSalesType',
  'specialty',
  'applicationDate',
  'mediaUploadDate',
  'businessName',
  'vipStatus'
];

function setLoadingStatus (loading) {
  store.dispatch(getLoaderStatus(loading))
}

export async function getVipStats(siteId) {
  setLoadingStatus(true);
  return vipRestApi.get(`vipcustomers/stats/${siteId}`).then(function({data}) {
    store.dispatch(getVipStatsSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error("Get Vip Stats failed: " + error);
    store.dispatch(getVipStatsFailed(error));
    setLoadingStatus(false);
  });
}

export async function simpleSearchVip(searchParams) {
  // escape special character `+` before sending to Mongo find
  const specialCharacterRegEx = /[+]/g;
  setLoadingStatus(true);
  let queryableFields = _.pickBy(_.pick(searchParams.query, searchableFields), _.identity);  //this will weed out all null properties
  let query = _.keys(queryableFields).map((key) => {
    return {
      name: key,
      value: queryableFields[key].replace(specialCharacterRegEx, '\\$&'),
      type: key.includes('Date') ? 'date' : 'string'
    };
  });

  let body = _.assign({query: query});
  body.take = searchParams.take;
  body.offset = searchParams.offset ? searchParams.offset : 0;

  return vipRestApi.post(`/vipcustomers/search`, body).then(function({data}) {
    store.dispatch(setVipQuery(searchParams));
    store.dispatch(simpleSearchSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error("Search Vip Customers failed: " + error);
    store.dispatch(searchFailed(error))
    setLoadingStatus(false);
  });
}

export function clearSearchVip() {
  store.dispatch(simpleSearchSuccess());
}

export async function getVipCustomer(customerNo) {
  return vipRestApi.get(`/vipcustomers/${customerNo}`).then(function({data}) {
    store.dispatch(getVipCustomerSuccess(data));
  }).catch(function (error) {
    console.error("Get Vip Customer failed: " + error);
    store.dispatch(getVipCustomerFailed(error));
  });
}

export async function updateVipCustomer(customerNo, updatedCustomer) {
  return vipRestApi.put(`/vipcustomers/${customerNo}`, updatedCustomer).then(function({data}) {
    store.dispatch(updateVipCustomerSuccess(data));
  }).catch(function (error) {
    console.error(`Update Vip Customer failed: ${JSON.stringify(error.response)}`);
    store.dispatch(updateVipCustomerFailed(error));
  });
}

export async function deleteVipCustomer(customerNo) {
  return vipRestApi.delete(`/vipcustomers/${customerNo}`).then(function({data}) {
    store.dispatch(deleteVipCustomerSuccess(data));
  }).catch(function (error) {
    console.error("Delete Vip Customer failed: " + error);
    store.dispatch(deleteVipCustomerFailed(error));
  });
}

export async function getCustomerGroups(siteId) {
  return vipRestApi.get(`/vipcustomers/customergroups/${siteId}`).then(function({data}) {
    store.dispatch(getCustomerGroupsSuccess(data));
  }).catch(function (error) {
    console.error("Get customer groups failed: " + error);
    store.dispatch(getCustomerGroupsFailed(error));
  });
}

export async function getVipSites() {
  setLoadingStatus(true);
  return vipRestApi.get(`/vipcustomers/sites`).then(function({data}) {
    store.dispatch(getVipSitesSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error("Get all sites failed: " + error);
    store.dispatch(getVipSitesFailed(error));
    setLoadingStatus(false);
  });
}

export function clearStats() {
  store.dispatch(getVipStatsSuccess());
}

export function setCurrentSite(siteId) {
  store.dispatch(setSiteId(siteId))
}

export function getVipAttachmentUrl (id) {
  return vipRestApi.get(`/vipcustomers/getAttachmentUrl/${id}`).then(function({data}) {
    store.dispatch(getVipAttachmentUrlSuccess(data));
  }).catch(function (error) {
    console.error("Get vip attachment url failed: " + error.message);
    store.dispatch(getVipAttachmentUrlFailed(error));
  });
}
