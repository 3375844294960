import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotifyMeBreadCrumbs extends Component {

  render() {
    const { id } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            { id &&
            <Link to="/notifyme/">Notify Me</Link>
            }
            { !id &&
            <span>Notify Me</span>
            }
          </div>
          { id &&
          <div className="breadcrumb">
            <span>{id}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}
