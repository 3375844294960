import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { SelectField, Table, TableBody, TableHeader, TableHeaderColumn, TableRow,
  TableRowColumn, MenuItem } from 'material-ui';
import { getNotifyMeSites, getNotifyMeStats, setCurrentNotifyMeSite, clearNotifyMeRecords } from '../api/notifyMeApi';

export const styles = {
  search: {
    marginRight: '10px',
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '25px'
  },
  tableRowColumn: {
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
};


class NotifyMeStatsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyMeSiteId: '',
      loading: false
    };
  }

  componentDidMount() {
    if (!this.props.notifyMeSites) {
      getNotifyMeSites();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.notifyMeSiteId)
      this.setState(Object.assign({}, this.state, { notifyMeSiteId: nextProps.notifyMeSiteId }));

    if( nextProps.notifyMeSitesFailed || nextProps.notifyStatsFailed) {
        let errorMsg = "";
        if (nextProps.notifyMeSitesFailed)
            errorMsg = `Unable to retrieve Notify Me Sites: ${nextProps.notifyMeSitesFailed.message}\n`;
        if (nextProps.notifyMeStatsFailed)
            errorMsg += `Unable to retrieve Notify Me Stats: ${nextProps.notifyMeStatsFailed.message}\n`;
        alert(errorMsg);
    }
  }

  onSiteIdChange = (event, index, value) => {
    clearNotifyMeRecords();
    setCurrentNotifyMeSite(value);
    getNotifyMeStats(value);
  };

  showTable = () => {
    let tableItems;
    if (!this.props.loading && this.props.notifyMeStats && this.props.notifyMeStats.length > 0)
      tableItems = this.props.notifyMeStats.map((stat,index) => {
        return (
            <TableRow key={index}>
                <TableRowColumn style={styles.tableRowColumn}>{ stat._id }</TableRowColumn>
                <TableRowColumn style={styles.tableRowColumn}>{ stat.count }</TableRowColumn>
            </TableRow>
        );
    });
    else {
      tableItems = <TableRow><TableRowColumn colSpan={2} style={styles.tableRowColumn}>No Results</TableRowColumn></TableRow>;
    }
    return (
        <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={styles.tableRowColumn}>Status</TableHeaderColumn>
                <TableHeaderColumn style={styles.tableRowColumn}>Count</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {tableItems}
            </TableBody>
        </Table>
    );
  };

  getDropdownItems = () => {
    if (this.props.notifyMeSites && this.props.notifyMeSites.length > 0) {
      return this.props.notifyMeSites.map((notifyMeSite, index) => {
        return <MenuItem key={index} value={notifyMeSite.id} primaryText={notifyMeSite.name}/>
      });
    }
  };

  render() {
    const table = this.showTable();
    const dropDownItems = this.getDropdownItems();
    return (
      <Grid fluid style={{marginTop: '20px'}}>
        <Row>
          <Col xs={12}>
            <SelectField
              style={styles.search}
              value={this.state.notifyMeSiteId}
              onChange={this.onSiteIdChange}>
              { dropDownItems }
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            { table }
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    notifyMeSites: store.notifyMeState.notifyMeSites,
    notifyMeSitesFailed: store.notifyMeState.notifyMeSitesFailed,
    notifyMeStats: store.notifyMeState.notifyMeStats,
    notifyMeStatsFailed: store.notifyMeState.notifyMeStatsFailed,
    notifyMeSiteId: store.notifyMeState.notifyMeSiteId
  };
};

export const NotifyMeStats = connect(mapStateToProps)(NotifyMeStatsContainer);
