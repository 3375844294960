import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import spacing from 'material-ui/styles/spacing';
import transitions from 'material-ui/styles/transitions';
import typography from 'material-ui/styles/typography';
import {grey100, grey200} from 'material-ui/styles/colors';
import { Paper } from 'material-ui';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Row, Col } from 'react-flexbox-grid';

export class AWSServicesClickableTile extends Component {

  getStyles() {
    const desktopGutter = spacing.desktopGutter;
    const desktopKeylineIncrement = spacing.desktopKeylineIncrement;
    const styles = {
      root: {
        transition: transitions.easeOut(),
        backgroundColor: grey100,
        maxWidth: '300px',
        //width: '100%',
        margin: `0 auto ${desktopGutter}px auto`,
      },
      link: {
        textDecoration: 'none'
      },
      heading: {
        fontSize: 16,
        letterSpacing: 0,
        fontWeight: typography.fontWeightMedium,
        color: typography.textDarkBlack,
        backgroundColor: grey200,
        textAlign: 'center',
        margin: 0,
        padding: 0,
        lineHeight: `${desktopKeylineIncrement}px`,
      },
      body: {
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: typography.fontWeightMedium,
        color: typography.textDarkBlack,
        backgroundColor: grey100,
        textAlign: 'center',
        margin: 0,
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        marginBottom: 11,
      },
      innerGrid: {
        padding: 0,
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem'
      },
      rootWhenLastChild: {
        marginBottom: 0,
      },
      rootWhenMediumAndLastChild: {
        marginRight: 0,
        marginBottom: 0,
      },
      rootWhenMediumAndFirstChild: {
        marginLeft: 0,
      },
    };

    return styles;
  }

  state = {
    zDepth: 0,
  };

  handleMouseEnter = () => {
    this.setState({
      zDepth: 4,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      zDepth: 0,
    });
  };

  render() {
    const styles = this.getStyles();
    return (
      <Link style={styles.link} to={this.props.route} >
        <Paper
          zDepth={this.state.zDepth}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={Object.assign(
            styles.root,
            this.props.lastChild && styles.rootWhenLastChild
          )}
        >
          { this.props.heading &&
            <h3 style={styles.heading}>{this.props.heading}</h3>
          }
          <div style={styles.body}>
            { this.props.icon ? (
              <Grid fluid style={styles.innerGrid}>
                <Row middle="xs">
                  <Col xs={4} style={styles.iconColumn}>
                    <div style={styles.iconColumnInner}>
                      <FontAwesomeIcon icon={this.props.icon} size='4x' fixedWidth style={this.props.iconStyle}/>
                    </div>
                  </Col>
                  <Col xs={8} style={styles.textColumn}>
                    { this.props.text &&
                    <span>{this.props.text}</span>
                    }
                    {this.props.children}
                  </Col>
                </Row>
              </Grid>
            ) : (
              <div>
                { this.props.text &&
                <span>{this.props.text}</span>
                }
                {this.props.children}
              </div>
            )}
          </div>
        </Paper>
      </Link>
    );
  }
}

AWSServicesClickableTile.propTypes = {
  heading: PropTypes.string,
  iconStyle: PropTypes.object,
  text: PropTypes.string,
  route: PropTypes.string,
  width: PropTypes.number
};
