import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {Paper, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TextField, Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui';
import ReactJson from 'react-json-view';
import { searchOrderHistory, getOrderHistoryCount, getRecentOrderStatus, searchOrderStatus } from "../api/orderHistoryApi";
import OrderHistoryBreadCrumbs from "./OrderHistoryBreadCrumbs";
import {grey500} from "material-ui/styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoaderComponent from "../components/Loader";
import {connect} from "react-redux";

export const OrderStatusItem = ( orderStatus ) => {
  let styles = {
    checkMark: {
      color: 'green'
    },
    xMark: {
      color: 'red'
    }
  };

  return (
    <TableRow key={orderStatus.orderNumber}>
      <TableRowColumn>{orderStatus.orderNumber}</TableRowColumn>
      <TableRowColumn>{orderStatus.siteId}</TableRowColumn>
      <TableRowColumn>{ orderStatus.eosStatus === 'processed' ?  
        <FontAwesomeIcon icon="check-circle" size="lg" fixedWidth style={styles.checkMark} /> :
        <FontAwesomeIcon icon="times-circle" size="lg" fixedWidth style={styles.xMark} /> }
      </TableRowColumn>
      <TableRowColumn>{ orderStatus.odStatus === 'processed' ?  
        <FontAwesomeIcon icon="check-circle" size="lg" fixedWidth style={styles.checkMark} /> :
        <FontAwesomeIcon icon="times-circle" size="lg" fixedWidth style={styles.xMark} /> }
      </TableRowColumn>
      <TableRowColumn>N/A</TableRowColumn>
      <TableRowColumn>N/A</TableRowColumn>
    </TableRow>
  )
};

export const OrderStatusTable = (props) => {
  const orderStatusList = 
    props.orderStatuses && 
    props.orderStatuses.length ? props.orderStatuses.map(OrderStatusItem) : null;
  return (
    <Table fixedHeader={true}>
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn>Order Number</TableHeaderColumn>
          <TableHeaderColumn>Site Id</TableHeaderColumn>
          <TableHeaderColumn>EOS</TableHeaderColumn>
          <TableHeaderColumn>OD</TableHeaderColumn>
          <TableHeaderColumn>SAP</TableHeaderColumn>
          <TableHeaderColumn>Data Lake</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {orderStatusList}
      </TableBody>
    </Table>
  );
};
  

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: '',
      statusOrderNumber: '',
      order: { },
      orderStatus: [ ],
      count: 0
    }
  };

  componentDidMount () {
    getOrderHistoryCount();
    getRecentOrderStatus();
  }

  handleOrderNumberTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { orderNumber: text.length > 0 ? text : undefined }));
  };

  handleStatusOrderNumberTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { statusOrderNumber: text.length ? text : undefined }));
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      return alert(`Order Search Failed: ${nextProps.error.message}`);
    }
    this.setState(Object.assign({}, this.state, { order: nextProps.order, count: nextProps.count, orderStatus: nextProps.orderStatus }));
  }

  searchOrderHistory = () => {
    searchOrderHistory(this.state.orderNumber);
  };

  searchOrderStatus = () => {
    if (this.state.statusOrderNumber){
      searchOrderStatus(this.state.statusOrderNumber);
    } else {
      getRecentOrderStatus();
    }
  };

  render() {
    let styles = {
      container: {
        padding: "10px"
      },
      icon: {
        marginRight: '20px',
        color: grey500
      },
      paper: {
        marginBottom: '30px'
      }
    };

    return (
      <div>
        <OrderHistoryBreadCrumbs />

        <Paper style={styles.paper}>
          <Toolbar>
            <ToolbarGroup>
              <FontAwesomeIcon icon="shopping-cart" size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="Order Status"/>
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton id="search" label="Search" primary={true} onClick={this.searchOrderStatus} />
            </ToolbarGroup>
          </Toolbar>
          <div>
            <Row style={styles.container}>
              <Col xs={12}>
                <TextField
                  floatingLabelText="Order Number"
                  onChange={this.handleStatusOrderNumberTextChange}
                  value={this.state.statusOrderNumber}
                />
              </Col>
            </Row>
            <Toolbar />
            <Row style={styles.container}>
              <Col xs={12}>
                <OrderStatusTable orderStatuses={this.state.orderStatus} />
              </Col>
            </Row>
          </div>
        </Paper>

        <Paper style={styles.paper}>
          <Toolbar>
            <ToolbarGroup>
              <FontAwesomeIcon icon="shopping-cart" size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="Order History"/>
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton id="search" label="Search" primary={true} onClick={this.searchOrderHistory} />
            </ToolbarGroup>
          </Toolbar>
          <div>
            <Row style={styles.container}>
              <Col xs={12}>
                <TextField
                  floatingLabelText="Order Number"
                  onChange={this.handleOrderNumberTextChange}
                  value={this.state.orderNumber}
                />
              </Col>
            </Row>
            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text={`Order Number: ${this.state.orderNumber}`}/>
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarTitle text={`Total Orders: ${this.state.count}`}/>
              </ToolbarGroup>
            </Toolbar>
            <Row style={styles.container}>
              <Col xs={12}>
                <ReactJson src={(this.state.order ? this.state.order : { })}/>
              </Col>
            </Row>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    order: store.orderHistoryState.order,
    count: store.orderHistoryState.count,
    orderStatus: store.orderHistoryState.orderStatus
  };
};

export default connect(mapStateToProps)(OrderHistory);
