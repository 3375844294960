import { green, red, yellow } from '@material-ui/core/colors'; // 500 property matches snackbar default colors

export function copyToClipboard (id) {
  const el = document.createElement('textarea');
  el.value = id;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function statusColorHelper(state, uptimeStatus) {
  const statusColors = {};
  const colorShade = 500;
  statusColors.state= (state === 'starting' || state === 'started') ? green[colorShade] : red[colorShade];

  if (uptimeStatus === 'green') {
    statusColors.upTime = green[colorShade]
  } else if (uptimeStatus === 'red') {
    statusColors.upTime = red[colorShade]
  } else {
    statusColors.upTime = yellow[colorShade];
  }

  return statusColors;
}
