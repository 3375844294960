import React, { Component } from 'react';

import { Row, Col } from 'react-flexbox-grid';
import { Dialog, MenuItem, FlatButton, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Field, reduxForm } from 'redux-form';
import { TextField, SelectField, DatePicker } from 'redux-form-material-ui';

import { couponRestApi } from '../config';

export const styles = {
  actionsContainer: {
    margin: '10px 0'
  }
};

export const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a Coupon Name'
  }
  if (!values.prefix) {
    errors.prefix = 'Required'
  }
  if (!values.startsOn) {
    errors.startsOn = 'Required';
  }
  if (!values.endsOn) {
    errors.endsOn = 'Required'
  } else if (values.startsOn >= values.endsOn) {
    errors.endsOn = 'Please enter a date later than the start date'
  }
  if (!values.description) {
    errors.description = 'Please enter a description'
  }
  return errors
};

export class CreateCouponForm extends Component {

  render() {

    const {handleSubmit, pristine, reset, submitting, submitSucceeded} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="name"
                component={TextField}
                id="couponName"
                hintText="Ex. Friends & Family SF171"
                floatingLabelText="Coupon Name*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12}>
              <Field
                name="description"
                component={TextField}
                id="couponDesc"
                hintText="Enter a short description"
                floatingLabelText="Description*"
                multiLine={true}
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="prefix"
                component={TextField}
                id="couponPrefix"
                hintText="Ex. SF171"
                floatingLabelText="Prefix*"
                fullWidth={true}
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="type"
                component={SelectField}
                id="couponType"
                floatingLabelText="Type*"
                fullWidth={true}
                disabled={submitting}
              >
                <MenuItem value="f&f" primaryText="F&F"/>
              </Field>
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="startsOn"
                component={DatePicker}
                id="couponStartsOn"
                format={null}
                fullWidth={true}
                floatingLabelText="Start Date*"
                disabled={submitting}
              />
            </Col>
          }
          {!submitSucceeded &&
            <Col xs={12} sm={6}>
              <Field
                name="endsOn"
                component={DatePicker}
                id="couponEndsOn"
                hintText="End Date:"
                format={null}
                fullWidth={true}
                floatingLabelText="End Date*"
                disabled={submitting}
              />
            </Col>
          }
          <Col xs={12}>
            {submitSucceeded &&
              <p>
                Coupon successfully created!
              </p>
            }
            <div style={styles.actionsContainer}>
              {!submitSucceeded &&
                <span>
                  <FlatButton
                    label="Submit"
                    type="submit"
                    primary={true}
                    disabled={submitting}
                  >
                    {submitting &&
                    <FontAwesomeIcon icon='sync' spin/>
                    }
                  </FlatButton>
                  <FlatButton
                    label="Clear"
                    disabled={pristine || submitting}
                    onClick={reset}
                  />
                  </span>
              }
              <FlatButton
                label="Close"
                disabled={submitting}
                onClick={this.props.closeButton}
              />
            </div>
          </Col>
        </Row>
      </form>
    )
  };
}

CreateCouponForm = reduxForm({
  form: 'createCouponForm',
  initialValues: {
    name: '',
    description: '',
    prefix: '',
    type: 'f&f',
    startsOn : null, // set to null because datepicker requires object for value
    endsOn   : null  // set to null because datepicker requires object for value
  },
  validate
})(CreateCouponForm);

export default class CreateCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  submit = (body) => (
    couponRestApi.post('/coupons', body).then(() => {
      this.props.onRefresh && this.props.onRefresh();
    }).catch((error) => {
      console.log("Post coupon failed: " + error);
    })
  );

  render() {
    return (
      <div>
        <RaisedButton label="New" primary={true} onClick={this.handleOpen} />
        <Dialog
          title="Create New Coupon"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
        >
          <CreateCouponForm onSubmit={this.submit} closeButton={this.handleClose} />
        </Dialog>
      </div>
    );
  }
}
