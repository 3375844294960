import React from 'react';
import ProductImageSearch from './ProductImageSearch';
import { Route, Switch } from 'react-router-dom'

const ProductImageSearchRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/product-image-search" component={ProductImageSearch}/>
    </Switch>
  </div>
);

export default ProductImageSearchRoutes;
