import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { PropagateLoader } from 'react-spinners';
import useStyles from '../../styles/SandboxStyles';
import SandboxMobileMenu from './menu/SandboxMobileMenu';
import SandboxTableMobile from './table/SanboxTableMobile';
import SandboxActionButtons from './menu/SandboxActionButtons';
import SandboxTable from './table/SandboxTable';
import { columbiaDarkBlue } from '../../../themes/brandColors';

function SandboxContainer ({ handleUpdateClick, isLoading, onRefresh, sandboxes }) {
  const classes = useStyles();
  const breakSM = useMediaQuery('(min-width:960px)');

  return (
    <Paper className={classes.paper}>
      <Toolbar disableGutters={false} className={classes.toolbar}>
        <Typography className={classes.toolbarTitle}>On-Demand Sandbox List</Typography>
        {isLoading &&
        <PropagateLoader
          sizeUnit={"px"}
          size={breakSM ? 15 : 5}
          color={columbiaDarkBlue}
          loading={isLoading}
        />
        }
        {breakSM
          ? <SandboxActionButtons onRefresh={onRefresh} classes={classes}/>
          : <SandboxMobileMenu onRefresh={onRefresh} classes={classes}/>
        }
      </Toolbar>

      {breakSM
        ? <SandboxTable sandboxes={sandboxes} isLoading={isLoading} classes={classes} handleUpdateClick={handleUpdateClick} />
        : <SandboxTableMobile sandboxes={sandboxes} isLoading={isLoading} classes={classes} handleUpdateClick={handleUpdateClick} />
      }
    </Paper>
  );
};

SandboxContainer.propTypes = {
  isLoading: PropTypes.bool,
  onRefresh: PropTypes.func,
  sandboxes: PropTypes.array
}

export default SandboxContainer;
