import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Pingdom from './Pingdom';


const PingdomRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/pingdom" component={Pingdom}/>
    </Switch>
  </div>
);

export default PingdomRoutes;
