import * as types from '../actions/actionTypes';

const initialState = {
  sites: null,
  x1Sites: null,
  sync: {},
  config: {},
  statistics: null,
  epsilonX1Sites: null,
  epsilonX1Sync: {},
  epsilonX1Config: {},
  epsilonX1Statistics: null
};

const emailReducer = function(state = initialState, action) {
  switch(action.type) {
    case types.GET_EMAIL_SITES_SUCCESS:
      return Object.assign({}, state, { sites: action.sites });

    case types.GET_EMAIL_X1_SITES_SUCCESS:
      return Object.assign({}, state, { x1Sites: action.x1Sites });

    case types.GET_EMAIL_SITE_SYNC_STATUS_SUCCESS:
      let newSite = {};
      newSite[action.siteId] = action.data;
      return Object.assign({}, state, { sync: Object.assign({}, state.sync, newSite) });

    case types.GET_EMAIL_X1_SITE_SYNC_STATUS_SUCCESS:
      let newX1Site = {};
      newX1Site[action.siteId] = action.data;
      return Object.assign({}, state, { sync: Object.assign({}, state.sync, newX1Site) });

    case types.GET_EMAIL_SITES_SUCCESS_EPSILON:
      return Object.assign({}, state, { epsilonX1Sites: action.epsilonX1Sites });

    case types.GET_EMAIL_SITE_SYNC_STATUS_SUCCESS_EPSILON:
      let newEpsilonX1Site = {};
      newEpsilonX1Site[action.siteId] = action.data;
      return Object.assign({}, state, { epsilonX1Sync: Object.assign({}, state.epsilonX1Sync, newEpsilonX1Site) });

    case types.GET_EMAIL_PROFILE_STATISTICS_SUCCESS_EPSILON:
      return Object.assign({}, state, { epsilonX1Statistics: action.data });

    case types.GET_EMAIL_SITE_CONFIG_SUCCESS_EPSILON:
      let newEpsilonX1Config = {};
      newEpsilonX1Config[action.siteId] = action.data;
      return Object.assign({}, state, { epsilonX1Config: Object.assign({}, state.epsilonX1Config, newEpsilonX1Config) });

    case types.GET_EMAIL_PROFILE_ACTIONS_SUCCESS_EPSILON:
      return Object.assign({}, state, { epsilonX1Actions: action.data });

    case types.GET_EMAIL_SITE_CONFIG_SUCCESS:
      let newConfig = {};
      newConfig[action.siteId] = action.data;
      return Object.assign({}, state, { config: Object.assign({}, state.config, newConfig) });

    case types.GET_EMAIL_PROFILE_STATISTICS_SUCCESS:
      return Object.assign({}, state, { statistics: action.data });

    case types.GET_EMAIL_PROFILE_ACTIONS_SUCCESS:
      return Object.assign({}, state, { actions: action.data });

    case types.SET_FAILED_OBJECTS_SEARCH_CRITERIA:
      return Object.assign({}, state, { searchCriteria: action.searchCriteria });

    case types.SET_EMAIL_FAILED_OBJECTS:
      return Object.assign({}, state, { failedObjects: action.failedObjects });

    case types.SET_FAILED_OBJECT_TAG:
      return Object.assign({}, state, { tags: action.tags});

    case types.SET_FAILED_OBJECT_TYPES:
      return Object.assign({}, state, { objectTypes: action.objectTypes});

    case types.SHOW_DETAIL_OBJECT:
      return Object.assign({}, state, { show: action.show });

    default:
      return state;
  }
};

export default emailReducer;
