import React, { Component } from 'react';
import {connect} from "react-redux";
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paper, RaisedButton, Table, TableBody, TableHeader, TableRow, TableRowColumn,
  TableHeaderColumn, TextField, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import SEOLintBreadCrumbs from "./SEOLintBreadCrumbs";
import { seoLint, clearSeoLintReport } from "../api/utilsApi";
import LoaderComponent from '../components/Loader';
import {grey500} from "material-ui/styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const styles = {
  container: {
    padding: '20px'
  },
  tableRowColumn: {
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  },
  textCenter: {
    textAlign: 'center'
  },
  loader: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'center'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class SEOLintContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seoLintUrl: '',
      seoLintReport: []
    };
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.seoLintReport) {
      this.setState(Object.assign({}, this.state, nextProps.seoLintReport));
    }
  }

  componentDidMount() {
    clearSeoLintReport();
  }

  handleUrlChange = (event, value) => {
    this.setState(Object.assign({}, this.state, { seoLintUrl: value }));
  };

  seoLintSubmit = async () => {
    if (!this.state.seoLintUrl || this.state.seoLintUrl === '') {
      return alert('Please enter a URL to SEO lint.');
    }
    clearSeoLintReport();
    await seoLint(this.state.seoLintUrl);
  };

  showReport = () => {
    if (this.props.seoLintReport && this.props.seoLintReport.length) {
      return this.props.seoLintReport.map((r, i) => {
        return (
          <TableRow key={i}>
            <TableRowColumn style={styles.tableRowColumn}>{ r.tagName }</TableRowColumn>
            <TableRowColumn style={styles.tableRowColumn}>{ r.code }</TableRowColumn>
            <TableRowColumn style={styles.tableRowColumn}>{ r.message }</TableRowColumn>
          </TableRow>
        )
      });
    }
  };

  render() {
    let report = this.showReport();
    return (
      <div style={{paddingBottom: '20px'}}>
        <SEOLintBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup>
              <FontAwesomeIcon icon="link" size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="Search Engine Optimization Linter"/>
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton
                primary={true}
                label="SEO Lint"
                onClick={ this.seoLintSubmit }
              />
            </ToolbarGroup>
          </Toolbar>
          <Grid style={styles.container}>
            <Row>
              <Col xs={12}>
                <TextField
                  hintText="URL:"
                  floatingLabelText="URL:"
                  value={this.state.seoLintUrl}
                  onChange={this.handleUrlChange}
                  fullWidth={true}
                  multiLine={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                      <TableHeaderColumn style={styles.tableRowColumn}>Tag Name</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Code</TableHeaderColumn>
                      <TableHeaderColumn style={styles.tableRowColumn}>Message</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody displayRowCheckbox={false}>
                    { report }
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    seoLintReport: store.utilsState.seoLintReport
  };
};

export default connect(mapStateToProps)(SEOLintContainer);
