import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Row, Col } from 'react-flexbox-grid';
import { blue300, blue800, red200, green200, green800,
  red800, blue200, grey800, grey200 } from 'material-ui/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import typography from 'material-ui/styles/typography';
import { Avatar, Toggle, TextField, MenuItem, Chip, Card, CardHeader, CardActions, CardText, FlatButton, SelectField } from 'material-ui';
import AdminUserAccounts from './AdminUserAccounts';

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  heading: {
    fontSize: 16,
    letterSpacing: 0,
    fontWeight: typography.fontWeightMedium,
    color: typography.textLightBlack,
    //backgroundColor: grey200,
    textAlign: 'left',
    margin: 0,
    marginTop: "10px",
    padding: 0,
    //lineHeight: `10px`,
  }
};

export const AdminUserAddRoleChip = ({ role, addRole, disabled, definedRoles }) => {
  let roleName;
  let roleIcon;
  let roleIconColor;
  let roleIconBackgroundColor;
  if (definedRoles[role]) {
    roleName = definedRoles[role].name;
    roleIcon = definedRoles[role].icon;
    switch(definedRoles[role].hint){
      case "read":
        roleIconColor = green800;
        roleIconBackgroundColor = green200;
        break;
      case "write":
        roleIconColor = red800;
        roleIconBackgroundColor = red200;
        break;
      case "admin":
        roleIconColor = blue800;
        roleIconBackgroundColor = blue200;
        break;
      default:
        roleIconColor = grey800;
        roleIconBackgroundColor = grey200;
        break;
    }
  } else {
    roleName = role;
  }

  let addThisRole = function(){
    addRole(role);
  };
  return disabled ? (
    <Chip style={styles.chip}>
      { roleIcon &&
      <Avatar
        icon={<FontAwesomeIcon style={styles.icon} icon={roleIcon} fixedWidth/>}
        color={roleIconColor}
        backgroundColor={roleIconBackgroundColor}
      />
      }
      {roleName}
    </Chip>
  ) : (
    <Chip style={styles.chip} onClick={addThisRole}>
      { roleIcon &&
      <Avatar
        icon={<FontAwesomeIcon style={styles.icon} icon={roleIcon} fixedWidth/>}
        color={roleIconColor}
        backgroundColor={roleIconBackgroundColor}
      />
      }
      {roleName}
    </Chip>
  );
};

export const AdminUserRemoveRoleChip = ({ role, removeRole, disabled, definedRoles }) => {
  let roleName;
  let roleIcon;
  let roleIconColor;
  let roleIconBackgroundColor;
  if (definedRoles[role]) {
    roleName = definedRoles[role].name;
    roleIcon = definedRoles[role].icon;
    switch(definedRoles[role].hint){
      case "read":
        roleIconColor = blue800;
        roleIconBackgroundColor = blue200;
        break;
      case "write":
        roleIconColor = red800;
        roleIconBackgroundColor = blue200;
        break;
      case "admin":
        roleIconColor = red800;
        roleIconBackgroundColor = red200;
        break;
      default:
        roleIconColor = blue800;
        roleIconBackgroundColor = blue200;
        break;
    }  } else {
    roleName = role;
  }
  let removeThisRole = function(){
    removeRole(role);
  };
  return disabled ? (
    <Chip style={styles.chip} backgroundColor={blue300}>
      { roleIcon &&
      <Avatar
        icon={<FontAwesomeIcon style={styles.icon} icon={roleIcon} fixedWidth/>}
        color={roleIconColor}
        backgroundColor={roleIconBackgroundColor}
      />
      }
      {roleName}
    </Chip>
  ) : (
    <Chip style={styles.chip} onRequestDelete={removeThisRole} backgroundColor={blue300}>
      { roleIcon &&
      <Avatar
        icon={<FontAwesomeIcon style={styles.icon} icon={roleIcon} fixedWidth/>}
        color={roleIconColor}
        backgroundColor={roleIconBackgroundColor}
      />
      }
      {roleName}
    </Chip>
  );
};

export const AdminUserCurrentRoleList = ({ user, removeRole, disabled, definedRoles }) => {
  const roleList = user.roles.map(role => <AdminUserRemoveRoleChip key={role} disabled={disabled} role={role} removeRole={removeRole} definedRoles={definedRoles}/>);
  return (
    <div style={styles.wrapper}>{roleList}</div>
  );
};

export const AdminUserAvailableRoleList = ({ user, addRole, disabled, definedRoles }) => {
  let availableRoles = [];
  for (let role in definedRoles) {
    if (!user.roles.find(r => r === role)) {
      availableRoles.push(role);
    }
  }
  const roleList = availableRoles.map(role => <AdminUserAddRoleChip key={role} disabled={disabled} role={role} addRole={addRole} definedRoles={definedRoles}/>);
  return (
    <div style={styles.wrapper}>{roleList}</div>
  );
};

export class AdminUserEdit extends Component {

  constructor (props) {
    super(props);
    this.state = {
      selectedHandler: 'Time Zone'
    };
    this.toggleActive = this.toggleActive.bind(this);
    this.addAllRoles = this.addAllRoles.bind(this);
    this.removeAllRoles = this.removeAllRoles.bind(this);
    this.addAdminRoles = this.addAdminRoles.bind(this);
  }

  addAllRoles () {
    this.props.addAllRoles(this.props.definedRoles);
  }

  removeAllRoles () {
    this.props.removeAllRoles();
  }

  addAdminRoles () {
    this.props.addAdminRoles(this.props.definedRoles);
  }

  toggleActive () {
    this.props.setActiveFlag(!(this.props.user.active === true));
  }

  render() {
    const timezoneItems = [
      { payload: 'PDT', text: 'Pacific Daylight Time' },
      { payload: 'MDT', text: 'Mountain Daylight Time' },
      { payload: 'CDT', text: 'Central Daylight Time' },
      { payload: 'EDT', text: 'Eastern Daylight Time' },
      { payload: 'CEST', text: 'Central European Summer Time' },
      { payload: 'IST', text: 'Indian Standard Time' },
      ];

    return (
      <Row style={{ padding:"20px" }}>
        <Col xs={12} sm={this.props.isNew ? 12 : 4}>
          <Card>
            <CardHeader title="Ecommerce Hub Account" />
            { !this.props.isNew &&
              <CardActions>
                <Toggle id="active"
                  toggled={this.props.user ? (this.props.user.active === true) : false}
                  onToggle={this.toggleActive}
                  label="User Enabled"
                  labelPosition="right"
                  disabled={!this.props.editable}
                />
              </CardActions>
            }
            <CardText>
              <Row>
                <Col xs={12}>
                  <TextField id="email"
                    defaultValue={this.props.user ? this.props.user.email : ""}
                    fullWidth={true}
                    floatingLabelText="Email Address"
                    underlineDisabledStyle={{border:'none'}}
                    onChange={this.props.handleFieldUpdate}
                    disabled={!this.props.isNew || !this.props.editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField id="firstName"
                    defaultValue={this.props.user ? this.props.user.firstName : ""}
                    fullWidth={true}
                    floatingLabelText="First Name"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField id="lastName"
                    defaultValue={this.props.user ? this.props.user.lastName : ""}
                    fullWidth={true}
                    floatingLabelText="Last Name"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField id="slackId"
                    defaultValue={this.props.user ? this.props.user.slackId : ""}
                    fullWidth={true}
                    floatingLabelText="Slack Id"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12}>
                  <SelectField
                    id="timezone"
                    value={this.props.user.timezone}
                    onChange={(event, target, value) => {
                      const wrapperObject = {
                          target: {
                            id: 'timezone',
                            value
                          }
                      }
                      return this.props.handleFieldUpdate(wrapperObject);
                    }}
                    floatingLabelText={this.state.selectedHandler}>
                    {timezoneItems.map(x => <MenuItem key={x.payload} value={x.payload} primaryText={x.text} />)}
                  </SelectField>
                </Col>
              </Row>
            </CardText>
          </Card>
          { !this.props.isNew &&
            <AdminUserAccounts user={this.props.user} />
          }
        </Col>
        { !this.props.isNew &&
          <Col xs={12} sm={8}>
            <Card initiallyExpanded={true}>
              <CardHeader
                title="Currently Assigned Roles"
                actAsExpander={true}
                showExpandableButton={true}
              />
              <CardText expandable={true}>
                <AdminUserCurrentRoleList
                  user={this.props.user}
                  removeRole={this.props.removeRole}
                  disabled={!this.props.editable}
                  definedRoles={this.props.definedRoles}/>
              </CardText>
            </Card>
            <Card initiallyExpanded={true}>
              <CardHeader
                title="Available Roles"
                actAsExpander={true}
                showExpandableButton={true}
              />
              {this.props.editable &&
              <CardActions>
                <FlatButton
                  label="Add All"
                  onClick={this.addAllRoles}
                />
                <FlatButton
                  label="Remove All"
                  onClick={this.removeAllRoles}
                />
                <FlatButton
                  label="Admin"
                  onClick={this.addAdminRoles}
                />
              </CardActions>
              }
              <CardText expandable={true}>
                <AdminUserAvailableRoleList
                  user={this.props.user}
                  addRole={this.props.addRole}
                  disabled={!this.props.editable}
                  definedRoles={this.props.definedRoles}/>
              </CardText>
            </Card>
          </Col>
        }
      </Row>
    );
  }
}

AdminUserEdit.propTypes = {
  user: PropTypes.object,
  isNew: PropTypes.bool,
  editable: PropTypes.bool,
  addRole: PropTypes.func,
  addAllRoles: PropTypes.func,
  removeRole: PropTypes.func,
  removeAllRoles: PropTypes.func,
  addAdminRoles: PropTypes.func,
  setActiveFlag: PropTypes.func,
  handleFieldUpdate: PropTypes.func,
  definedRoles: PropTypes.object
};

export default AdminUserEdit;
