import React, { Component } from 'react';
import PhotoStudioBreadCrumbs from "./PhotoStudioBreadCrumbs";

import { connect } from "react-redux";
import PhotoStudioOptions from './PhotoStudioOptions';
import PhotoStudioModelList from './PhotoStudioModelList'

class PhotoStudioMianView extends Component {
  
  render() {    
    return (
      <div>
        <PhotoStudioBreadCrumbs />
        <PhotoStudioOptions />
        <PhotoStudioModelList />     
      </div>
    );
  }
}
const mapStateToProps = function (store) {
  return {
    
  };
};

export default connect(mapStateToProps)(PhotoStudioMianView);
