import * as types from '../actions/actionTypes';

const initialState = {
    selectedModelCodes: [],
    modelsList: [],
    modelToView: {},
    modelToEdit: {},
    selectedModels: [],
    queryModelData: [],
    modelsPublished: false,
    modelsPublishPending: false,
    publishMultipleModelsSuccess: false
};

const photoStudioReducer = function (state = initialState, action) {
    switch (action.type) {
        case types.GET_MODELS_LIST_SUCCESS:
            return Object.assign({}, state, { modelsList: action.data });
        case types.GET_MODELS_LIST_FAIL:
            return Object.assign({}, state, { error: action.error });
        case types.SET_SELECTED_MODELS:
            return Object.assign({}, state, { selectedModels: action.data });

        case types.QUERY_MODEL_DATA:
            return Object.assign({}, state, { queryModelData: action.data });

        case types.PUBLISH_MULTIPLE_MODELS_PENDING:
        return Object.assign({}, state, {  modelsPublishPending: true });

        case types.SET_PUBLISH_MULTIPLE_MODELS_SUCCESS:
        return Object.assign({}, state, { modelsPublished: true, modelsPublishPending: false });

        case types.SET_PUBLISH_MULTIPLE_MODELS_ERROR:
        return Object.assign({}, state, { modelsPublished: false, modelsPublishPending: false });
        
        default:
            return state;
    }
}

export default photoStudioReducer;