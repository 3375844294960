import React from 'react';
import Coverage from './Coverage';
import { Route, Switch } from 'react-router-dom'

const CoverageRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/coverage" component={Coverage}/>
    </Switch>
  </div>
);

export default CoverageRoutes;
