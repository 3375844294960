import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DrawerSubLink from './DrawerSubLink';

function DrawerLink ({
  classes,
  handleClick,
  icon,
  id,
  menuItems,
  open,
  primaryText,
  route,
  toggleDrawer
}) {
  return (
    <Fragment>
    {route
      ? (
        <NavLink exact to={route} className={classes.link} onClick={toggleDrawer('left', false)}>
          <ListItem button>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} fixedWidth/>
            </ListItemIcon>
            <ListItemText primary={primaryText}/>
          </ListItem>
        </NavLink>
        )
      : (
        <Fragment>
          <ListItem button onClick={(e) => handleClick(id, e)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} fixedWidth/>
            </ListItemIcon>
            <ListItemText primary={primaryText} />
            {open[id] ? <ExpandLess/> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[id]} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {menuItems.map(item => (
                <DrawerSubLink
                  key={item.primaryText}
                  classes={classes}
                  icon={item.icon}
                  route={item.route}
                  primaryText={item.primaryText}
                  toggleDrawer={toggleDrawer} />
              ))}
            </List>
          </Collapse>
        </Fragment>
    )}
    </Fragment>
  )
}

export default DrawerLink;

DrawerLink.propTypes = {
  classes: PropTypes.object,
  handleClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  id: PropTypes.string,
  menuItems: PropTypes.array,
  open: PropTypes.object,
  primaryText: PropTypes.string,
  route: PropTypes.string,
  toggleDrawer: PropTypes.func
}
