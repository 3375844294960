import React from 'react';
import MismatchedQty from './MismatchedQty';
import MisMatchedQtyDetails from './MismatchedQtyDetails';
import { Route, Switch } from 'react-router-dom'

const MismatchedQtyRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/mismatchedqty" component={MismatchedQty}/>
      <Route path="/mismatchedqty/:id" component={MisMatchedQtyDetails}/>
    </Switch>
  </div>
);

export default MismatchedQtyRoutes;
