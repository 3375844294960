import React, { Component } from 'react';
import { Paper, Tabs, Tab  } from 'material-ui';
import SwipeableViews from 'react-swipeable-views';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolbarDynamic } from '../components/ToolbarDynamic';
import AWSServicesBreadCrumbs from "./AWSServicesBreadCrumbs";
import { AWSServicesDashboard } from "./AWSServicesDashboard";
import { AWSServicesList } from "./AWSServicesList";
import { AWSServicesConfiguration } from "./AWSServicesConfiguration";
import {grey500} from 'material-ui/styles/colors';

export const styles = {
  container: {
    padding: '10px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class AWSServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    };
  }

  handleChange = (value) => {
    this.setState(Object.assign({}, this.state, { slideIndex: value }));
  };

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <AWSServicesBreadCrumbs />
        <Paper>
          <ToolbarDynamic title={"AWS Services"} icon={["fab", "aws"]} iconStyle={styles.icon}/>
          <div>
            <Tabs
              onChange={this.handleChange}
              value={this.state.slideIndex}>
              <Tab label="Dashboard" value={0} icon={<FontAwesomeIcon icon="tachometer-alt" fixedWidth />}/>
              <Tab label="Lambdas" value={1} icon={<FontAwesomeIcon icon="microchip" fixedWidth />}/>
              <Tab label="Configuration" value={2} icon={<FontAwesomeIcon icon="clipboard-list" fixedWidth />}/>
            </Tabs>
            <SwipeableViews
              index={this.state.slideIndex}
              onChangeIndex={this.handleChange}>
              <div style={styles.container}>
                <AWSServicesDashboard />
              </div>
              <div style={styles.container}>
                <AWSServicesList />
              </div>
              <div>
                <AWSServicesConfiguration />
              </div>
            </SwipeableViews>
          </div>
      </Paper>
      </div>
    );
  }
}

export default AWSServices;
