import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AWSServicesDetails from "./AWSServicesDetails";
import AWSServices from "./AWSServices";

const AWSServicesRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/awsservices" component={AWSServices}/>
      <Route path="/awsservices/:id" component={AWSServicesDetails}/>
    </Switch>
  </div>
);

export default AWSServicesRoutes;
