import * as types from '../actions/actionTypes';
import _ from 'lodash';

const initialState = {
  vipStats: null,
  media: []
};

const vipReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.GET_VIP_STATS_SUCCESS:
            return Object.assign({}, state, { vipStats: action.vipStats });

        case types.GET_VIP_STATS_FAILED:
            return Object.assign({}, state, { vipStats: null, vipStatsFailed: action.error });

        case types.SIMPLE_SEARCH_SUCCESS:
            return Object.assign({}, state, { vipCustomers: action.vipCustomers });

        case types.SEARCH_FAILED:
            return Object.assign({}, state, { vipCustomers: {}, searchFailed: action.error});

        case types.GET_VIP_CUSTOMER_SUCCESS:
            return Object.assign({}, state, { vipCustomer: action.vipCustomer });

        case types.GET_VIP_CUSTOMER_FAILED:
            return Object.assign({}, state, { vipCustomer: {}, getCustomerFailed: action.error });

        case types.GET_VIP_CUSTOMER_MEDIA_SUCCESS:
            state.media.push(action.media);
            return Object.assign({}, state, { media: _.slice(state.media) });

        case types.GET_VIP_CUSTOMER_MEDIA_FAILED:
            return Object.assign({}, state, { media: [], getMediaFailed: action.error });

        case types.UPDATE_VIP_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {vipCustomer: action.vipCustomer});

        case types.UPDATE_VIP_CUSTOMER_FAILED:
            return Object.assign({}, state, {updateCustomerFailed: action.error});

        case types.GET_CUSTOMER_GROUPS_SUCCESS:
            return Object.assign({}, state, { vipCustomerGroups: action.customerGroups });

        case types.GET_CUSTOMER_GROUPS_FAILED:
            return Object.assign({}, state, { vipCustomerGroups: {}, getCustomerGroupsFailed: action.error});

        case types.GET_VIP_SITES_SUCCESS:
          return Object.assign({}, state, { vipSites: action.sites });

        case types.GET_VIP_SITES_FAILED:
          return Object.assign({}, state, { vipSites: {}, vipSitesFailed: action.error });

        case types.SET_VIP_QUERY:
          return Object.assign({}, state, { vipQuery: action.vipQuery });

        case types.SET_SITE_ID:
          return Object.assign({}, state, { siteId: action.siteId });

        case types.GET_LOADER_STATUS:
          return Object.assign({}, state, { loading: action.loading });

        case types.GET_VIP_ATTACHMENT_URL_SUCCESS:
          return Object.assign({}, state, { mediaUrl: action.mediaUrl });

        case types.GET_VIP_ATTACHMENT_URL_FAILED:
          return Object.assign({}, state, { getVipAttachmentUrlFailed: action.error });


        default:
            return state;
  }
};

export default vipReducer;
