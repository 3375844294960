import React from 'react';
import NotLiveReports from './NotLiveReports';
import NotLiveReportDetails from './NotLiveDetails';
import { Route, Switch } from 'react-router-dom'

const NotLiveRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/notlivereport" component={NotLiveReports}/>
      <Route path="/notlivereport/:id" component={NotLiveReportDetails}/>
    </Switch>
  </div>
);

export default NotLiveRoutes;
