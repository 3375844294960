import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class AdminCronBreadCrumbs extends Component {

  render() {
    const { cronTaskId } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            <span>Admin</span>
          </div>
          <div className="breadcrumb">
            { cronTaskId &&
            <Link to="/admin/cron">Cron Tasks</Link>
            }
            { !cronTaskId &&
            <span>Cron Tasks</span>
            }
          </div>
          { cronTaskId &&
          <div className="breadcrumb">
            <span>{cronTaskId}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}

AdminCronBreadCrumbs.propTypes = {
  cronTaskId: PropTypes.string
};
