import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class AdminDecryptBreadcrumbs extends Component {

  render() {
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            <span>Admin</span>
          </div>
          <div className="breadcrumb">
            <span>Decrypt</span>
          </div>
        </div>
      </nav>
    )
  }
}
