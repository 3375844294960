import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardActions, Divider, Link, TableContainer, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import { hasRoles } from '../../../../api/userApi';
import StartStopSandbox from '../../buttons/StartStopSandbox';
import ToolTipClick from '../../elements/ToolTipClick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyToClipboard, statusColorHelper } from '../../../utils/utils';

function SandboxMobileTable ({ classes, handleUpdateClick, isLoading, sandboxes }) {
  const mobileTable = sandboxes.length
    ? sandboxes.map(sandbox => {
      const statusColors = statusColorHelper(sandbox.state, sandbox.uptimeStatus);

      return (
      <Fragment key={sandbox.id}>
        <Card>
          <CardContent>
            <TableContainer className={classes.container}>
              <Table className={classes.table} aria-label='sfcc sandbox table'>
                <TableBody>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      Name:
                    </TableCell>
                    <TableCell align='right'>
                      {sandbox.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      UUID:
                    </TableCell>
                    <TableCell align='right' onClick={(e) => copyToClipboard(sandbox.id, e)}>
                      {'*****'}<ToolTipClick icon={['far', 'clone']} classes={classes} isMobile={true}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      Business Mngr:
                    </TableCell>
                    <TableCell align='right'>
                      <Link href={sandbox.bmlink} color='primary' target='_blank' rel='noopener'>
                        {sandbox.realm}-{sandbox.instance}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      CreatedAt (Local Time):
                    </TableCell>
                    <TableCell align='right'>
                    {new Date(sandbox.createdAt).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      End of Life (Local Time):
                    </TableCell>
                    <TableCell align='right'>
                    {sandbox.eol ? sandbox.eol : 'No End of Life'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      Status:
                    </TableCell>
                    <TableCell align='right'>
                    {sandbox.state === 'starting' || sandbox.state === 'stopping'
                      ? <FontAwesomeIcon icon={['fas', 'spinner']} color={statusColors.state} pulse size='2x'/>
                      : <FontAwesomeIcon icon={['fas', 'circle']} color={statusColors.state} size='2x'/>
                    }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      Uptime:
                    </TableCell>
                    <TableCell align='right'>
                      <div className={classes.inlineDiv}>{sandbox.uptime}</div>
                      <FontAwesomeIcon icon={['fas', 'circle']} color={statusColors.upTime} size='2x' className={classes.paddedIcon} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.thMobile}>
                      Downtime:
                    </TableCell>
                    <TableCell align='right'>
                    {sandbox.downtime}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <CardActions className={classes.cardAction}>
              { hasRoles('start:sandbox') &&
              <StartStopSandbox uuid={sandbox.id} state={sandbox.state}  handleUpdateClick={handleUpdateClick}/>
              }
            </CardActions>
          </CardContent>
        </Card>
        <Divider/>
      </Fragment>
    )})
    : <Card>
        <CardContent>
          <Typography align='center'>
            {isLoading ? '...Loading' : 'No Sandboxes to Display'}
          </Typography>
        </CardContent>
      </Card>
  return (
    <Fragment>
      {mobileTable}
    </Fragment>
  )
}

SandboxMobileTable.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  handleUpdateClick: PropTypes.func,
  sandboxes: PropTypes.array
}

export default SandboxMobileTable;
