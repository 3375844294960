import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class AWSServicesBreadCrumbs extends Component {

  render() {
    const { id } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            { id &&
            <Link to="/awsservices/">AWS Services</Link>
            }
            { !id &&
            <span>AWS Services</span>
            }
          </div>
          { id &&
          <div className="breadcrumb">
            <span>{id}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}
