import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { MenuItem, RaisedButton, SelectField, TextField } from 'material-ui';

import { simpleSearchVip } from '../api/vipApi';
import {connect} from "react-redux";


class VipSearchSimpleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: null,
      customerNo: '',
      firstName: '',
      lastName: '',
      vipStatus: '',
      take: 10,
      searching: false
    };
  }

  componentDidMount() {
    this.setState(Object.assign({}, this.state, { siteId: this.props.siteId }));
  }

  componentWillReceiveProps(nextProps) {
    this.setState(Object.assign({}, this.state, { siteId: nextProps.siteId }));
  }

  handleCustomerNoTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { customerNo: text.length > 0 ? text : undefined }));
  };

  handleFirstNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { firstName: text.length > 0 ? text : undefined }));
  };

  handleLastNameTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { lastName: text.length > 0 ? text : undefined }));
  };

  handleVipStatusChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { vipStatus: value === 'all' ? undefined : value }));
  };

  handleEmailTextChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { email: text.length > 0 ? text : undefined }));
  };

  handleTakeChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { take: value }));
  };

  simpleSearchSubmit = () => {
    simpleSearchVip({query: this.state, offset: 0, take: this.state.take});
  };

  buildStatusMenuItems = () => {
    const menuInfo = [
      { "key": "All", "value": "all", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Accepted", "value": "accepted", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Approved", "value": "approved", "sites": ["Prana_US"] },
      { "key": "Expired", "value": "expired", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Inactive", "value": "inactive", "sites": ["Prana_US"] },
      { "key": "Pending", "value": "pending", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Rejected", "value": "rejected", "sites": ["Prana_US", "Columbia_US", "MountainHardwear_US"] },
      { "key": "Renew Accepted", "value": "renew/a", "sites": ["Columbia_US", "MountainHardwear_US"] },
      { "key": "Renew Expiring", "value": "renew/e", "sites": ["Columbia_US", "MountainHardwear_US"] },
      { "key": "Renewed", "value": "renewed", "sites": ["Prana_US"] }
    ];

    const menuItems = menuInfo.map((menuItem)=>{
      return (menuItem.sites.includes(this.state.siteId)) ? <MenuItem value={menuItem.value} primaryText={menuItem.key} /> : undefined;
    });

    return menuItems;
  }

  render() {
    return (
      <Grid fluid>
            <Row>
              <Col xs={12} md={3}>
                <TextField
                  hintText="Customer No:"
                  floatingLabelText="Customer No:"
                  onChange={this.handleCustomerNoTextChange}
                  value={this.state.customerNo}
                />
              </Col>
              <Col xs={12} md={3}>
                <TextField
                  hintText="First Name:"
                  floatingLabelText="First Name:"
                  onChange={this.handleFirstNameTextChange}
                  value={this.state.firstName}
                />
              </Col>
              <Col xs={12} md={3}>
                <TextField
                  hintText="Last Name:"
                  floatingLabelText="Last Name:"
                  onChange={this.handleLastNameTextChange}
                  value={this.state.lastName}
                />
              </Col>
              <Col xs={12} md={3}>
                <SelectField
                  floatingLabelText="Status"
                  value={this.state.vipStatus}
                  onChange={this.handleVipStatusChange}
                >
                    {this.buildStatusMenuItems()}
                </SelectField>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <TextField
                  hintText="Email"
                  floatingLabelText="Email:"
                  onChange={this.handleEmailTextChange}
                  value={this.state.email}
                />
              </Col>
              <Col xs={12} md={3}>
                <SelectField
                  floatingLabelText="Results per page"
                  value={this.state.take}
                  onChange={this.handleTakeChange}
                >
                  <MenuItem value={10} primaryText="10" />
                  <MenuItem value={25} primaryText="25" />
                  <MenuItem value={50} primaryText="50" />
                  <MenuItem value={100} primaryText="100" />
                </SelectField>
              </Col>
            </Row>
            <RaisedButton primary={true} label="Search" onClick={ this.simpleSearchSubmit }/>
        </Grid>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    siteId: store.vipState.siteId,
  };
};

export const VipSearchSimple = connect(mapStateToProps)(VipSearchSimpleContainer);
