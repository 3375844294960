import * as types from './actionTypes';

export function getVipStatsSuccess(vipStats) {
  return {
    type: types.GET_VIP_STATS_SUCCESS,
    vipStats
  };
}

export function getVipStatsFailed(error) {
  return {
    type: types.GET_VIP_STATS_FAILED,
    error
  };
}

export function simpleSearchSuccess(vipCustomers) {
    return {
        type: types.SIMPLE_SEARCH_SUCCESS,
        vipCustomers
    };
}

export function searchFailed(error) {
    return {
        type: types.SEARCH_FAILED,
        error
    };
}

export function getVipCustomerSuccess(vipCustomer) {
    return {
        type: types.GET_VIP_CUSTOMER_SUCCESS,
        vipCustomer
    }
}

export function getVipCustomerFailed(error) {
    return {
        type: types.GET_VIP_CUSTOMER_FAILED,
        error
    }
}

export function getVipCustomerMediaSuccess(media, index){
    return {
        type: types.GET_VIP_CUSTOMER_MEDIA_SUCCESS,
        media,
        index
    }
}

export function getVipCustomerMediaFailed(error){
    return {
        type: types.GET_VIP_CUSTOMER_MEDIA_FAILED,
        error
    }
}

export function deleteVipCustomerSuccess(vipCustomer) {
  return {
    type: types.DELETE_VIP_CUSTOMER_SUCCESS,
    vipCustomer
  }
}

export function deleteVipCustomerFailed(error) {
  return {
    type: types.DELETE_VIP_CUSTOMER_FAILED,
    error
  }
}

export function updateVipCustomerSuccess(vipCustomer) {
  return {
    type: types.UPDATE_VIP_CUSTOMER_SUCCESS,
    vipCustomer
  }
}

export function updateVipCustomerFailed(error) {
  return {
    type: types.UPDATE_VIP_CUSTOMER_FAILED,
    error
  }
}

export function getCustomerGroupsSuccess(customerGroups) {
  return {
    type: types.GET_CUSTOMER_GROUPS_SUCCESS,
    customerGroups
  }
}

export function getCustomerGroupsFailed(error){
  return {
    type: types.GET_CUSTOMER_GROUPS_FAILED,
    error
  }
}

export function getVipSitesSuccess(sites) {
  return {
    type: types.GET_VIP_SITES_SUCCESS,
    sites
  }
}

export function getVipSitesFailed(error) {
  return {
    type: types.GET_VIP_SITES_FAILED,
    error
  }
}

export function setVipQuery(vipQuery) {
    return {
        type: types.SET_VIP_QUERY,
        vipQuery
    }
}

export function setSiteId(siteId) {
  return {
    type: types.SET_SITE_ID,
    siteId
  }
}

export function getLoaderStatus(loading) {
  return {
    type: types.GET_LOADER_STATUS,
    loading
  };
}

export function getVipAttachmentUrlSuccess(mediaUrl) {
  return {
    type: types.GET_VIP_ATTACHMENT_URL_SUCCESS,
    mediaUrl: mediaUrl
  }
}

export function getVipAttachmentUrlFailed(error) {
  return {
    type: types.GET_VIP_ATTACHMENT_URL_FAILED,
    error
  }
}
