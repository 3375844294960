import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Avatar,
  IconButton,
  Paper,
  RaisedButton,
  TextField,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle } from 'material-ui';
import Tooltip from 'rc-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {clearEncryptedData, encrypt} from '../api/utilsApi';
import { hasRoles } from '../api/userApi';
import AdminEncryptBreadCrumbs from './AdminEncryptBreadcrumbs'
import LoaderComponent from '../components/Loader';
import {connect} from "react-redux";

class EncryptContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plainText: '',
      encryptedData: ''
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.error) {
      return alert(`Encryption failed: ${nextProps.error.message}`);
    }
    this.setState(Object.assign({}, this.state, { encryptedData: nextProps.encryptedData }));
  }

  componentDidMount () {
    clearEncryptedData();
  }

  handlePlainDataChange = (e, text) => {
    this.setState(Object.assign({}, this.state, {plainText: text}));
  };

  encryptBtnPress = () => {
    if (this.state.plainText === '') {
      return alert('Plain Text is missing.');
    }
    encrypt(this.state.plainText);
  };

  copyToClipboard = () => {
    let copyText = document.getElementById("encryptedDataTxtField");
    copyText.select();
    document.execCommand("copy");
  };

  render() {
    return (
      <div>
        <AdminEncryptBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text="AWS KMS Encrypt" />
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
            { hasRoles('write:kmsencrypt') && (
              <RaisedButton
                label="Encrypt"
                primary={true}
                onClick={ () => this.encryptBtnPress()}
              />
            )}
            </ToolbarGroup>
          </Toolbar>
          <Row style={{ padding: "24px" }}>
            <Col xs={12} md={5}>
              <TextField
                floatingLabelText="Plain Text"
                multiLine={true}
                fullWidth={true}
                rows={5}
                rowsMax={20}
                hintText="Enter plain text to encrypt"
                onChange={this.handlePlainDataChange}
                value={this.props.plainData} />
            </Col>
            <Col xs={12} md={5}>
              <TextField
                id="encryptedDataTxtField"
                floatingLabelText="Encrypted Data"
                multiLine={true}
                fullWidth={true}
                rows={5}
                rowsMax={20}
                value={this.props.encryptedData}  />
              </Col>
              <Col xs={12} md={2}>
              <Tooltip
                  animation="zoom"
                  trigger="click"
                  placement="right"
                  overlay="Copied"
                >
                  <IconButton style={{padding:'0px'}}>
                    <Avatar
                      icon={<FontAwesomeIcon icon="clone" fixedWidth/>}
                      color='#A9A9A9'
                      backgroundColor='#FFFFFF'
                      onClick={() => this.copyToClipboard()}
                    />
                  </IconButton>
                </Tooltip>
            </Col>
          </Row>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    encryptedData: store.utilsState.encryptedData
  };
};

export default connect(mapStateToProps)(EncryptContainer);
