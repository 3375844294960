import React from 'react';
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ToolbarDynamic = (props) => {
  return(
    <Toolbar style={props.style}>
      <ToolbarGroup>
        { props.icon &&
        <FontAwesomeIcon icon={props.icon} size="2x" fixedWidth style={props.iconStyle}/>
        }
        <ToolbarTitle text={props.title}/>
      </ToolbarGroup>
    </Toolbar>
  );
};
