import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { PageHeader } from '../Components';
import GetAzureADUrl from './AzureADEncode';

export default function Login(props) {
  GetAzureADUrl((err, url) => {
    console.log("Redirect to " + url);
    window.location.assign(url);
  });

  return (
    <Row>
      <Col xs={12} md={12}>
        <PageHeader>Login</PageHeader>
      </Col>
    </Row>
  )
};
