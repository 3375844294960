import * as types from '../actions/actionTypes';

const initialState = {
    dashboardInfo: null
};

const dashboardReducer = function(state = initialState, action) {
  switch(action.type) {
    case types.GET_ENVIRONMENT_SUCCESS:
      return Object.assign({}, state, { dashboardInfo: action.data });

    
    default:
      return state;
  }
};

export default dashboardReducer;
