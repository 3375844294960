import React, { Component } from 'react';
import { Paper } from 'material-ui';

import InventoryBreadCrumbs from './InventoryBreadCrumbs';
import InventoryRecordsSearch from './InventoryRecordsSearch';
import { InventoryRecordsSearchResults } from './InventoryRecordsSearchResults';

export const styles = {};


class InventoryRecords extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '20px' }}>
        <InventoryBreadCrumbs records={true} />
        <Paper>
          <InventoryRecordsSearch />
          <InventoryRecordsSearchResults />
        </Paper>
      </div>
    );
  }
}

export default InventoryRecords;
