import React from 'react';
import Coupons from './Coupons';
import CouponDetails from './CouponDetails';
import { Route, Switch } from 'react-router-dom'

const CouponRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/coupons" component={Coupons}/>
      <Route path="/coupons/:id" component={CouponDetails}/>
    </Switch>
  </div>
);

export default CouponRoutes;
