import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropDownMenu, MenuItem, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';
import { ToolbarDynamic } from '../components/ToolbarDynamic';
import { getVipSites, getVipStats, clearStats, setCurrentSite, clearSearchVip } from '../api/vipApi';
import {Grid, Row, Col} from "react-flexbox-grid";

export const styles = {
  container: {
    padding: '20px'
  },
  toolbarMargin: {
    marginTop: '40px'
  },
  search: {
    marginRight: '10px',
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '25px'
  }
};


class VipStatsContainer extends Component {
  componentDidMount() {
    clearStats();
    if (!this.props.vipSites)
      getVipSites();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vipSites && (!nextProps.vipStats) && (!nextProps.vipSitesFailed)){
      if(this.props.siteId)
        getVipStats(this.props.siteId);
      else if(nextProps.vipSites.length){
        setCurrentSite(nextProps.vipSites[0].name);
        getVipStats(nextProps.vipSites[0].name);
      }
    }

    if( nextProps.vipSitesFailed || nextProps.vipStatsFailed){
        let errorMsg = "";
        if(nextProps.vipSitesFailed)
            errorMsg = `Unable to retrieve siteId's: ${nextProps.vipSitesFailed.message}\n`;
        if(nextProps.vipStatsFailed)
            errorMsg = errorMsg + `Unable to retrieve Vip Stats: ${nextProps.vipStatsFailed.message}\n`;  //eslint-disable-line operator-assignment
        alert(errorMsg);
    }
  }

  onSiteIdChange = (event, index, value) => {
    setCurrentSite(value);
    getVipStats(value);
    clearSearchVip();
  };

  showTable = () => {
    let tableItems;
    if (this.props.vipStats.length > 0)
      tableItems = this.props.vipStats.map((stat,index) => {
        return (
            <TableRow key={index}>
                <TableRowColumn>{ stat._id }</TableRowColumn>
                <TableRowColumn>{ stat.count }</TableRowColumn>
            </TableRow>
        );
    });
    else{
      tableItems = <TableRow><TableRowColumn>No Results</TableRowColumn></TableRow>;
    }
    return (
        <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>Status</TableHeaderColumn>
                <TableHeaderColumn>Total</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
                {tableItems}
            </TableBody>
        </Table>
    );
  };

  getDropdownItems = () => {
    if (this.props.vipSites) {
      return this.props.vipSites.map((vipSite, index) => {
        return <MenuItem key={index} value={vipSite.id} primaryText={vipSite.name}/>
      });
    }
  };

  render() {
    let table = this.props.vipStats ? this.showTable() : <div></div>;
    let title = this.props.siteId === "Prana_US" ? "Influencer Statistics" : "Pro Purchase Statistics";
    let title_search = this.props.siteId === "Prana_US" ? "Influencer Search" : "Pro Purchase Search";
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <DropDownMenu
                style={styles.search}
                value={this.props.siteId}
                onChange={this.onSiteIdChange}>
                { this.getDropdownItems() }
              </DropDownMenu>
            </Col>
          </Row>
        </Grid>
        <ToolbarDynamic title={title} />
        <Grid fluid>
          <Row>
            <Col xs={12}>
          { table }
            </Col>
          </Row>
        </Grid>
        <ToolbarDynamic title={title_search} style={styles.toolbarMargin} />
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    vipSites: store.vipState.vipSites,
    vipSitesFailed: store.vipState.vipSitesFailed,
    vipStats: store.vipState.vipStats,
    vipStatsFailed: store.vipState.vipStatsFailed,
    siteId: store.vipState.siteId
  };
};

export const VipStats = connect(mapStateToProps)(VipStatsContainer);
