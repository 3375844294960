import React from 'react';
import IdManagement from '../id-management/IdManagement';
import IdManagementUserDetails from '../id-management/IdManagementUserDetails';
import { Route, Switch } from 'react-router-dom'

const IdManagementRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/id-management" component={IdManagement}/>
      <Route path="/id-management/:id" component={IdManagementUserDetails}/>
    </Switch>
  </div>
);

export default IdManagementRoutes;
