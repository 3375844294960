import { azureAD } from '../config';
import saml from 'saml-encoder-decoder-js';

export default function GetAzureADUrl(callback) {
  console.log("Auth url is: " + azureAD.authUrl);
  console.log("Saml request is: " + azureAD.samlRequest);
  saml.encodeSamlRedirect(azureAD.samlRequest, (err, encoded) => {
    if (err) {
      console.error(`Error encoding samlRequest for AzureAD: ${err}`);
      return callback(err);
    }

    let url = `${azureAD.authUrl}?SAMLRequest=${encoded}`;
    callback(null, url);
  });
};
