import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import {SelectField, MenuItem, Paper, RaisedButton, Toolbar, ToolbarGroup, ToolbarTitle} from 'material-ui';
import { getSwaggerUrls } from '../api/utilsApi';
import {Grid, Col, Row} from 'react-flexbox-grid';
import CoverageBreadCrumbs from './CoverageBreadCrumbs';
import LoaderComponent from '../components/Loader';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from "material-ui/styles/colors";
import {restBaseApi} from "../config";

export const styles = {
  container: {
    padding: '0 10px 10px 10px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

class CoverageContainer extends Component {
  constructor(props) {
    super(props);
    this.viewReport = this.viewReport.bind(this);
    this.state = {
      swaggerUrls: [],
      selectedServiceName: '',
      serviceUrl: '',
      response: ''
    };
  }

  componentDidMount = async () => {
    getSwaggerUrls();
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.swaggerUrls) {
      this.setState(Object.assign({}, this.state, { swaggerUrls: nextProps.swaggerUrls, token: nextProps.token }));
    }
  }

  handleServiceChange = (event, index, value) => {
    const url = `${restBaseApi}/codecoverage/${this.state.selectedServiceName}/index.html`;
    this.setState(Object.assign({}, this.state, { selectedServiceName: value, serviceUrl: url }));
  };

  renderServiceOptions () {
    if (this.state.swaggerUrls && this.state.swaggerUrls.length) {
      return this.state.swaggerUrls.map((s) => {
        const item = JSON.parse(s.value);
        return (
          <MenuItem key={s.id} value={item.name}>{item.name}</MenuItem>
        );
      });
    }
  }

  refreshBtnSubmit = async () => {
    await getSwaggerUrls();
  };

  viewReport () {
    window.open(this.state.serviceUrl, '_blank');
  }

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <CoverageBreadCrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <FontAwesomeIcon icon={["far", "file-code"]} size="2x" fixedWidth style={styles.icon}/>
              <ToolbarTitle text="AWS Code Coverage" />
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton
                primary={true}
                label="View Report"
                onClick={ this.viewReport }
              />
              <RaisedButton
                label="Refresh"
                onClick={this.refreshBtnSubmit} />
            </ToolbarGroup>
          </Toolbar>
          <Grid style={styles.container}>
            <Row>
              <Col xs={4}>
                <SelectField
                  floatingLabelText={this.state.selectedServiceName}
                  hintText="Select Service"
                  value={this.state.selectedServiceName}
                  onChange={this.handleServiceChange}
                  fullWidth={true}
                >
                  < MenuItem value={'none'} primaryText="None Selected" />
                  {this.renderServiceOptions()}
                </SelectField>
              </Col>
            </Row>
          </Grid>
        </Paper>
      </div>
    )
  }
}

CoverageContainer.propTypes = {
  swaggerUrls: PropTypes.array,
};

const mapStateToProps = function(store) {
  return {
    swaggerUrls: store.utilsState.swaggerUrls
  };
};

export default connect(mapStateToProps)(CoverageContainer);
