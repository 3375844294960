import React from 'react';
import NotifyMe from './NotifyMe';
import { Route, Switch } from 'react-router-dom'

const NotifyMeRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/notifyme" component={NotifyMe}/>
    </Switch>
  </div>
);

export default NotifyMeRoutes;
