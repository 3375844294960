import {connect} from "react-redux";
import {Component} from "react";
import { PropTypes } from "prop-types";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AWSServicesBreadCrumbs from "./AWSServicesBreadCrumbs";
import { Paper, RaisedButton, Table, TableBody, TableRow, TableRowColumn, Toolbar,
  TableHeader, TableHeaderColumn, ToolbarGroup, ToolbarTitle } from "material-ui";
import { clearAWSServices, getAWSServices, setAWSServices } from "../api/utilsApi";
import LoaderComponent from '../components/Loader';

export const styles = {
  loader: {
    margin: '20px 0 20px 0'
  },
  toolBar: {
    marginBottom: '20px'
  },
  container: {
    padding: '10px'
  },
  tableRowColumn: {
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
};

class AWSServicesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      awsServices: []
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(Object.assign({}, this.state, { awsServices: nextProps.awsServices, loading: nextProps.loading }));
  }

  componentDidMount() {
    if (!this.props.awsServices) {
      clearAWSServices();
      getAWSServices(this.state.id);
    } else {
      const service = this.props.awsServices.filter(s => s.info.serviceName === this.state.id);
      setAWSServices(service);
    }
  }

  showServiceStatus () {
    if (this.props.awsServices && this.props.awsServices[0]
      && this.props.awsServices[0].serviceStatus && this.props.awsServices[0].serviceStatus.length) {
      return this.props.awsServices[0].serviceStatus.map((s,i) => {
        return (
          <TableRow key={i}>
            <TableRowColumn style={styles.tableRowColumn}><div>{ s.url }</div></TableRowColumn>
            <TableRowColumn style={styles.tableRowColumn}>
              <div>
                <FontAwesomeIcon icon={s.display.icon} size='2x' fixedWidth style={s.display.iconStyle}/>{ ' ' + s.status + ': ' + s.statusText }
              </div>
            </TableRowColumn>
            <TableRowColumn style={styles.tableRowColumn}><div dangerouslySetInnerHTML={s.data}></div></TableRowColumn>
          </TableRow>
        )
      })
    }
  }

  refreshBtnSubmit = () => {
    clearAWSServices();
    getAWSServices(this.state.id);
  };

  render () {
    const tableResults = this.showServiceStatus();
    return (
      <div>
        <AWSServicesBreadCrumbs id={this.state.id} />
        <Paper>
          <Toolbar>
            <ToolbarGroup firstChild={false}>
              <ToolbarTitle text="AWS Service Details"/>
            </ToolbarGroup>
            <ToolbarGroup>
              <RaisedButton primary={true} label="Refresh" onClick={this.refreshBtnSubmit} />
            </ToolbarGroup>
          </Toolbar>
          <div style={{padding: '10px'}}>
            <Table>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn style={styles.tableRowColumn}>Url</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Status</TableHeaderColumn>
                  <TableHeaderColumn style={styles.tableRowColumn}>Message</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                {this.props.loading ? (
                  <TableRow>
                    <TableRowColumn colSpan={3}>
                      <LoaderComponent/>
                    </TableRowColumn>
                  </TableRow>) : (tableResults)}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }

}

AWSServicesDetails.propTypes = {
  awsServices: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const mapStateToProps = function(store) {
  return {
    awsServices: store.utilsState.awsServices,
    error: store.utilsState.awsServicesFailed,
    loading: store.utilsState.loading
  };
};

export default connect(mapStateToProps)(AWSServicesDetails);
