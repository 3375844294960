import moment from 'moment';

export const numberFormat = (value) => {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
};

export const dateFormat = (value, format) => {
  return moment(value).format(format);
};

export const dateFormatUTC = (value, format) => {
  return moment.utc(value).format(format);
};

export const dateFromNowFormat = (value, defaultValue = "never") => {
  return value ? moment(value).fromNow() : defaultValue;
};
