import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import {MenuItem, RaisedButton, SelectField} from 'material-ui';
import { getServicesList, getServiceConfiguration } from "../api/utilsApi";
import LoaderComponent from '../components/Loader';
import ReactJson from "react-json-view";
import {ToolbarDynamic} from "../components/ToolbarDynamic";

export const styles = {
  container: {
    padding: '10px'
  },
};

class AWSServicesConfigurationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicesList: [],
      selectedServiceId: '',
      serviceConfiguration: {}
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error)
      alert(`Get service configuration failed: ${nextProps.error.message}`);
    if (nextProps.servicesList)
      this.setState(Object.assign({}, this.state, { servicesList: nextProps.servicesList }));
    if (nextProps.serviceConfiguration)
      this.setState(Object.assign({}, this.state, { serviceConfiguration: nextProps.serviceConfiguration }));
  }

  componentDidMount () {
    getServicesList();
  }

  refreshBtnSubmit = () => {
    if (this.state.selectedServiceId) {
      getServiceConfiguration(this.state.selectedServiceId);
    }
  };

  renderServicesList () {
    if (this.state.servicesList && this.state.servicesList.length) {
      return this.state.servicesList.map(s => {
        return (
          <MenuItem key={s.id} value={s.id}>{s.id}</MenuItem>
        );
      });
    }
  }

  handleServiceChange = (event, index, value) => {
    this.setState(Object.assign({}, this.state, { selectedServiceId: value }));
  };

  render() {
    return (
      <div>
        <Row style={styles.container}>
          <Col xs={12} md={4}>
            <SelectField
              floatingLabelText={this.state.selectedServiceId}
              hintText="Select Service"
              value={this.state.selectedServiceId}
              onChange={this.handleServiceChange}
              fullWidth={true}
            >
              < MenuItem value={'none'} primaryText="None Selected" />
              {this.renderServicesList()}
            </SelectField>
          </Col>
          <Col xs={12} md={4}>
            <LoaderComponent />
          </Col>
          <Col xs={12} md={4} style={{textAlign: "right"}}>
            <RaisedButton
              primary={true}
              label="Get Configuration"
              onClick={this.refreshBtnSubmit} />
          </Col>
        </Row>
        <ToolbarDynamic title={`Service: ${this.state.selectedServiceId}`}/>
        <Row style={styles.container}>
          <Col xs={12}>
            <ReactJson src={(this.state.serviceConfiguration ? this.state.serviceConfiguration : { })}/>
          </Col>
        </Row>
      </div>
    );
  }
}

AWSServicesConfigurationContainer.propTypes = {
  servicesList: PropTypes.array,
  servicesConfiguration: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const mapStateToProps = function(store) {
  return {
    servicesList: store.utilsState.servicesList,
    serviceConfiguration: store.utilsState.serviceConfiguration,
    error: store.utilsState.searchFailed,
    loading: store.utilsState.loading
  };
};

export const AWSServicesConfiguration = connect(mapStateToProps)(AWSServicesConfigurationContainer);
