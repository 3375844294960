import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card, CardHeader, CardText, CardActions, Divider } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green400, red400 } from 'material-ui/styles/colors';
import { notlivereportRestApi } from '../config';

import { numberFormat, dateFormat } from '../filters';
import GetNotLiveReportFileButton from "./GetDownloadButton";

export const styles = {
  blockCard : {
    marginTop: '10px',
    marginBottom: '10px',
  }
};

export default class BlockCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
    };
    this.getBlockStatus = this.getBlockStatus.bind(this);
  }

  getBlockStatus() {
    let self = this;
    notlivereportRestApi.get('/notlivereportCodeBlocks/' + this.props.id).then(({data}) => {
      if (data.status === 'ready') {
        //update state to ready
        self.setState({status: data.status});
        clearInterval(this.checkStatus);
        this.props.onRefresh && this.props.onRefresh();
      }
    }).catch((error) => {
      console.log("Get notlivereport failed: " + error);
      clearInterval(this.checkStatus);
    });
  }

  componentDidMount() {
    // check if ready
    if (this.state.status !== 'ready' && this.state.status !== 'error') {
      // if not ready, check status every two seconds
      this.checkStatus = setInterval(
        () => this.getBlockStatus(), //getBlockStatus will clearInterval if status is ready
        2000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkStatus); //stop checking status when component unmounts
  }

  static propTypes = {
    status: PropTypes.string.isRequired,
    description: PropTypes.string,
    numCodes: PropTypes.number.isRequired,
    isDefinition: PropTypes.bool.isRequired
  };

  render(){
    const { id, description, numCodes, createdBy, isDefinition, requestedBy, dateCreated, issueId } = this.props;
    let title;
    if (isDefinition)
      title = `${numberFormat(numCodes)} codes`;// generated on ${dateFormat(date, 'l')}`;
    else
      title = `${numberFormat(numCodes)} codes for ${requestedBy}`;
    return(
      <Card style={styles.blockCard} expandable={true}>
        <CardHeader
          title={title}
          avatar={
            <Avatar
              icon={
                <FontAwesomeIcon icon={this.state.status === "ready" ? "check" : (this.state.status === "error" ? "close" : "sync")} fixedWidth spin={this.state.status === "initializing"}/>
              }
              size={30}
              backgroundColor={this.state.status === "ready" ? green400 : (this.state.status === "error" ? red400 : '')}
            />
          }
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText expandable={true}>
          { description &&
            <p>{description}</p>
          }
          { description &&
          <Divider />
          }
          <p>Created by {createdBy} on {dateFormat(dateCreated, 'l LTS')}</p>

          { issueId &&
          <p><b>JIRA Issue:</b> <a href={"https://columbia.atlassian.net/browse/" + issueId} target="_blank">{issueId}</a></p>
          }
        </CardText>
        <CardActions expandable={true}>
          { isDefinition &&
            <GetNotLiveReportFileButton status={this.state.status} type="import" id={id}/>
          }
          <GetNotLiveReportFileButton status={this.state.status} type="list" id={id}/>
        </CardActions>
      </Card>
    )
  }
}
