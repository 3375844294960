import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class CloudwatchBreadCrumbs extends Component {

  render() {
    const { cloudwatch } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>

          <div className="breadcrumb">
            { cloudwatch &&
            <Link to="/cloudwatch">AWS Cloudwatch Logs</Link>
            }
            { !cloudwatch &&
            <span>Cloudwatch Logs</span>
            }
          </div>
          { cloudwatch &&
            <div className="breadcrumb">
              <span>{cloudwatch.name}</span>
            </div>
          }
        </div>
      </nav>
    )
  }
}

CloudwatchBreadCrumbs.propTypes = {
  cloudwatch: PropTypes.object
};
