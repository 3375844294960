import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelForm from './ModelForm';
import UpdateModelForm from './UpdateModelCodes';
import { Route } from 'react-router-dom';
import PhotoStudioMianView from './PhotoStudioMainView';
class PhotoStudio extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  };

  render() {
    return (
      <div>
          <Route exact path='/photostudio' component={PhotoStudioMianView} />
          <Route exact path='/photostudio/add' render={(props)=> <ModelForm {...props}   type="Add" />} />
          <Route exact path='/photostudio/edit' render={(props)=> <UpdateModelForm {...props} type="Edit" />} />
          <Route path='/photostudio/view/:id' render={
            (props)=> <UpdateModelForm {...props} type="View" />
            } />
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {

  };
};

export default connect(mapStateToProps)(PhotoStudio);
