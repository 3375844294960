import React from 'react';
import UserDetails from './UserDetails';
import { Route, Switch } from 'react-router-dom'

const UserRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/updateProfile/:id" component={UserDetails}/>
    </Switch>
  </div>
);

export default UserRoutes;