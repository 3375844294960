import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Nav from './_nav/components/Nav';
import columbiaTheme from './themes/columbiaTheme';
import { Grid } from 'react-flexbox-grid';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isLoggedIn: true,
      isAdmin: true
    };

    library.add(fas, far, fab);
  }

  render() {
    return (
      <ThemeProvider theme={createMuiTheme(columbiaTheme.updatedTheme)}>
        <MuiThemeProvider muiTheme={getMuiTheme(columbiaTheme.defaultTheme)}>
          <div>
            <Nav/>
            <Grid fluid>
              {this.props.children}
            </Grid>
          </div>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

export default MainLayout;
