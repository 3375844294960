import React from 'react';
import Translator from './Translator';
import { Route, Switch } from 'react-router-dom'

const TranslatorRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/translator" component={Translator}/>
    </Switch>
  </div>
);

export default TranslatorRoutes;
