import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, ListItemIcon, MenuItem,  Toolbar, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { appTitle } from '../../config';
import DrawerList from './drawer/DrawerList';
import NavMenu from './menu/NavMenu';
import useStyles from '../styles/NavBarStyles';

export function NavBar({ profile }) {
  const classes = useStyles();

  return (
    <Fragment>
      <AppBar position='static'>
        <Toolbar>
          <Box className={classes.box}>
          <IconButton edge='start' className={classes.menuButton} aria-label='menu'>
            <DrawerList />
          </IconButton>
          <Typography variant='h5'>
            {appTitle}
          </Typography>
          </Box>
          { profile.isLoggedIn ? (
          <NavMenu profile={profile} classes={classes} />
          ) : (
          <Toolbar>
            <NavLink exact to='/login' className={classes.link}>
              <MenuItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.icon} icon='sign-in-alt' fixedWidth/>
                </ListItemIcon>
                <Typography className={classes.secondaryText}>
                  Login
                </Typography>
              </MenuItem>
            </NavLink>
          </Toolbar>
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

const mapStateToProps = function(store) {
  return {
    profile: store.userState.profile
  };
};

NavBar.propTypes = {
  profile: PropTypes.object
}

export default connect(mapStateToProps)(NavBar);
