import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class MismatchedQtyBreadcrumbs extends Component {

  static propTypes = {
    notlivereport: PropTypes.object
  };

  render() {
    const { notlivereport } = this.props;
    return (
      <nav>
        <div className="breadcrumbs">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
          </div>
          <div className="breadcrumb">
            { notlivereport &&
            <Link to="/notlivereport">NotLiveReports</Link>
            }
            { !notlivereport &&
            <span>NotLiveReports</span>
            }
          </div>
          { notlivereport &&
          <div className="breadcrumb">
            <span>{notlivereport.name}</span>
          </div>
          }
        </div>
      </nav>
    )
  }
}
