import store from "../services/store";
import { searchNotifyMeRecordsSuccess, searchtNotifyMeRecordsFailed, getNotifyMeSitesSuccess, getNotifyMeSitesFailed, getNotifyMeStatsSuccess, getNotifyMeStatsFailed } from "../actions/notifyMeActions";
import { notifyMeRestApi } from "../config";
import { setNotifyMeSiteId } from "../actions/notifyMeActions";
import { setNotifyMeItemsPerPage } from "../actions/notifyMeActions";
import { getLoaderStatus } from "../actions/utilsActions";

function setLoadingStatus (loading) {
  store.dispatch(getLoaderStatus(loading))
}

export function clearNotifyMeRecords () {
  store.dispatch(searchNotifyMeRecordsSuccess([]));
}

export function searchNotifyMeRecords(siteId, status) {
  setLoadingStatus(true);
  return notifyMeRestApi.get(`/notifyme/search/${siteId}/status/${status}`).then(function({data}) {
    store.dispatch(searchNotifyMeRecordsSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    console.error("Get Notify Me records failed: " + error);
    store.dispatch(searchtNotifyMeRecordsFailed(error));
    setLoadingStatus(false);
  });
}

export function getNotifyMeSites() {
  return notifyMeRestApi.get('/notifyme/sites').then(function({data}) {
    store.dispatch(getNotifyMeSitesSuccess(data));
  }).catch(function (error) {
    store.dispatch(getNotifyMeSitesFailed(error));
  });
}

export function setCurrentNotifyMeSite(siteId) {
  store.dispatch(setNotifyMeSiteId(siteId));
}

export function storePerPage(perPage) {
  store.dispatch(setNotifyMeItemsPerPage(perPage))
}

export function getNotifyMeStats(siteId) {
  setLoadingStatus(true);
  return notifyMeRestApi.get(`/notifyme/stats/${siteId}`).then(function({data}) {
    store.dispatch(getNotifyMeStatsSuccess(data));
    setLoadingStatus(false);
  }).catch(function (error) {
    store.dispatch(getNotifyMeStatsFailed(error));
    setLoadingStatus(false);
  });
}

