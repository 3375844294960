import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  Avatar,
  IconButton,
  Paper,
  RaisedButton,
  TextField,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle
} from 'material-ui';
import Tooltip from 'rc-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {clearDecryptedData, decrypt} from '../api/utilsApi';
import { hasRoles } from '../api/userApi';
import AdminDecryptBreadcrumbs from './AdminDecryptBreadcrumbs'
import LoaderComponent from "../components/Loader";
import {connect} from "react-redux";

class DecryptContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plainData: '',
      encryptedData: ''
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.error) {
      return alert(`Decryption failed: ${nextProps.error.message}`);
    }
    this.setState(Object.assign({}, this.state, { plainData: nextProps.plainData }));
  }

  componentDidMount () {
    clearDecryptedData();
  }

  handleEncryptedDataChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { encryptedData: text }));
  };

  decryptBtnPress = () => {
    if (this.state.encryptedData === '') {
      return alert('Encrypted Data is missing.');
    }
    decrypt(this.state.encryptedData);
  };

  copyToClipboard = () => {
    let copyText = document.getElementById("plainDataTxtField");
    copyText.select();
    document.execCommand("copy");
  };

  render() {
    return (
      <div>
        <AdminDecryptBreadcrumbs />
        <Paper>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text="AWS KMS Decrypt" />
            </ToolbarGroup>
            <ToolbarGroup>
              <LoaderComponent />
            </ToolbarGroup>
            <ToolbarGroup>
              { hasRoles('write:kmsdecrypt') && (
                <RaisedButton
                label="Decrypt"
                primary={true}
                onClick={ () => this.decryptBtnPress()} />
              )}
            </ToolbarGroup>
          </Toolbar>
          <Grid fluid>
            <Row style={{ padding: "24px" }}>
              <Col xs={12} md={5}>
                <TextField
                  floatingLabelText="Encrypted Data"
                  multiLine={true}
                  fullWidth={true}
                  rows={5}
                  rowsMax={20}
                  hintText="Post encrypted text to decrypt"
                  onChange={this.handleEncryptedDataChange}
                  value={this.state.encryptedData} />
              </Col>
              <Col xs={12} md={5}>
                <TextField
                  id="plainDataTxtField"
                  floatingLabelText="Decrypted Data"
                  multiLine={true}
                  fullWidth={true}
                  rows={5}
                  rowsMax={20}
                  value={this.state.plainData}  />
              </Col>
              <Col xs={12} md={2}>
                <Tooltip
                    animation="zoom"
                    trigger="click"
                    placement="right"
                    overlay="Copied"
                  >
                  <IconButton style={{padding:'0px'}}>
                    <Avatar
                      icon={<FontAwesomeIcon icon="clone" fixedWidth/>}
                      color='#A9A9A9'
                      backgroundColor='#FFFFFF'
                      // onClick={() => this.copyToClipboard()}
                    />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>
          </Grid>
        </Paper>
      </div>
    );
  }
}


const mapStateToProps = function(store) {
  return {
    plainData: store.utilsState.plainData
  };
};

export default connect(mapStateToProps)(DecryptContainer);
