import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

function FlexibleSnackBar({ snack }) {
  const defaultSnack = {
    open: false,
    message: '',
    severity: 'info' // cannot be an empty string
  }
  const [flexibleSnack, setFlexibleSnack] = useState(snack || defaultSnack);

  const handleSnackClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFlexibleSnack(defaultSnack)
  }

  useEffect(() => {
    setFlexibleSnack(snack)
  },[snack])

  return (
    <Snackbar open={flexibleSnack.open} autoHideDuration={4000} onClose={handleSnackClose}>
      <Alert onClose={handleSnackClose} severity={flexibleSnack.severity}>
        {flexibleSnack.message}
      </Alert>
    </Snackbar>
  )
}

FlexibleSnackBar.propTypes = {
  handleSnackClose: PropTypes.func,
  snack: PropTypes.object
}

export default FlexibleSnackBar;
