import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function SandboxBreadCrumbs({ sandbox }) {
  return (
    <nav>
      <div className='breadcrumbs'>
        <div className='breadcrumb'>
          <Link to='/'>Home</Link>
        </div>
        <div className='breadcrumb'>
          { sandbox &&
          <Link to='/sandboxes'>SFCC On-Demand Sandbox Manager</Link>
          }
          { !sandbox &&
          <span>Sandboxes</span>
          }
        </div>
        { sandbox &&
        <div className='breadcrumb'>
          <span>{sandbox.name}</span>
        </div>
        }
      </div>
    </nav>
  )
}

SandboxBreadCrumbs.propTypes = {
  sandbox: PropTypes.object
};

export default SandboxBreadCrumbs;
