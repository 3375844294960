import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {red500, green500, yellow800, grey700, grey400, grey500} from 'material-ui/styles/colors';
import { Paper, Toolbar, ToolbarGroup, ToolbarTitle, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, IconButton, Avatar, Snackbar, TextField } from 'material-ui';
import { restApi } from '../config';
import { hasRoles } from '../api/userApi';
import CheckCircleIcon from 'material-ui/svg-icons/action/check-circle';
import HighlightOffIcon from 'material-ui/svg-icons/action/highlight-off';
import HelpOutlineIcon from 'material-ui/svg-icons/action/help-outline';
import AdminUserBreadCrumbs from "./AdminUserBreadcrumbs";
import AdminUserCreate from './AdminUserCreate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const styles = {
  icon: {
    marginRight: '20px',
    color: grey500
  },
  userEmail: {
    width: '200px',
    fontSize: '12px'
  }
};

class AdminUsersContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      users: [],
      generateSelected: null,
      snackbarOpen: false,
      snackBarMessage: '',
      selectedEmail: ''
    };
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.refreshList();
  }

  handleEmailSearchOnChange = (e, text) => {
    this.setState(Object.assign({}, this.state, { selectedEmail: text.length > 0 ? text : undefined }));
  };

  // equivalent of pipe/filter in Angular
  number = (value) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
  };

  AdminUserActiveIcon = (user) => {
    if (user.active === true)
      return (<CheckCircleIcon color={green500}/>);
    if (user.active === false)
      return (<HighlightOffIcon color={red500}/>);
    return (<HelpOutlineIcon color={yellow800}/>);
  };

  copyTokenToClipboard = (inputElementId) => {
    let copyText = document.getElementById(inputElementId);  // Get the hidden text field positioned way off screen
    copyText.select();
    document.execCommand("copy");
  };

  generateToken = (user) => {
    this.setState({ generateSelected: user.email});
    restApi.put(`/users/${user.email}/token`).then(({data}) => {
      this.refreshList();
      this.setState(Object.assign( {}, this.state, { generateSelected: null, snackBarMessage: "JWT Generated", snackbarOpen: true }));
    }).catch((error) => {
      this.setState({ generateSelected: null})
      alert("Generate token failed: " + error);
    });
  };

  deleteToken = (user) => {
    restApi.delete(`/users/${user.email}/token`).then(({data}) => {
      this.refreshList();
      this.setState(Object.assign( {}, this.state, { generateSelected: null, snackBarMessage: "JWT Deleted", snackbarOpen: true }));
    }).catch((error) => {
      this.setState({ generateSelected: null})
      alert("Generate token failed: " + error);
    });
  };

  AdminUserItem = ( user ) => {
    let threshold = new Date();
    threshold.setDate(threshold.getDate() + 30);
    let jwtExpDate = new Date(user.jwtExpirationDate);
    let expiring = jwtExpDate < threshold;
    let expiringColor = expiring ? { color: 'red'} : { color : "black"};
    let hasUserTokensPermissions = hasRoles('admin:usertokens');
    let showGenerateBtn = hasUserTokensPermissions && (expiring || !user.jwt);
    return (
      <TableRow key={user.email}>
        <TableRowColumn>
          <Link to={"/admin/users/" + user.email} style={{width:'250px'}}>
            { user.email }
          </Link>
        </TableRowColumn>
        <TableRowColumn>{user.firstName} {user.lastName}</TableRowColumn>
        <TableRowColumn>{user.slackId}</TableRowColumn>
        <TableRowColumn>{user.timezone}</TableRowColumn>
        <TableRowColumn>
          { this.AdminUserActiveIcon(user) }
        </TableRowColumn>
        <TableRowColumn>
          {user.jwt &&
          <div>
            <div style={{width: '100px', display: 'inline', padding: '0 10px 0 0'}}>{'****'}</div>
            { hasUserTokensPermissions &&
            <div style={{width: '100px', display: 'inline', padding: '0'}}>
              <input id={`tokenInput_${user.email}`} style={{position: 'absolute', left: "-999px"}} value={user.jwt} onChange={() => {}}/>
              <Tooltip
                animation="zoom"
                trigger="click"
                placement="right"
                overlay="Copied"
              >
                <IconButton style={{padding:'0px'}}>
                  <Avatar
                    icon={<FontAwesomeIcon icon={["far", "clone"]} fixedWidth/>}
                    color={grey700}
                    backgroundColor='#FFFFFF'
                    onClick={() => this.copyTokenToClipboard(`tokenInput_${user.email}`)}
                  />
                </IconButton>
              </Tooltip>
            </div>
            }
          </div>
          }
        </TableRowColumn>
        <TableRowColumn style={expiringColor}>
          {user.jwtExpirationDate}
        </TableRowColumn>
        <TableRowColumn>
          { showGenerateBtn ?
            <Tooltip
              animation="zoom"
              trigger="hover"
              placement="left"
              overlay="Generate JWT"
            >
              <IconButton style={{padding: '0px'}} disabled={this.state.generateSelected === user.email}>
                <Avatar
                  icon={<FontAwesomeIcon icon="plus-square" fixedWidth/>}
                  color={grey400}
                  backgroundColor='#FFFFFF'
                  onClick={() => this.generateToken(user)}
                />
              </IconButton>
            </Tooltip>
            :
            <div>
              <Tooltip
                animation="zoom"
                trigger="hover"
                placement="left"
                overlay="Delete JWT"
              >
                <IconButton style={{padding: '0px'}} disabled={this.state.generateSelected === user.email}>
                  <Avatar
                    icon={<FontAwesomeIcon icon="minus-square" fixedWidth/>}
                    color={grey400}
                    backgroundColor='#FFFFFF'
                    onClick={() => this.deleteToken(user)}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                animation="zoom"
                trigger="hover"
                placement="left"
                overlay="Regenerate JWT"
              >
                <IconButton style={{padding: '0px'}} disabled={this.state.generateSelected === user.email}>
                  <Avatar
                    icon={<FontAwesomeIcon icon="pen-square" fixedWidth/>}
                    color={grey400}
                    backgroundColor='#FFFFFF'
                    onClick={() => this.generateToken(user)}
                  />
                </IconButton>
              </Tooltip>
            </div>
          }
        </TableRowColumn>
      </TableRow>
    )
  };

  AdminUserList = (onRefresh, users) => {
    const selctedEmail = this.state.selectedEmail;
    let userObj;
    if (this.state.selectedEmail) {
      userObj = users.filter((user) => {
        if (user.email.startsWith(selctedEmail.toLowerCase())) {
          return user;
        }
        return false;
      });
    } else {
      userObj = users;
    }
    const adminUserList = userObj.map(this.AdminUserItem);
    return (
      <Paper>
        <Toolbar>
          <ToolbarGroup firstChild={false}>
            <FontAwesomeIcon icon="users" size="2x" fixedWidth style={styles.icon}/>
            <ToolbarTitle text="Users" />
          </ToolbarGroup>
          <ToolbarGroup>
            <AdminUserCreate onRefresh={onRefresh} />
            <RaisedButton label="Refresh" onClick={onRefresh}/>
          </ToolbarGroup>
        </Toolbar>
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>
                <TextField
                  id="emailSearch"
                  hintText="Email"
                  onChange={this.handleEmailSearchOnChange}
                  placeholder="Email"
                  value={this.state.selectedEmail}
                  style={styles.userEmail}
                />
              </TableHeaderColumn>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Slack Id</TableHeaderColumn>
              <TableHeaderColumn>Timezone</TableHeaderColumn>
              <TableHeaderColumn>Active</TableHeaderColumn>
              <TableHeaderColumn>Token</TableHeaderColumn>
              <TableHeaderColumn>Token Expiration</TableHeaderColumn>
              <TableHeaderColumn>Token Actions</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {adminUserList}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  AdminUsers = (onRefresh, users) => (
    <div>
      { this.AdminUserList(onRefresh, users) }
    </div>
  );

  handleRefreshClick(e){
    this.refreshList();
  }

  refreshList(){
    let self = this;
    restApi.get('/users').then(({data}) => {
      self.setState(Object.assign({}, self.state, { users: data, snackbarOpen: false }));
    }).catch((error) => {
      console.error("Get users failed: " + error);
    });
  }

  render() {
    return (
      <div>
        <AdminUserBreadCrumbs />
        { this.AdminUsers(this.handleRefreshClick, this.state.users) }
        <Snackbar
          open={this.state.snackbarOpen}
          message={this.state.snackBarMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackbarClose}
        />
      </div>
    );
  }
}

export default AdminUsersContainer;
