/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearSearchVip, simpleSearchVip } from "../api/vipApi";
import { ToolbarDynamic } from "../components/ToolbarDynamic";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import "ag-grid-community/dist/styles/ag-theme-bootstrap.css";

export const styles = {
  link: {
    color: 'rgb(0, 136, 206)'
  },
  toolbar: {
    marginTop: '20px'
  }
};

class VipResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      offset: 0,
      take: 10
    };
  }

  componentDidMount() {
    clearSearchVip();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.vipQuery) {
      this.setState({
        query: nextProps.vipQuery.query,
        offset: nextProps.vipQuery.offset,
        take: nextProps.vipQuery.take
      });
    }
    if (nextProps.error)
      alert(`Search Vip Customers failed: ${nextProps.error.message}`);
  }

  render() {
    let ColumnDefs = [
      {
        headerName: "Customer No", field: "customerNo", width: 130, headerHeight: 50, unSortIcon: true,
        cellRendererFramework: (params) => {
          return <Link target="_blank"
            to={"/vip/" + params.value}
            style={styles.link}>
            {params.value}
          </Link>
        }
      },
      { headerName: "Last Name", field: "lastName", width: 130, unSortIcon: true },
      { headerName: "First Name", field: "firstName", width: 130, unSortIcon: true },
      { headerName: "Email", field: "email", width: 210, unSortIcon: true },
      { headerName: "City", field: "city", width: 130, unSortIcon: true },
      { headerName: "State", field: "state", width: 110, unSortIcon: true },
      { headerName: "Business Name", field: "businessName", width: 180, unSortIcon: true },
      { headerName: "Last Application Date", field: "applicationDate", width: 210, unSortIcon: true },
      { headerName: "Status", field: "vipStatus", width: 130, unSortIcon: true }
    ];

    return (
      <div>
        <ToolbarDynamic title={'Search Results'} style={styles.toolbar} />
        <div
          className="ag-theme-bootstrap"
          style={{
            height: '580px',
          }}
        >
          <AgGridReact
            defaultColDef={{ sortable: true, resizable: true, cellStyle: {display: 'flex', justifyContent: 'left', alignItems: 'center'}}}
            columnDefs={[...ColumnDefs]}
            rowData={this.props.vipCustomers || []}
            pagination={true}
            paginationPageSize={this.state.take}
            suppressRowClickSelection={true}
            accentedSort={true}
            rowHeight={50}
            headerHeight={50}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    vipQuery: store.vipState.vipQuery,
    vipCustomers: store.vipState.vipCustomers,
    error: store.vipState.searchFailed
  };
};

export const VipSearchResults = connect(mapStateToProps)(VipResultsContainer);
