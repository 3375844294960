import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MainLayout from '../MainLayout';
import Home from '../home/Home';
import About from '../home/About';
import ProductImageSearchRoutes from '../product-image-search/ProductImageSearchRoutes';
import CouponRoutes from '../coupons/CouponRoutes';
import CouponManagerRoutes from '../coupons/CouponsManagerRoutes';
import NotLiveRoutes from '../notlivereport/NotLiveRoutes';
import MismatchedQtyRoutes from '../mismatchedqty/MismatchedQtyRoutes';
import AdminRoutes from '../admin/AdminRoutes';
import UserRoutes from '../updateProfile/UserRoutes';
import VipRoutes from '../vip/VipRoutes';
import SwaggerRoutes from '../swagger/SwaggerRoutes';
import TranslatorRoutes from '../translator/TranslatorRoutes';
import NotifyMeRoutes from '../notifyme/NotifyMeRoutes';
import IdManagementRoutes from '../id-management/IdManagementRoutes';
import AWSServicesRoutes from '../awsservices/AWSServicesRoutes';
import SEOLintRoutes from '../seolint/SEOLintRoutes';
import { PrivateRoute } from '../Components';
import Login from '../login/Login';
import LoginAzureAD from '../login/LoginAzureAD';
import Authorize from '../login/AuthorizeOkta';
import { awsEnvironment } from '../config';
import PingdomRoutes from '../pingdom/PingdomRoutes';
import CoverageRoutes from "../coverage/CoverageRoutes";
import OrderHistory from "../orderhistory/OrderHistory";
import PhotoStudio from "../photostudio/PhotoStudio";
import SandboxRoutes from '../sandboxes/components/SandboxRoutes';
import CloudwatchRoutes from '../cloudwatch/CloudwatchRoutes';
import DashboardRoutes from '../dashboard/DashboardRoutes';
import InventoryRoutes from '../inventory/InventoryRoutes';

export default (
  <BrowserRouter>
    <MainLayout>
      <Switch>
        <PrivateRoute exact path="/" component={Home}/>
        <PrivateRoute path="/about" component={About} />
        <Route path="/login" component={awsEnvironment === 'colmlocal' ? Login : LoginAzureAD}/>
        <Route path="/authorize/okta/:token" component={Authorize}/>
        <PrivateRoute path="/product-image-search" component={ProductImageSearchRoutes} />
        <PrivateRoute path="/coupons" component={CouponRoutes} />
        <PrivateRoute path="/couponsmanager" component={CouponManagerRoutes} />
        <PrivateRoute path="/notlivereport" component={NotLiveRoutes} />
        <PrivateRoute path="/mismatchedqty" component={MismatchedQtyRoutes} />
        <PrivateRoute path="/admin" component={AdminRoutes} />
        <PrivateRoute path="/updateProfile" component={UserRoutes} />
        <PrivateRoute path="/vip" component={VipRoutes} />
        <PrivateRoute path="/swagger" component={SwaggerRoutes} />
        <PrivateRoute path="/translator" component={TranslatorRoutes} />
        <PrivateRoute path="/notifyme" component={NotifyMeRoutes} />
        <PrivateRoute path="/id-management" component={IdManagementRoutes} />
        <PrivateRoute path="/awsservices" component={AWSServicesRoutes} />
        <PrivateRoute path="/seolint" component={SEOLintRoutes} />
        <PrivateRoute path="/pingdom" component={PingdomRoutes} />
        <PrivateRoute path="/coverage" component={CoverageRoutes} />
        <PrivateRoute path="/orderhistory" component={OrderHistory} />
        <PrivateRoute path="/photostudio" component={PhotoStudio} />
        <PrivateRoute path="/sandboxes" component={SandboxRoutes} />
        <PrivateRoute path="/cloudwatch" component={CloudwatchRoutes} />
        <PrivateRoute path="/dashboard" component={DashboardRoutes} />
        <PrivateRoute path="/inventory" component={InventoryRoutes} />
      </Switch>
    </MainLayout>
  </BrowserRouter>
);
