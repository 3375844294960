import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';

export class ConfirmDialog extends Component {

  constructor(props) {
    super();
  }
  render() {
    const actions = [
      <FlatButton
        label={this.props.cancelText || "Cancel"}
        primary={true}
        onClick={this.props.onCancel}
      />,
      <FlatButton
        label={this.props.confirmText || "OK"}
        primary={true}
        onClick={this.props.onConfirm}
      />,
    ];
    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={true}
        open={this.props.open}
      >
      {this.props.body}
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool.isRequired,
};
