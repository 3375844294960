import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Row, Col } from 'react-flexbox-grid';
import { restApi } from '../config';
import { Paper, RaisedButton, Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui';
import UserBreadCrumbs from "./UserBreadcrumbs";
import { currentUserId } from '../api/userApi';
import UserEdit from './UserEdit';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {grey500} from "material-ui/styles/colors";

const styles = {
  section: {
    marginBottom: '20px'
  },
  icon: {
    marginRight: '20px',
    color: grey500
  }
};

export const UserDetail = ({ onRefresh, user, editable, putUpdates, handleFieldUpdate }) => (
  <div>
    { user &&
    <div>
      <Row style={styles.section}>
        <Col xs={12}>
          <Paper zDepth={1}>
            <Toolbar>
              <ToolbarGroup firstChild={false}>
                <FontAwesomeIcon icon="user" size="2x" fixedWidth style={styles.icon}/>
                <ToolbarTitle text="My Information"/>
              </ToolbarGroup>
              <ToolbarGroup>
                { editable &&
                  <RaisedButton primary={true} label="Update" onClick={putUpdates} style={{margin: '10px 4px'}}/>
                }
                <RaisedButton label="Refresh" onClick={onRefresh} style={{margin: '10px 4px'}}/>
              </ToolbarGroup>
            </Toolbar>
            <UserEdit
              user={user}
              handleFieldUpdate={handleFieldUpdate}
              editable={editable}
              isNew={false}
            />
          </Paper>
        </Col>
      </Row>
    </div>
    }
  </div>
);

class UserContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { userId: props.match.params.id, myself: props.match.params.id === currentUserId() };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.putUpdates = this.putUpdates.bind(this);
    this.loadUser();
  }

  handleRefresh(e){
    this.loadUser();
  }

  handleFieldUpdate(e) {
    let key = e.target.id;
    let value = e.target.value;
    let newUser = Object.assign({}, this.state.user, { [key]: value });
    this.setState(Object.assign({}, this.state, { user: newUser }));
  }

  putUpdates() {
    let user = this.state.user;
    const email = user.email;
    const handleRefresh = this.handleRefresh;
    restApi.put('/updateProfile/' + email, {
      firstName: user.firstName,
      lastName: user.lastName,
      slackId: user.slackId,
      timezone: user.timezone,
    }).then((response) => {
      if (response.status === 200) {
        handleRefresh();
      }
    }).catch((error) => {
      console.log("Update my information failed: " + error);
    });
  }

  loadUser() {
    let self = this;
    restApi.get('/users/' + this.state.userId).then(({data}) => {
      self.setState(Object.assign({}, self.state, { user: data }));
    }).catch((error) => {
      console.log("Get my information failed: " + error);
    });
  }

  render() {
    return (
      <div>
        <UserBreadCrumbs email={this.state.userId} />
        <UserDetail
          onRefresh={this.handleRefresh}
          user={this.state.user}
          editable={this.state.myself}
          handleFieldUpdate={this.handleFieldUpdate}
          putUpdates={this.putUpdates}
        />
      </div>
    );
  }
}

UserContainer.propTypes = {
  router: PropTypes.object
};

export default UserContainer;
