import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Dialog, FlatButton, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { cloudwatchRestApi } from '../config';

export const styles = {
    actionsContainer: {
      margin: '10px 0'
    }
  };

export const validate = values => {
  const errors = {};
  if (!values.limit) {
    errors.limit = 'Please enter the number of Log Events to return';
  }
  else {
    // eslint-disable-next-line
    values.limit = parseInt(values.limit);
  }
  if (!values.logGroupName) {
    errors.logGroupName = 'Please enter the Log Group on which to perform the query';
  }
  if (!values.queryString) {
    errors.queryString = 'Please enter the Query String to use';
  }
  if (!values.startTime) {
    errors.startTime = 'Required';
  }
  if (!values.endTime) {
    errors.endTime = 'Required'
  }
  else if (values.startTime >= values.endTime) {
    errors.endTime = 'Please enter a date later than the start date'
  }
  return errors;
};

export class StartQueryForm extends Component {
  render() {
    const { handleSubmit, pristine, reset, submitting, submitSucceeded } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Row>
        {!submitSucceeded &&
          <Col xs={12} sm={12}>
            <Field
              name="logGroupName"
              component={TextField}
              id="logGroupName"
              floatingLabelText="Log Group Name*"
              fullWidth={true}
              disabled={submitting}
            />
          </Col>
        }
        {!submitSucceeded &&
          <Col xs={12} sm={12}>
            <Field
              name="queryString"
              component={TextField}
              id="queryString"
              floatingLabelText="Query String*"
              fullWidth={true}
              disabled={submitting}
            />
          </Col>
        }
        {!submitSucceeded &&
          <Col xs={12} sm={12}>
            <Field
              name="limit"
              component={TextField}
              id="limit"
              floatingLabelText="Number of Log Events*"
              fullWidth={true}
              disabled={submitting}
            />
          </Col>
        }
        {!submitSucceeded &&
          <Col xs={12} sm={12}>
            <Field
              name="startTime"
              component={TextField}
              id="startTime"
              label="Start Time"
              type="datetime-local"
              defaultValue="2020-05-24T10:30"
              disabled={submitting}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        }
        {!submitSucceeded &&
          <Col xs={12} sm={12}>
            <Field
              name="endTime"
              component={TextField}
              id="endTime"
              label="End Time*"
              type="datetime-local"
              defaultValue="2020-05-25T11:30"
              disabled={submitting}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        }
        <Col xs={12}>
            {submitSucceeded &&
              <p>
                Your query request has been received. We are processing the query.
              </p>
            }
            <div style={styles.actionsContainer}>
                {!submitSucceeded &&
                <span>
                  <FlatButton
                    label="Run Query"
                    type="submit"
                    primary={true}
                    disabled={submitting}
                  >
                    {submitting &&
                    <FontAwesomeIcon icon='sync' spin/>
                    }
                  </FlatButton>
                  <FlatButton
                    label="Clear Query"
                    disabled={pristine || submitting}
                    onClick={reset}
                  />
                </span>
                }
            <FlatButton
                label="Close"
                onClick={this.props.closeButton}
            />
            </div>
        </Col>
        </Row>
      </form>
    )
  };
}

StartQueryForm = reduxForm({
  form: 'startQueryForm',
  enableReinitialize : true,
  keepValues: true,
  initialValues: {
    endTime: null,
    limit: '',
    logGroupName: '',
    queryString: '',
    startTime: null
    },
    validate
})(StartQueryForm);

export default class StartQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
  }
  handleOpen = () => {
    this.setState({open: true});
  };
  handleClose = () => {
    this.setState({open: false});
  };
  submit = (requestBody) => (
    cloudwatchRestApi.post('/cloudwatch/startQuery', requestBody).then(({res,data}) => {
      console.log(data);
      window.getQueryRequest = data;
      this.props.onRefresh && this.props.onRefresh();
    }).catch((error) => {
      console.log("Wrong Query Request: " + error);
    })
  );

  render() {
    return (
      <div>
        <RaisedButton label="Start Query" primary={true} onClick={this.handleOpen} />
        <Dialog
          title="Create A New Query Request"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
        >
          <StartQueryForm onSubmit={this.submit} closeButton={this.handleClose} />
        </Dialog>
      </div>
    );
  }
}
