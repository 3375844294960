import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Row, Col } from 'react-flexbox-grid';
import { TextField, Card, CardHeader, CardText } from 'material-ui';

export class UserEdit extends Component {

  render() {
    return (
      <Row style={{ padding:"20px" }}>
        <Col xs={12} sm={this.props.isNew ? 12 : 4}>
          <Card>
            <CardHeader title="Ecommerce Hub Account" />
            <CardText>
              <Row>
                <Col xs={12}>
                  <TextField id="email"
                    defaultValue={this.props.user ? this.props.user.email : ""}
                    fullWidth={true}
                    floatingLabelText="Email Address"
                    underlineDisabledStyle={{border:'none'}}
                    onChange={this.props.handleFieldUpdate}
                    disabled={this.props.editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <TextField id="firstName"
                    defaultValue={this.props.user ? this.props.user.firstName : ""}
                    fullWidth={true}
                    floatingLabelText="First Name"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField id="lastName"
                    defaultValue={this.props.user ? this.props.user.lastName : ""}
                    fullWidth={true}
                    floatingLabelText="Last Name"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField id="slackId"
                    defaultValue={this.props.user ? this.props.user.slackId : ""}
                    fullWidth={true}
                    floatingLabelText="Slack Id"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <TextField id="timezone"
                    defaultValue={this.props.user ? this.props.user.timezone : ""}
                    fullWidth={true}
                    floatingLabelText="Timezone"
                    onChange={this.props.handleFieldUpdate}
                    underlineDisabledStyle={{border:'none'}}
                    disabled={!this.props.editable}
                  />
                </Col>
              </Row>
            </CardText>
          </Card>
        </Col>
      </Row>
    );
  }
}

UserEdit.propTypes = {
  user: PropTypes.object,
  isNew: PropTypes.bool,
  editable: PropTypes.bool,
  handleFieldUpdate: PropTypes.func
};

export default UserEdit;
