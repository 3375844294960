/* eslint-disable no-unused-vars */
export const columbiaBlue = '#0088ce';
export const columbiaDarkBlue = '#004b75';
export const columbiaWarmRed = '#e10e0e';
export const columbiaDryBlue = '#0d2b9b';
export const columbiaCoolTeal = '#0179a6';
export const columbiaProtectedGold = '#f19a03';
export const columbiaVividGold = '#ffbf50';
export const columbiaDarkGray = '#333333';
export const columbiaMediumGray = '#4f4f4f';
export const columbiaMediumDarkGray = '#767676';
export const columbiaLightGray = '#999999';
export const columbiaPaleGray = '#e5e5e5';
export const columbiaWhite = '#ffffff';
